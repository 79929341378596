import styled from "@emotion/styled"
import { Dialog } from "@mui/material"

const StyledAdmin = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledAdminTable = styled.div`
  display: flex;
  flex-direction: column;

  .filter-bar {
    background-color: white;
    padding: 15px 15px;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    width: 100%;
    width: calc(100% - 30px);
  }

  .role-section {
    display: flex;
    justify-content: flex-end;
  }

  .pagination-section {
    display: flex;
    justify-content: flex-end;
  }
`

const StyledAdminTableDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  .MuiPaper-root {
    width: 600px;
  }
`

const StyledAdminDetail = styled.div`
  display: flex;
  flex-direction: column;
  .MuiPaper-root {
    width: 600px;
  }
  .role-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .info {
    font-size: 12px;
    ul {
      margin: 0;
      padding: 24px 0 0 22px;
      color: #9e1b1b;
    }
    margin-bottom: 20px;
  }
`

export {
  StyledAdmin,
  StyledAdminTable,
  StyledAdminTableDialog,
  StyledAdminDetail,
}
