import React, { useState } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { addRole, updateRole } from "services/permission";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { StyledRoleDialog } from "./Role.style";

const RoleDlg = (props) => {
  const initRole = {
    name: "",
    description: "",
  };

  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { onSubmit } = props;
  const [role, setRole] = useState(initRole);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const openDlg = (editRole) => {
    reset();
    setRole(editRole || initRole);
    setIsEditMode(editRole ? true : false);
    setOpen(true);
  };

  const handleAction = async (data) => {
    setLoading(true);
    const response = isEditMode
      ? await updateRole({ ...data, id: role.id })
      : await addRole(data);
    if (response.rtnCode === 200) {
      enqueueSnackbar(isEditMode ? "Role Update" : "Create Role success", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(isEditMode ? "Update Role Fail" : "Create Role Fail", {
        variant: "warning",
      });
    }
    onSubmit();
    setLoading(false);
    handleClose();
  };

  const dlg = (
    <StyledRoleDialog open={open}>
      <DialogTitle>{isEditMode ? "Edit Role" : "Add New Role"}</DialogTitle>
      <form onSubmit={handleSubmit(handleAction)}>
        <DialogContent>
          <Box>
            <FormControl fullWidth margin="dense">
              <TextField
                label="Tag"
                variant="standard"
                fullWidth
                defaultValue={role.name}
                {...register("name", { required: true })}
                error={!!errors?.name}
                helperText={!!errors?.name ? "* Name is required" : " "}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth margin="dense">
              <TextField
                margin="dense"
                label="Description"
                variant="standard"
                rows={5}
                multiline
                defaultValue={role.description}
                {...register("description", { required: true })}
                error={!!errors?.description}
                helperText={
                  !!errors?.description ? "* Description is required" : ""
                }
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" size="small">
            {isEditMode ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </form>
    </StyledRoleDialog>
  );

  return [openDlg, dlg, loading];
};

export default RoleDlg;
