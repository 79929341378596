import { httpPost } from "utils/httpRequest";
import { PrimeType } from "constants/global";

const addPrime = async ({
  title,
  displayTitle,
  type,
  quoteContent,
  resourceId,
  thumbResourceId,
}) => {
  const resp = await httpPost("admin/prime/add", {
    title,
    displayTitle,
    type,
    quoteContent,
    resourceId,
    thumbResourceId,
  });

  return resp.data;
};

const getPrime = async (id) => {
  const response = await httpPost(`admin/prime/query/${id}`, {
    pageNo: 1,
    pageSize: 100,
    title: "",
  });
  return response.data.data;
};

const getPrimes = async () => {
  const response = await httpPost("admin/prime/list", {
    pageNo: 1,
    pageSize: 9999,
    title: "",
  });
  return response.data;
};

const deletePrime = async (id) => {
  const response = await httpPost(`admin/prime/delete/${id}`);
  return response.data;
};

const exchangeOrder = async (updateIndexArray) => {
  const response = await httpPost("admin/prime/exchangeIndex", {
    updateIndexArray,
  });
  return response;
};

const updatePrime = async ({
  id,
  title,
  displayTitle,
  type,
  quoteContent,
  resourceId,
  thumbResourceId,
}) => {
  const response = await httpPost("admin/prime/edit", {
    id,
    title,
    displayTitle,
    type,
    quoteContent: type === PrimeType.Quote.value ? quoteContent : "",
    resourceId: type === PrimeType.Quote.value ? null : resourceId,
    thumbResourceId: thumbResourceId,
  });
  return response.data;
};
export {
  addPrime,
  deletePrime,
  getPrime,
  getPrimes,
  updatePrime,
  exchangeOrder,
};
