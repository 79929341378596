import React, { useState } from "react"
import { TextField, Button } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"
import Alert from "@mui/material/Alert"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import { useSnackbar } from "notistack"
import { updateMemberPassword, deleteMember } from "services/member"
import { useForm } from "react-hook-form"
import {
  deactivateMember,
  reactivateMember,
  removeFreeTrial,
} from "services/member"
import { verifyResponse, httpUrlRequest } from "utils/httpRequest"
import {
  MEMBER_STATUS_INDEX,
  TRIAL_STATUS_INDEX,
  TRIAL_STATUS,
} from "constants/global"
import MemberEmailUpdateDlg from "./MemberEmailUpdateDlg"
import { UseConfirmDialog } from "components/customHooks"
import { StyledMemberSubscriptions } from "./Member.style"

const MemberAccount = ({
  member,
  access,
  refreshMember,
  refreshSearch,
  onClose,
}) => {
  const [loading, setLoading] = useState(false)
  const [ConfirmDialog, openConfirmDialog] = UseConfirmDialog({
    title: "Account Action",
    description: "Are you sure deactivate this user?",
    agreeText: "Yes",
    disagreeText: "No",
  })

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm()

  const { enqueueSnackbar } = useSnackbar()

  const [
    openMemberEmailUpdateDlg,
    memberEmailUpdateDlg,
    memberEmailUpdateLoading,
  ] = MemberEmailUpdateDlg({ refreshMemberDetail: refreshMember })

  const isEmailRegistrationExist = () => {
    return member.subId && member.subId !== "" && member.subId.includes("auth0")
      ? true
      : false
  }

  const isEmailRegistrationOnly = () => {
    return member.subId &&
      member.subId !== "" &&
      member.subId.includes("auth0") &&
      member.appleThirdId === null &&
      member.googleThirdId === null &&
      member.facebookThirdId === null
      ? true
      : false
  }

  const delMember = async (memberId) => {
    setLoading(true)
    const response = await deleteMember({ memberId: memberId })
    setLoading(false)
    verifyResponse(response)

    if (response.rtnCode === 200) {
      enqueueSnackbar("Remove member account success.", {
        variant: "success",
      })
      refreshSearch()
      setTimeout(() => {
        onClose()
      }, 600)
    } else {
      enqueueSnackbar(`Fail to delete member: ${response.message}`, {
        variant: "warning",
      })
    }
  }

  const handleAction = async (data) => {
    await changeMemberPassword(data.password)
    reset()
  }

  const sendRequestEmailForPasswordReset = async () => {
    const url = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password?email=${member.email}&connection=${process.env.REACT_APP_CLIENT_AUTH0_DB}`

    setLoading(true)
    const response = await httpUrlRequest(url)
    setLoading(false)

    if (response.status === 200) {
      enqueueSnackbar(response.data, {
        variant: "success",
      })
    } else {
      enqueueSnackbar("Fail to send request", {
        variant: "warning",
      })
    }
  }

  const openUpdateEmailModal = () => {
    openMemberEmailUpdateDlg(member.userId)
  }

  const changeMemberPassword = async (password) => {
    setLoading(true)
    const response = await updateMemberPassword({
      memberId: member.userId,
      newPassword: password,
    })
    setLoading(false)
    verifyResponse(response)

    if (response.rtnCode === 200) {
      enqueueSnackbar("Reset Password Success", {
        variant: "success",
      })
    } else {
      enqueueSnackbar(response.message, {
        variant: "warning",
      })
    }
  }

  const deactivateMemberAction = async (memberId) => {
    setLoading(true)
    const response = await deactivateMember({ id: memberId })
    setLoading(false)
    verifyResponse(response)
    refreshMember()
  }

  const reactivateMemberAction = async (memberId) => {
    setLoading(true)
    const response = await reactivateMember({ id: memberId })
    setLoading(false)
    verifyResponse(response)
    refreshMember()
  }

  const endFreeTrial = async (memberId) => {
    setLoading(true)
    const response = await removeFreeTrial(memberId)
    verifyResponse(response)
    setLoading(false)
  }

  if (loading) {
    return (
      <Card className="card" sx={{ marginBottom: "10px" }}>
        <CardContent>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            <CircularProgress />
          </Typography>
        </CardContent>
      </Card>
    )
  }

  return (
    <StyledMemberSubscriptions>
      <Card className="card" sx={{ marginBottom: "10px" }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Email
          </Typography>
          <Typography variant="p" component="div">
            {member.email}
          </Typography>
          <p></p>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Account Modifications
          </Typography>
          {member && isEmailRegistrationExist() && (
            <Typography component="div" className="button-block">
              <Button
                color="success"
                variant="contained"
                onClick={sendRequestEmailForPasswordReset}
              >
                Request Password Reset Email for User
              </Button>
            </Typography>
          )}

          {member && isEmailRegistrationOnly() && (
            <Typography component="div" className="button-block">
              <Button
                color="success"
                variant="contained"
                onClick={openUpdateEmailModal}
              >
                Click here to update account email
              </Button>
            </Typography>
          )}

          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Member Nickname:
          </Typography>
          <Typography variant="body2">{member.nickName}</Typography>

          <Typography
            sx={{ fontSize: 16, mb: 1.5, marginTop: 3, marginBottom: "30px" }}
            color="text.secondary"
          >
            {member.isActive === MEMBER_STATUS_INDEX.ACTIVATE ? (
              <>
                <strong style={{ color: "red", fontSize: "26px" }}>
                  Warning:
                </strong>{" "}
                Once the account was deactivated, the member cannot use the
                functions of the system normally.
              </>
            ) : (
              <>
                Member account has been deactivated, you can reactivate the
                account here.
              </>
            )}
          </Typography>

          {access.memberDeactivateAccess &&
            member.isActive === MEMBER_STATUS_INDEX.ACTIVATE && (
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  openConfirmDialog(() => {
                    deactivateMemberAction(member.userId)
                  })
                }}
              >
                Deactivate User
              </Button>
            )}

          {access.memberReactivateAccess &&
            member.isActive === MEMBER_STATUS_INDEX.DEACTIVATE && (
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  openConfirmDialog(() => {
                    reactivateMemberAction(member.userId)
                  }, "Are you sure reactivate this user?")
                }}
              >
                Reactivate User
              </Button>
            )}
        </CardContent>
      </Card>

      {member && isEmailRegistrationExist() && (
        <Card className="card" sx={{ marginBottom: "10px" }}>
          <CardContent>
            <Typography variant="h5" component="div">
              Password Reset
            </Typography>

            {(errors.password || errors.confirmPassword) && (
              <Alert severity="warning">
                <ul>
                  {errors.password && <li>{errors.password.message}</li>}
                  {errors.confirmPassword && (
                    <li>{errors.confirmPassword.message}</li>
                  )}
                </ul>
              </Alert>
            )}

            <form
              onSubmit={handleSubmit(handleAction)}
              className="reset-password-form"
            >
              <TextField
                type="password"
                label="Password"
                {...register("password", { required: "Password is required" })}
              />
              <TextField
                type="password"
                label="Confirm Password"
                {...register("confirmPassword", {
                  required: "Confirm password is required",
                  validate: (val) => {
                    if (watch("password") !== val) {
                      return "Your passwords do no match"
                    }
                  },
                })}
              />
              <Button type="submit" size="large" variant="contained">
                Reset
              </Button>
            </form>
          </CardContent>
        </Card>
      )}

      <Card className="card" sx={{ marginBottom: "10px" }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Free Trial Status:
          </Typography>
          <Typography variant="h5" component="div">
            {TRIAL_STATUS[member.trialStatus].text}
          </Typography>

          <Typography variant="h5" gutterBottom></Typography>

          {access.memberRemoveFreeTrial &&
            member.trialStatus !== TRIAL_STATUS_INDEX.EXPIRED && (
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  openConfirmDialog(() => {
                    endFreeTrial(member.userId)
                  }, "Are you sure remove the free trial?")
                }}
              >
                Remove Free Trial
              </Button>
            )}
          {memberEmailUpdateDlg}
        </CardContent>
      </Card>

      <Card className="card" sx={{ marginBottom: "10px" }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Delete Account
          </Typography>
          <Typography style={{ margin: "20px 0" }}>
            <strong style={{ color: "red", fontSize: "26px" }}>Warning:</strong>{" "}
            After the account is removed, all user data will be permanently
            deleted.
          </Typography>

          <Typography variant="h5" gutterBottom></Typography>

          {access.memberRemoveFreeTrial &&
            member.trialStatus !== TRIAL_STATUS_INDEX.EXPIRED && (
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  openConfirmDialog(() => {
                    delMember(member.userId)
                  }, "Are you sure want to delete this member account.")
                }}
              >
                Delete member account
              </Button>
            )}
          {memberEmailUpdateDlg}
        </CardContent>
      </Card>

      {ConfirmDialog}
    </StyledMemberSubscriptions>
  )
}

export default MemberAccount
