import React, { useState, useEffect } from "react"
import PageHeading from "components/common/LayoutComponents/PageHeading"
import PageContent from "components/common/LayoutComponents/PageContent"
import InputLabel from "@mui/material/InputLabel"
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker"
import MenuItem from "@mui/material/MenuItem"
import TextField from "@mui/material/TextField"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import Button from "@mui/material/Button"
import moment from "moment"
import LoadingScreen from "components/common/LoadingScreen"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Pagination from "@mui/material/Pagination"
import { verifyResponse } from "utils/httpRequest"
import { getMarketoSyncLog, syncToMarketo } from "services/stat"
import { UseConfirmDialog } from "components/customHooks"
import { useSnackbar } from "notistack"
import {
  StyledMarketPanel,
  StyledSearch,
  StyledSearchBar,
  StyledResultTable,
  StyledActionPanel,
} from "./Marketo.style"

const Marketo = () => {
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)
  const [logs, setLogs] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(7)
  const [searchResultInfo, setSearchResultInfo] = useState({
    currentPageNo: 1,
    hasNext: 1,
    pageSize: 1,
    pages: 1,
    total: 0,
  })
  const [dateRange, setDateRange] = React.useState([
    moment().add(-7, "days"),
    moment(),
  ])

  const [ConfirmDialog, openConfirmDialog] = UseConfirmDialog({
    title: "Action Confirm",
    description: "Are you sure want to Sync to Marketo ?",
    agreeText: "Yes",
    disagreeText: "No",
  })

  const uploadToMarketo = async () => {
    setLoading(true)
    const response = await syncToMarketo({ memberIdList: null })
    verifyResponse(response)
    setLoading(false)
  }

  const searchAction = async () => {
    try {
      setLoading(true)
      const response = await getMarketoSyncLog({
        pageNo: page,
        pageSize,
        startAt: dateRange[0],
        endAt: dateRange[1],
      })
      verifyResponse(response)

      if (response.rtnCode === 200) {
        setLogs(response.data.list)
        setSearchResultInfo({
          currentPageNo: response.data.currentPageNo,
          hasNext: response.data.hasNext,
          pageSize: response.data.pageSize,
          pages: response.data.pages,
          total: response.data.total,
        })
      } else {
        enqueueSnackbar("Fail to search Marketo process logs", {
          variant: "warning",
        })
      }
    } catch (e) {
      enqueueSnackbar("Fail to search Marketo process logs", {
        variant: "warning",
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    searchAction()
  }, [page])

  return (
    <PageContent>
      <StyledMarketPanel>
        <PageHeading>Marketo</PageHeading>

        <div>
          <StyledActionPanel>
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                openConfirmDialog(() => {
                  // trigger sync worker to Marketo
                  uploadToMarketo()
                })
              }}
            >
              Sync All Users to Marketo
            </Button>
          </StyledActionPanel>
          <StyledSearch>
            <StyledSearchBar>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  className="media-search-range-picker"
                  startText="Date From"
                  endText="Date To"
                  value={dateRange}
                  onOpen={() => {
                    setTimeout(() => {
                      ;[
                        ...document
                          .querySelector(".media-search-range-picker")
                          .querySelectorAll("div"),
                      ]
                        .filter(
                          (a) => a.textContent === "MUI X: Missing license key"
                        )
                        .forEach((a) => a.remove())
                    }, 0)
                  }}
                  onChange={(newValue) => {
                    setDateRange(newValue)
                  }}
                  renderInput={(startProps, endProps) => (
                    <>
                      <TextField {...startProps} className="time-input" />
                      <TextField {...endProps} className="time-input" />
                    </>
                  )}
                />
              </LocalizationProvider>

              <FormControl style={{ width: "100px" }}>
                <InputLabel id="media-page-size-select-label">
                  Page Size
                </InputLabel>
                <Select
                  labelId="redeem-code-page-size-select-label"
                  id="redeem-code-page-size-select"
                  value={pageSize}
                  label="Page Size"
                  onChange={(e) => {
                    setPageSize(e.target.value)
                  }}
                >
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <Button
                className="search-btn"
                variant="contained"
                size="large"
                onClick={() => {
                  setPage(1)
                  searchAction()
                }}
              >
                Search
              </Button>
            </StyledSearchBar>
          </StyledSearch>
        </div>

        <StyledResultTable>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "100px" }}>Job ID</TableCell>
                  <TableCell sx={{ width: "120px", textAlign: "center" }}>
                    Effected Records
                  </TableCell>
                  <TableCell sx={{ width: "120px", textAlign: "center" }}>
                    Status
                  </TableCell>
                  <TableCell sx={{ width: "200px" }}>Created Time</TableCell>
                  <TableCell>Trigger By</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {logs.map((row) => (
                  <TableRow
                    key={`marketo-log-item-${row.id}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row.syncNum}
                    </TableCell>
                    <TableCell sx={{ width: "120px", textAlign: "center" }}>
                      {row.execStatus}
                    </TableCell>
                    <TableCell>{moment(row.createdAt).format("lll")}</TableCell>
                    <TableCell>
                      {row.syncType !== 0 ? row.syncUserEmail : "System"}
                    </TableCell>
                  </TableRow>
                ))}

                {searchResultInfo && (
                  <TableRow key={"pg"}>
                    <TableCell colSpan={7}>
                      <div className="pagination-section">
                        <div>Total Record {searchResultInfo.total} Found</div>
                        <Pagination
                          count={searchResultInfo.pages}
                          page={searchResultInfo.currentPageNo}
                          onChange={(event, value) => {
                            setPage(value)
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledResultTable>
      </StyledMarketPanel>
      <LoadingScreen loading={loading} />
      {ConfirmDialog}
    </PageContent>
  )
}

export default Marketo
