import React, { useRef, useState, useEffect, useCallback } from "react";
import Button from "@mui/material/Button";
import PageHeading from "components/common/LayoutComponents/PageHeading";
import PageContent from "components/common/LayoutComponents/PageContent";
import { searchMembers } from "services/member";
import { verifyResponse } from "utils/httpRequest";
import LoadingScreen from "components/common/LoadingScreen";
import { PERMISSIONS } from "constants/permission";
import { UsePermissionVerification } from "components/customHooks";
import MemberTable from "./MemberTable";
import MemberDetail from "./MemberDetail";
import MemberSearch from "./MemberSearch";
import { StyledMember } from "./Member.style";

const Member = () => {
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [searchResultInfo, setSearchResultInfo] = useState(null);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [searchOptions, setSearchOptions] = useState({});
  const firstUpdate = useRef(true);

  const AccessControl = UsePermissionVerification();

  const doSearch = useCallback(async () => {
    setLoading(true);
    let response = null;
    try {
      response = await searchMembers(searchOptions);
      if (response) {
        // Process search response
        verifyResponse(response);
        setMembers(response.data.list);
        setSearchResultInfo({
          currentPageNo: response.data.currentPageNo,
          hasNext: response.data.hasNext,
          pageSize: response.data.pageSize,
          pages: response.data.pages,
          total: response.data.total,
        });
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [searchOptions]);

  const closeMemberDetail = () => {
    setSelectedMemberId(null);
  };

  const openMemberDetail = (memberId) => {
    console.log("openMemberDetail = ", memberId);
    setSelectedMemberId(memberId);
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    doSearch();
  }, [searchOptions?.pageNo, doSearch]);

  useEffect(() => {
    AccessControl.SetAccess({
      ...AccessControl.Access,
      listMemberAccess: AccessControl.VerifyAccess([
        PERMISSIONS.MEMBER.MEMBER_LIST,
      ]),
      editMemberAccess: AccessControl.VerifyAccess([
        PERMISSIONS.MEMBER.MEMBER_EDIT,
      ]),
      memberDeactivateAccess: AccessControl.VerifyAccess([
        PERMISSIONS.MEMBER.MEMBER_DEACTIVATE,
      ]),
      memberReactivateAccess: AccessControl.VerifyAccess([
        PERMISSIONS.MEMBER.MEMBER_REACTIVATE,
      ]),
      memberRemoveFreeTrial: AccessControl.VerifyAccess([
        PERMISSIONS.MEMBER.MEMBER_REMOVE_FREE_TRIAL,
      ]),
    });
  }, [AccessControl.UserPermissions]);

  useEffect(() => {
    if (selectedMemberId !== null) {
      setSelectedMemberId(
        members.find((member) => member.id === selectedMemberId).id
      );
    }
  }, [members]);

  return (
    <PageContent>
      <StyledMember>
        <PageHeading>
          <div>Members</div>
          <div className="actions">
            {selectedMemberId && (
              <Button
                style={{ width: "100px" }}
                onClick={() => {
                  closeMemberDetail();
                }}
                variant="outlined"
              >
                Close
              </Button>
            )}
          </div>
        </PageHeading>
        <div>
          {selectedMemberId ? (
            <MemberDetail
              memberId={selectedMemberId}
              refreshSearch={doSearch}
              onClose={closeMemberDetail}
              access={AccessControl.Access}
            />
          ) : (
            <>
              <MemberSearch
                searchResultInfo={searchResultInfo}
                searchOptions={searchOptions}
                setSearchOptions={setSearchOptions}
              />
              <MemberTable
                members={members}
                access={AccessControl.Access}
                searchOptions={searchOptions}
                setSearchOptions={setSearchOptions}
                searchResultInfo={searchResultInfo}
                openMemberDetail={openMemberDetail}
              />
            </>
          )}
        </div>
      </StyledMember>

      <LoadingScreen loading={loading} />
    </PageContent>
  );
};

export default Member;
