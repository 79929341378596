import styled from "@emotion/styled"
import { Dialog } from "@mui/material"

const InnerciseSelectorWrapper = styled(Dialog)`
  display: flex;
  flex-direction: column;
  .MuiPaper-root {
    width: 100%;
    max-width: 900px;
    min-height: 700px;
  }
  .error {
    font-size: 0.75rem;
    color: #d32f2f;
    margin-top: 3px;
  }

  .search-bar {
    //
    display: flex;
    flex-direction: row;

    .search-text {
      width: 100%;
    }

    .MuiButton-root {
      padding-left: 30px;
      padding-right: 30px;
    }

    button {
      margin-left: 10px;
    }
  }

  .loading-icon {
    margin-top: 20px;
  }

  .search-results {
    .result-item {
      display: flex;
      flex-direction: row;
      border: 1px solid gray;
      margin: 5px 0;
      padding: 6px 10px;
      cursor: pointer;

      .thumb {
        max-width: 180px;
      }

      &.selected {
        background-color: #eee;
      }

      .content {
        display: flex;
        flex-direction: column;
        padding: 5px;
      }
    }
  }
`

export { InnerciseSelectorWrapper }
