import styled from "@emotion/styled";

const StyledMedia = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  .item-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .item {
      margin: 10px;
      .count-label {
        font-size: 26px;
        font-weight: 500;
        color: #3e3508;
      }
    }
  }
`;

export { StyledMedia };
