import React from "react"
import PropTypes from "prop-types"
import { WithContext as ReactTags } from "react-tag-input"
import { StyledTagsInput } from "./TagsInput.style"

const TagsInput = ({ tags, setTags }) => {
  const suggestions = [
    {
      id: "abc",
      text: "abc",
    },
    {
      id: "cdf",
      text: "cdf",
    },
    {
      id: "feg",
      text: "feg",
    },
  ]

  const KeyCodes = {
    comma: 188,
    enter: 13,
  }

  const delimiters = [KeyCodes.comma, KeyCodes.enter]

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i))
  }

  const handleAddition = (tag) => {
    setTags([...tags, tag])
  }

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice()

    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)

    // re-render
    setTags(newTags)
  }

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked")
  }

  return (
    <StyledTagsInput>
      <ReactTags
        tags={tags}
        suggestions={suggestions}
        delimiters={delimiters}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        handleTagClick={handleTagClick}
        inputFieldPosition="inline"
        autocomplete
        placeholder="Add New Tag Here ..."
      />
    </StyledTagsInput>
  )
}

export default TagsInput
