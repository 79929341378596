import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { getRoleDetail, updateRolePermissions } from "services/permission";
import { verifyResponse } from "utils/httpRequest";
import { StyledRoleDetail } from "./Role.style";

const RoleDetail = ({ role, access, permissionByModule, closeRoleDetail }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, setValue, watch } = useForm();
  const [loading, setLoading] = useState(false);

  const loadRoleDetail = async () => {
    setLoading(true);
    const response = await getRoleDetail(role.id);
    setLoading(false);
    verifyResponse(response);

    const permissionIdList = response.data.permissionList.map(
      (permission) => permission.id
    );

    for (const module of permissionByModule) {
      for (const permission of module.permissionList) {
        setValue(
          `permission-${permission.id}`,
          permissionIdList.includes(permission.id)
        );
      }
    }
  };

  const changeModuleCheckedStatus = (module, status) => {
    const idList = module.permissionList.map((permission) => permission.id);
    for (const permissionId of idList) {
      setValue(`permission-${permissionId}`, status);
    }
  };

  useEffect(() => {
    role && loadRoleDetail();
  }, [role]);

  const handleAction = async (data) => {
    const arr = Object.keys(data).map((key) => ({ key, value: data[key] }));
    const permissions = arr
      .filter((o) => o.value)
      .map((o) => parseInt(o.key.split("-")[1]));
    setLoading(true);
    const response = await updateRolePermissions({
      roleId: role.id,
      permissionIdList: permissions,
    });
    setLoading(false);
    verifyResponse(response);
    if (response.rtnCode === 200) {
      enqueueSnackbar("Role Permissions Updated", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Role Permissions Update Fail", {
        variant: "warning",
      });
    }
  };

  const content =
    !loading && role && permissionByModule && access.getDetailByIdRoleAccess ? (
      <StyledRoleDetail>
        <Card sx={{ maxWidth: 500 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Role Name:
            </Typography>
            <Typography variant="h5" component="div">
              {role.name}
            </Typography>
            <Typography
              sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
              color="text.secondary"
            >
              Role Description:
            </Typography>
            <Typography variant="body2">{role.description}</Typography>
          </CardContent>
        </Card>

        <Card sx={{ marginTop: "20px" }}>
          <CardContent>
            <form onSubmit={handleSubmit(handleAction)}>
              <div className="permission-header">
                <Typography variant="h5" component="div">
                  Permissions :
                </Typography>
                <div>
                  {access.setPermissionRoleAccess && (
                    <Button type="submit" variant="contained" size="small">
                      Save
                    </Button>
                  )}
                </div>
              </div>
              {permissionByModule.map((moduleItem) => (
                <div key={`module-item-${moduleItem.module}`}>
                  <Typography
                    sx={{ fontSize: 16, mb: 1.5, marginTop: 3 }}
                    color="text.secondary"
                  >
                    {moduleItem.module}

                    <FormControlLabel
                      className="select-all-checkbox"
                      control={
                        <Checkbox
                          size="small"
                          onChange={(e) => {
                            changeModuleCheckedStatus(
                              moduleItem,
                              e.target.checked
                            );
                          }}
                        />
                      }
                      label="Select All"
                    />
                  </Typography>
                  <div className="permission-list ">
                    {moduleItem.permissionList.map(
                      (permission) => (
                        <FormControlLabel
                          checked={watch(`permission-${permission.id}`)}
                          control={
                            <Checkbox
                              size="small"
                              onChange={(e) => {
                                setValue(
                                  `permission-${permission.id}`,
                                  e.target.checked
                                );
                              }}
                            />
                          }
                          label={permission.description}
                          key={`permission-item-${permission.id}`}
                          {...register(`permission-${permission.id}`)}
                        />
                      )
                    )}
                  </div>
                </div>
              ))}
            </form>
          </CardContent>
        </Card>
      </StyledRoleDetail>
    ) : (
      access.getDetailByIdRoleAccess && <div>loading ...</div>
    );

  return [content, loading];
};

export default RoleDetail;
