import React from "react";
import styled from "@emotion/styled";

const PageContentWrapper = styled.div`
  padding: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
`;

const PageContent = (props) => {
  return <PageContentWrapper>{props.children}</PageContentWrapper>;
};

export default PageContent;
