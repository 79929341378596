import React from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { SESSION_VARIABLES } from "constants/system";
import { StyledProfileInfo } from "./Profile.style";

const ProfileInfo = () => {
  const profileImg = localStorage.getItem(
    SESSION_VARIABLES.ADMIN_USER_PROFILE_IMG
  );
  const adminUserEmail = localStorage.getItem(
    SESSION_VARIABLES.ADMIN_USER_EMAIL
  );
  const adminUserName = localStorage.getItem(SESSION_VARIABLES.ADMIN_USER_NAME);

  return (
    <StyledProfileInfo>
      <h3>Basic Info</h3>
      <div className="basic-info">
        <Avatar
          alt={adminUserName}
          sx={{ width: 70, height: 70 }}
          src={profileImg}
        />

        <Stack direction="column" spacing={1}>
          <div>{adminUserName}</div>
          <div>{adminUserEmail}</div>
        </Stack>
      </div>
    </StyledProfileInfo>
  );
};

export default ProfileInfo;
