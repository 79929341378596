import React, { useState, useEffect } from "react"
import Button from "@mui/material/Button"
import PageHeading from "components/common/LayoutComponents/PageHeading"
import PageContent from "components/common/LayoutComponents/PageContent"
import LoadingScreen from "components/common/LoadingScreen"
import { PERMISSIONS } from "constants/permission"
import { UsePermissionVerification } from "components/customHooks"
import { searchAdminUser, getAllRoles } from "services/admin"
import { verifyResponse } from "utils/httpRequest"
import AdminTable from "./AdminTable"
import AdminDlg from "./AdminDlg"
import AdminDetail from "./AdminDetail"
import { StyledAdmin } from "./Admin.style"

const Admin = () => {
  const [adminUsers, setAdminUsers] = useState([])
  const [roles, setRoles] = useState([])
  const [adminUserLoading, setAdminUserLoading] = useState(false)
  const [rolesLoading, setRolesLoading] = useState(false)
  const [selectedAdmin, setSelectedAdmin] = useState(null)

  const AccessControl = UsePermissionVerification()

  useEffect(() => {
    AccessControl.SetAccess({
      ...AccessControl.Access,
      listAdminUserAccess: AccessControl.VerifyAccess([
        PERMISSIONS.ADMIN_USER.ADMIN_USER_LIST,
      ]),
      addAdminUserAccess: AccessControl.VerifyAccess([
        PERMISSIONS.ADMIN_USER.ADMIN_USER_ADD,
      ]),
      getDetailAdminUserAccess: AccessControl.VerifyAccess([
        PERMISSIONS.ADMIN_USER.ADMIN_USER_DETAIL,
      ]),
      setRoleAdminUserAccess: AccessControl.VerifyAccess([
        PERMISSIONS.ADMIN_USER.ADMIN_USER_SET_ROLE,
      ]),
      resetPasswordOtherAdminUserAccess: AccessControl.VerifyAccess([
        PERMISSIONS.ADMIN_USER.ADMIN_USER_RESET_PASSWORD_OTHER,
      ]),
    })
  }, [AccessControl.UserPermissions])

  const [openAdminDialog, adminDialog, adminDialogLoading] = AdminDlg({
    onSubmit: () => {
      loadAdminUsers()
    },
  })

  const closeAdminDetail = () => {
    setSelectedAdmin(null)
  }

  const [adminDetailContent, adminDetailLoading] = AdminDetail({
    admin: selectedAdmin,
    roles,
    access: AccessControl.Access,
    closeDetail: () => {
      closeAdminDetail()
      loadAdminUsers()
    },
  })

  const showAdminDetail = (adminItem) => {
    setSelectedAdmin(adminItem)
  }

  const loadAdminUsers = async () => {
    setAdminUserLoading(true)
    const response = await searchAdminUser({ pageNo: 1, pageSize: 10000 })
    setAdminUserLoading(false)
    verifyResponse(response)
    setAdminUsers(response.data.list)
  }

  const loadAllRoles = async () => {
    setRolesLoading(true)
    const response = await getAllRoles()
    setRolesLoading(false)
    verifyResponse(response)
    setRoles(response.data)
  }

  useEffect(() => {
    loadAdminUsers()
    loadAllRoles()
  }, [])

  return (
    <PageContent>
      <StyledAdmin>
        <PageHeading>
          <div>Admin User</div>
          <div className="actions">
            {selectedAdmin && <Button onClick={closeAdminDetail}>Back</Button>}
            {AccessControl.Access.addAdminUserAccess && (
              <Button
                variant="contained"
                onClick={() => {
                  openAdminDialog()
                }}
              >
                Add User
              </Button>
            )}
          </div>
        </PageHeading>

        {selectedAdmin && adminDetailContent}

        {AccessControl.Access.listAdminUserAccess && !selectedAdmin && (
          <AdminTable
            adminUsers={adminUsers}
            access={AccessControl.Access}
            openAdminDialog={openAdminDialog}
            showAdminDetail={showAdminDetail}
          />
        )}
      </StyledAdmin>

      {adminDialog}
      <LoadingScreen
        loading={
          adminUserLoading ||
          adminDialogLoading ||
          adminDetailLoading ||
          rolesLoading
        }
      />
    </PageContent>
  )
}

export default Admin
