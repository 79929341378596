import React, { useState, useEffect } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Pagination from "@mui/material/Pagination"
import { StyledMemberHistory } from "./Member.style"
import moment from "moment"

const MemberHistory = ({ member }) => {
  const [emailChangeHistory, setEmailChangeHistory] = useState([])
  const [displayEmailHistory, setDisplayEmailHistory] = useState([])
  // const [numPerPage, setNumPerPage] = useState(8)
  const [page, setPage] = useState(1)

  const numPerPage = 8

  useEffect(() => {
    setEmailChangeHistory(member.emailUpdList)
  }, [])

  useEffect(() => {
    setDisplayEmailHistory(
      emailChangeHistory.slice((page - 1) * numPerPage, page * numPerPage)
    )
  }, [page, emailChangeHistory, numPerPage])

  return (
    <StyledMemberHistory>
      <TableContainer component={Paper}>
        <div className="table-heading">Email Update History</div>
        <Table sx={{ minWidth: 650 }} aria-label="role table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Operator</TableCell>
              <TableCell>Email From</TableCell>
              <TableCell>To</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayEmailHistory.map((record) => {
              return (
                <TableRow
                  key={`role-item-${record.id}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {moment(record.createdAtStamp).format("LLL")}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {record.adminNickName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {record.emailBefore}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {record.emailAfter}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
          <TableRow key={"pg"}>
            <TableCell colSpan={1}>
              Total Record: <strong>{emailChangeHistory.length}</strong>
            </TableCell>
            <TableCell colSpan={7}>
              <div className="pagination-section">
                <Pagination
                  // rowsPerPageOptions={[5, 10, 25]}
                  count={Math.ceil(emailChangeHistory.length / numPerPage)}
                  page={page}
                  onChange={(event, value) => {
                    setPage(value)
                  }}
                />
              </div>
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </StyledMemberHistory>
  )
}

export default MemberHistory
