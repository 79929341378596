import { httpPost } from "utils/httpRequest"

const listStartResource = async ({ pageNo, pageSize, dataType, dataTitle }) => {
  const resp = await httpPost("admin/startResource/list", {
    pageNo,
    pageSize,
    dataType,
    dataTitle,
  })

  return resp.data
}

const addStartResource = async ({ dataType, dataId, isHome, id }) => {
  const resp = await httpPost("admin/startResource/add", {
    dataType,
    dataId,
    isHome,
    id,
  })

  return resp.data
}

const editStartResource = async ({ dataType, dataId, isHome, id }) => {
  const resp = await httpPost("admin/startResource/edit", {
    dataType,
    dataId,
    isHome,
    id,
  })
  return resp.data
}

const deleteStartResource = async ({ id }) => {
  const resp = await httpPost("admin/startResource/delete", {
    id,
  })
  return resp.data
}

const exchangeStartResourceIndex = async (updateIndexArray) => {
  const resp = await httpPost("admin/startResource/exchangeIndex", {
    updateIndexArray,
  })
  return resp.data
}

export {
  listStartResource,
  addStartResource,
  editStartResource,
  deleteStartResource,
  exchangeStartResourceIndex,
}
