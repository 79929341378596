import { httpPost } from "utils/httpRequest";

const getFaqs = async () => {
  const resp = await httpPost("admin/faq/list", {
    pageNo: 1,
    pageSize: 10000,
  });
  return resp.data;
};

const addFaq = async ({ title, answer }) => {
  const resp = await httpPost("admin/faq/add", {
    title,
    answer,
  });
  return resp.data;
};

const editFaq = async ({ id, title, answer }) => {
  const resp = await httpPost("admin/faq/edit", {
    id,
    title,
    answer,
  });
  return resp.data;
};

const deleteFaq = async (id) => {
  const resp = await httpPost(`admin/faq/delete/${id}`);
  return resp.data;
};

const exchangeFaqIndex = async (updateIndexArray) => {
  const resp = await httpPost(`admin/faq/exchangeIndex`, {
    updateIndexArray,
  });
  return resp.data;
};

export { getFaqs, addFaq, editFaq, deleteFaq, exchangeFaqIndex };
