const generateInnerciseLinkHtml = ({ title }) => {
  return `<a class="innercise-link"  href="https://myinnercise.com/innercise/?name=${encodeURIComponent(
    title.replaceAll(" ", "-")
  )}">${title}</a>`
}

const generateProgramLinkHtml = ({ title }) => {
  return `<a class="innercise-link"  href="https://myinnercise.com/program/?name=${encodeURIComponent(
    title.replaceAll(" ", "-")
  )}">${title}</a>`
}

export { generateInnerciseLinkHtml, generateProgramLinkHtml }
