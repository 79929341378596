import Routes from "components/Routes";
import { SnackbarProvider } from "notistack";
import "./App.scss";

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <div className="App">
        <Routes />
      </div>
    </SnackbarProvider>
  );
}

export default App;
