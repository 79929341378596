import React from "react"
import MediaContentDisplay from "components/common/MediaManager/MediaContentDisplay"
import { CHANNEL_TYPES } from "constants/global"
import { StyledInputLabel } from "components/Style/Forms.style"
import { StyledProgramSingleInnerciseControl } from "./Program.style"

const ProgramSingleInnerciseControl = ({ innercise, openMediaManager }) => {
  return (
    <StyledProgramSingleInnerciseControl>
      {innercise && (
        <>
          <div className="input-group form-control-row two-col">
            <StyledInputLabel>Title</StyledInputLabel>
            <div>{innercise.title}</div>
          </div>
          <div className="input-group form-control-row two-col">
            <StyledInputLabel>Description</StyledInputLabel>
            <div>{innercise.description}</div>
          </div>
          <div className="input-group form-control-row two-col">
            <div>
              <StyledInputLabel>Thumbnail</StyledInputLabel>
              <MediaContentDisplay resource={innercise.thumbResourceDto} />
            </div>
          </div>

          <StyledInputLabel>Resource</StyledInputLabel>
          <div className="input-group flex-row  form-control-row two-col">
            {innercise.resourceDtos.map(
              (innerciseResource) => (
                <div
                  key={`innercise-channel-item-${innerciseResource.id}`}
                  className="innercise-channel"
                >
                  <MediaContentDisplay resource={innerciseResource} />
                  <div className="channel-label">
                    {CHANNEL_TYPES[innerciseResource.channelType].text}
                  </div>
                </div>
              )
            )}
          </div>
        </>
      )}
    </StyledProgramSingleInnerciseControl>
  )
}

export default ProgramSingleInnerciseControl
