import styled from "@emotion/styled"
import Dialog from "@mui/material/Dialog"

const StyledMember = styled.div`
  /* .MuiPaper-root {
    min-width: 800px;
    min-height: 550px;
  } */
`

const StyledMemberDetail = styled.div`
  display: flex;
  flex-direction: column;

  .header-bar {
    display: flex;
    flex-direction: row;
  }
`

const StyledMemberInfo = styled.div`
  display: flex;
  flex-direction: column;

  .review {
  }
`

const StyledMemberReviews = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .review {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .review-content {
      max-height: 300px;
      overflow-y: scroll;
    }
    .MuiCircularProgress-root {
      width: 20px !important;
      height: 20px !important;
    }
  }
  .card {
    width: 300px;
    margin-right: 10px;
    height: 100%;
  }

  .review-rate {
    display: flex;
    align-items: center;
    margin-left: -3px;
    svg {
      width: 15px;
      path {
        fill: #e59819;
      }
    }
  }
  .review-time {
    font-size: 12px;
    margin: 10px 0 20px 0;
  }
`

const StyledMemberSearch = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledMemberSearchBar = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 30px);
  align-items: center;
  /* border: 1px solid #f9eded; */
  padding: 20px 15px;
  border-radius: 7px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

  .email {
    margin: 0;
    width: 300px;
  }
  .time-input {
    width: 150px;
  }
  .search-btn {
    padding: 14px 22px;
  }
  .MuiInputBase-root {
    margin-right: 10px;
  }
`

const StyledMemberTable = styled.div`
  .pagination-section {
    display: flex;
    justify-content: flex-end;
  }
  .search-result-info {
    background-color: white;
    padding: 15px 10px;
    margin: 15px 0;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 0px,
      rgb(0 0 0 / 10%) 0px 0px 1px 0px;
    font-size: 15px;
  }
`

const StyledMemberSubscriptions = styled.div`
  display: flex;
  flex-direction: column;
  .button-block {
    margin-top: 10px;
    button {
      min-width: 400px;
    }
  }

  .can-btn {
    padding: 7px 75px !important;
    margin: 10px 0;
  }

  .reset-password-form {
    max-width: 400px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    .MuiFormControl-root {
      margin-bottom: 10px;
    }
  }
`

const StyledModalMemberPasswordResetDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  padding: 10px;

  .MuiPaper-root {
    width: 600px;
  }
  .error {
    font-size: 0.75rem;
    color: #d32f2f;
    margin-top: 3px;
  }

  .MuiDialogContent-root {
    form {
      margin: 20px 0 !important;
      display: flex;
      flex-direction: column;
      button {
        margin-top: 10px;
      }
    }
  }
`

const StyledMemberHistory = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 300;
  .email-history {
    margin: 15px 0;
  }

  .table-heading {
    font-size: 22px;
    font-weight: 400;
    padding: 20px 15px 5px 15px;
  }

  .pagination-section {
    display: flex;
    justify-content: flex-end;
  }
`

export {
  StyledMember,
  StyledMemberDetail,
  StyledMemberSearch,
  StyledMemberSearchBar,
  StyledMemberTable,
  StyledMemberReviews,
  StyledMemberInfo,
  StyledMemberSubscriptions,
  StyledModalMemberPasswordResetDialog,
  StyledMemberHistory,
}
