import { httpGet, httpPost } from "utils/httpRequest";

const getAllSystemSettingTags = async () => {
  const resp = await httpGet("admin/systemSetting/listAllSettingTags");
  return resp.data;
};

const getAllSystemSettings = async () => {
  const resp = await httpGet("admin/systemSetting/listAll");
  return resp.data;
};

const addSystemSetting = async ({
  content,
  htmlContent,
  description,
  resourceId,
}) => {
  const resp = await httpPost("admin/systemSetting/add", {
    content,
    htmlContent,
    description,
    resourceId,
  });
  return resp.data;
};

const editSystemSetting = async ({
  content,
  htmlContent,
  description,
  resourceId,
  id,
}) => {
  const resp = await httpPost("admin/systemSetting/edit", {
    content,
    htmlContent,
    description,
    resourceId,
    id,
  });
  return resp;
};

export {
  getAllSystemSettingTags,
  getAllSystemSettings,
  addSystemSetting,
  editSystemSetting,
};
