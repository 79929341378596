import styled from "@emotion/styled"

const StyledCategorySection = styled.div`
  display: flex;

  flex-direction: column;
  padding: 10px;

  .sub-category-table {
    padding: 25px;
    box-shadow: rgb(9 30 66 / 25%) 0px 4px 8px -2px,
      rgb(9 30 66 / 8%) 0px 0px 0px 1px;
    margin: 20px 10px;
  }
`

const CategoryWrapper = styled.div`
  .category-list {
    display: flex;
  }

  .category {
    margin: 10px;
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    div {
      margin-bottom: 10px;
    }
    .media {
      height: 150px;
      border: 1px dashed #ebcfcf;
      padding: 0 30px;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-top: 20px;
      color: gray;
      &:hover {
        background-color: #f4f0f0;
      }
    }
  }
`

const StyledCategoryItem = styled.div`
  margin: 5px 0;
  .action-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 30px 0;
  }

  .MuiChip-root {
    min-width: 120px;
  }
  .MuiAccordionSummary-content {
    align-items: center;
  }

  .MuiChip-outlined {
    margin-right: 10px;
  }
`

const StyledCategoryProgramItem = styled.div`
  display: flex;
  border-radius: 3px;
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 14px;
  padding: 15px 15px;
  background-color: white;
  box-shadow: rgb(9 30 66 / 25%) 0px 1px 1px, rgb(9 30 66 / 13%) 0px 0px 1px 1px;
  align-items: flex-start;

  .thumbnail {
    width: 200px;
    img {
      max-width: 150px;
      max-height: 85px;
      border: 2px solid #00000014;
      border-radius: 3px;
    }
  }

  .info {
    width: 580px;
    .identity {
      font-size: 16px;
      font-weight: bold;
    }
    .title {
      font-size: 15px;
      font-weight: 400;
      margin: 10px 0;
    }
    .desc {
      font-size: 14px;
      font-weight: 200;
    }
  }
`
export {
  StyledCategorySection,
  CategoryWrapper,
  StyledCategoryItem,
  StyledCategoryProgramItem,
}
