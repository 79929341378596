import React from "react"
import moment from "moment/moment"
import Chip from "@mui/material/Chip"
import Typography from "@mui/material/Typography"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Stack from "@mui/material/Stack"
import { PAYMENT_TYPE } from "constants/global"
import { SUBSCRIPTION_PRODUCT } from "constants/global"
import { StyledMemberInfo } from "./Member.style"

const MemberInfo = ({ member }) => {
  return (
    <StyledMemberInfo>
      <Card sx={{ maxWidth: 500 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Email
          </Typography>
          <Typography variant="h5" component="div">
            {member.email}
          </Typography>

          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Username:
          </Typography>
          <Typography variant="body2">
            {member.userName ? member.userName : "N/A"}
          </Typography>

          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Member Nickname:
          </Typography>
          <Typography variant="body2">{member.nickName}</Typography>

          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            First Name:
          </Typography>

          <Typography variant="body2">{member.firstName}</Typography>
          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Last Name:
          </Typography>
          <Typography variant="body2">{member.lastName}</Typography>

          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Last Login Date:
          </Typography>
          <Typography variant="body2">
            {moment(member.lastLoginAt).format("lll")}
          </Typography>

          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Joined Date:
          </Typography>
          <Typography variant="body2">
            {moment(member.registerAt).format("lll")}
          </Typography>

          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Register Type:
          </Typography>
          <Typography variant="body2">
            <Stack direction="row" spacing={1}>
              {member && member.subId && member.subId.includes("auth0") && (
                <Chip label="Manual" />
              )}
              {member &&
                member.googleThirdId &&
                member.googleThirdId.includes("google") && (
                  <Chip label="Google" />
                )}
              {member &&
                member.facebookThirdId &&
                member.facebookThirdId.includes("facebook") && (
                  <Chip label="Facebook" />
                )}
              {/* <Chip label="Chip Outlined" variant="outlined" /> */}
            </Stack>
          </Typography>

          {member.vipInfo && (
            <>
              <Typography
                sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
                color="text.secondary"
              >
                Current Subscription Info:
              </Typography>
              <Typography variant="body2">
                <p>
                  Product:{" "}
                  <strong>
                    {" "}
                    {SUBSCRIPTION_PRODUCT[member.vipInfo.productCode]
                      ? SUBSCRIPTION_PRODUCT[member.vipInfo.productCode]
                      : member.vipInfo.productCode}
                  </strong>
                </p>
                <p>
                  Valid from{" "}
                  <strong>{moment(member.vipInfo.fromAt).format("LL")}</strong>{" "}
                  to{" "}
                  <strong>{moment(member.vipInfo.endAt).format("LL")}</strong>
                </p>
                <p>
                  Payment from{" "}
                  <strong>{PAYMENT_TYPE[member.vipInfo.payment]}</strong>
                </p>
              </Typography>
            </>
          )}

          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Status:
          </Typography>

          {member.isActive ? (
            <Chip label="Activated" color="primary" />
          ) : (
            <Chip label="Deactivated" color="error" />
          )}
        </CardContent>
        <CardActions></CardActions>
      </Card>
    </StyledMemberInfo>
  )
}

export default MemberInfo
