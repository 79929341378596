import styled from "styled-components"
import { Dialog } from "@mui/material"

const RailManagementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .header-bar {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .rails {
    padding: 20px 0;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;

    .rail-section {
      display: flex;
      flex-direction: row;
      cursor: move;
      font-size: 16px;
      background-color: white;
      border: 1px solid #d6d7d7;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px;
      border-radius: 5px;
      padding: 15px;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 10px;

      .thumb {
        display: flex;
        width: 200px;
        align-items: center;
        margin-right: 10px;
        img {
          width: 100%;
        }
      }
      .content {
        width: calc(100% - 150px);
        display: flex;
        flex-direction: row;
        font-size: 12px;
        .heading {
          width: 100%;
          background-color: #e8d6d6;
          color: #947474;
          padding: 3px 10px;
          border-radius: 3px;
        }
        .info-section {
          width: 100%;
          padding: 0px 5px;
          margin-bottom: 5px;
          .label {
            margin-right: 5px;
            display: inline-block;
            background-color: #e8d6d6;
            color: #947474;
            padding: 3px 10px;
            min-width: 70px;
            border-radius: 3px;
          }
        }
        .info-1 {
          width: 45%;
        }
        .info-2 {
          width: 30%;
          padding-right: 30px;
        }
        .info-3 {
          width: 25%;
          .category-section {
            .category-item {
              display: inline-block;
              border: 1px solid #ccc;
              border-radius: 4px;
              background-color: #faf6f6;
              padding: 3px 6px;
              margin-top: 5px;
              margin-right: 5px;
            }
          }
        }
      }
      .actions {
        min-width: 140px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        margin-top: -10px;
      }
    }
  }
`

const StyledRailDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  .MuiPaper-root {
    max-width: 700px;
    width: 100%;
  }

  .error {
    font-size: 0.75rem;
    color: #d32f2f;
    margin-top: 3px;
  }

  .title-bar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 30px;
  }
  .label-text {
    font-weight: 400;
    font-size: 13px;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.6);
  }

  .category-section {
    margin: 20px 0;

    .category-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .category-item {
        font-size: 12px;
        background-color: #ece7e7;
        color: #916d6d;
        padding: 7px 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
      }
    }
  }

  .resource-section {
    .resource-list {
      margin-top: 20px;
      .resource-item {
        border-radius: 4px;
        overflow: hidden;
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: calc(100% - 2px);
        margin-bottom: 8px;
        margin-top: 2px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
          rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        img {
          width: 110px;
        }
        .content {
          width: 90%;
          padding: 5px 10px;

          .resource-name {
            font-weight: 400;
            font-size: 13px;
          }
        }
      }
    }
  }
`

export { RailManagementWrapper, StyledRailDialog }
