const hexToRgbA = (hex) => {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("")
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = "0x" + c.join("")
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
    )
  }
  throw new Error("Bad Hex")
}

const isDarkColorByRGB = (rgba) => {
  const { r, g, b } = rgba
  let hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))
  return hsp < 127.5
}

const isDarkColorByHex = (hex) => {
  let rgb = hexToRgbA(hex)

  if (rgb.match(/^rgb/)) {
    var a = rgb.match(
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
      ),
      r = a[1],
      g = a[2],
      b = a[3]
  }
  let hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))
  return hsp < 127.5
}

export { isDarkColorByRGB, isDarkColorByHex, hexToRgbA }
