import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { PERMISSIONS } from "constants/permission";
import { UsePermissionVerification } from "components/customHooks";
import {
  MediaManagerWrapper,
  MediaMangerDialogTitle,
} from "./MediaManager.style";

import MediaUpload from "./MediaUpload";
import MediaSearch from "./MediaSearch";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MediaManager = (props) => {
  const [value, setValue] = useState("1");
  const [open, setOpen] = useState(false);
  const onSelectRef = useRef(null);
  const targetTypeRef = useRef(null);

  const AccessControl = UsePermissionVerification();

  useEffect(() => {
    AccessControl.SetAccess({
      ...AccessControl.Access,
      listMediaAccess: AccessControl.VerifyAccess([
        PERMISSIONS.RESOURCE.RESOURCE_LIST,
      ]),
      addMediaAccess: AccessControl.VerifyAccess([
        PERMISSIONS.RESOURCE.RESOURCE_ADD,
      ]),
      editMediaAccess: AccessControl.VerifyAccess([
        PERMISSIONS.RESOURCE.RESOURCE_EDIT,
      ]),
      archiveMediaAccess: AccessControl.VerifyAccess([
        PERMISSIONS.RESOURCE.RESOURCE_ARCHIVE,
      ]),
      searchMediaAccess: AccessControl.VerifyAccess([
        PERMISSIONS.RESOURCE.RESOURCE_QUERY_SEARCH,
      ]),
      queryMediaAccess: AccessControl.VerifyAccess([
        PERMISSIONS.RESOURCE.RESOURCE_QUERY,
      ]),
      queryByIdMediaAccess: AccessControl.VerifyAccess([
        PERMISSIONS.RESOURCE.RESOURCE_QUERY_BY_ID,
      ]),
    });
  }, [AccessControl.UserPermissions]);

  const openMediaManager = (onSelect, targetType, initSearchType) => {
    onSelectRef.current = onSelect;
    targetTypeRef.current = targetType;
    setOpen(true);
    if (initSearchType) {
      //
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const content = (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      TransitionComponent={Transition}
    >
      <MediaMangerDialogTitle>
        Media Manager
        <CloseIcon
          className="close-button"
          role="button"
          onClick={handleClose}
        />
      </MediaMangerDialogTitle>
      <DialogContent>
        <MediaManagerWrapper>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                {AccessControl.Access.searchMediaAccess && (
                  <Tab label="Search Media" value="1" />
                )}
                {AccessControl.Access.addMediaAccess && (
                  <Tab label="Upload Media" value="2" />
                )}
              </TabList>
            </Box>
            {AccessControl.Access.searchMediaAccess && (
              <TabPanel value="1">
                <MediaSearch
                  access={AccessControl.Access}
                  onSelect={onSelectRef.current}
                  handleClose={handleClose}
                  targetType={targetTypeRef.current}
                />
              </TabPanel>
            )}
            {AccessControl.Access.addMediaAccess && (
              <TabPanel value="2">
                <MediaUpload />
              </TabPanel>
            )}
          </TabContext>
        </MediaManagerWrapper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );

  return [openMediaManager, content];
};

export default MediaManager;
