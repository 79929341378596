import { httpPost } from "utils/httpRequest"

const addResource = async ({
  mediaName,
  mediaType,
  mediaDescription,
  mediaSize,
  mediaDuration,
  mediaAwsKey,
  mediaAwsBucket,
}) => {
  const resp = await httpPost("admin/resource/add", {
    name: mediaName,
    description: mediaDescription,
    type: mediaType,
    awsKey: mediaAwsKey,
    awsBucket: mediaAwsBucket,
    totalLength: mediaDuration,
    size: mediaSize,
  })
  return resp
}

const editResource = async ({
  id,
  name,
  description,
  type,
  awsKey,
  awsBucket,
  totalLength,
  size,
}) => {
  const resp = await httpPost("admin/resource/edit", {
    id,
    name,
    description,
    type,
    awsKey,
    awsBucket,
    totalLength,
    size,
  })

  return resp.data
}

const archiveResource = async (resourceId) => {
  const response = await httpPost("admin/resource/archive", {
    resourceIdList: [resourceId],
  })
  return response.data
}

const unarchiveResource = async (resourceId) => {
  const response = await httpPost("admin/resource/unarchive", {
    resourceIdList: [resourceId],
  })
  return response.data
}

const searchResource = async ({
  name,
  type,
  isArchive,
  minFileSize,
  maxFileSize,
}) => {
  const response = await httpPost("admin/resource/query", {
    name: name,
    type: type,
    isArchive: isArchive,
    minFileSize: minFileSize,
    maxFileSize: maxFileSize,
  })
  return response.data
}

const getResourceStatInfo = async () => {
  const response = await httpPost("admin/resource/statInfo")
  return response.data
}

const checkAudioFileSize = async ({ id }) => {
  const response = await httpPost("admin/resource/checkFileSize", {
    id,
  })
  return response.data
}

const resizeAudioResource = async (id) => {
  const response = await httpPost("admin/resource/convertAudioFile", {
    id: id,
  })
  return response.data
}

export {
  addResource,
  editResource,
  archiveResource,
  unarchiveResource,
  searchResource,
  getResourceStatInfo,
  checkAudioFileSize,
  resizeAudioResource,
}
