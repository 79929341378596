import { httpPost, httpGet } from "utils/httpRequest";

const getPermissionModules = async () => {
  const resp = await httpGet("admin/permission/listPermissionModule");
  return resp.data;
};

const getPermissionsByModule = async () => {
  const resp = await httpGet("admin/permission/listAllByModule");
  return resp.data;
};

const getPermissions = async () => {
  const resp = await httpPost("admin/permission/list", {
    pageNo: 1,
    pageSize: 100000,
  });
  return resp.data;
};

const addPermission = async ({ tag, module, endpointUrl, description }) => {
  const resp = await httpPost("admin/permission/add", {
    tag,
    module,
    endpointUrl,
    description,
  });
  return resp.data;
};

const updatePermission = async ({
  id,
  tag,
  module,
  endpointUrl,
  description,
}) => {
  const resp = await httpPost("admin/permission/edit", {
    id,
    tag,
    module,
    endpointUrl,
    description,
  });
  return resp.data;
};

// roles
const getRoles = async () => {
  const resp = await httpPost("admin/role/list", {
    pageNo: 1,
    pageSize: 10000,
    name: "",
  });
  return resp.data;
};

const getRoleDetail = async (id) => {
  const resp = await httpPost(`admin/role/queryDetail/${id}`);
  return resp.data;
};

const addRole = async ({ name, description }) => {
  const resp = await httpPost("admin/role/add", {
    name,
    description,
  });
  return resp.data;
};

const updateRole = async ({ id, name, description }) => {
  const resp = await httpPost("admin/role/edit", {
    id,
    name,
    description,
  });
  return resp.data;
};

const updateRolePermissions = async ({ roleId, permissionIdList }) => {
  const resp = await httpPost("admin/role/setPermission", {
    roleId,
    permissionIdList,
  });
  return resp.data;
};

export {
  getPermissionModules,
  getPermissions,
  getPermissionsByModule,
  addPermission,
  updatePermission,
  getRoles,
  getRoleDetail,
  addRole,
  updateRole,
  updateRolePermissions,
};
