import React from "react"
import { useNavigate } from "react-router-dom"
import { UsePermissionVerification } from "components/customHooks"
import { PERMISSIONS } from "constants/permission"
import { LeftPanelWrapper, PanelMenu, PanelMenuItem } from "./LeftPanel.style"
import { getRandomInt } from "utils/number"
const LeftPanel = () => {
  let navigate = useNavigate()

  const AccessControl = UsePermissionVerification()

  const menuItems = []
  // Menu for Content Management
  ;(AccessControl.VerifyAccessByCategory(PERMISSIONS.CATEGORY) ||
    AccessControl.VerifyAccessByCategory(PERMISSIONS.PRIME) ||
    AccessControl.VerifyAccessByCategory(PERMISSIONS.MODAL) ||
    AccessControl.VerifyAccessByCategory(PERMISSIONS.FAQ) ||
    AccessControl.VerifyAccessByCategory(PERMISSIONS.START_HERE) ||
    AccessControl.VerifyAccessByCategory(PERMISSIONS.REVIEW)) &&
    menuItems.push({
      groupText: "Content Management",
      items: [],
    })

  AccessControl.VerifyAccess([PERMISSIONS.CATEGORY.CATEGORY_LIST]) &&
    menuItems
      .find((menu) => menu.groupText === "Content Management")
      .items.push({ text: "Categories", route: "/category" })

  AccessControl.VerifyAccess([PERMISSIONS.PROGRAM.PROGRAM_LIST]) &&
    menuItems
      .find((menu) => menu.groupText === "Content Management")
      .items.push({ text: "Programs", route: "/program" })

  AccessControl.VerifyAccess([PERMISSIONS.PROGRAM.PROGRAM_LIST]) &&
    menuItems
      .find((menu) => menu.groupText === "Content Management")
      .items.push({ text: "Rails", route: "/rail" })

  AccessControl.VerifyAccess([
    PERMISSIONS.START_HERE.START_HERE_RESOURCE_LIST,
  ]) &&
    menuItems
      .find((menu) => menu.groupText === "Content Management")
      .items.push({ text: "Start Resource", route: "/startHere" })

  AccessControl.VerifyAccess([
    PERMISSIONS.START_HERE.START_HERE_RESOURCE_LIST,
  ]) &&
    menuItems
      .find((menu) => menu.groupText === "Content Management")
      .items.push({ text: "Features Program", route: "/featuredProgram" })

  AccessControl.VerifyAccess([PERMISSIONS.PRIME.PRIME_LIST]) &&
    menuItems
      .find((menu) => menu.groupText === "Content Management")
      .items.push({ text: "Daily Primes", route: "/prime" })

  AccessControl.VerifyAccess([PERMISSIONS.MODAL.MODAL_LIST]) &&
    menuItems
      .find((menu) => menu.groupText === "Content Management")
      .items.push({ text: "Modals", route: "/modal" })

  AccessControl.VerifyAccess([PERMISSIONS.FAQ.FAQ_LIST]) &&
    menuItems
      .find((menu) => menu.groupText === "Content Management")
      .items.push({ text: "FAQs", route: "/faq" })

  AccessControl.VerifyAccess([PERMISSIONS.REVIEW.REVIEW_LIST]) &&
    menuItems
      .find((menu) => menu.groupText === "Content Management")
      .items.push({ text: "Reviews", route: "/review" })

  AccessControl.VerifyAccess([PERMISSIONS.REVIEW.REVIEW_LIST]) &&
    menuItems
      .find((menu) => menu.groupText === "Content Management")
      .items.push({ text: "Contributors", route: "/contributor" })

  // Menu for Subscriptions
  AccessControl.VerifyAccessByCategory(PERMISSIONS.SUBSCRIPTION) &&
    menuItems.push({
      groupText: "Subscriptions",
      items: [],
    })

  AccessControl.VerifyAccess([
    PERMISSIONS.SUBSCRIPTION.SUBSCRIPTION_LIST_ALL_GROUP,
  ]) &&
    menuItems
      .find((menu) => menu.groupText === "Subscriptions")
      .items.push({ text: "Subscription Group", route: "/subscriptionGroup" })

  AccessControl.VerifyAccess([
    PERMISSIONS.SUBSCRIPTION.SUBSCRIPTION_GET_ALL_PRODUCT_LIST,
  ]) &&
    menuItems
      .find((menu) => menu.groupText === "Subscriptions")
      .items.push({ text: "Subscription Product", route: "/product" })

  // Menu for User Management
  ;(AccessControl.VerifyAccessByCategory(PERMISSIONS.MEMBER) ||
    AccessControl.VerifyAccessByCategory(PERMISSIONS.REDEEM)) &&
    menuItems.push({
      groupText: "User Management",
      items: [],
    })

  AccessControl.VerifyAccess([PERMISSIONS.MEMBER.MEMBER_LIST]) &&
    menuItems
      .find((menu) => menu.groupText === "User Management")
      .items.push({ text: "Members", route: "/member" })

  AccessControl.VerifyAccess([PERMISSIONS.REDEEM.REDEEM_LIST]) &&
    menuItems
      .find((menu) => menu.groupText === "User Management")
      .items.push({ text: "Redeem", route: "/redeem" })

  AccessControl.VerifyAccess([PERMISSIONS.REDEEM.REDEEM_LIST]) &&
    menuItems
      .find((menu) => menu.groupText === "User Management")
      .items.push({ text: "Marketo", route: "/marketo" })

  // Menu for System Management
  ;(AccessControl.VerifyAccessByCategory(PERMISSIONS.SYSTEM_SETTING) ||
    AccessControl.VerifyAccessByCategory(PERMISSIONS.SYSTEM_CONFIG) ||
    AccessControl.VerifyAccessByCategory(PERMISSIONS.ORDER) ||
    AccessControl.VerifyAccessByCategory(PERMISSIONS.RESOURCE)) &&
    menuItems.push({
      groupText: "System Management",
      items: [],
    })

  AccessControl.VerifyAccess([
    PERMISSIONS.SYSTEM_SETTING.SYSTEM_SETTING_LIST_ALL,
  ]) &&
    menuItems
      .find((menu) => menu.groupText === "System Management")
      .items.push({ text: "System Settings", route: "/systemSetting" })

  AccessControl.VerifyAccess([PERMISSIONS.SYSTEM_CONFIG.SYSTEM_CONFIG_LIST]) &&
    menuItems
      .find((menu) => menu.groupText === "System Management")
      .items.push({ text: "System Configs", route: "/systemConfig" })

  AccessControl.VerifyAccess([PERMISSIONS.ORDER.ORDER_LIST]) &&
    menuItems
      .find((menu) => menu.groupText === "System Management")
      .items.push({ text: "Orders", route: "/order" })

  AccessControl.VerifyAccess([PERMISSIONS.RESOURCE.RESOURCE_LIST]) &&
    menuItems
      .find((menu) => menu.groupText === "System Management")
      .items.push({ text: "Media", route: "/media" })

  // Todo : need to change the permission type here
  AccessControl.VerifyAccess([PERMISSIONS.RESOURCE.RESOURCE_LIST]) &&
    menuItems
      .find((menu) => menu.groupText === "System Management")
      .items.push({ text: "Redis", route: "/redis" })

  // Menu for Admin Management
  ;(AccessControl.VerifyAccessByCategory(PERMISSIONS.ADMIN_USER) ||
    AccessControl.VerifyAccessByCategory(PERMISSIONS.ROLE) ||
    AccessControl.VerifyAccessByCategory(PERMISSIONS.PERMISSION)) &&
    menuItems.push({
      groupText: "Admin Management",
      items: [],
    })

  AccessControl.VerifyAccess([PERMISSIONS.ADMIN_USER.ADMIN_USER_LIST]) &&
    menuItems
      .find((menu) => menu.groupText === "Admin Management")
      .items.push({ text: "Admin User", route: "/admin" })

  AccessControl.VerifyAccess([PERMISSIONS.ROLE.ROLE_SEARCH]) &&
    menuItems
      .find((menu) => menu.groupText === "Admin Management")
      .items.push({ text: "Role", route: "/role" })

  AccessControl.VerifyAccess([PERMISSIONS.PERMISSION.PERMISSION_LIST]) &&
    menuItems
      .find((menu) => menu.groupText === "Admin Management")
      .items.push({ text: "Permission", route: "/permission" })

  return (
    <LeftPanelWrapper>
      <PanelMenu>
        {menuItems.map((group) => (
          <div key={`group-${getRandomInt(1, 99999)}`}>
            <PanelMenuItem>{group.groupText}</PanelMenuItem>
            {group.items.map((item) => (
              <PanelMenuItem
                key={`item-${getRandomInt(1, 99999)}`}
                className="clickable"
              >
                <button onClick={() => navigate(item.route)}>
                  {item.text}
                </button>
              </PanelMenuItem>
            ))}
          </div>
        ))}
      </PanelMenu>
    </LeftPanelWrapper>
  )
}

export default LeftPanel
