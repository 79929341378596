import React, { useState, useEffect, useRef, useMemo } from "react"
import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import {
  addRailGroup,
  editRailGroup,
  getRails,
  editRailIntoGroup,
} from "services/rails"
import { getSystemConfigByKey } from "services/systemConfig"
import { useForm } from "react-hook-form"
import { useSnackbar } from "notistack"
import { RAIL_GROUP_TYPE } from "constants/global"
import { StyledRailGroupDialog } from "./RailGroupManagement.style"
import { verifyResponse } from "utils/httpRequest"

const RailGroupDlg = (props) => {
  const initRailGroup = {
    name: "",
    groupType: "",
    isShow: true,
    rule: "{}",
  }

  const { enqueueSnackbar } = useSnackbar()

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm()

  const { onSubmit, existingRails, setExistingRails } = props
  const [rails, setRails] = useState([])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [groupRailRules, setGroupRailRules] = useState([])
  const [groupRailRule, setGroupRailRule] = useState(null)
  const [selectGroupRule, setSelectGroupRule] = useState("")
  const [isEditMode, setIsEditMode] = useState(false)

  const railGroupRef = useRef(initRailGroup)
  const selectedRailsRef = useRef([])

  useEffect(() => {
    getAllRails()
    getGroupRailRules()
  }, [])

  useEffect(() => {
    const targetRailRule = groupRailRule
      ? groupRailRules.find((item) => item.key === groupRailRule.ruleKey)
      : null
    targetRailRule ? setSelectGroupRule(targetRailRule) : setSelectGroupRule({})
  }, [groupRailRule])

  useEffect(() => {
    railGroupRef.current = {
      ...railGroupRef.current,
      groupType: watch("groupType"),
    }
  }, [watch("groupType")])

  const handleClose = () => {
    setOpen(false)
  }

  const openDlg = async (editRailGroup) => {
    reset()
    if (editRailGroup) {
      railGroupRef.current = editRailGroup

      setGroupRailRule(
        editRailGroup.rule ? JSON.parse(editRailGroup.rule) : null
      )
      setIsEditMode(true)
      await loadGroupRails()
      setValue("groupType", editRailGroup.groupType)
    } else {
      railGroupRef.current = initRailGroup
      setGroupRailRule(null)
      selectedRailsRef.current = []
      setIsEditMode(false)
    }
    await getAllRails()
    setOpen(true)
  }

  const getAllRails = async () => {
    setLoading(true)
    // call api for create record
    const response = await getRails({})
    setLoading(false)
    verifyResponse(response)
    setRails(response.data)
  }

  const getGroupRailRules = async () => {
    setLoading(true)
    const response = await getSystemConfigByKey("GROUP-RAIL-RULE")
    setLoading(false)
    verifyResponse(response)
    setGroupRailRules(response.data.configValueJson.config)
  }

  const createNewRailGroup = async ({ name, groupType, rule, isShow }) => {
    setLoading(true)
    // call api for create record
    const response = await addRailGroup({
      name,
      groupType,
      rule,
      isShow,
    })
    setLoading(false)
    verifyResponse(response)
    // onSubmit callBack
    onSubmit && (await onSubmit())
    // close DLG
    setOpen(false)
    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar("Rial Group Create success", {
        variant: "success",
      })
    } else {
      // handling error message
      enqueueSnackbar("Rial Group Create Fail", {
        variant: "warning",
      })
    }
  }

  const updateRailGroup = async ({ id, name, groupType, rule, isShow }) => {
    setLoading(true)
    const response = await editRailGroup({
      id,
      name,
      groupType,
      rule,
      isShow,
    })
    setLoading(false)
    verifyResponse(response)
    onSubmit && (await onSubmit())

    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar("Rial group update success", {
        variant: "success",
      })
    } else {
      // handling error message
      enqueueSnackbar("Rial group update Fail", {
        variant: "warning",
      })
    }
    setOpen(false)
  }

  const loadGroupRails = async () => {
    setLoading(true)
    // call api for create record
    const response = await getRails({
      groupId: railGroupRef.current.id,
    })
    setLoading(false)
    verifyResponse(response)
    selectedRailsRef.current = response.data
  }

  const editRailIntoGroupAction = async () => {
    setLoading(true)
    const response = await editRailIntoGroup({
      groupId: railGroupRef.current.id,
      railIdList: selectedRailsRef.current.map((rail) => rail.id),
    })
    setLoading(false)
    verifyResponse(response)

    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar("Group Rail update success", {
        variant: "success",
      })
    } else {
      // handling error message
      enqueueSnackbar("Group Rail update Fail", {
        variant: "warning",
      })
    }
  }

  const handleAction = async (data) => {
    isEditMode
      ? updateRailGroup({
          id: railGroupRef.current.id,
          name: data.name,
          groupType: data.groupType,
          rule: groupRailRule ? JSON.stringify(groupRailRule) : "",
          isShow: data.isShow ? 1 : 0,
        })
      : createNewRailGroup({
          name: data.name,
          groupType: data.groupType,
          rule: groupRailRule ? JSON.stringify(groupRailRule) : "",
          isShow: data.isShow ? 1 : 0,
        })
  }

  const renderRuleConfigTemplate = (rules) => {
    return (
      <div>
        <FormControl fullWidth variant="standard" margin="dense">
          <InputLabel id="rail-group-rule-select-label">Rule</InputLabel>
          <Select
            labelId="rail-group-rule-select-label-id"
            id="rail-group-rule-select-id"
            label="Rule"
            value={groupRailRule?.ruleKey}
            onChange={(e) => {
              const targetRule = rules.find((it) => it.key === e.target.value)

              // update value for RANDOM-RULE
              if (e.target.value === "RANDOM-RULE") {
                !groupRailRule &&
                  setGroupRailRule({
                    ruleKey: targetRule.key,
                    parameters: {
                      numOfRandomNumber: targetRule.numOfRandomNumber,
                      targetKey:
                        targetRule.targets.length === 0
                          ? ""
                          : targetRule.targets[0].key,
                    },
                  })
              } else {
                setGroupRailRule({
                  ruleKey: e.target.value.key,
                  parameters: {},
                })
              }
            }}
          >
            {rules.map((rule) => {
              return (
                <MenuItem key={`rail-group-rule-${rule.id}`} value={rule.key}>
                  {rule.label}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        {groupRailRule && (
          <div className="rule-settings">
            {selectGroupRule.key === "RANDOM-RULE" && (
              <>
                <p>{selectGroupRule.desc}</p>
                <div className="random-rule-input-panel">
                  Select
                  <FormControl margin="dense">
                    <TextField
                      className="num-of-random-num"
                      label=""
                      inputProps={{ step: "1", min: "1" }}
                      variant="outlined"
                      fullWidth
                      value={groupRailRule.parameters.numOfRandomNumber}
                      onChange={(e) => {
                        setGroupRailRule({
                          ...groupRailRule,
                          parameters: {
                            ...groupRailRule.parameters,
                            numOfRandomNumber: e.target.value,
                          },
                        })
                      }}
                      type="number"
                    />
                  </FormControl>
                  Resources from Group of
                  <Select
                    className="target"
                    labelId="rail-group-rule-select-label-id"
                    id="rail-group-rule-select-id"
                    value={groupRailRule.parameters.targetKey}
                    onChange={(e) => {
                      setGroupRailRule({
                        ...groupRailRule,
                        parameters: {
                          ...groupRailRule.parameters,
                          targetKey: e.target.value,
                        },
                      })
                    }}
                  >
                    {selectGroupRule.targets.map((target) => {
                      return (
                        <MenuItem
                          key={`rail-group-rule-target-${target.key}`}
                          value={target.key}
                        >
                          {target.label}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    )
  }

  const dlg = (
    <StyledRailGroupDialog open={open}>
      <DialogTitle>
        {isEditMode ? "Edit Rail Group" : "Add New Rail Group"}
      </DialogTitle>
      <form onSubmit={handleSubmit(handleAction)}>
        <DialogContent>
          <Box>
            <FormControl fullWidth margin="dense">
              <TextField
                label="Name"
                variant="standard"
                fullWidth
                defaultValue={railGroupRef.current.name}
                {...register("name", { required: true })}
                error={!!errors?.name}
                helperText={!!errors?.name ? "* Name is required" : " "}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth variant="standard" margin="dense">
              <InputLabel id="rail-group-type-select-label">
                Group Type
              </InputLabel>
              <Select
                labelId="rail-group-type-select-label-id"
                id="rail-group-type-select-id"
                defaultValue={railGroupRef.current.groupType}
                label="Group Type"
                {...register("groupType", { required: true })}
              >
                {Object.values(RAIL_GROUP_TYPE).map((railGroupType) => {
                  return (
                    <MenuItem
                      key={`rail-group-type-${railGroupType.value}`}
                      value={railGroupType.value}
                    >
                      {railGroupType.text}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={railGroupRef.current.isShow === 1}
                  color="warning"
                  {...register("isShow", { required: false })}
                />
              }
              label="Is Show"
            />
          </Box>

          {isEditMode &&
            watch("groupType") === RAIL_GROUP_TYPE.REGULAR.value && (
              <div className="rail-select-region">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <div className="list-label">Group Rails</div>
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                      }}
                    >
                      <List>
                        {selectedRailsRef.current.map((rail) => (
                          <ListItem
                            disablePadding
                            key={`selected-rail-list-item-${rail.id}`}
                          >
                            <ListItemButton
                              onClick={() => {
                                selectedRailsRef.current =
                                  selectedRailsRef.current.filter(
                                    (item) => item.id !== rail.id
                                  )
                                setExistingRails(
                                  existingRails.filter((railId) => {
                                    return railId !== rail.id
                                  })
                                )
                                editRailIntoGroupAction()
                              }}
                            >
                              <ListItemText primary={rail.name} />
                              <ListItemIcon>
                                <ChevronRightIcon />
                              </ListItemIcon>
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="list-label">Available Rails</div>
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                      }}
                    >
                      <List>
                        {rails
                          .filter((rail) => {
                            return (
                              selectedRailsRef.current.find(
                                (e) => e.id === rail.id
                              ) === undefined &&
                              existingRails.find(
                                (railId) => railId === rail.id
                              ) === undefined
                            )
                          })
                          .map((rail) => (
                            <ListItem
                              disablePadding
                              key={`padding-rail-list-item-${rail.id}`}
                            >
                              <ListItemButton
                                onClick={() => {
                                  selectedRailsRef.current = [
                                    ...selectedRailsRef.current,
                                    rail,
                                  ]
                                  editRailIntoGroupAction()
                                }}
                              >
                                <ListItemIcon>
                                  <ChevronLeftIcon />
                                </ListItemIcon>
                                <ListItemText primary={rail.name} />
                              </ListItemButton>
                            </ListItem>
                          ))}
                      </List>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            )}

          {!isEditMode &&
            watch("groupType") === RAIL_GROUP_TYPE.REGULAR.value && (
              <div className="rail-select-region">
                <p>Rail Configuration Enable After Group Crated.</p>
              </div>
            )}

          {watch("groupType") === RAIL_GROUP_TYPE.RULE.value && (
            <div>{renderRuleConfigTemplate(groupRailRules)}</div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" size="small">
            {isEditMode ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </form>
    </StyledRailGroupDialog>
  )

  return [openDlg, dlg, loading]
}

export default RailGroupDlg
