import React from "react";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import { ORDER_STATUS } from "constants/global";
import { StyledRedeemTable } from "./Order.style";

const OrderTable = ({
  orders,
  access,
  openOrderDetail,
  searchResultInfo,
  searchOptions,
  setSearchOptions,
}) => {
  return (
    <StyledRedeemTable>
      <TableContainer component={Paper}>
        <div className="search-record-info">
          Total Record Found: <strong>{searchResultInfo?.total}</strong>
        </div>
        <Table sx={{ minWidth: 650 }} aria-label="role table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Order #</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Status</TableCell>
              <TableCell sx={{ minWidth: 160, textAlign: "right" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((row) => (
              <TableRow
                key={`role-item-${row.id}`}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell component="th" scope="row">
                  {moment(row.createdAt).format("lll")}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.orderNo}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.memberEmail}
                </TableCell>
                <TableCell>{row.productCode}</TableCell>
                <TableCell>{ORDER_STATUS[row.orderStatus]}</TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  {access.queryOrderAccess && (
                    <Button
                      style={{ width: "80px" }}
                      onClick={() => {
                        openOrderDetail(row);
                      }}
                      variant="outlined"
                    >
                      Detail
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {searchResultInfo && (
              <TableRow key={"pg"}>
                <TableCell colSpan={1}>
                  {/* add extra button here */}
                </TableCell>
                <TableCell colSpan={7}>
                  <div className="pagination-section">
                    <Pagination
                      count={searchResultInfo.pages}
                      page={searchResultInfo.currentPageNo}
                      onChange={(event, value) => {
                        setSearchOptions({
                          ...searchOptions,
                          pageNo: value,
                        });
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledRedeemTable>
  );
};

export default OrderTable;
