import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import { DURATION_UNIT_LIST } from "constants/global"
import { StyledRedeemDetail } from "../Redeem/Redeem.style"

const RedeemDetail = ({ redeem, subscriptionProduct, closeRedeemDetail }) => {
  const content = (
    <StyledRedeemDetail>
      <Card sx={{ maxWidth: 500 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Redeem Code:
          </Typography>
          <Typography variant="h5" component="div">
            {redeem.redeemCode}
          </Typography>
          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Batch ID:
          </Typography>
          <Typography variant="body2">{redeem.batchId}</Typography>

          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Status:
          </Typography>
          <Typography variant="body2">
            {redeem.redeemStatus ? "Activated" : "Inactivated"}
          </Typography>

          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Activated Member:
          </Typography>
          <Typography variant="body2">
            {redeem.redeemMemberEmail
              ? `${redeem.redeemMemberEmail} (ID: ${redeem.memberId})`
              : ""}
          </Typography>

          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Product:
          </Typography>
          <Typography variant="body2">
            {`${
              subscriptionProduct.find((o) => o.code === redeem.productCode)
                .name
            }  (${redeem.productCode})`}
          </Typography>
          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Duration:
          </Typography>
          <Typography variant="body2">
            {`${redeem.duration} ${
              DURATION_UNIT_LIST.find((o) => o.value === redeem.durationUnit)
                .text
            }`}
          </Typography>
          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Create By:
          </Typography>
          <Typography variant="body2">
            {`${redeem.adminUserFirstName} ${
              redeem.adminUserLastName
            } at ${moment(redeem.createdAt).format("lll")}`}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            onClick={() => {
              closeRedeemDetail()
            }}
          >
            Close
          </Button>
        </CardActions>
      </Card>
    </StyledRedeemDetail>
  )

  return content
}

RedeemDetail.propTypes = {
  redeem: PropTypes.object.isRequired,
  subscriptionProduct: PropTypes.array.isRequired,
  closeRedeemDetail: PropTypes.func.isRequired,
}

export default RedeemDetail
