import { httpPost } from "utils/httpRequest"

const getSystemConfigs = async () => {
  const resp = await httpPost("admin/systemConfig/list")
  return resp.data
}

const addSystemConfigs = async ({
  name,
  configKey,
  configValue,
  description,
}) => {
  const resp = await httpPost("admin/systemConfig/add", {
    name,
    configKey,
    configValue,
    description,
  })
  return resp.data
}

const editSystemConfigs = async ({
  name,
  configKey,
  configValue,
  description,
  id,
}) => {
  const resp = await httpPost("admin/systemConfig/edit", {
    name,
    configKey,
    configValue,
    description,
    id,
  })
  return resp.data
}

const deleteSystemConfigs = async ({ id }) => {
  const resp = await httpPost("admin/systemConfig/delete", { id })
  return resp.data
}

const getSystemConfigByKey = async (key) => {
  const resp = await httpPost("admin/systemConfig/queryByKey", {
    key,
  })
  return resp.data
}

export {
  getSystemConfigs,
  getSystemConfigByKey,
  addSystemConfigs,
  editSystemConfigs,
  deleteSystemConfigs,
}
