import React, { useState, useEffect, useRef } from "react"
import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined"
import TextField from "@mui/material/TextField"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import DeleteIcon from "@mui/icons-material/Delete"
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import { Container, Draggable } from "react-smooth-dnd"
import { calculateNewRank } from "utils/data"
import CategorySelector from "components/common/CategorySelector/CategorySelector"
import ProgramSelector from "components/common/ProgramSelector/ProgramSelector"
import {
  addRail,
  editRail,
  getRailResourceByRailId,
  addRailResource,
  editRailResource,
  deleteRailResource,
  exchangeRailResourceIndex,
} from "services/rails"
import { verifyResponse } from "utils/httpRequest"
import { useForm } from "react-hook-form"
import { useSnackbar } from "notistack"
import { UseConfirmDialog } from "components/customHooks"
import {
  RAIL_UI_TYPE,
  RAIL_TYPE,
  RAIL_RESOURCE_TYPE,
  RAIL_TAG,
} from "constants/global"
import { CATEGORY_SELECTOR_MODE } from "constants/selector"
import { StyledRailDialog } from "./RailManagement.style"

const RailDlg = (props) => {
  const initRail = {
    name: "",
    railType: RAIL_TYPE.CUSTOMIZED.value,
    uiType: RAIL_UI_TYPE.REGULAR.value,
    description: "",
    groupId: null,
    categoryList: [],
  }

  const { enqueueSnackbar } = useSnackbar()

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm()

  const { onSubmit } = props
  // const [rail, setRail] = useState(initRail)
  const [resources, setResources] = useState([])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const railRef = useRef(initRail)

  const [ConfirmDialog, openConfirmDialog] = UseConfirmDialog({
    title: "Delete Rail Resource Confirm",
    description: "Are you sure want delete this Rail Resource?",
    agreeText: "Yes",
    disagreeText: "No",
  })

  const [
    openProgramSelectorDlg,
    programSelectorDlgContent,
    programSelectorDlgLoading,
  ] = ProgramSelector({
    onSelect: (selectedProgram) => {
      if (isEditMode) {
        addResource({
          railId: railRef.current.id,
          dataType: RAIL_RESOURCE_TYPE.PROGRAM.value,
          dataId: selectedProgram.id,
          isHome: 0,
        })
      } else {
        setResources([
          ...resources,
          {
            dataId: selectedProgram.id,
            dataType: RAIL_RESOURCE_TYPE.PROGRAM.value,
            isHome: 0,
            railId: railRef.current.id,
            programDto: selectedProgram,
          },
        ])
      }
    },
  })

  const [
    openCategorySelectorDlg,
    categorySelectorDlg,
    categorySelectorLoading,
  ] = CategorySelector({
    onUpdate: (data) => {
      railRef.current = {
        ...railRef.current,
        categoryList: data,
      }
    },
  })

  const loadRailResource = async () => {
    if (railRef.current.id) {
      setLoading(true)
      const response = await getRailResourceByRailId({
        pageNo: 1,
        pageSize: 100000,
        railId: railRef.current.id,
      })
      setLoading(false)
      verifyResponse(response)
      setResources(response.data.list)
    }
  }

  const addRailItem = async ({
    name,
    railType,
    railTag,
    uiType,
    description,
    groupId,
    categoryIdList,
  }) => {
    setLoading(true)
    const response = await addRail({
      name,
      railType,
      railTag,
      uiType,
      description,
      groupId,
      categoryIdList,
    })
    setLoading(false)
    verifyResponse(response)
    onSubmit && onSubmit()
    setOpen(false)
    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar("Rial Create Success", {
        variant: "success",
      })
    } else {
      // handling error message
      enqueueSnackbar("Rial Create Fail", {
        variant: "warning",
      })
    }
  }

  const addResource = async ({ railId, dataType, dataId, isHome }) => {
    setLoading(true)
    const response = await addRailResource({ railId, dataType, dataId, isHome })
    setLoading(false)
    verifyResponse(response)
    loadRailResource()
    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar("Rail Resource Added", {
        variant: "success",
      })
    } else {
      // handling error message
      enqueueSnackbar("Rail Resource Add Fail", {
        variant: "warning",
      })
    }
  }

  const deleteResourceFromList = async (deleteResourceId) => {
    setLoading(true)
    const response = await deleteRailResource({
      id: deleteResourceId,
    })
    setLoading(false)
    verifyResponse(response)
    loadRailResource()
    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar("Rail Resource Removed", {
        variant: "success",
      })
    } else {
      // handling error message
      enqueueSnackbar("Rail Resource Delete Fail", {
        variant: "warning",
      })
    }
  }

  const toggleRailResourceIsHome = async (railResource) => {
    setLoading(true)
    const response = await editRailResource({
      ...railResource,
      isHome: railResource.isHome === 1 ? 0 : 1,
    })
    setLoading(false)
    verifyResponse(response)
    loadRailResource()
    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar("Rail Show Home Status Updated", {
        variant: "success",
      })
    } else {
      // handling error message
      enqueueSnackbar("Rail Show Home Status Update Fail", {
        variant: "warning",
      })
    }
  }

  const editRailItem = async ({
    id,
    name,
    railType,
    railTag,
    uiType,
    description,
    groupId,
    categoryIdList,
  }) => {
    setLoading(true)
    const response = await editRail({
      id,
      name,
      railType,
      railTag,
      uiType,
      description,
      groupId,
      categoryIdList,
    })
    setLoading(false)
    verifyResponse(response)
    onSubmit && onSubmit()
    setOpen(false)
    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar("Rail Update Success", {
        variant: "success",
      })
    } else {
      // handling error message
      enqueueSnackbar("Rail Update Fail", {
        variant: "warning",
      })
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const openDlg = async (editRail) => {
    reset()
    setResources([])
    railRef.current = editRail ? editRail : initRail
    setIsEditMode(editRail ? true : false)
    setValue(
      "railType",
      editRail ? editRail.railType : RAIL_TYPE.CUSTOMIZED.value
    )
    loadRailResource()
    setOpen(true)
  }

  const handleAction = async (data) => {
    isEditMode
      ? editRailItem({
          id: railRef.current.id,
          name: data.name,
          railType:
            data.railType === RAIL_TAG.FEATURED_PROGRAM.value
              ? 0
              : data.railType,
          railTag: data.railType,
          uiType: data.uiType,
          description: data.description,
          groupId: data.groupId,
          categoryIdList: railRef.current.categoryList.map((it) => it.id),
        })
      : addRailItem({
          name: data.name,
          railType:
            data.railType === RAIL_TAG.FEATURED_PROGRAM.value
              ? 0
              : data.railType,
          railTag: data.railType,
          uiType: data.uiType,
          description: data.description,
          groupId: data.groupId,
          categoryIdList: railRef.current.categoryList.map((it) => it.id),
        })
  }

  const dlg = (
    <StyledRailDialog open={open}>
      <DialogTitle>{isEditMode ? "Edit Rail " : "Add New Rail"}</DialogTitle>
      <form onSubmit={handleSubmit(handleAction)}>
        <DialogContent>
          <Box>
            <FormControl fullWidth margin="dense">
              <TextField
                label="Name"
                variant="standard"
                fullWidth
                defaultValue={railRef.current.name}
                {...register("name", { required: true })}
                error={!!errors?.name}
                helperText={!!errors?.name ? "* Name is required" : " "}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth margin="dense">
              <TextField
                margin="dense"
                label="Description"
                variant="standard"
                rows={3}
                multiline
                defaultValue={railRef.current.description}
                {...register("description", { required: true })}
                error={!!errors?.description}
                helperText={
                  !!errors?.description ? "* Description is required" : ""
                }
              />
            </FormControl>

            <FormControl fullWidth variant="standard" margin="dense">
              <InputLabel id="rail-type-select-label">Rail Type</InputLabel>
              <Select
                labelId="rail-type-select-label-id"
                id="rail-type-select-id"
                defaultValue={railRef.current.railTag}
                label="Rail Type"
                onChange={(e) => {
                  setValue("railType", e.target.value)
                }}
                {...register("railType", { required: false })}
              >
                {Object.values(RAIL_TAG).map((railType) => {
                  return (
                    <MenuItem
                      key={`rail-type-${railType.value}`}
                      value={railType.value}
                    >
                      {railType.text}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>

            <FormControl fullWidth variant="standard" margin="dense">
              <InputLabel id="rail-ui-type-select-label">UI Type</InputLabel>
              <Select
                labelId="rail-ui-type-select-label-id"
                id="rail-ui-type-select-id"
                defaultValue={railRef.current.uiType}
                label="UI Type"
                onChange={(e) => {
                  setValue("uiType", e.target.value)
                }}
                {...register("uiType", { required: true })}
              >
                {Object.values(RAIL_UI_TYPE).map((uiType) => {
                  return (
                    <MenuItem
                      key={`rail-ui-type-${uiType.value}`}
                      value={uiType.value}
                    >
                      {uiType.text}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Box>

          {watch("railType") === RAIL_TYPE.CUSTOMIZED.value &&
            watch("railTag") === RAIL_TAG.FEATURED_PROGRAM.value && (
              <>
                <div>dfd</div>
              </>
            )}

          {watch("railType") === RAIL_TYPE.CUSTOMIZED.value && (
            <>
              <div className="category-section">
                <div className="title-bar">
                  <div className="label-text">Category</div>
                  <AddCircleOutlinedIcon
                    variant="contained"
                    size="small"
                    onClick={() => {
                      openCategorySelectorDlg(
                        railRef.current.categoryList,
                        CATEGORY_SELECTOR_MODE.CATEGORY.value
                      )
                    }}
                  />
                </div>
                <div className="category-list">
                  {railRef.current.categoryList.map((cate) => {
                    return (
                      <div
                        key={`dlg-category-item-${cate.id}`}
                        className="category-item"
                      >
                        {cate.name}
                      </div>
                    )
                  })}
                </div>
              </div>

              {isEditMode && (
                <div className="resource-section">
                  <div className="title-bar">
                    <div className="label-text">Resource</div>
                    <div>
                      <Chip
                        label="Add Program"
                        color="success"
                        size="small"
                        onClick={() => {
                          openProgramSelectorDlg()
                        }}
                      />
                    </div>
                  </div>
                  <div className="resource-list">
                    <Container
                      onDrop={async (e) => {
                        const updateIndexArray = calculateNewRank({
                          addedIndex: e.addedIndex,
                          removedIndex: e.removedIndex,
                          indexString: "dataIndex",
                          list: resources,
                          updateOriginalList: setResources,
                        })
                        await exchangeRailResourceIndex({ updateIndexArray })
                        loadRailResource()
                      }}
                    >
                      {resources.map((resource) => (
                        <Draggable key={resource.id}>
                          <div className="resource-item">
                            <img
                              src={
                                resource.programDto.thumbResourceDto.urlDto
                                  .imgUrl_XS
                              }
                              alt="thumb img"
                            />
                            <div className="content">
                              <div className="resource-name">
                                {resource.programDto.title}
                              </div>
                              <p>{resource.programDto.description}</p>
                            </div>
                            <div className="actions">
                              <Tooltip title="Delete Rail Resource">
                                <IconButton
                                  sx={{ color: "#e20707" }}
                                  aria-label="remove rail resource"
                                  component="label"
                                  onClick={() => {
                                    openConfirmDialog(async () => {
                                      deleteResourceFromList(resource.id)
                                    })
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title={`Home display ${
                                  resource.isHome === 0 ? "OFF" : "NO"
                                }`}
                              >
                                <IconButton
                                  sx={{
                                    color:
                                      resource.isHome === 0
                                        ? "#b2b2b2"
                                        : "#6a8721",
                                  }}
                                  aria-label="rail resource home display"
                                  component="label"
                                  onClick={() => {
                                    toggleRailResourceIsHome(resource)
                                  }}
                                >
                                  {resource.isHome === 0 ? (
                                    <CancelIcon />
                                  ) : (
                                    <CheckCircleIcon />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </div>
                          </div>
                        </Draggable>
                      ))}
                    </Container>
                  </div>
                </div>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" size="small">
            {isEditMode ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </form>
      {programSelectorDlgContent}
      {categorySelectorDlg}
      {ConfirmDialog}
    </StyledRailDialog>
  )

  return [
    openDlg,
    dlg,
    loading || programSelectorDlgLoading || categorySelectorLoading,
  ]
}

export default RailDlg
