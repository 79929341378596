import React, { useState } from "react"
import PageHeading from "components/common/LayoutComponents/PageHeading"
import PageContent from "components/common/LayoutComponents/PageContent"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import Box from "@mui/material/Box"
import LoadingScreen from "components/common/LoadingScreen"

import RailGroupManagement from "./RailGroupManagement/RailGroupManagement"
import RailManagement from "./RailManagement/RailManagement"
import { RailControlPanelWrapper } from "./Rails.style"

const RailControlPanel = () => {
  const [value, setValue] = useState("rail")

  const [railGroupContent, railGroupLoading] = RailGroupManagement()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <PageContent>
      <RailControlPanelWrapper>
        <PageHeading>
          <div>Rails Management</div>
          <div className="actions"></div>
        </PageHeading>
        <div>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Rails" value="rail" />
                <Tab label="Groups" value="group" />
              </TabList>
            </Box>
            <TabPanel value="rail">
              <RailManagement />
            </TabPanel>
            <TabPanel value="group">{railGroupContent}</TabPanel>
          </TabContext>
        </div>
        <LoadingScreen loading={railGroupLoading} />
      </RailControlPanelWrapper>
    </PageContent>
  )
}

export default RailControlPanel
