import React, { useState } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { getRedeemBatchInfo } from "services/redeem";
import { verifyResponse } from "utils/httpRequest";
import { StyledRedeemDetail } from "../Redeem/Redeem.style";

const RedeemBatchDetail = () => {
  const [batchInfo, setBatchInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const loadBatchDetail = async (id) => {
    try {
      setLoading(true);
      const response = await getRedeemBatchInfo(id);
      verifyResponse(response);
      setBatchInfo(response.data);
    } catch (e) {
      console.error("getRedeemBatchInfo error: ", e);
    } finally {
      setLoading(false);
    }
  };

  const openBatchDetail = async (batchId) => {
    await loadBatchDetail(batchId);
    setOpen(true);
  };

  const content = open ? (
    <StyledRedeemDetail>
      <Card sx={{ maxWidth: 500 }}>
        <CardContent>
          <Typography variant="h5" color="text.secondary" gutterBottom>
            Redeem Batch Detail
          </Typography>
          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Batch ID:
          </Typography>
          <Typography variant="body2">{batchInfo?.id}</Typography>

          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Description:
          </Typography>
          <Typography variant="body2">{batchInfo?.description}</Typography>
          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Product:
          </Typography>
          <Typography variant="body2">
            {batchInfo.productName ? batchInfo.productName : "N/A"}
          </Typography>
          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Duration:
          </Typography>
          <Typography variant="body2">{batchInfo.duration}</Typography>
          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Created:
          </Typography>
          <Typography variant="body2">
            {`${batchInfo.createdUserNickName} at ${batchInfo.createdUserNickName}`}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </Button>
        </CardActions>
      </Card>
    </StyledRedeemDetail>
  ) : null;

  return [openBatchDetail, content, loading];
};

export default RedeemBatchDetail;
