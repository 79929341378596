import React, { useState, useEffect } from "react"
import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import Typography from "@mui/material/Typography"
import Switch from "@mui/material/Switch"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"
import SubscriptionProductPlatform from "./SubscriptionProductPlatform"
import { verifyResponse } from "utils/httpRequest"
import { useForm } from "react-hook-form"
import { useSnackbar } from "notistack"
import {
  DURATION_UNIT_LIST,
  SUBSCRIPTION_TYPE_LIST,
  CURRENCY_LIST,
} from "constants/global"
import {
  addSubscriptionProduct,
  editSubscriptionProduct,
  listPlatform,
} from "services/subscription"
import {
  StyledSubscriptionProductDlg,
  ProgramRankSlider,
  StyledPlatformSection,
} from "./SubscriptionProduct.style"

const SubscriptionProductDlg = (props) => {
  const initSubscriptionProduct = {
    groupId: 0,
    code: "",
    name: "",
    description: "",
    duration: 3,
    durationUnit: 1,
    price: 0.0,
    currency: "usd",
    subscriptionType: 1,
    programRank: 0,
    levelRank: 0,
    trialFlag: 0,
    trialDuration: 1,
  }

  const { enqueueSnackbar } = useSnackbar()

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm()

  const { onSubmit, subscriptionGroups, access } = props
  const [subscriptionProduct, setSubscriptionProduct] = useState([])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [platforms, setPlatforms] = useState([])

  const handleClose = () => {
    setOpen(false)
  }

  const openDlg = (editSubscriptionProduct) => {
    reset()

    setSubscriptionProduct(
      editSubscriptionProduct
        ? editSubscriptionProduct
        : {
            ...initSubscriptionProduct,
            groupId:
              subscriptionGroups.length > 0 ? subscriptionGroups[0].id : 0,
          }
    )
    setIsEditMode(editSubscriptionProduct ? true : false)
    setOpen(true)
  }

  const handleAction = async (data) => {
    // send request
    setLoading(true)
    const response = isEditMode
      ? await editSubscriptionProduct({
          ...data,
          id: subscriptionProduct.id,
          trialFlag: data.trialFlag ? 1 : 0,
        })
      : await addSubscriptionProduct({
          ...data,
          trialFlag: data.trialFlag ? 1 : 0,
        })
    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar(
        isEditMode
          ? "Update Subscription Product Success"
          : "Create Subscription Product Success",
        { variant: "success" }
      )
    } else {
      // handling error message
      enqueueSnackbar(
        isEditMode
          ? "Update Subscription Product Fail"
          : "Create Subscription Product Fail",
        { variant: "warning" }
      )
      response.rtnCode === 20033 &&
        enqueueSnackbar(response.message, {
          variant: "warning",
        })
    }
    setLoading(false)
    onSubmit()
    handleClose()
  }

  const refreshPlatform = async (code) => {
    if (code && code.trim() !== "") {
      const response = await listPlatform(code)
      verifyResponse(response)
      setPlatforms(response.data)
    }
  }

  useEffect(() => {
    subscriptionProduct?.code && refreshPlatform(subscriptionProduct.code)
  }, [subscriptionProduct])

  const dlg = (
    <StyledSubscriptionProductDlg open={open}>
      <form onSubmit={handleSubmit(handleAction)}>
        <DialogTitle>
          {isEditMode
            ? "Edit Subscription Product"
            : "Add Subscription Product"}
        </DialogTitle>
        <DialogContent>
          <div className="form-row-two-cols">
            <FormControl variant="standard">
              <InputLabel id="subscription-group-select-label">
                Subscription Group
              </InputLabel>
              <Select
                labelId="subscription-group-select"
                id="subscription-group-select"
                label="Group"
                defaultValue={subscriptionProduct.groupId}
                {...register("groupId", { required: true })}
                error={!!errors?.groupId}
                helperText={!!errors?.groupId ? "groupId is required" : " "}
              >
                {subscriptionGroups.map((group) => (
                  <MenuItem
                    key={`subscription-group-item-${group.id}`}
                    value={group.id}
                  >
                    {group.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <TextField
                autoFocus
                autoComplete="off"
                id="subscription-product-code"
                label="Code"
                type="text"
                fullWidth
                defaultValue={subscriptionProduct.code}
                {...register("code", { required: true })}
                variant="standard"
                error={!!errors?.code}
                helperText={!!errors?.code ? "Code is required" : " "}
              />
            </FormControl>
          </div>
          <FormControl>
            <TextField
              autoFocus
              autoComplete="off"
              id="subscription-product-name"
              label="Name"
              type="text"
              fullWidth
              defaultValue={subscriptionProduct.name}
              {...register("name", { required: true })}
              error={!!errors?.name}
              helperText={!!errors?.name ? "Name is required" : " "}
              variant="standard"
            />
          </FormControl>

          <FormControl>
            <TextField
              autoFocus
              autoComplete="off"
              id="subscription-product-description"
              label="Description"
              type="text"
              fullWidth
              multiline
              rows={5}
              defaultValue={subscriptionProduct.description}
              {...register("description", { required: true })}
              error={!!errors?.description}
              helperText={
                !!errors?.description ? "Description is required" : " "
              }
              variant="standard"
            />
          </FormControl>

          <div className="form-row-two-cols">
            <FormControl>
              <Typography className="control-label" gutterBottom>
                Trial Flag
              </Typography>
              <FormControlLabel
                size="small"
                control={
                  <Switch
                    defaultChecked={subscriptionProduct.trialFlag === 1}
                    size="small"
                    {...register("trialFlag", { required: false })}
                  />
                }
                label={watch("trialFlag") === false ? "Off" : "No"}
              />
            </FormControl>

            <FormControl>
              <TextField
                autoFocus
                autoComplete="off"
                id="subscription-product-trial-duration"
                label="Trial Duration"
                type="number"
                inputProps={{ step: "1", min: "1" }}
                defaultValue={subscriptionProduct.trialDuration}
                {...register("trialDuration", { required: true })}
                error={!!errors?.trialDuration}
                helperText={
                  !!errors?.trialDuration ? "trialDuration is required" : " "
                }
                variant="standard"
              />
            </FormControl>
          </div>

          <div className="form-row-two-cols">
            <FormControl>
              <TextField
                autoFocus
                autoComplete="off"
                id="subscription-product-duration"
                label="Duration"
                type="number"
                defaultValue={subscriptionProduct.duration}
                {...register("duration", { required: true })}
                error={!!errors?.duration}
                helperText={!!errors?.duration ? "duration is required" : " "}
                variant="standard"
              />
            </FormControl>

            <FormControl variant="standard">
              <InputLabel id="subscription-type-select-label">
                Duration Unit
              </InputLabel>
              <Select
                labelId="subscription-type-select"
                id="subscription-type-select"
                defaultValue={subscriptionProduct.durationUnit}
                {...register("durationUnit", { required: true })}
                error={!!errors?.durationUnit}
                helperText={
                  !!errors?.durationUnit ? "duration unit is required" : " "
                }
                label="Duration Unit"
              >
                {DURATION_UNIT_LIST.map((type) => (
                  <MenuItem
                    key={`subscription-type-select-item-${type.value}`}
                    value={type.value}
                  >
                    {type.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="form-row-two-cols">
            <FormControl>
              <TextField
                inputProps={{ step: "0.01", min: "0" }}
                autoFocus
                autoComplete="off"
                id="subscription-product-price"
                label="Price"
                type="number"
                min="0"
                defaultValue={subscriptionProduct.price}
                {...register("price", { required: true })}
                error={!!errors?.price}
                helperText={!!errors?.price ? "price is required" : " "}
                variant="standard"
              />
            </FormControl>

            <FormControl variant="standard" sx={{ m: 1, minWidth: 230 }}>
              <InputLabel id="subscription-product-price-currency-label">
                Currency
              </InputLabel>
              <Select
                labelId="subscription-product-price-currency"
                id="subscription-product-price-currency"
                defaultValue={subscriptionProduct.currency}
                {...register("currency", { required: true })}
                error={!!errors?.currency}
                helperText={!!errors?.currency ? "currency is required" : " "}
                label="Currency"
              >
                {CURRENCY_LIST.map((currency) => (
                  <MenuItem
                    key={`subscription-product-price-${currency}`}
                    value={currency}
                  >
                    {currency.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <FormControl variant="standard" sx={{ m: 1.5, minWidth: 230 }}>
            <InputLabel id="subscription-type-select-label">
              Subscription Type
            </InputLabel>
            <Select
              labelId="subscription-type-select"
              id="subscription-type-select"
              defaultValue={subscriptionProduct.subscriptionType}
              {...register("subscriptionType", { required: true })}
              error={!!errors?.subscriptionType}
              helperText={
                !!errors?.subscriptionType
                  ? "Subscription Type is required"
                  : " "
              }
              label="Subscription Type"
            >
              {SUBSCRIPTION_TYPE_LIST.map((type) => (
                <MenuItem
                  key={`subscription-type-select-item-${type.value}`}
                  value={type.value}
                >
                  {type.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div className="form-row-two-cols">
            <Box sx={{ width: 320, paddingRight: "60px" }}>
              <Typography className="control-label" gutterBottom>
                Program Rank {watch("programRank") ? watch("programRank") : "0"}
              </Typography>
              <ProgramRankSlider
                aria-label="Program Rank"
                getAriaValueText={(value) => {
                  return `${value}°C`
                }}
                defaultValue={subscriptionProduct.programRank}
                {...register("programRank", { required: true })}
                valueLabelDisplay="auto"
                step={1}
                min={0}
                max={10}
              />
            </Box>

            <Box sx={{ width: 320, paddingRight: "60px" }}>
              <Typography className="control-label" gutterBottom>
                Level Rank {watch("levelRank") ? watch("levelRank") : "0"}
              </Typography>
              <ProgramRankSlider
                aria-label="Level Rank"
                getAriaValueText={(value) => {
                  return `${value}°C`
                }}
                defaultValue={subscriptionProduct.levelRank}
                {...register("levelRank", { required: true })}
                valueLabelDisplay="auto"
                step={1}
                min={0}
                max={10}
              />
            </Box>
          </div>
          {isEditMode && (
            <StyledPlatformSection>
              <div className="platform-title-label">Product Platform</div>
              <SubscriptionProductPlatform
                productId={subscriptionProduct.id}
                code={subscriptionProduct.code}
                platforms={platforms}
                refreshPlatform={refreshPlatform}
                access={access}
              />
            </StyledPlatformSection>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="Submit" variant="contained" size="small">
            {isEditMode ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </form>
    </StyledSubscriptionProductDlg>
  )

  return [openDlg, dlg, loading]
}

export default SubscriptionProductDlg
