import React, { useState } from "react"
import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import Switch from "@mui/material/Switch"
import Box from "@mui/material/Box"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import FormControlLabel from "@mui/material/FormControlLabel"
import { addModal, editModal } from "services/modal"
import { MODAL_PRESENTATION_TYPE } from "constants/global"
import { useForm } from "react-hook-form"
import { useSnackbar } from "notistack"
import { StyledModalDialog } from "./Modal.style"

const ModelDlg = (props) => {
  const initModal = {
    tag: "",
    description: "",
    presentationType: 0,
    showEveryTime: 0,
  }

  const { enqueueSnackbar } = useSnackbar()

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm()

  const { onSubmit } = props
  const [modal, setModal] = useState(initModal)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const openDlg = (editModal) => {
    reset()
    setModal(editModal || initModal)
    setIsEditMode(editModal ? true : false)
    setOpen(true)
  }

  const handleAction = async (data) => {
    // send request
    setLoading(true)
    const response = isEditMode
      ? await editModal({
          ...modal,
          ...data,
          showEveryTime: data.showEveryTime ? 1 : 0,
        })
      : await addModal({
          ...modal,
          ...data,
          showEveryTime: data.showEveryTime ? 1 : 0,
        })
    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar(isEditMode ? "Modal Update" : "Create Modal success", {
        variant: "success",
      })
    } else {
      // handling error message
      enqueueSnackbar(isEditMode ? "FAQ Modal Fail" : "Create Modal Fail", {
        variant: "warning",
      })
    }
    onSubmit()
    setLoading(false)
    handleClose()
  }

  const dlg = (
    <StyledModalDialog open={open}>
      <DialogTitle>{isEditMode ? "Edit Modal" : "Add Modal"}</DialogTitle>
      <form onSubmit={handleSubmit(handleAction)}>
        <DialogContent>
          <Box>
            <FormControl fullWidth margin="dense">
              <TextField
                label="Tag"
                variant="standard"
                fullWidth
                defaultValue={modal.tag}
                {...register("tag", { required: true })}
                error={!!errors?.tag}
                helperText={!!errors?.tag ? "* Tag is required" : " "}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl variant="standard" fullWidth margin="dense">
              <input
                type="hidden"
                defaultValue={modal.presentationType}
                {...register("presentationType", { required: true })}
              />
              <InputLabel id="modal-presentation-select-label">Type</InputLabel>
              <Select
                labelId="modal-presentation-type-select"
                id="demo-simple-select"
                defaultValue={modal.presentationType}
                label="Presentation Type"
                onChange={(e) => {
                  setValue("presentationType", e.target.value)
                }}
              >
                {MODAL_PRESENTATION_TYPE.map((type) => (
                  <MenuItem
                    key={`modal-presentation-type-item-${type.value}`}
                    value={type.value}
                  >
                    {type.text}
                  </MenuItem>
                ))}
              </Select>
              {!!errors?.presentationType && (
                <div className="error">* Presentation type is required</div>
              )}
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth margin="dense">
              <TextField
                margin="dense"
                label="Description"
                variant="standard"
                rows={5}
                multiline
                defaultValue={modal.description}
                {...register("description", { required: true })}
                error={!!errors?.description}
                helperText={
                  !!errors?.answer ? "* Description is required" : " "
                }
              />
            </FormControl>
          </Box>

          <FormControlLabel
            control={
              <Switch
                {...{ inputProps: { "aria-label": "Show Every Time" } }}
                defaultChecked={modal.showEveryTime === 1 ? true : false}
                {...register("showEveryTime")}
              />
            }
            label="Show Every Time"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" size="small">
            {isEditMode ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </form>
    </StyledModalDialog>
  )

  return [openDlg, dlg, loading]
}

export default ModelDlg
