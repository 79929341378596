import { httpPost } from "utils/httpRequest";

//-------------------------------
//  Modal
//-------------------------------
const addModal = async ({
  tag,
  description,
  presentationType,
  showEveryTime,
}) => {
  const resp = await httpPost("admin/modal/add", {
    tag,
    description,
    presentationType,
    showEveryTime,
  });
  return resp.data;
};
const editModal = async ({
  id,
  tag,
  description,
  presentationType,
  showEveryTime,
}) => {
  const resp = await httpPost("admin/modal/edit", {
    id,
    tag,
    description,
    presentationType,
    showEveryTime,
  });
  return resp.data;
};

const searchModel = async () => {
  const resp = await httpPost("admin/modal/listModal", {
    pageNo: 1,
    pageSize: 1000,
  });
  return resp.data;
};

//-------------------------------
//  Modal content
//-------------------------------

const getModelContentByModalId = async (modalId) => {
  const resp = await httpPost(
    `admin/modal/getModalContentByModalId/${modalId}`
  );
  return resp.data;
};

const addModalContent = async ({ modalId, content, type, resourceId, ext }) => {
  const resp = await httpPost(`admin/modal/addModalContent`, {
    modalId,
    content,
    type,
    resourceId,
    ext,
  });
  return resp.data;
};

const editModalContent = async ({
  id,
  modalId,
  content,
  type,
  resourceId,
  ext,
}) => {
  const resp = await httpPost(`admin/modal/editModalContent`, {
    id,
    modalId,
    content,
    type,
    resourceId,
    ext,
  });
  return resp.data;
};

const deleteModalContent = async (modalContentId) => {
  const resp = await httpPost(
    `admin/modal/deleteModalContent/${modalContentId}`
  );
  return resp.data;
};

const exchangeModalContentOrder = async (updateIndexArray) => {
  const resp = await httpPost(`admin/modal/exchangeModalContentIndex`, {
    updateIndexArray,
  });
  return resp.data;
};

export {
  addModal,
  editModal,
  searchModel,
  getModelContentByModalId,
  addModalContent,
  editModalContent,
  deleteModalContent,
  exchangeModalContentOrder,
};
