import React, { useState, useEffect } from "react"
import Button from "@mui/material/Button"
import LoadingScreen from "components/common/LoadingScreen"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { PERMISSIONS } from "constants/permission"
import { UsePermissionVerification } from "components/customHooks"
import Paper from "@mui/material/Paper"
import { useSnackbar } from "notistack"
import { verifyResponse } from "utils/httpRequest"
import PageHeading from "components/common/LayoutComponents/PageHeading"
import PageContent from "components/common/LayoutComponents/PageContent"
import {
  getSubscriptionGroups,
  getSubscriptionProducts,
  pubSubscriptionProduct,
  cancelPubSubscriptionProduct,
} from "services/subscription"
import SubscriptionProductDlg from "./SubscriptionProductDlg"
import {
  StyledSubscriptionProduct,
  StyledSubscriptionProductTable,
} from "./SubscriptionProduct.style"

const SubscriptionProduct = () => {
  const [loading, setLoading] = useState(false)
  const [subscriptionProducts, setSubscriptionProducts] = useState([])
  const [subscriptionGroups, setSubscriptionGroups] = useState([])

  const { enqueueSnackbar } = useSnackbar()

  const AccessControl = UsePermissionVerification()

  const [
    openSubscriptionProductDialog,
    subscriptionProductDialog,
    primeDialogLoading,
  ] = SubscriptionProductDlg({
    onSubmit: () => {
      refreshSubscriptionProduct()
    },
    subscriptionGroups,
    access: AccessControl.Access,
  })

  useEffect(() => {
    AccessControl.SetAccess({
      ...AccessControl.Access,
      listSubscriptionProductAccess: AccessControl.VerifyAccess([
        PERMISSIONS.SUBSCRIPTION.SUBSCRIPTION_GET_ALL_PRODUCT_LIST,
      ]),
      addSubscriptionProductAccess: AccessControl.VerifyAccess([
        PERMISSIONS.SUBSCRIPTION.SUBSCRIPTION_ADD_PRODUCT,
      ]),
      editSubscriptionProductAccess: AccessControl.VerifyAccess([
        PERMISSIONS.SUBSCRIPTION.SUBSCRIPTION_EDIT_PRODUCT,
      ]),
      pubSubscriptionProductAccess: AccessControl.VerifyAccess([
        PERMISSIONS.SUBSCRIPTION.SUBSCRIPTION_PUB_PRODUCT,
      ]),
      cancelPubSubscriptionProductAccess: AccessControl.VerifyAccess([
        PERMISSIONS.SUBSCRIPTION.SUBSCRIPTION_CANCEL_PUB_PRODUCT,
      ]),
      listSubscriptionPlatformProductAccess: AccessControl.VerifyAccess([
        PERMISSIONS.SUBSCRIPTION.SUBSCRIPTION_LIST_PLATFORM_PRODUCT,
      ]),
      addSubscriptionPlatformProductAccess: AccessControl.VerifyAccess([
        PERMISSIONS.SUBSCRIPTION.SUBSCRIPTION_ADD_PLATFORM_PRODUCT,
      ]),
      editSubscriptionPlatformProductAccess: AccessControl.VerifyAccess([
        PERMISSIONS.SUBSCRIPTION.SUBSCRIPTION_EDIT_PLATFORM_PRODUCT,
      ]),
      deleteSubscriptionPlatformProductAccess: AccessControl.VerifyAccess([
        PERMISSIONS.SUBSCRIPTION.SUBSCRIPTION_DELETE_PLATFORM_PRODUCT,
      ]),
    })
  }, [AccessControl.UserPermissions])

  const refreshSubscriptionProduct = async () => {
    setLoading(true)
    const response = await getSubscriptionProducts()
    verifyResponse(response)
    setSubscriptionProducts(response.data)
    setLoading(false)
  }

  const refreshSubscriptionGroup = async () => {
    const response = await getSubscriptionGroups()
    setSubscriptionGroups(response.data)
  }

  const pubProduct = async (productId) => {
    const response = await pubSubscriptionProduct(productId)
    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar("Publish subscription product Success", {
        variant: "success",
      })
    } else {
      // handling error message
      enqueueSnackbar("Fail to publish product", {
        variant: "warning",
      })
    }
    refreshSubscriptionProduct()
  }

  const cancelPubProduct = async (productId) => {
    const response = await cancelPubSubscriptionProduct(productId)
    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar("Cancel publish product Suc cess", {
        variant: "success",
      })
    } else {
      // handling error message
      enqueueSnackbar("Fail to cancel publish product", {
        variant: "warning",
      })
    }
    refreshSubscriptionProduct()
  }

  useEffect(() => {
    // fetch group list
    refreshSubscriptionGroup()
    // fetch product list
    refreshSubscriptionProduct()
  }, [])

  return (
    <StyledSubscriptionProduct>
      <PageContent>
        <PageHeading>
          <div>Subscription Product</div>
          <div className="actions">
            {AccessControl.Access.addSubscriptionProductAccess && (
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  openSubscriptionProductDialog()
                }}
              >
                Add New Product
              </Button>
            )}
          </div>
        </PageHeading>
        <StyledSubscriptionProductTable>
          {!subscriptionProducts || subscriptionProducts.length === 0 ? (
            <div className="empty-group-des">
              No Subscription Product yet, please create a Subscription Product
            </div>
          ) : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="left">Group</TableCell>
                    <TableCell align="center">Duration</TableCell>
                    <TableCell align="center">Price</TableCell>
                    <TableCell align="left">Subscription Type</TableCell>
                    <TableCell align="center">Trial</TableCell>
                    <TableCell align="left">Rank</TableCell>
                    <TableCell align="right">&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subscriptionProducts.map((row) => (
                    <TableRow
                      key={`program-table-row-${row.id}`}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.code}</TableCell>
                      <TableCell align="left">
                        {row.pubStatus ? "Published" : "Unpublished"}
                      </TableCell>
                      <TableCell align="left">{row.groupName}</TableCell>
                      <TableCell align="center">{row.duration}</TableCell>
                      <TableCell align="center">{row.price}</TableCell>
                      <TableCell align="center">
                        {row.subscriptionType}
                      </TableCell>
                      <TableCell align="center">
                        {row.trialFlag
                          ? `${row.trialDuration} days`
                          : "No Available"}
                      </TableCell>
                      <TableCell align="center">{row.programRank}</TableCell>
                      <TableCell align="right">
                        {row.pubStatus === 1 &&
                          AccessControl.Access
                            .cancelPubSubscriptionProductAccess && (
                            <Button
                              variant="outlined"
                              onClick={() => {
                                cancelPubProduct(row.id)
                              }}
                            >
                              Cancel Publish
                            </Button>
                          )}
                        {row.pubStatus === 0 &&
                          AccessControl.Access.pubSubscriptionProductAccess && (
                            <Button
                              variant="outlined"
                              onClick={() => {
                                pubProduct(row.id)
                              }}
                            >
                              Process Publish
                            </Button>
                          )}
                        {AccessControl.Access.editSubscriptionProductAccess && (
                          <Button
                            variant="contained"
                            onClick={() => {
                              openSubscriptionProductDialog(row)
                            }}
                          >
                            Edit
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </StyledSubscriptionProductTable>
        {subscriptionProductDialog}
        <LoadingScreen loading={loading || primeDialogLoading} />
      </PageContent>
    </StyledSubscriptionProduct>
  )
}

export default SubscriptionProduct
