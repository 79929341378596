import React, { useState } from "react"
import TextField from "@mui/material/TextField"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import Button from "@mui/material/Button"

import { StyledMemberSearch, StyledMemberSearchBar } from "./Member.style"

const MemberSearch = ({ setSearchOptions }) => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [isGuest, setIsGuest] = useState(-1)
  const [isTrial, setIsTrial] = useState(-1)
  const [status, setStatus] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const clearSearch = () => {
    setEmail("")
    setName("")
    setIsGuest(-1)
    setIsTrial(-1)
  }

  const searchAction = () => {
    const options = {
      pageNo: 1,
      pageSize: pageSize,
      searchName: name,
      searchEmail: email,
      isGuest: isGuest === -1 ? null : isGuest,
      trialFlag: isTrial === -1 ? null : isTrial,
      isActive: status,
    }
    setSearchOptions(options)
  }

  return (
    <StyledMemberSearch>
      <StyledMemberSearchBar>
        <TextField
          style={{ width: "200px", margin: "0" }}
          className="name"
          autoFocus
          autoComplete="off"
          margin="dense"
          id="member-search-user-name"
          label="Name"
          type="text"
          value={name}
          onChange={(e) => {
            setName(e.target.value)
          }}
          variant="outlined"
        />
        <TextField
          style={{ width: "200px" }}
          className="email"
          autoComplete="off"
          margin="dense"
          id="member-search-user-email"
          label="Email"
          type="text"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          variant="outlined"
        />
        <FormControl style={{ width: "120px" }}>
          <InputLabel id="member-is-guess-select-label">Is Guess</InputLabel>
          <Select
            labelId="member-is-guess-select-label"
            id="member-is-guess-select"
            value={isGuest}
            label="Is Guess"
            onChange={(e) => {
              setIsGuest(e.target.value)
            }}
          >
            <MenuItem value={-1}>All</MenuItem>
            <MenuItem value={0}>Member</MenuItem>
            <MenuItem value={1}>Guess</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ width: "120px" }}>
          <InputLabel id="member-is-guess-select-label">Is Trial</InputLabel>
          <Select
            labelId="member-is-guess-select-label"
            id="member-is-guess-select"
            value={isTrial}
            label="Is Trial"
            onChange={(e) => {
              setIsTrial(e.target.value)
            }}
          >
            <MenuItem value={-1}>All</MenuItem>
            <MenuItem value={0}>Member</MenuItem>
            <MenuItem value={1}>Trial</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ width: "150px" }}>
          <InputLabel id="member-is-guess-select-label">Status</InputLabel>
          <Select
            labelId="member-is-active-select-label"
            id="member-is-active-select"
            value={status}
            label="Status"
            onChange={(e) => {
              setStatus(e.target.value)
            }}
          >
            <MenuItem value={1}>Activated</MenuItem>
            <MenuItem value={0}>Deactivated</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ width: "100px" }}>
          <InputLabel id="demo-simple-select-label">Page Size</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={pageSize}
            label="Page Size"
            onChange={(e) => {
              setPageSize(e.target.value)
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
        <Button
          className="search-btn"
          variant="contained"
          size="large"
          onClick={() => {
            searchAction()
          }}
        >
          Search
        </Button>
        <Button
          className="search-btn"
          variant=""
          size="large"
          onClick={clearSearch}
        >
          Clear
        </Button>
      </StyledMemberSearchBar>
    </StyledMemberSearch>
  )
}

export default MemberSearch
