import React, { useState } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { addFaq, editFaq } from "services/faq";
import { StyledFaqDialog } from "./Faq.style";

const FaqDlg = (props) => {
  const initFaq = {
    title: "",
    answer: "",
  };

  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const { onSubmit } = props;
  const [faq, setFaq] = useState(initFaq);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const openDlg = (editFaq) => {
    reset();
    setFaq(editFaq || initFaq);
    setIsEditMode(editFaq ? true : false);
    if (editFaq) {
      setValue("answer", faq.answer);
    } else {
      setValue("answer", "");
    }
    setOpen(true);
  };

  const handleAction = async (data) => {
    // send request
    setLoading(true);
    const response = isEditMode
      ? await editFaq({ ...data, id: faq.id })
      : await addFaq(data);
    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar(isEditMode ? "FAQ Update" : "Create FAQ success", {
        variant: "success",
      });
    } else {
      // handling error message
      enqueueSnackbar(isEditMode ? "FAQ Update Fail" : "Create FAQ Fail", {
        variant: "warning",
      });
    }
    setLoading(false);
    onSubmit();
    handleClose();
  };

  const dlg = (
    <StyledFaqDialog open={open}>
      <DialogTitle>{isEditMode ? "Edit FAQ" : "Add FAQ"}</DialogTitle>
      <form onSubmit={handleSubmit(handleAction)}>
        <DialogContent>
          <TextField
            label="FAQ question"
            rows={3}
            multiline
            fullWidth
            defaultValue={faq.title}
            {...register("title", { required: true })}
            error={!!errors?.title}
            helperText={!!errors?.title ? "Title is required" : " "}
          />
          <FormControl>
            <ReactQuill
              theme="snow"
              defaultValue={faq.answer}
              onChange={(val) => {
                setValue("answer", val);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" size="small">
            {isEditMode ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </form>
    </StyledFaqDialog>
  );

  return [openDlg, dlg, loading];
};

export default FaqDlg;
