import { useSelector } from "react-redux";
import _ from "lodash";

const GetStoreUserPermissions = () => {
  return useSelector((state) => state.user.permissions);
};

const AccessVerification = (userPermissions, permissionList) => {
  if (permissionList === undefined || permissionList === null) {
    return false;
  }

  if (typeof permissionList === "object" && permissionList.length > 0) {
    for (const permission of permissionList) {
      if (!userPermissions.includes(permission)) {
        return false;
      }
    }
    return true;
  }

  return false;
};

const AccessVerificationByCategory = (
  userPermissions,
  categoryPermissionObject
) => {
  if (
    categoryPermissionObject === undefined ||
    categoryPermissionObject === null ||
    typeof categoryPermissionObject !== "object"
  ) {
    return false;
  }
  const permissionList = _.values(categoryPermissionObject);
  if (permissionList.length > 0) {
    for (const permission of permissionList) {
      if (userPermissions.includes(permission)) {
        return true;
      }
    }
  }

  return false;
};

export {
  GetStoreUserPermissions,
  AccessVerification,
  AccessVerificationByCategory,
};
