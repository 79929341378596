import styled from "@emotion/styled"
import Dialog from "@mui/material/Dialog"

const SystemConfigWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .code-display {
    width: 100%;
    height: calc(100% - 40px);
    background-color: red;
    color: white;
    padding: 20px;
    font-size: 13px;
    .monaco-editor {
      height: calc(100vh - 80px);
      padding: 15px 0;
    }
  }

  .display-config-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .heading {
      font-size: 20px;
      font-weight: 400;
    }
  }

  .display-code-info {
    margin-top: 20px;
    .label-text {
      background-color: #eee;
      font-size: 14px;
      font-weight: 300;
      display: inline-block;
      min-width: 80px;
      padding: 5px 10px;
      border-radius: 3px;
      margin: 0 15px 8px 0px;
      color: #666;
    }
    .name {
      font-size: 18px;
      font-weight: 300;
    }
    .key {
      font-size: 14px;
      font-weight: 200;
    }
  }

  .json-editor {
    margin-top: 10px;
    height: calc(100% - 65px);
    .monaco-editor {
      padding: 15px 0;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    }
  }

  .config-panel {
    height: calc(100vh - 180px);
    width: 100%;
    display: flex;
    flex-direction: row;

    .config-list {
      width: 300px;
      height: 100%;
      background-color: white;
      border-right: 1px solid #bbb;
      overflow-y: scroll;

      .config-item {
        cursor: pointer;
        padding: 10px 10px;
        width: calc(100% - 20px);
        background-color: white;
        border-bottom: 1px solid #ede5e5;
        color: #444;
        &:hover {
          background-color: #f6f1f1;
        }
        &.selected {
          background-color: #eee5e6;
        }
        .info {
          .name {
            font-size: 16px;
          }
          .key {
            display: inline-block;
            margin-top: 8px;
            font-size: 12px;
            color: #956c6c;
            padding: 4px 10px;
            background-color: #ede5e5;
            border-radius: 3px;
          }
        }

        textarea {
          margin-top: 30px;
          height: 300px;
          width: calc(100% - 20px);
          padding: 10px;
          border: 1px solid #bbb;
          color: #666;
          border-radius: 3px;
          &:focus,
          &:focus-visible {
            border: 1px solid #bbb;
            outline: none;
          }
        }
      }
    }

    .config-value-display {
      width: calc(100% - 350px);
      height: calc(100% - 40px);
      padding: 20px;
      background-color: white;
      textarea {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        padding: 10px;
        border: 1px solid #bbb;
        color: #666;
        border-radius: 3px;
        &:focus,
        &:focus-visible {
          border: 1px solid #bbb;
          outline: none;
        }
      }
    }

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
`

const StyledSystemConfigDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;

  .MuiPaper-root {
    width: 100%;
    max-width: 800px;
  }

  .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
  }

  .label-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4375em;
    -webkit-letter-spacing: 0.00938em;
    -moz-letter-spacing: 0.00938em;
    -ms-letter-spacing: 0.00938em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.6);
    margin: 10px 0;
  }

  .error-message {
    height: 30px;
    color: #d32f2f;
    font-size: 0.75rem;
    line-height: 20px;
  }
  .json-editor {
    margin-top: 10px;
    .monaco-editor {
      padding: 15px 0;
    }
  }
`

export { SystemConfigWrapper, StyledSystemConfigDialog }
