import styled from "@emotion/styled";
import Dialog from "@mui/material/Dialog";

const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  .actions {
    button {
      margin-left: 10px;
    }
  }
`;

const StyledModalDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  padding: 10px;

  .MuiPaper-root {
    width: 600px;
  }
  .error {
    font-size: 0.75rem;
    color: #d32f2f;
    margin-top: 3px;
  }
`;

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  .header-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      margin-left: 10px;
    }
  }
  .smooth-dnd-container {
    display: flex;
    flex-direction: column;
    width: 840px;
    margin: 0 auto;
  }

  .modal-content-card {
    padding: 20px 15px;
    border-radius: 4px;
    margin-top: 10px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .modal-content-card-detail {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-around;
    }

    .control-block {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 200px;
      align-items: flex-end;
      button {
        width: 100px;
        margin: 0;
      }
    }
  }
`;

const StyledModalContentDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  min-width: 600px;
  .control-label {
    font-size: 16px;
    color: #818181;
    margin: 15px 0 7px 0;
  }
  .error {
    font-size: 0.75rem;
    color: #d32f2f;
    margin-top: 3px;
  }

  .MuiPaper-root {
    min-width: 800px;
    min-height: 550px;
  }
`;

export {
  StyledModal,
  StyledModalDialog,
  StyledModalContent,
  StyledModalContentDialog,
};
