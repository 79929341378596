import React, { useState } from "react"
import Tooltip from "@mui/material/Tooltip"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import LinkIcon from "@mui/icons-material/Link"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import EditIcon from "@mui/icons-material/Edit"
import AddIcon from "@mui/icons-material/Add"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import Button from "@mui/material/Button"
import Chip from "@mui/material/Chip"
import { generateInnerciseLinkHtml } from "utils/program"
import { StyledProgramLevelCard } from "./Program.style"
import { INNERCISE_MODE } from "constants/global"

const ProgramLevelCard = ({
  programId,
  level,
  access,
  openProgramInnerciseDlg,
  openProgramLevelDlg,
  openDeleteLevelDlg,
  deleteProgramInnerciseAction,
}) => {
  const [showInnercise, setShowInnercise] = useState(false)

  return (
    <StyledProgramLevelCard>
      <div className="header">
        <div>{level.name}</div>
        <div>
          <Chip color="primary" label={`Rank : ${level.rank}`} />
          {access.editLevelAccess && (
            <EditIcon
              className="btn edit-btn"
              size="sm"
              onClick={() => {
                openProgramLevelDlg(level)
              }}
            />
          )}
          {access.deleteLevelAccess && (
            <CloseIcon
              className="btn close-btn"
              size="sm"
              role="button"
              onClick={() => {
                openDeleteLevelDlg(level.id)
              }}
            />
          )}
        </div>
      </div>

      <div className="innercise-header">
        <div className="label">
          {access.addInnerciseAccess && (
            <>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  openProgramInnerciseDlg(
                    INNERCISE_MODE.INNERCISE.value,
                    programId,
                    level.id,
                    null
                  )
                  setShowInnercise(true)
                }}
                size="small"
                style={{ marginRight: "10px" }}
              >
                Add Innercise
              </Button>

              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  openProgramInnerciseDlg(
                    INNERCISE_MODE.PLAYBOOK.value,
                    programId,
                    level.id,
                    null
                  )
                  setShowInnercise(true)
                }}
                size="small"
                style={{
                  marginRight: "10px",
                  backgroundColor: "rgb(93, 143, 45)",
                }}
              >
                Add Playbook
              </Button>
            </>
          )}
        </div>
        <div>
          <Button
            variant="contained"
            endIcon={showInnercise ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={() => {
              setShowInnercise(!showInnercise)
            }}
            size="small"
            color="success"
          >
            {showInnercise ? "Hide" : "Show"}
          </Button>
        </div>
      </div>

      <div className={`innercise-list ${!showInnercise && "hide"}`}>
        {level.innerciseList.map((innercise) => (
          <div
            className="innercise-item"
            key={`program-innercise-item-${innercise.id}`}
          >
            <div className="header">
              <div>
                {innercise.dataType === INNERCISE_MODE.INNERCISE.value && (
                  <>
                    Innercise ID : <strong>{innercise.id}</strong>
                  </>
                )}

                {innercise.dataType === INNERCISE_MODE.PLAYBOOK.value && (
                  <>
                    Playbook ID : <strong>{innercise.id}</strong>
                  </>
                )}
              </div>

              <div className="icon-btn">
                {access.editInnerciseAccess && (
                  <>
                    <Tooltip title="Copy Deep Link Html">
                      <IconButton
                        sx={{ color: "#182b06" }}
                        aria-label="copy innercise share link"
                        component="label"
                        onClick={async () => {
                          const htmlLink = generateInnerciseLinkHtml({
                            title: innercise.title,
                          })
                          await navigator.clipboard.writeText(htmlLink)
                        }}
                        size="small"
                      >
                        <LinkIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Edit Innercise">
                      <IconButton
                        sx={{ color: "#5d8f2d" }}
                        aria-label="edit innercise"
                        component="label"
                        onClick={() => {
                          openProgramInnerciseDlg(
                            innercise.dataType, // Innercise Mode: 1=Innercise, 2=Playbook
                            programId,
                            level.id,
                            innercise.id
                          )
                        }}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}

                {access.deleteInnerciseAccess && (
                  <Tooltip title="Delete Innercise">
                    <IconButton
                      sx={{ color: "#bb3030" }}
                      aria-label="delete innercise"
                      component="label"
                      onClick={() => {
                        deleteProgramInnerciseAction(innercise.id)
                      }}
                      size="small"
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>
            <div className="title">{innercise.title}</div>
            <div className="thumb">
              <img
                src={innercise.thumbResourceDto.urlDto.imgUrl_XS}
                alt="innercise thumbnail"
              />
            </div>
          </div>
        ))}
      </div>
    </StyledProgramLevelCard>
  )
}

export default ProgramLevelCard
