const getRandomNumber = () => {
  const crypto = window.crypto || window.msCrypto
  const randomBytes = new Uint32Array(1)
  crypto.getRandomValues(randomBytes)
  return randomBytes[0] / 4294967295
}

const getRandomInt = (min, max) => {
  const crypto = window.crypto || window.msCrypto
  const randomBytes = new Uint32Array(1)
  crypto.getRandomValues(randomBytes)
  const randomFloat = randomBytes[0] / 4294967295
  return min + parseInt((max - min) * randomFloat)
}

export { getRandomNumber, getRandomInt }
