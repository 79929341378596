import React, { useState } from "react"
import LoadingButton from "@mui/lab/LoadingButton"
import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import { useSnackbar } from "notistack"
import { useForm } from "react-hook-form"
import { updateMemberEmail } from "services/member"
import { verifyResponse } from "utils/httpRequest"
import { StyledModalMemberPasswordResetDialog } from "./Member.style"

const MemberUpdateEmailDlg = ({ refreshMemberDetail }) => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [memberId, setMemberId] = useState(0)

  const { enqueueSnackbar } = useSnackbar()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const handleClose = () => {
    setOpen(false)
  }
  const openDlg = (userId) => {
    setMemberId(userId)
    setOpen(true)
  }

  const changeAccountEmail = async ({ newEmail }) => {
    setLoading(true)
    const response = await updateMemberEmail({
      memberId,
      newEmail,
    })
    setLoading(false)
    verifyResponse(response)

    if (response.rtnCode === 200) {
      refreshMemberDetail()
      enqueueSnackbar("Update Email Address Succeeded!", {
        variant: "success",
      })
      reset()
      setOpen(false)
    } else {
      enqueueSnackbar("Fail Update Email Address", {
        variant: "warning",
      })
    }
  }

  const handleAction = async (data) => {
    // send request
    await changeAccountEmail({ newEmail: data.newEmail })

    // send notification message here
  }
  const dlg = (
    <StyledModalMemberPasswordResetDialog open={open} minWidth={600}>
      <DialogTitle>Update Account Email Address</DialogTitle>
      <DialogContent>
        <form
          onSubmit={handleSubmit(handleAction)}
          className="email-change-form"
        >
          <TextField
            type="email"
            label="New Email Address"
            required
            {...register("newEmail", {
              required: "New Email address is required",
            })}
          />

          <LoadingButton
            loading={loading}
            loadingIndicator="Updating..."
            type="submit"
            size="large"
            variant="contained"
          >
            Send Email Update Request
          </LoadingButton>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </StyledModalMemberPasswordResetDialog>
  )

  return [openDlg, dlg, loading]
}

export default MemberUpdateEmailDlg
