import React, { useState } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { editResource } from "services/media";
import { verifyResponse } from "utils/httpRequest";
import { StyledMediaMangerDialog } from "./MediaManager.style";

const MediaRecordDlg = (props) => {
  const initMediaRecord = {
    name: "",
    type: 0,
    awsKey: "",
    awsBucket: "",
    totalLength: 0,
    size: 0,
    tags: "",
    suffix: "",
    description: "",
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { onSubmit } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [mediaRecord, setMediaRecord] = useState(initMediaRecord);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const openDlg = (editMediaRecord) => {
    reset();
    setMediaRecord(editMediaRecord);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAction = async (data) => {
    // send request
    setLoading(true);
    const response = await editResource({ ...mediaRecord, ...data });
    verifyResponse(response);
    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar("Update Media Success", {
        variant: "success",
      });
    } else {
      // handling error message
      enqueueSnackbar("Update Subscription Group Fail", { variant: "warning" });
    }
    setLoading(false);
    onSubmit();
    handleClose();
  };

  const dlg = (
    <StyledMediaMangerDialog open={open}>
      <form onSubmit={handleSubmit(handleAction)}>
        <DialogTitle>Edit Media</DialogTitle>
        <DialogContent>
          <FormControl margin="dense">
            <TextField
              autoFocus
              autoComplete="off"
              margin="dense"
              id="subscription-group-name"
              label="Name"
              type="text"
              fullWidth
              defaultValue={mediaRecord.name}
              {...register("name", { required: true })}
              error={!!errors?.name}
              helperText={!!errors?.name ? "Name is required" : ""}
              variant="standard"
            />
          </FormControl>

          <FormControl margin="dense">
            <TextField
              autoFocus
              autoComplete="off"
              margin="dense"
              id="subscription-group-name"
              label="Description"
              type="text"
              fullWidth
              multiline
              rows={5}
              defaultValue={mediaRecord.description}
              {...register("description", { required: true })}
              error={!!errors?.description}
              helperText={
                !!errors?.description ? "description is required" : ""
              }
              variant="standard"
            />
          </FormControl>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" variant="contained" size="small">
              Update
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </StyledMediaMangerDialog>
  );

  return [openDlg, dlg, loading];
};

export default MediaRecordDlg;
