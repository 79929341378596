import React, { useState, useEffect } from "react"
import PageHeading from "components/common/LayoutComponents/PageHeading"
import PageContent from "components/common/LayoutComponents/PageContent"
import Button from "@mui/material/Button"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import LoadingScreen from "components/common/LoadingScreen"
import { PERMISSIONS } from "constants/permission"
import { UsePermissionVerification } from "components/customHooks"
import { searchModel } from "services/modal"
import { verifyResponse } from "utils/httpRequest"
import ModelDlg from "./ModalDlg"
import ModalContentForm from "./ModalContentForm"
import { StyledModal } from "./Modal.style"
import { MODAL_PRESENTATION_TYPE } from "constants/global"

const Modal = (props) => {
  const [modals, setModals] = useState([])
  const [loading, setLoading] = useState([])

  const AccessControl = UsePermissionVerification()

  const [openModalDialog, modalDialog, modalDialogLoading] = ModelDlg({
    onSubmit: () => {
      refreshModals()
    },
    access: AccessControl.Access,
  })

  const [openModalContentForm, modalForm, modalContentFormLoading] =
    ModalContentForm({
      onSubmit: () => {
        refreshModals()
      },
      access: AccessControl.Access,
    })

  const refreshModals = async () => {
    setLoading(true)
    const response = await searchModel()
    verifyResponse(response)
    setModals(response.data.list)
    setLoading(false)
  }

  useEffect(() => {
    refreshModals()
  }, [])

  useEffect(() => {
    AccessControl.SetAccess({
      ...AccessControl.Access,
      listModalAccess: AccessControl.VerifyAccess([
        PERMISSIONS.MODAL.MODAL_LIST,
      ]),
      addModalAccess: AccessControl.VerifyAccess([PERMISSIONS.MODAL.MODAL_ADD]),
      editModalAccess: AccessControl.VerifyAccess([
        PERMISSIONS.MODAL.MODAL_EDIT,
      ]),
      deleteModalAccess: AccessControl.VerifyAccess([
        PERMISSIONS.MODAL.MODAL_DELETE,
      ]),
      addModalContentAccess: AccessControl.VerifyAccess([
        PERMISSIONS.MODAL.MODAL_CONTENT_ADD,
      ]),
      editModalContentAccess: AccessControl.VerifyAccess([
        PERMISSIONS.MODAL.MODAL_CONTENT_EDIT,
      ]),
      deleteModalContentAccess: AccessControl.VerifyAccess([
        PERMISSIONS.MODAL.MODAL_CONTENT_DELETE,
      ]),
      exchangeIndexModalContentAccess: AccessControl.VerifyAccess([
        PERMISSIONS.MODAL.MODAL_CONTENT_INDEX_EXCHANGE,
      ]),
      listTypeModalContentAccess: AccessControl.VerifyAccess([
        PERMISSIONS.MODAL.MODAL_LIST_ALL_CONTENT_TYPES,
      ]),
    })
  }, [AccessControl.UserPermissions])

  return (
    <StyledModal>
      <PageContent>
        <PageHeading>
          <div>Modals</div>
          <div className="actions">
            {!modalForm && AccessControl.Access.addModalAccess && (
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  openModalDialog()
                }}
              >
                New Modal Item
              </Button>
            )}
          </div>
        </PageHeading>
        <div>
          {!modalForm && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Tag</TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="left">Presentation Type</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {modals.map((row) => (
                    <TableRow
                      key={`modal-row-${row.id}`}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.tag}
                      </TableCell>
                      <TableCell align="left">{row.description}</TableCell>
                      <TableCell align="left">
                        {
                          MODAL_PRESENTATION_TYPE.find(
                            (item) => item.value === row.presentationType
                          )?.text
                        }
                      </TableCell>
                      <TableCell align="right">
                        <div className="actions">
                          {AccessControl.Access.editModalAccess && (
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => {
                                openModalDialog(row)
                              }}
                            >
                              Edit
                            </Button>
                          )}
                          {AccessControl.Access.listTypeModalContentAccess && (
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => {
                                openModalContentForm(row)
                              }}
                            >
                              Content
                            </Button>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </PageContent>
      <LoadingScreen
        loading={loading || modalDialogLoading || modalContentFormLoading}
      />
      {modalDialog}
      {modalForm}
    </StyledModal>
  )
}

export default Modal
