import React, { useState, useEffect, useRef } from "react"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import TextField from "@mui/material/TextField"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { useForm } from "react-hook-form"
import { useSnackbar } from "notistack"
import Editor from "@monaco-editor/react"
import { verifyResponse } from "utils/httpRequest"
import { addSystemConfigs, editSystemConfigs } from "services/systemConfig"
import { StyledSystemConfigDialog } from "./SystemConfig.style"
import { setConfig } from "dompurify"

const SystemConfigDlg = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      resourceId: null,
    },
  })

  const initSystemConfig = {
    id: 0,
    name: "",
    configKey: "",
    configValue: "",
    description: "",
  }

  const { onSubmit } = props
  const [systemConfig, setSystemConfig] = useState(initSystemConfig)
  const [isEditMode, setIsEditMode] = useState(false)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const systemConfigRef = useRef(initSystemConfig)

  // const editorRef = useRef(null)
  // useEffect(() => {
  //   window.MonacoEnvironment = {
  //     getWorkerUrl: function (workerId, label) {
  //       return `data:text/javascript;charset=utf-8,${encodeURIComponent(`
  //             self.MonacoEnvironment = { baseUrl: '${window.location.origin}/' };
  //             importScripts('${window.location.origin}/vs/base/worker/workerMain.js');
  //         `)}`
  //     },
  //   }
  // }, [])
  const openDlg = (editSystemConfig) => {
    setOpen(true)
    reset()
    if (editSystemConfig) {
      setConfig(editSystemConfig)
      systemConfigRef.current = editSystemConfig
      setValue("configValue", editSystemConfig.configValue)
      setIsEditMode(true)
    } else {
      setIsEditMode(false)
    }
  }

  const createNewSystemConfig = async ({
    name,
    configKey,
    configValue,
    description,
  }) => {
    setLoading(true)
    const response = await addSystemConfigs({
      name,
      configKey,
      configValue,
      description,
    })
    setLoading(false)
    verifyResponse(response)
    onSubmit && (await onSubmit())
    setOpen(false)
  }

  const updateSystemConfig = async ({
    name,
    configKey,
    configValue,
    description,
    id,
  }) => {
    console.log(name, configKey, configValue, description, id)
    setLoading(true)
    const response = await editSystemConfigs({
      name,
      configKey,
      configValue,
      description,
      id,
    })
    setLoading(false)
    verifyResponse(response)
    onSubmit && (await onSubmit())
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleAction = async (data) => {
    console.log("data: ", data)

    isEditMode
      ? updateSystemConfig({ ...data, id: systemConfigRef.current.id })
      : createNewSystemConfig(data)
  }

  const dlg = (
    <StyledSystemConfigDialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(handleAction)}>
        <DialogTitle>Edit System Setting</DialogTitle>
        <DialogContent>
          <FormControl>
            <TextField
              autoFocus
              autoComplete="off"
              margin="dense"
              id="system-config-name"
              label="Name"
              type="text"
              fullWidth
              defaultValue={systemConfigRef.current?.name}
              {...register("name", { required: true })}
              variant="standard"
              error={!!errors?.name}
              helperText={!!errors?.name ? "* Config nameP is required" : ""}
            />
          </FormControl>

          <FormControl>
            <TextField
              autoFocus
              autoComplete="off"
              margin="dense"
              id="system-config-key"
              label="Config Key"
              type="text"
              fullWidth
              defaultValue={systemConfigRef.current?.configKey}
              {...register("configKey", { required: true })}
              variant="standard"
              error={!!errors?.configKey}
              helperText={!!errors?.configKey ? "* Config Key is required" : ""}
            />
          </FormControl>

          <FormControl margin="dense">
            <TextField
              label="Description"
              margin="dense"
              id="system-config-description"
              fullWidth
              variant="standard"
              rows={3}
              multiline
              defaultValue={systemConfigRef.current?.description}
              {...register("description", { required: true })}
              error={!!errors?.description}
              helperText={
                !!errors?.description ? "* Description is required" : ""
              }
            />
          </FormControl>

          <div className="label-text">Json Value</div>

          <div className="error-message">
            {!!errors?.configValue ? "Wrong Json Format" : ""}
          </div>
          <Editor
            className="json-editor"
            height="350px"
            theme="vs-dark"
            defaultLanguage="json"
            defaultValue={systemConfigRef.current.configValue}
            onChange={(newValue, e) => {
              setValue("configValue", newValue)
            }}
          />
          <input
            type="hidden"
            defaultValue={""}
            {...register("configValue", {
              validate: (value, formValues) => {
                try {
                  const praseObj = JSON.parse(value)
                  return true
                } catch (err) {
                  console.log("error: ", err)
                  return false
                }
              },
            })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" size="small">
            {isEditMode ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </form>
    </StyledSystemConfigDialog>
  )

  return [openDlg, dlg, loading]
}

export default SystemConfigDlg
