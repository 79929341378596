import React, { useState } from "react"
import PropTypes from "prop-types"
import TextField from "@mui/material/TextField"
import Chip from "@mui/material/Chip"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Button from "@mui/material/Button"
import { searchContributor } from "services/contributor"
import { verifyResponse } from "utils/httpRequest"
import { StyledProgramContributorDialog } from "./Program.style"

const ProgramContributorDialog = ({ selectAction, currentContributorList }) => {
  const [email, setEmail] = useState("")
  const [userName, setUserName] = useState("")
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [searched, setSearched] = useState(false)
  const [contributors, setContributors] = useState([])

  const currentContributorIdList = currentContributorList
    ? currentContributorList.map((contributor) => contributor.id)
    : []

  const openDlg = () => {
    setContributors([])
    setOpen(true)
  }

  const searchContributorAction = async () => {
    setLoading(true)
    let searchObject = {
      pageNo: 1,
      pageSize: 99999,
    }
    if (email !== "") {
      searchObject["email"] = email
    } else if (userName !== "") {
      searchObject["userName"] = userName
    }
    const response = await searchContributor(searchObject)
    setLoading(false)
    verifyResponse(response)
    setSearched(true)
    setContributors(response.data.list)
  }

  const clearSearch = () => {
    setUserName("")
    setEmail("")
  }

  const selectContributor = (contributor) => {
    selectAction(contributor)
    setOpen(false)
  }

  const content = (
    <StyledProgramContributorDialog open={open}>
      <DialogTitle>Find Contributor</DialogTitle>
      <DialogContent className="dialog-content">
        <div className="search-bar">
          <TextField
            className="name"
            autoFocus
            autoComplete="off"
            margin="dense"
            id="contributor-name"
            label="Name"
            type="text"
            value={userName}
            onChange={(e) => {
              setUserName(e.target.value)
            }}
            variant="outlined"
          />
          <TextField
            className="email"
            autoComplete="off"
            margin="dense"
            id="contributor-email"
            label="Email"
            type="text"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            variant="outlined"
          />
          <Button
            className="search-btn"
            variant="contained"
            size="large"
            onClick={() => {
              searchContributorAction()
            }}
          >
            Search
          </Button>
          <Button
            className="search-btn"
            variant=""
            size="large"
            onClick={clearSearch}
          >
            Clear
          </Button>
        </div>

        <div className="search-result">
          {contributors
            .filter((contributor) => {
              return !currentContributorIdList.includes(contributor.id)
            })
            .map((contributor) => (
              <Chip
                label={contributor.userName}
                key={`search-contributor-item-${contributor.id}`}
                onClick={() => {
                  selectContributor(contributor)
                }}
              />
            ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false)
          }}
        >
          close
        </Button>
        <Button>Select</Button>
      </DialogActions>
    </StyledProgramContributorDialog>
  )

  return [openDlg, content]
}

ProgramContributorDialog.propTypes = {
  currentContributorList: PropTypes.array.isRequired,
  selectAction: PropTypes.func.isRequired,
}

export default ProgramContributorDialog
