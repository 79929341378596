import { ACCEPT_FILE_EXT, EXT_TO_MEDIA_TYPE } from "constants/global"
import moment from "moment"
import { sha512_256 } from "js-sha512"
import { getRandomNumber } from "./number"

const calculateVideoDuration = (file, setDurationCallback) => {
  const video = document.createElement("video")
  video.preload = "metadata"
  video.onloadedmetadata = () => {
    window.URL.revokeObjectURL(video.src)
    setDurationCallback(video.duration)
  }
  video.src = URL.createObjectURL(file)
}

String.prototype.toLowerCase()

const findMediaInfoByFileName = (fileName) => {
  if (!fileName) {
    return {}
  }
  const path = fileName.split(".")
  const ext =
    path.length === 0 ||
    !ACCEPT_FILE_EXT.includes(path[path.length - 1].toLowerCase())
      ? ""
      : path[path.length - 1].toLowerCase()
  const type = EXT_TO_MEDIA_TYPE[ext]
  return { ext, type }
}

const generateUniqueFileName = (fileName) => {
  const fileInfo = findMediaInfoByFileName(fileName)
  const hash = sha512_256.update(`${fileName}-${moment().toString()}`).hex()
  const awsFileName = `${hash}-${fileInfo.type}-${moment().format(
    "YYYYMMDDhhmmss"
  )}.${fileInfo.ext}`
  return awsFileName
}

const generateUniqueString = (fileName) => {
  const hash = sha512_256
    .update(`${fileName}-${moment().toString()}-${getRandomNumber()}`)
    .hex()
  return hash
}

export {
  calculateVideoDuration,
  findMediaInfoByFileName,
  generateUniqueFileName,
  generateUniqueString,
}
