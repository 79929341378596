import React, { useState } from "react"
import { BarChartDisplay } from "charts"
import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { StyledChartDialog } from "./Home.style"

const BarChartDlg = () => {
  const [open, setOpen] = useState(false)
  const [chartContent, setChartData] = BarChartDisplay({
    data: null,
    width: 1200,
    height: 500,
  })

  const openDlg = (data) => {
    setChartData(data)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const content = (
    <StyledChartDialog open={open}>
      <DialogTitle>Data Chart</DialogTitle>
      <DialogContent>{chartContent}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </StyledChartDialog>
  )

  return [content, openDlg]
}

export default BarChartDlg
