import { useState } from "react";
import {
  GetStoreUserPermissions,
  AccessVerification,
  AccessVerificationByCategory,
} from "utils/user";

const UsePermissionVerification = () => {
  const [access, setAccess] = useState({});
  const userPermissions = GetStoreUserPermissions();

  const verify = (permissionList, showLog) => {
    showLog && console.info("permissionList: ", permissionList);
    return AccessVerification(userPermissions, permissionList);
  };

  const verifyByCategory = (permissionList, showLog) => {
    showLog && console.info("permissionList: ", permissionList);
    return AccessVerificationByCategory(userPermissions, permissionList);
  };

  return {
    VerifyAccess: verify,
    VerifyAccessByCategory: verifyByCategory,
    UserPermissions: userPermissions,
    Access: access,
    SetAccess: setAccess,
  };
};

export default UsePermissionVerification;
