import styled from "@emotion/styled";
import { Dialog } from "@mui/material";

const StyledPermission = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledPermissionTable = styled.div`
  display: flex;
  flex-direction: column;

  .permission-filter {
    padding: 30px 10px;
  }

  .pagination-section {
    display: flex;
    justify-content: flex-end;
  }
`;

const StyledPermissionDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  .MuiPaper-root {
    width: 600px;
  }
  .error {
    font-size: 0.75rem;
    color: #d32f2f;
    margin-top: 3px;
  }
`;

export { StyledPermission, StyledPermissionTable, StyledPermissionDialog };
