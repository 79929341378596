import styled from "@emotion/styled"
import { Dialog } from "@mui/material"

const StartHereWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .resource-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px auto;
    padding: 5px;
    border-radius: 5px;
    overflow: hidden;
    max-width: 800px;
    border: 1px solid #e1d8d8;
    font-size: 14px;
    background-color: white;

    .thumb-container {
      position: relative;
      .chip {
        position: absolute;
        font-size: 9px;
        top: 5px;
        left: 5px;
      }
    }
    .content {
      position: relative;
      padding: 10px;
      width: 100%;
      font-weight: 200;
      .title {
        font-weight: 500;
      }

      .actions {
        position: absolute;
        bottom: -5px;
        right: 0px;
      }
    }

    .thumb {
      max-width: 200px;
    }
  }
`

const StartHereDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;

  .MuiPaper-root {
    width: 100%;
    max-width: 900px;
    min-height: 400px;
  }

  .error {
    font-size: 0.75rem;
    color: #d32f2f;
    margin-top: 3px;
  }

  .innercise {
    min-height: 250px;

    .no-selection {
      font-size: 16px;
      margin-top: 30px;
    }

    .innercise-content {
      margin-top: 20px;
      .thumb {
        max-height: 100px;
        margin-bottom: 20px;
      }
      .info {
        font-size: 16px;
      }
    }
  }
`

export { StartHereWrapper, StartHereDialog }
