import React, { useState, useEffect } from "react"
import moment from "moment"
import PageHeading from "components/common/LayoutComponents/PageHeading"
import PageContent from "components/common/LayoutComponents/PageContent"
import Button from "@mui/material/Button"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Chip from "@mui/material/Chip"
import { isDarkColorByHex } from "utils/color"
import { PERMISSIONS } from "constants/permission"
import { SYSTEM_SETTINGS_INDEX } from "constants/global"
import { UsePermissionVerification } from "components/customHooks"
import MediaManager from "components/common/MediaManager"
import LoadingScreen from "components/common/LoadingScreen"
import { getAllSystemSettings } from "services/systemSetting"
import { verifyResponse } from "utils/httpRequest"
import SystemSettingDlg from "./SystemSettingDlg"
import { StyledSystemSetting, StyledSettingTable } from "./SystemSetting.style"

const SystemSetting = () => {
  const [settings, setSettings] = useState(null)
  const [loading, setLoading] = useState(false)
  const [openMediaManager, mediaManager] = MediaManager()

  const AccessControl = UsePermissionVerification()

  const [
    openSystemSettingDialog,
    systemSettingDialog,
    systemSettingDialogLoading,
  ] = SystemSettingDlg({
    onSubmit: () => {
      getSettings()
    },
    openMediaManager: openMediaManager,
  })

  const getSettings = async () => {
    try {
      setLoading(true)
      const response = await getAllSystemSettings()
      verifyResponse(response)
      setSettings(response.data)
    } catch (e) {
      console.error("Get Setting Error: ", e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getSettings()
  }, [])

  useEffect(() => {
    AccessControl.SetAccess({
      ...AccessControl.Access,
      listSystemSettingAccess: AccessControl.VerifyAccess([
        PERMISSIONS.SYSTEM_SETTING.SYSTEM_SETTING_LIST_ALL,
      ]),
      editSystemSettingAccess: AccessControl.VerifyAccess([
        PERMISSIONS.SYSTEM_SETTING.SYSTEM_SETTING_EDIT,
      ]),
    })
  }, [AccessControl.UserPermissions])

  return (
    <StyledSystemSetting>
      <PageContent>
        <PageHeading>
          <div>System Settings</div>
        </PageHeading>
        {AccessControl.Access.listSystemSettingAccess && settings && (
          <StyledSettingTable>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="system setting table">
                <TableHead sx={{ fontWeight: 600 }}>
                  <TableRow>
                    <TableCell align="left" sx={{ fontWeight: 600, width: 20 }}>
                      Tag
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontWeight: 600, width: 150 }}
                    >
                      Setting Name
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: 600 }}>
                      Description
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontWeight: 600, minWidth: 320 }}
                    >
                      Content
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontWeight: 600, width: 120 }}
                    >
                      Modified
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: 600, width: 70 }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {settings.map((row) => (
                    <TableRow key={`system-setting-item-${row.id}`}>
                      <TableCell scope="row">{row.tag}</TableCell>
                      <TableCell scope="row">{row.title}</TableCell>
                      <TableCell scope="row">{row.description}</TableCell>
                      <TableCell align="left">
                        {row.type === SYSTEM_SETTINGS_INDEX.HTML && (
                          <>
                            <Chip label="HTML" color="primary" size="small" />
                            {row.content}
                          </>
                        )}
                        {row.type === SYSTEM_SETTINGS_INDEX.NUMBER && (
                          <>
                            <Chip label="Number" color="primary" size="small" />
                            {row.content}
                          </>
                        )}
                        {row.type === SYSTEM_SETTINGS_INDEX.TEXT && (
                          <>
                            <Chip label="Text" color="primary" size="small" />
                            {row.content}
                          </>
                        )}
                        {row.type === SYSTEM_SETTINGS_INDEX.LINK && (
                          <>
                            <Chip label="Link" color="primary" size="small" />
                            {row.content}
                          </>
                        )}
                        {row.type === SYSTEM_SETTINGS_INDEX.IMAGE && (
                          <Chip label="Image" color="success" size="small" />
                        )}
                        {row.type === SYSTEM_SETTINGS_INDEX.AUDIO && (
                          <Chip label="Audio" color="success" size="small" />
                        )}
                        {row.type === SYSTEM_SETTINGS_INDEX.VIDEO && (
                          <Chip label="Video" color="success" size="small" />
                        )}
                        {row.type === SYSTEM_SETTINGS_INDEX.ATTACHMENT && (
                          <Chip
                            label="Attachment"
                            color="default"
                            size="small"
                          />
                        )}
                        {row.type === SYSTEM_SETTINGS_INDEX.MEDIA && (
                          <Chip
                            label="Media"
                            color="success"
                            size="small"
                            style={{ backgroundColor: "#444" }}
                          />
                        )}

                        {row.type === SYSTEM_SETTINGS_INDEX.COLOR && (
                          <>
                            <Chip
                              label="Color"
                              color="success"
                              size="small"
                              style={{ backgroundColor: "#333" }}
                            />
                            <div
                              className={`color-box ${
                                isDarkColorByHex(row.content) ? "dark-mode" : ""
                              }`}
                              style={{
                                backgroundColor: row.content,
                              }}
                            >
                              {row.content}
                            </div>
                          </>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {moment(row.updatedAt).format("lll")}
                      </TableCell>
                      <TableCell align="right">
                        {AccessControl.Access.editSystemSettingAccess && (
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              openSystemSettingDialog(row)
                            }}
                          >
                            Edit
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledSettingTable>
        )}

        {systemSettingDialog}
        {mediaManager}
        <LoadingScreen loading={loading || systemSettingDialogLoading} />
      </PageContent>
    </StyledSystemSetting>
  )
}

export default SystemSetting
