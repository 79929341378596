import React, { useState, useEffect, useCallback, useRef } from "react"
import PageHeading from "components/common/LayoutComponents/PageHeading"
import { searchReviews, pubReview, cancelPubReview } from "services/review"
import { verifyResponse } from "utils/httpRequest"
import LoadingScreen from "components/common/LoadingScreen"
import { PERMISSIONS } from "constants/permission"
import { UsePermissionVerification } from "components/customHooks"
import { StyledReview } from "./Review.style"
import ReviewSearch from "./ReviewSearch"
import ReviewResult from "./ReviewResult"

const Review = () => {
  const [reviews, setReviews] = useState([])
  const [searchOptions, setSearchOptions] = useState({})
  const [searchResultInfo, setSearchResultInfo] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const firstUpdate = useRef(true)

  const AccessControl = UsePermissionVerification()

  const doSearch = useCallback(async () => {
    setIsLoading(true)
    let response = null
    try {
      response = await searchReviews(searchOptions)
      if (response) {
        // Process search response
        verifyResponse(response)
        setReviews(response.data.list)
        setSearchResultInfo({
          currentPageNo: response.data.currentPageNo,
          hasNext: response.data.hasNext,
          pageSize: response.data.pageSize,
          pages: response.data.pages,
          total: response.data.total,
        })
      }
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }, [searchOptions])

  useEffect(() => {
    AccessControl.SetAccess({
      ...AccessControl.Access,
      listReviewAccess: AccessControl.VerifyAccess([
        PERMISSIONS.REVIEW.REVIEW_LIST,
      ]),
      cancelPubReviewAccess: AccessControl.VerifyAccess([
        PERMISSIONS.REVIEW.REVIEW_CANCEL_PUB,
      ]),
      pubReviewAccess: AccessControl.VerifyAccess([
        PERMISSIONS.REVIEW.REVIEW_PUB,
      ]),
      pubBatchReviewAccess: AccessControl.VerifyAccess([
        PERMISSIONS.REVIEW.REVIEW_PUB_BATCH,
      ]),
    })
  }, [AccessControl.UserPermissions])

  const publishReview = async (id) => {
    setIsLoading(true)
    const response = await pubReview(id)
    setIsLoading(false)
    verifyResponse(response)
    doSearch()
  }

  const cancelPublishReview = async (id) => {
    setIsLoading(true)
    const response = await cancelPubReview(id)
    setIsLoading(false)
    verifyResponse(response)
    doSearch()
  }

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    doSearch()
  }, [searchOptions?.pageNo, doSearch])

  return (
    <StyledReview>
      <PageHeading>
        <div>Reviews</div>
        <div className="actions"></div>
      </PageHeading>
      {AccessControl.Access.listReviewAccess && (
        <>
          <ReviewSearch setSearchOptions={setSearchOptions} />
          <ReviewResult
            reviews={reviews}
            access={AccessControl.Access}
            searchResultInfo={searchResultInfo}
            searchOptions={searchOptions}
            setSearchOptions={setSearchOptions}
            setIsLoading={setIsLoading}
            publishReview={publishReview}
            cancelPublishReview={cancelPublishReview}
          />
        </>
      )}
      <LoadingScreen loading={isLoading} />
    </StyledReview>
  )
}

export default Review
