import styled from "@emotion/styled"

const StyledPrimeSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  .pagination-section {
    display: flex;
  }
`

const StyledPrimeList = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 15px;
  row-gap: 15px;
  flex-wrap: wrap;
  .smooth-dnd-container {
    margin: 0 auto;
  }
  .row-section {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 900px;
  }
`
const StyledPrimeCard = styled.div`
  display: flex;
  margin: -28px 20px;
  border-radius: 5px;
  font-size: 14px;
  width: 1095px;
  padding: 0px 15px 5px 15px;
  background-color: white;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  flex-direction: column;
  transform: scale(0.8);

  .title {
    height: 20px;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .MuiOutlinedInput-root {
    height: 115px;
  }

  .preview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;

    .thumb {
      display: flex;
      justify-content: center;
      width: 300px;
    }
    .content {
      width: 32%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;

      .MuiTextField-root {
        width: 100%;
      }

      .display-title-content {
        border: 2px dashed grey;
        border-radius: 3px;
        height: 190px;
        padding: 15px;
        font-size: 16px;
        overflow-y: scroll;
      }
      .quote-content {
        width: calc(100% - 45px);
        border: 2px dashed grey;
        border-radius: 3px;
        height: 190px;
        padding: 15px;
        font-size: 16px;
        overflow-y: scroll;
      }
    }
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 7px;
    .icon-button {
      cursor: pointer;
      padding: 5px;
      border-radius: 50%;
      transition: all 0.5s ease;
      color: #8658bb;
      font-size: 20px;
      margin-left: 5px;
      &.red {
        color: red;
      }
      &:hover {
        transition: all 0.5s ease;
        background: #a6bea245;
      }
    }
  }
`
export { StyledPrimeSection, StyledPrimeList, StyledPrimeCard }
