import styled from "@emotion/styled";
import { Dialog } from "@material-ui/core";
const StyledProfile = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledProfileInfo = styled.div`
  .basic-info {
    display: flex;
    flex-direction: row;
    flex-grow: 4;
    align-items: center;
    color: #0000009e;
    font-size: 15px;
    line-height: 16px;
    > div {
      margin-right: 15px;
    }
  }
`;

const StyledProfilePasswordReset = styled.div`
  width: 600px;
  form {
    display: flex;
    flex-direction: column;
  }
  .MuiCardActions-root {
    margin: 20px 0 0 0;
  }
  .info {
    font-size: 12px;
    ul {
      margin: 0;
      padding: 24px 0 0 22px;
      color: #9e1b1b;
    }
  }
`;

const StyledProfileImageDialog = styled(Dialog)`
  display: flex;
`;

export {
  StyledProfile,
  StyledProfileInfo,
  StyledProfileImageDialog,
  StyledProfilePasswordReset,
};
