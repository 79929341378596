const exchangeArrayItem = ({ addedIndex, removedIndex, indexString, list }) => {
  const processList = [...list]
  if (addedIndex > removedIndex) {
    processList.splice(addedIndex + 1, 0, list[removedIndex])
    processList.splice(removedIndex, 1)
  } else {
    processList.splice(addedIndex, 0, list[removedIndex])
    processList.splice(removedIndex + 1, 1)
  }
  const objectIndex = processList
    .map((item) => item[indexString])
    .sort((a, b) => {
      return a - b
    })

  return processList.map((item, i) => {
    return {
      ...item,
      [indexString]: objectIndex[i],
    }
  })
}

const calculateNewRank = ({
  addedIndex,
  removedIndex,
  indexString,
  list,
  updateOriginalList,
}) => {
  const processList = [...list]
  if (addedIndex > removedIndex) {
    processList.splice(addedIndex + 1, 0, list[removedIndex])
    processList.splice(removedIndex, 1)
  } else {
    processList.splice(addedIndex, 0, list[removedIndex])
    processList.splice(removedIndex + 1, 1)
  }
  const objectIndex = processList
    .map((item) => item[indexString])
    .sort((a, b) => {
      return a - b
    })

  if (updateOriginalList) {
    updateOriginalList(processList)
  }

  return processList.map((item, i) => {
    return {
      id: item.id,
      index: objectIndex[i],
    }
  })
}

export { exchangeArrayItem, calculateNewRank }
