import styled from "@emotion/styled"
import { Dialog } from "@mui/material"

const ProgramSelectorWrapper = styled(Dialog)`
  display: flex;
  flex-direction: column;
  .MuiPaper-root {
    width: 100%;
    max-width: 900px;
    min-height: 700px;
  }
  .error {
    font-size: 0.75rem;
    color: #d32f2f;
    margin-top: 3px;
  }

  .search-bar {
    //
    display: flex;
    flex-direction: row;

    .search-text {
      width: 100%;
    }

    .MuiButton-root {
      padding-left: 30px;
      padding-right: 30px;
    }

    button {
      margin-left: 10px;
    }
  }

  .loading-icon {
    margin-top: 20px;
  }

  .search-results {
    .notice {
      font-size: 15px;
      font-weight: 300;
      margin: 22px 0;
      padding: 0 7px;
      color: #544747;
    }

    .result-item {
      display: inline-block;
      flex-direction: column;
      border: 1px solid #c3c4c3;
      border-radius: 3px;
      margin: 5px 8px;
      padding: 6px 10px;
      cursor: pointer;
      width: 175px;
      height: 230px;
      overflow-y: hidden;
      /* justify-content: flex-start; */

      .thumb {
        max-width: 180px;
      }

      &.selected {
        background-color: #eee;
      }

      .content {
        display: flex;
        flex-direction: column;
        padding: 5px;
        .title {
          font-size: 13px;
          font-weight: 300;
        }
        p {
          font-size: 10px;
          font-weight: 200;
          overflow: hidden;
          height: 58px;
        }
      }
    }
  }
`

export { ProgramSelectorWrapper }
