import React, { useState, useRef, useEffect } from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import MediaContentDisplay from "components/common/MediaManager/MediaContentDisplay"
import FormControl from "@mui/material/FormControl"
import { SketchPicker } from "react-color"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { verifyResponse } from "utils/httpRequest"
import { isDarkColorByRGB } from "utils/color"
import { editSystemSetting } from "services/systemSetting"
import { MEDIA_TYPE_INDEX } from "constants/global"
import { useForm } from "react-hook-form"
import { useSnackbar } from "notistack"
import { SYSTEM_SETTINGS_INDEX } from "constants/global"
import {
  StyledSystemSettingDialog,
  StyledInputLabel,
} from "./SystemSetting.style"

const SystemSettingDialog = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      resourceId: null,
    },
  })

  const { onSubmit, openMediaManager } = props
  const [systemSetting, setSystemSetting] = useState({})
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isDarkColor, setIsDarkColor] = useState(false)
  const [resource, setResource] = useState({})
  const resourceRef = useRef(null)

  const handleClose = () => {
    setOpen(false)
  }

  const openDlg = (editSystemSetting) => {
    reset()
    setSystemSetting(editSystemSetting)
    if (editSystemSetting) {
      setValue("resourceId", editSystemSetting.resourceId)
      resourceRef.current = editSystemSetting.resourceDto
      setValue("content", editSystemSetting.content)
      updateResource()
      setValue("htmlContent", editSystemSetting.htmlContent)
    } else {
      setValue("resourceId", null)
      setValue("htmlContent", "")
      resourceRef.current = null
    }
    setOpen(true)
  }

  const handleAction = async (data) => {
    editSetting(data)
  }

  const clearResource = () => {
    setValue("resourceId", null)
    resourceRef.current = null
    updateResource()
  }

  const updateResource = () => {
    setResource({
      ...resourceRef.current,
      selectAction: selectIntroResource,
      clearResourceAction: clearResource,
    })
  }

  const getResourceSelectType = () => {
    if (systemSetting.type === SYSTEM_SETTINGS_INDEX.VIDEO)
      return [MEDIA_TYPE_INDEX.video]

    if (systemSetting.type === SYSTEM_SETTINGS_INDEX.AUDIO)
      return [MEDIA_TYPE_INDEX.audio]

    if (systemSetting.type === SYSTEM_SETTINGS_INDEX.IMAGE)
      return [MEDIA_TYPE_INDEX.image]

    if (systemSetting.type === SYSTEM_SETTINGS_INDEX.ATTACHMENT)
      return [MEDIA_TYPE_INDEX.document]

    if (systemSetting.type === SYSTEM_SETTINGS_INDEX.MEDIA)
      return [
        MEDIA_TYPE_INDEX.image,
        MEDIA_TYPE_INDEX.video,
        MEDIA_TYPE_INDEX.audio,
      ]

    return [
      MEDIA_TYPE_INDEX.video,
      MEDIA_TYPE_INDEX.audio,
      MEDIA_TYPE_INDEX.image,
      MEDIA_TYPE_INDEX.document,
    ]
  }

  const selectIntroResource = () => {
    openMediaManager((media) => {
      resourceRef.current = media
      setValue("resourceId", media.id)
      updateResource()
    }, getResourceSelectType())
  }

  const editSetting = async (data) => {
    const postData = {
      ...data,
      id: systemSetting.id,
    }
    try {
      setLoading(true)
      const response = await editSystemSetting(postData)
      verifyResponse(response)
      if (response.data.rtnCode === 200) {
        enqueueSnackbar("System Setting updated", {
          variant: "success",
        })
      } else {
        enqueueSnackbar("System Setting update Fail", { variant: "warning" })
      }
    } catch (e) {
      console.error("Update Setting Error: ", e)
    } finally {
      setLoading(false)
      onSubmit()
      setOpen(false)
    }
  }

  const changeColorValue = (e) => {
    setValue("content", e.hex)
    setIsDarkColor(isDarkColorByRGB(e.rgb))
  }

  useEffect(() => {
    updateResource()
  }, [systemSetting])

  const dlg = (
    <StyledSystemSettingDialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(handleAction)}>
        <DialogTitle>Edit System Setting</DialogTitle>
        <DialogContent>
          <FormControl>
            <TextField
              autoFocus
              autoComplete="off"
              margin="dense"
              id="system-setting-title"
              label="Title"
              type="text"
              fullWidth
              defaultValue={systemSetting.title}
              {...register("title")}
              disabled
              variant="standard"
            />
          </FormControl>
          <FormControl>
            <TextField
              autoFocus
              autoComplete="off"
              margin="dense"
              id="system-setting-tag"
              label="Tag"
              type="text"
              fullWidth
              defaultValue={systemSetting.tag}
              {...register("tag")}
              disabled
              variant="standard"
            />
          </FormControl>

          <FormControl margin="dense">
            <TextField
              label="Description"
              margin="dense"
              id="system-setting-description"
              fullWidth
              variant="standard"
              rows={6}
              multiline
              defaultValue={systemSetting.description}
              {...register("description", { required: true })}
              error={!!errors?.description}
            />
          </FormControl>

          {(systemSetting.type === SYSTEM_SETTINGS_INDEX.TEXT ||
            systemSetting.type === SYSTEM_SETTINGS_INDEX.NUMBER ||
            systemSetting.type === SYSTEM_SETTINGS_INDEX.LINK ||
            systemSetting.type === SYSTEM_SETTINGS_INDEX.COLOR) && (
            <FormControl>
              <TextField
                label={`${
                  systemSetting.type === SYSTEM_SETTINGS_INDEX.TEXT
                    ? "Text"
                    : ""
                }${
                  systemSetting.type === SYSTEM_SETTINGS_INDEX.NUMBER
                    ? "Number Value"
                    : ""
                }${
                  systemSetting.type === SYSTEM_SETTINGS_INDEX.LINK
                    ? "Html Link"
                    : ""
                }${
                  systemSetting.type === SYSTEM_SETTINGS_INDEX.COLOR
                    ? "Color Code"
                    : ""
                }`}
                margin="dense"
                id="system-setting-content"
                fullWidth
                variant="standard"
                type={
                  systemSetting.type === SYSTEM_SETTINGS_INDEX.NUMBER
                    ? "number"
                    : "text"
                }
                defaultValue={systemSetting.content}
                {...register("content", { required: true })}
                error={!!errors?.content}
              />
            </FormControl>
          )}

          {systemSetting.type === SYSTEM_SETTINGS_INDEX.HTML && (
            <FormControl>
              <StyledInputLabel>HTML Content</StyledInputLabel>
              <ReactQuill
                theme="snow"
                defaultValue={systemSetting.htmlContent}
                onChange={(val) => {
                  setValue("htmlContent", val)
                }}
              />
            </FormControl>
          )}

          {(systemSetting.type === SYSTEM_SETTINGS_INDEX.IMAGE ||
            systemSetting.type === SYSTEM_SETTINGS_INDEX.AUDIO ||
            systemSetting.type === SYSTEM_SETTINGS_INDEX.VIDEO ||
            systemSetting.type === SYSTEM_SETTINGS_INDEX.MEDIA ||
            systemSetting.type === SYSTEM_SETTINGS_INDEX.ATTACHMENT) && (
            <div>
              <StyledInputLabel>Resource</StyledInputLabel>
              <input
                type="hidden"
                defaultValue={systemSetting.resourceId}
                {...register("resourceId")}
              />
              <MediaContentDisplay resource={resource} />
            </div>
          )}

          {systemSetting.type === SYSTEM_SETTINGS_INDEX.COLOR && (
            <FormControl>
              <StyledInputLabel>Pick Color</StyledInputLabel>
              <div
                className={`color-box ${isDarkColor ? "dark-mode" : ""}`}
                style={{
                  backgroundColor: watch("content"),
                }}
              >
                {watch("content")}
              </div>
              <div>
                <SketchPicker
                  color={watch("content")}
                  onChangeComplete={changeColorValue}
                />
              </div>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" size="small">
            Update
          </Button>
        </DialogActions>
      </form>
    </StyledSystemSettingDialog>
  )

  return [openDlg, dlg, loading]
}

export default SystemSettingDialog
