import React, { useState } from "react"
import { DataGrid } from "@mui/x-data-grid"
import { Button } from "@mui/material"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { DataTableWrapper } from "./DataTable.style"

const DataTable = () => {
  // Example of Table data input
  /*
    const columns = [
      { field: "id", headerName: "ID", width: 70 },
      { field: "firstName", headerName: "First name", width: 130 },
      { field: "lastName", headerName: "Last name", width: 130 },
      {
        field: "age",
        headerName: "Age",
        type: "number",
        width: 90,
      },
      {
        field: "fullName",
        headerName: "Full name",
        description: "This column has a value getter and is not sortable.",
        sortable: false,
        width: 160,
        valueGetter: (params) =>
          `${params.row.firstName || ""} ${params.row.lastName || ""}`,
      },
    ]

    const rows = [
      { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
      { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
      { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
      { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
      { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
      { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
      { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
      { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
      { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
    ]
  */

  const [tableColumns, setTableColumns] = useState([])
  const [tableRows, setTableRows] = useState([])
  const [title, setTitle] = useState([])
  const [summaryContent, setSummaryContent] = useState()

  const [open, setOpen] = useState(false)

  const openDlg = ({ columns, rows, title, summary }) => {
    setTableColumns(columns)
    setTableRows(rows)
    setSummaryContent(summary)
    title ? setTitle(title) : setTitle(null)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const dlg = (
    <DataTableWrapper open={open}>
      <DialogTitle>{title || "Data Table"}</DialogTitle>
      <DialogContent>
        {summaryContent}
        <DataGrid
          rows={tableRows}
          columns={tableColumns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 15, 20, 25]}
          checkboxSelection={false}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </DataTableWrapper>
  )
  return [openDlg, dlg]
}

export default DataTable
