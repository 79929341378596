import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import styled from "@emotion/styled";
import auth0 from "auth0-js";
import { useDispatch } from "react-redux";
import { updateUser } from "state/reducers/userReducer";
import Button from "@mui/material/Button";
import { LogoutCallBack } from "utils/auth0";
import { getAdminUserInfo } from "services/user";
import { SESSION_VARIABLES } from "constants/system";
import { getAdminUserPermissions } from "services/admin";
import { verifyResponse } from "utils/httpRequest";

const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  background-color: #1b1b1a;
  color: white;
  align-items: center;
  justify-content: space-between;

  .logo {
    img {
      width: 160px;
      margin-left: 20px;
    }
  }

  .header-content {
    display: flex;
    align-items: center;
    color: white;
    font-size: 14px;

    button {
      color: white;
      font-size: 14px;
      text-transform: capitalize;
    }

    a {
      color: white;
      font-size: 14px;
    }
    & > div {
      margin-right: 10px;
    }
  }
`;
const StyledProfileImageWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
`;

const Header = () => {
  const myAuth0 = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT,
  });

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const loadAdminUserPermissions = async () => {
    try {
      setLoading(true);
      const loadPermissionResponse = await getAdminUserPermissions();
      verifyResponse(loadPermissionResponse);
      const permissionList = [];
      for (const permission of loadPermissionResponse.data) {
        permissionList.push(permission.tag);
      }
      dispatch(updateUser({ permissions: permissionList }));
    } catch (e) {
      console.error("Get Permission Error: ", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    myAuth0.parseHash(
      { hash: window.location.hash },
      async (err, authResult) => {
        if (err) {
          console.log(err);
          const idToken = localStorage.getItem(SESSION_VARIABLES.ACCESS_TOKEN);
          if (!idToken || idToken === "") {
            navigate("/login");
          }
        }

        if (authResult) {
          //
          localStorage.setItem(
            SESSION_VARIABLES.ACCESS_TOKEN,
            authResult.idToken
          );
          // call login request to backend api
          let loginResponse;
          try {
            loginResponse = await getAdminUserInfo();
            verifyResponse(loginResponse);
            loadAdminUserPermissions();
          } catch (error) {
            console.error("Login Error: ", error);
          }

          // store user info into local
          localStorage.setItem("nickname", authResult.idTokenPayload.nickname);
          localStorage.setItem("name", authResult.idTokenPayload.name);
          localStorage.setItem("email", authResult.idTokenPayload.email);
          localStorage.setItem("picture", authResult.idTokenPayload.picture);
          navigate("/");
        } else {
          const idToken = localStorage.getItem(SESSION_VARIABLES.ACCESS_TOKEN);

          if (!idToken || idToken === "") {
            navigate("/login");
          } else {
            loadAdminUserPermissions();
          }
        }
      }
    );
  }, []);

  return (
    <StyledHeader>
      <div className="logo">
        <Link to={"/"}>
          <img
            src="https://www.myneurogym.com/assets/images/logos/NeuroGym-Horizonal-Logo-white.png"
            alt="NeuroGym"
          />
        </Link>
      </div>
      <div className="header-content">
        <StyledProfileImageWrapper>
          <Link to={"/profile"}>
            <img src={localStorage.getItem("picture")} alt="profile" />
          </Link>
        </StyledProfileImageWrapper>
        <Link to={"/profile"}>
          <div>Hi {localStorage.getItem("name")}</div>
        </Link>
        <Button
          variant="text"
          onClick={() => {
            LogoutCallBack();
            setTimeout(() => {
              myAuth0.logout({ returnTo: window.location.origin });
              localStorage.clear();
            });
          }}
        >
          Logout
        </Button>
      </div>
    </StyledHeader>
  );
};

export default Header;
