import styled from "@emotion/styled";

const StyledProgramInnerciseListControl = styled.div`
  display: flex;
  flex-direction: column;

  .header-bar {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0;
    h4 {
      font-size: 15px;
      color: gray;
    }

    button {
    }
  }
  .resource-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .resource-name {
    font-size: 14px;
    font-weight: 500;
    width: 80%;
    position: absolute;
    top: 55px;
    color: #754444;
  }
`;

const StyledProgramInnerciseResourceWarper = styled.div`
  display: flex;
  flex-direction: column;

  .MuiFormControl-root {
    margin: 12px 15px;
    width: 353px;
  }
`;

const StyledProgramInnerciseResourcePreview = styled.div`
  position: relative;
  display: flex;
  border: 2px dashed gray;
  height: 200px;
  width: 330px;
  padding: 10px;
  border-radius: 5px;
  margin: 7px 15px;
  .action-btn {
    position: absolute;
    top: -12px;
    right: -12px;
    width: 10px;
    height: 10px;
    background-color: #1976d2;
    color: white;
  }
  video {
    width: 100%;
  }
  justify-content: space-around;
  align-items: center;
`;

export {
  StyledProgramInnerciseListControl,
  StyledProgramInnerciseResourceWarper,
  StyledProgramInnerciseResourcePreview,
};
