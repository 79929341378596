import styled from "@emotion/styled"
import Dialog from "@mui/material/Dialog"

const StyledProgramDialog = styled(Dialog)`
  background-color: #fafafa;
  .MuiPaper-root {
    min-width: 830px;
    //max-height: calc(100% - 10px);
    background-color: #fafafa;
    //height: calc(100vh - 40px);
  }

  .MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
  }
  .MuiDialogContent-root {
    min-height: 400px;
  }

  .form-control-row {
    padding-top: 10px;
    &.two-col {
      display: flex;
      flex-wrap: wrap;
      > div {
        margin-right: 20px;
      }
    }
  }

  .title-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 15px 0;

    label {
      font-size: 15px;
      color: gray;
      font-weight: bold;
    }
  }

  .category-section {
    display: flex;
    flex-direction: row;
    padding: 20px;
    border-radius: 3px;
    font-size: 14px;
    border-radius: 3px;
    font-size: 14px;
    background-color: #dcf0e4;
    color: #686464;

    .category-item {
      display: inline-block;
      border-radius: 3px;
      padding: 8px 10px;
      background-color: #bcdec9;
      color: #777d69;
      margin-right: 20px;
      font-size: 12px;
    }
  }

  .contributor-section {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
    .MuiChip-root {
      margin-right: 10px;
    }
  }
  .error-message {
    color: red;
    margin: 10px 0px 0px 10px;
    font-size: 13px;
  }
  .dialog-content {
    max-height: calc(100vh - 200px);
  }

  .image-select-frame {
    display: flex;
    height: 200px;
    width: 330px;
    border: 2px dashed gray;
    font-size: 16px;
    color: gray;
    align-items: center;
    justify-content: space-around;
    margin-left: 8px;
    cursor: pointer;
    text-transform: capitalize;
    padding: 10px;
    border-radius: 5px;
  }

  .questions {
    margin: 20px 0;
    .action-bar {
      margin-bottom: 15px;
    }
    .question-list {
      .question {
        background-color: #f4ebeb;
        padding: 7px 20px;
        margin: 5px 0;
        border-radius: 4px;
        color: #473d3d;
        font-size: 12px;

        .title {
          font-weight: 600;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;

          .content {
            .MuiChip-root {
              min-width: 120px;
              margin-right: 10px;
            }
          }

          .actions {
            .MuiButtonBase-root {
              margin-left: 3px;
            }
          }
        }

        ol {
          margin: -5px 0px 3px -15px;
          li {
            margin: 0 0 0 0;
          }
        }
      }
    }
  }

  .attachments-section {
    .attachment-list {
      display: flex;
      border: 1px dashed silver;
      padding: 15px 10px;
      margin: 10px;
      font-size: 12px;
      color: gray;
      justify-content: flex-start;
      flex-wrap: wrap;

      .attachment-item {
        position: relative;
        margin-right: 30px;
        .info {
          padding: 10px 5px 10px 10px;
        }
        .attachment-close-btn {
          position: absolute;
          right: -2px;
          top: -4px;
        }
      }
    }
  }
`

const StyledProgramWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  .MuiDialogContent-root {
    padding-top: 20px !important;
  }

  .filter-bar {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 15px 15px;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    width: 100%;
    width: calc(100% - 30px);
  }

  .action-cell {
    button {
      margin-bottom: 15px;
    }
  }

  .category-tag {
    display: inline-block;
    border-radius: 3px;
    padding: 8px 15px;
    background-color: #bcdec9;
    color: #777d69;
    margin-bottom: 10px;
    margin-left: 10px;
    font-size: 11px;
  }
`

const StyledProgramSectionPanel = styled.div`
  padding: 15px 25px 15px 15px;
  margin-top: 20px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 5px;
  .error-label {
    font-size: 12px;
    color: red;
    margin: 10px;
  }

  .action-bar {
    display: flex;
    align-items: center;

    button {
      height: 55px;
    }
  }
`

// program card section
const StyledProgramLevelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
`

const StyledProgramLevelCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 5px;
  margin: 5px;
  padding: 20px;
  background-color: white;

  .label {
    font-size: 16px;
    color: gray;
    font-weight: bold;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    font-size: 18px;
    font-weight: 600;

    .MuiChip-root {
      transform: scale(0.75);
      margin: -15px 7px 0 6px;
    }
  }

  .innercise-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    /* border-bottom: 1px dashed #9f7171; */
    padding: 7px 0;
  }

  .btn {
    cursor: pointer;
    color: #777777;
    &:hover {
      color: red;
    }
  }

  .close-btn {
  }
  .edit-btn {
    width: 20px;
    height: 20px;
    margin: 0 9px 3px 0px;
  }
  .title {
    font-size: 16px;
    font-weight: 400;
    margin: 10px 0;
  }

  .innercise-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: auto;
    padding: 0 1px;
    transition: all 0.5s ease;
    max-height: 400px;
    &.hide {
      max-height: 0;
      transition: all 0.5s ease;
    }
  }

  .innercise-item {
    height: 250px;
    width: 235px;
    border-radius: 4px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    margin: 5px 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 14px;
    padding: 15px;
    margin-right: 10px;

    .header {
      font-weight: 400;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;

      .MuiIconButton-root {
        padding: 0px;
        margin-left: 10px;
      }
    }

    .icon-btn {
      display: flex;
    }

    .title {
      font-size: 12px;
      height: 50px;
      background-color: #0000000c;
      width: 92%;
      overflow: auto;
      padding: 6px 10px;
      border-radius: 3px;
    }

    .thumb {
      /* padding: 20px 0; */
      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 3px;
      }
    }
  }
`

const StyledProgramContributorDialog = styled(Dialog)`
  background-color: #fafafa;
  .MuiPaper-root {
    min-width: 830px;
    //max-height: calc(100% - 10px);
    background-color: #fafafa;
  }

  .dialog-content {
    max-height: calc(100vh - 200px);
  }
  .search-bar {
    display: flex;
    flex-direction: row;
    width: calc(100% - 30px);
    align-items: center;
    padding: 20px 15px;
    border-radius: 7px;
    background: white;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 0px,
      rgb(0 0 0 / 10%) 0px 0px 1px 0px;
    align-items: center;
    .MuiFormControl-root {
      margin-right: 10px;
      width: 300px;
    }

    .MuiButton-root {
      height: 56px;
      margin-top: 4px;
      margin-right: 10px;
    }
  }

  .search-result {
    display: flex;
    flex-direction: row;
    width: calc(100% - 30px);
    align-items: flex-start;
    margin-top: 20px;
    padding: 20px 15px;
    border-radius: 7px;
    background: white;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 0px,
      rgb(0 0 0 / 10%) 0px 0px 1px 0px;
    height: 100px;
    font-size: 15px;
    overflow-y: scroll;
    .MuiChip-root {
      margin-right: 10px;
    }
  }
`

const StyledProgramSingleInnerciseControl = styled.div`
  display: flex;
  flex-direction: column;
  .input-group {
    display: flex;
    flex-direction: column;
    &.flex-row {
      flex-direction: row;
    }
  }

  .innercise-channel {
    display: inline-block;
    .channel-label {
      font-size: 14px;
      font-weight: 400;
      margin: 7px 0px 0px 10px;
    }
  }
`

export {
  StyledProgramDialog,
  StyledProgramWrapper,
  StyledProgramSectionPanel,
  StyledProgramLevelContainer,
  StyledProgramLevelCard,
  StyledProgramContributorDialog,
  StyledProgramSingleInnerciseControl,
}
