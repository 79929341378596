import React, { useState, useEffect, useCallback, useRef } from "react"
import PageHeading from "components/common/LayoutComponents/PageHeading"
import PageContent from "components/common/LayoutComponents/PageContent"
import { getSubscriptionProducts } from "services/subscription"
import { verifyResponse } from "utils/httpRequest"
import LoadingScreen from "components/common/LoadingScreen"
import { PERMISSIONS } from "constants/permission"
import { UsePermissionVerification } from "components/customHooks"
import { searchOrders } from "services/order"
import OrderSearch from "./OrderSearch"
import OrderTable from "./OrderTable"
import OrderDetail from "./OrderDetail"
import { StyledOrderSection } from "./Order.style"

const Order = () => {
  const [loading, setLoading] = useState(false)
  const [subscriptionProduct, setSubscriptionProduct] = useState([])
  const [searchOptions, setSearchOptions] = useState(null)
  const [searchResultInfo, setSearchResultInfo] = useState(null)
  const [orders, setOrders] = useState(null)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const firstUpdate = useRef(true)

  const AccessControl = UsePermissionVerification()

  const loadSubscriptionProduct = async () => {
    setLoading(true)
    const response = await getSubscriptionProducts()
    setLoading(false)
    verifyResponse(response)
    setSubscriptionProduct(response.data)
  }

  const doSearch = useCallback(async () => {
    setLoading(true)
    let response = null
    try {
      response = await searchOrders(searchOptions)
      if (response) {
        // Process search response
        verifyResponse(response)
        setOrders(response.data.list)
        setSearchResultInfo({
          currentPageNo: response.data.currentPageNo,
          hasNext: response.data.hasNext,
          pageSize: response.data.pageSize,
          pages: response.data.pages,
          total: response.data.total,
        })
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }, [searchOptions])

  useEffect(() => {
    AccessControl.SetAccess({
      ...AccessControl.Access,
      listOrderAccess: AccessControl.VerifyAccess([
        PERMISSIONS.ORDER.ORDER_LIST,
      ]),
      queryOrderAccess: AccessControl.VerifyAccess([
        PERMISSIONS.ORDER.ORDER_QUERY_BY_ID,
      ]),
    })
  }, [AccessControl.UserPermissions])

  useEffect(() => {
    loadSubscriptionProduct()
  }, [])

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    doSearch()
  }, [searchOptions?.pageNo, doSearch])

  return (
    <PageContent>
      <StyledOrderSection>
        <PageHeading>
          <div>Order</div>
          <div className="actions"></div>
        </PageHeading>
        <div>
          {!selectedOrder && (
            <OrderSearch setSearchOptions={setSearchOptions} />
          )}
          {orders && !selectedOrder && (
            <OrderTable
              orders={orders}
              access={AccessControl.Access}
              openOrderDetail={(order) => {
                setSelectedOrder(order)
              }}
              searchResultInfo={searchResultInfo}
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
            />
          )}
          {selectedOrder && (
            <OrderDetail
              order={selectedOrder}
              subscriptionProduct={subscriptionProduct}
              closeOrderDetail={() => setSelectedOrder(null)}
            />
          )}
        </div>

        <LoadingScreen loading={loading} />
      </StyledOrderSection>
    </PageContent>
  )
}

export default Order
