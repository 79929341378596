import styled from "@emotion/styled"
import Dialog from "@mui/material/Dialog"

const StyledDashboard = styled.div`
  display: flex;
  /* font-size: 25px; */
  /* height: 80vh;
  align-items: center;
  justify-content: center; */
  padding: 20px;
  flex-direction: row;
  flex-wrap: wrap;
`

const StyledInfoCard = styled.div`
  position: relative;
  display: flex;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  overflow: hidden;
  width: 220px;
  height: 120px;
  padding: 10px 20px;
  flex-direction: column;
  margin: 0 15px 15px 0;
  h3 {
    margin: 10px 0;
    font-size: 16px;
    min-height: 35px;
  }
  .value {
    /* text-align: center; */
    font-size: 30px;
    font-weight: 200;
    &.text {
      font-size: 20px;
    }
    &.info {
      font-size: 16px;
    }
  }
  .icon-btn {
    position: absolute;
    top: 8px;
    right: 2px;
  }
`

const StyledInfoSearchBar = styled.div`
  display: flex;
  background-color: white;
  margin: 40px 20px 16px 20px;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px;

  .time-input {
    margin-right: 10px;
  }
`
const StyledDashboardHeading = styled.h2`
  padding: 0px 20px;
`

const StyledChartDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  padding: 10px;

  .MuiPaper-root {
    width: auto;
    max-width: calc(100% - 64px);
    padding: 10px;
  }
  .error {
    font-size: 0.75rem;
    color: #d32f2f;
    margin-top: 3px;
  }
`

export {
  StyledDashboardHeading,
  StyledDashboard,
  StyledInfoCard,
  StyledInfoSearchBar,
  StyledChartDialog,
}
