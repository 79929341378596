import React, { useState, useRef, useEffect } from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Card from "@mui/material/Card"
import FormControl from "@mui/material/FormControl"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import { useForm } from "react-hook-form"
import { useSnackbar } from "notistack"
import { verifyResponse } from "utils/httpRequest"
import { resetAdminUserPassword } from "services/admin"

const AdminResetPassword = ({ admin }) => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm()
  const [loading, setLoading] = useState(false)

  const resetPasswordResetFormRef = useRef(null)

  useEffect(() => {
    reset()
  }, [admin])

  const handlePasswordResetAction = async (data) => {
    try {
      setLoading(true)
      const response = await resetAdminUserPassword({
        targetUserId: admin.id,
        newPassword: data.newPassword,
      })
      verifyResponse(response)
      if (response.rtnCode === 200) {
        enqueueSnackbar("Password Reset Success", {
          variant: "success",
        })
      } else {
        enqueueSnackbar("Password Reset Fail", {
          variant: "warning",
        })
      }
    } catch (error) {
      console.error("SetAdminUserRole error: ", error)
    } finally {
      setLoading(false)
      resetPasswordResetFormRef.current.reset()
    }
  }

  const content = admin ? (
    <Card sx={{ marginTop: "20px" }}>
      <CardContent>
        <form
          ref={resetPasswordResetFormRef}
          name="password-reset"
          id="password-reset"
          onSubmit={handleSubmit(handlePasswordResetAction)}
        >
          <div className="role-header">
            <Typography variant="h5" component="div">
              Reset Password :
            </Typography>

            <FormControl sx={{ m: 1, maxWidth: 892, width: "300px" }}>
              <TextField
                autoComplete="off"
                margin="dense"
                id="new-password"
                label="New Password"
                type="password"
                fullWidth
                {...register("newPassword", { required: true })}
                error={!!errors?.newPassword}
                helperText={
                  !!errors?.newPassword ? "* Password is required" : " "
                }
                variant="standard"
              />
            </FormControl>
            <FormControl sx={{ m: 1, maxWidth: 892, width: "300px" }}>
              <TextField
                autoComplete="off"
                id="new-password-verify"
                label="Verify Password"
                type="password"
                fullWidth
                {...register("newPasswordVerify", {
                  required: " * Required",
                  validate: {
                    match: (v) => v === watch("newPassword"),
                  },
                })}
                error={!!errors?.newPasswordVerify}
                helperText={
                  !!errors?.newPasswordVerify
                    ? errors?.newPasswordVerify.type === "required"
                      ? "* Verify Password is required"
                      : "* Password must be match"
                    : ""
                }
                variant="standard"
              />
            </FormControl>
            <div className="info">
              <ul>
                <li>
                  Must have one lower case (a-z), one upper case (A-Z) and one
                  numbers (0-9)
                </li>
                <li> Must have 8 characters in length</li>
              </ul>
            </div>
          </div>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button
              id="reset-password-reset-btn"
              className="reset-password-reset-btn"
              type="reset"
              variant="standard"
              size="small"
            >
              clear
            </Button>
            <Button type="submit" variant="contained" size="small">
              Reset
            </Button>
          </CardActions>
        </form>
      </CardContent>
    </Card>
  ) : null
  return [content, loading]
}

export default AdminResetPassword
