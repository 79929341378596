import React from "react"
import PropTypes from "prop-types"
import moment from "moment/moment"
import Button from "@mui/material/Button"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { StyledAdminTable } from "../Admin/Admin.style"

const ContributorTable = ({ contributors, openDetailPage, openEditDlg }) => {
  const content = (
    <StyledAdminTable>
      {!Boolean(contributors) ||
        (contributors.length === 0 && (
          <div className="search-result-info">No Record.</div>
        ))}
      {Boolean(contributors) && contributors.length > 0 && (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="role table">
              <TableHead>
                <TableRow>
                  <TableCell>Contributor </TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Created Date</TableCell>
                  <TableCell sx={{ minWidth: 160, textAlign: "right" }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contributors.map((row) => (
                  <TableRow
                    key={`role-item-${row.id}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell scope="row">{row.userName}</TableCell>
                    <TableCell scope="row">{row.email}</TableCell>
                    <TableCell>{moment(row.createdAt).format("LLL")}</TableCell>
                    <TableCell sx={{ textAlign: "right" }}>
                      <Button
                        style={{ width: "80px" }}
                        onClick={() => {
                          openDetailPage(row)
                        }}
                        variant="outlined"
                      >
                        Detail
                      </Button>
                      <Button
                        style={{ width: "80px" }}
                        onClick={() => {
                          openEditDlg(row)
                        }}
                        variant="outlined"
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </StyledAdminTable>
  )

  return content
}

ContributorTable.propTypes = {
  contributors: PropTypes.array.isRequired,
  openDetailPage: PropTypes.func.isRequired,
  openEditDlg: PropTypes.func.isRequired,
}

export default ContributorTable
