import { SESSION_VARIABLES } from "constants/system";

const LoginCallBack = async () => {
  console.info("succeeded Login with Auth0");
};

const LogoutCallBack = () => {
  localStorage.removeItem(SESSION_VARIABLES.ACCESS_TOKEN);
  localStorage.removeItem(SESSION_VARIABLES.ADMIN_USER_ID);
};

export { LoginCallBack, LogoutCallBack };
