import styled from "@emotion/styled";

const StyledOrderSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledOrderSearch = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledOrderSearchBar = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 30px);
  align-items: center;
  /* border: 1px solid #f9eded; */
  padding: 20px 15px;
  border-radius: 7px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

  .email {
    margin: 0;
    width: 300px;
  }
  .time-input {
    width: 150px;
  }
  .search-btn {
    padding: 14px 22px;
  }
  .MuiInputBase-root {
    margin-right: 10px;
  }
`;

const StyledRedeemTable = styled.div`
  display: flex;
  margin-top: 20px;

  .search-record-info {
    padding: 10px 13px 10px 13px;
    font-size: 14px;
    border-bottom: 1px solid #e0e0e0;
  }

  .pagination-section {
    display: flex;
    justify-content: flex-end;
  }
`;

export {
  StyledOrderSection,
  StyledOrderSearch,
  StyledOrderSearchBar,
  StyledRedeemTable,
};
