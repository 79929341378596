import React, { useState, useRef } from "react";
import PageContent from "components/common/LayoutComponents/PageContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import ImageCrop from "components/common/ImageCrop";
import LoadingScreen from "components/common/LoadingScreen";
import { uploadPortraitImage } from "utils/s3";
import { getUploadPortraitUrl } from "services/user";
import { editContributor } from "services/contributor";
import { StyledContributorDetail } from "./Contributor.style";
import { verifyResponse } from "utils/httpRequest";

const ContributorDetail = ({ contributor, close, refreshContributors }) => {
  const [loading, setLoading] = useState(false);
  const portraitUrlRef = useRef(contributor.portraitUrl + "?tr=w-400,h-300");
  const uploadImage = async (imageBlob) => {
    // turn blob file to File format
    const myFile = new File([imageBlob], "image.jpeg", {
      type: imageBlob.type,
    });

    setLoading(true);

    // get upload info first
    const response = await getUploadPortraitUrl();
    verifyResponse(response);
    const { awsBucket, awsKey, uploadSignedUrl } = response.data;
    // upload to S3
    await uploadPortraitImage({ file: myFile, uploadUrl: uploadSignedUrl });
    // update contributor record with new portrait image
    await editContributor({
      ...contributor,
      portraitAwskey: awsKey,
      portraitAwsbucket: awsBucket,
    });
    //

    const newImageUrl = `https://${awsBucket}.s3.amazonaws.com/${awsKey}?tr=w-400,h-300`;
    portraitUrlRef.current = newImageUrl;
    setLoading(false);
  };
  return (
    <PageContent>
      <StyledContributorDetail>
        <Card sx={{ maxWidth: 500 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Name
            </Typography>
            <Typography variant="h5" component="div">
              {contributor.userName}
            </Typography>

            <Typography
              sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
              color="text.secondary"
            >
              Email:
            </Typography>
            <Typography variant="body2">{contributor.email}</Typography>

            <Typography
              sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
              color="text.secondary"
            >
              Email:
            </Typography>
            <Typography variant="body2">{contributor.email}</Typography>
          </CardContent>
          <CardActions>
            <Button
              onClick={() => {
                close();
              }}
            >
              Close
            </Button>
          </CardActions>
        </Card>

        <Card sx={{ maxWidth: 500 }}>
          <CardContent>
            <h4>Avatar</h4>
            <div>
              <img
                className="portrait"
                src={portraitUrlRef.current}
                alt="portrait"
              />
            </div>
            <ImageCrop onCrop={uploadImage} />
          </CardContent>
        </Card>
        <LoadingScreen loading={loading} />
      </StyledContributorDetail>
    </PageContent>
  );
};

export default ContributorDetail;
