import React, { useState, useRef, useCallback } from "react"
import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import MediaContentDisplay from "components/common/MediaManager/MediaContentDisplay"
import { addModalContent, editModalContent } from "services/modal"
import {
  MODAL_CONTENT_TYPE,
  MEDIA_TYPE_INDEX,
  MODAL_CONTENT_TYPE_INDEX,
} from "constants/global"
import { useForm } from "react-hook-form"
import { useSnackbar } from "notistack"
import { StyledModalContentDialog } from "./Modal.style"

const ModalContentDlg = (props) => {
  const initModalContent = {
    modalId: 0,
    content: "",
    type: 0,
    resourceId: 0,
    ext: "",
  }

  const { enqueueSnackbar } = useSnackbar()

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm()

  const { onSubmit, openMediaManager, modal } = props
  const [modalContent, setModalContent] = useState(initModalContent)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)

  const [resource, setResource] = useState(false)
  const resourceRef = useRef(null)

  const getTypeAccess = (type) => {
    const typeObj = MODAL_CONTENT_TYPE.find((item) => item.value === type)

    if (typeObj.text === "Audio") {
      return [MEDIA_TYPE_INDEX.audio]
    }
    if (typeObj.text === "Video") {
      return [MEDIA_TYPE_INDEX.video]
    }
    if (typeObj.text === "Image") {
      return [MEDIA_TYPE_INDEX.image]
    }

    return []
  }

  const updateResource = () => {
    setResource({
      ...resourceRef.current,
      selectAction: selectResource,
      clearResourceAction: clearResource,
    })
  }

  const selectResource = useCallback(() => {
    openMediaManager((media) => {
      resourceRef.current = media
      setValue("resourceId", media.id)
      updateResource()
    }, getTypeAccess(watch("type")))
  }, [watch("type")])

  const clearResource = () => {
    resourceRef.current = null
    setValue("resourceId", null)
    updateResource()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const openDlg = (editModalContent) => {
    reset()
    const editMode = editModalContent ? true : false
    setModalContent(editModalContent || initModalContent)
    setIsEditMode(editMode)

    if (editMode) {
      setValue("resourceId", editModalContent.resourceId)
      setValue("type", editModalContent.type)
      setValue("ext", editModalContent.ext)
      resourceRef.current = editModalContent.resourceDto
    } else {
      resourceRef.current = null
    }
    updateResource()

    setOpen(true)
  }

  const handleAction = async (data) => {
    // send request
    setLoading(true)
    const response = isEditMode
      ? await editModalContent({
          ...data,
          modalId: modal.id,
          id: modalContent.id,
        })
      : await addModalContent({
          ...data,
          modalId: modal.id,
        })
    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar(
        isEditMode ? "Modal Content Update" : "Create Modal Content success",
        {
          variant: "success",
        }
      )
    } else {
      // handling error message
      enqueueSnackbar(
        isEditMode ? "Update Modal Content Fail" : "Create Modal Content Fail",
        {
          variant: "warning",
        }
      )
    }
    setLoading(false)
    onSubmit()
    handleClose()
  }

  const dlg = (
    <StyledModalContentDialog open={open} minWidth={600}>
      <DialogTitle>{isEditMode ? "Edit Modal" : "Add Modal"}</DialogTitle>
      <form onSubmit={handleSubmit(handleAction)}>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <TextField
              label="Content"
              variant="standard"
              margin="dense"
              multiline
              rows={3}
              fullWidth
              defaultValue={modalContent.content}
              {...register("content", { required: true })}
              error={!!errors?.content}
              helperText={!!errors?.content ? "* Content is required" : " "}
            />
          </FormControl>
          <Box>
            <FormControl fullWidth margin="dense" variant="standard">
              <input type="hidden" {...register("type", { required: true })} />
              <InputLabel id="modal-type-select-label">Type</InputLabel>
              <Select
                labelId="modal-type-select"
                id="demo-simple-select"
                defaultValue={modalContent.type}
                label="Age"
                onChange={(e) => {
                  setValue("type", e.target.value)
                  // remove resource if not relate
                  if (
                    MODAL_CONTENT_TYPE_INDEX[watch("type")] !== "Video" &&
                    MODAL_CONTENT_TYPE_INDEX[watch("type")] !== "Audio" &&
                    MODAL_CONTENT_TYPE_INDEX[watch("type")] !== "Image"
                  ) {
                    setValue("resourceId", null)
                    resourceRef.current = null
                    updateResource()
                  }
                }}
              >
                {MODAL_CONTENT_TYPE.map((type) => (
                  <MenuItem
                    key={`modal-type-item-${type.value}`}
                    value={type.value}
                  >
                    {type.text}
                  </MenuItem>
                ))}
              </Select>
              {!!errors?.type && (
                <div className="error">* Type is required</div>
              )}
            </FormControl>
          </Box>
          <FormControl fullWidth margin="dense">
            <TextField
              label="Extension Data (JSON)"
              variant="standard"
              margin="dense"
              multiline
              rows={4}
              fullWidth
              defaultValue={modalContent.ext}
              {...register("ext")}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <div className="control-label">Resource</div>
            <input
              type="hidden"
              {...register("resourceId", {
                required:
                  MODAL_CONTENT_TYPE_INDEX[watch("type")] === "Video" ||
                  MODAL_CONTENT_TYPE_INDEX[watch("type")] === "Audio" ||
                  MODAL_CONTENT_TYPE_INDEX[watch("type")] === "Image",
              })}
            />
            <MediaContentDisplay resource={resource} />
            {!!errors?.resourceId && (
              <div className="error">* Resource is required</div>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" size="small">
            {isEditMode ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </form>
    </StyledModalContentDialog>
  )

  return [openDlg, dlg, loading]
}

export default ModalContentDlg
