import React, { useState } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import LoadingScreen from "components/common/LoadingScreen";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { StyledSubscriptionGroupDlg } from "./SubscriptionGroup.style";
import {
  addSubscriptionGroup,
  editSubscriptionGroup,
} from "services/subscription";

const SubscriptionGroupDialog = (props) => {
  const initSubscriptionGroup = {
    name: "",
    code: "",
    description: "",
  };

  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { onSubmit } = props;
  const [subScriptionGroup, setSubscriptionGroup] = useState(
    initSubscriptionGroup
  );
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const openDlg = (editSubscriptionGroup) => {
    reset();
    setSubscriptionGroup(editSubscriptionGroup || initSubscriptionGroup);
    setIsEditMode(editSubscriptionGroup ? true : false);
    setOpen(true);
  };

  const handleAction = async (data) => {
    // send request
    setLoading(true);
    const response = isEditMode
      ? await editSubscriptionGroup({ ...data, id: subScriptionGroup.id })
      : await addSubscriptionGroup(data);
    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar(
        isEditMode
          ? "Update Subscription Group Success"
          : "Create Subscription Group Success",
        { variant: "success" }
      );
    } else {
      // handling error message
      enqueueSnackbar(
        isEditMode
          ? "Update Subscription Group Fail"
          : "Create Subscription Group Fail",
        { variant: "warning" }
      );
    }
    setLoading(false);
    onSubmit();
    handleClose();
  };

  const dlg = (
    <StyledSubscriptionGroupDlg open={open}>
      <form onSubmit={handleSubmit(handleAction)}>
        <DialogTitle>
          {isEditMode ? "Edit Subscription Group" : "Add Subscription Group"}
        </DialogTitle>
        <DialogContent>
          <FormControl>
            <TextField
              autoFocus
              autoComplete="off"
              id="subscription-group-name"
              label="Name"
              type="text"
              fullWidth
              defaultValue={subScriptionGroup.name}
              {...register("name", { required: true })}
              error={!!errors?.name}
              helperText={!!errors?.name ? "Name is required" : " "}
              variant="standard"
            />
          </FormControl>

          <FormControl>
            <TextField
              autoFocus
              autoComplete="off"
              id="subscription-group-code"
              label="Code"
              type="text"
              fullWidth
              defaultValue={subScriptionGroup.code}
              {...register("code", { required: true })}
              error={!!errors?.code}
              helperText={!!errors?.code ? "Code is required" : " "}
              variant="standard"
            />
          </FormControl>
          <FormControl>
            <TextField
              autoFocus
              autoComplete="off"
              id="subscription-group-description"
              label="Description"
              type="text"
              fullWidth
              multiline
              rows={5}
              defaultValue={subScriptionGroup.description}
              {...register("description", { required: true })}
              error={!!errors?.description}
              helperText={
                !!errors?.description ? "Description is required" : " "
              }
              variant="standard"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" size="small">
            {isEditMode ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </form>
      <LoadingScreen loading={loading} />
    </StyledSubscriptionGroupDlg>
  );

  return [openDlg, dlg, loading];
};

export default SubscriptionGroupDialog;
