import { httpPost } from "utils/httpRequest"

const listFeatureProgram = async ({ pageNo, pageSize, programTitle }) => {
  const resp = await httpPost("admin/featuredProgram/list", {
    pageNo,
    pageSize,
    programTitle,
  })

  return resp.data
}

const addFeaturedProgram = async ({ programId }) => {
  const resp = await httpPost("admin/featuredProgram/add", {
    programId,
  })

  return resp.data
}

const editFeaturedProgram = async ({ programId, id }) => {
  const resp = await httpPost("admin/featuredProgram/edit", {
    programId,
    id,
  })
  return resp.data
}

const deleteFeaturedProgram = async ({ id }) => {
  const resp = await httpPost("admin/featuredProgram/delete", {
    id,
  })
  return resp.data
}

export {
  listFeatureProgram,
  addFeaturedProgram,
  editFeaturedProgram,
  deleteFeaturedProgram,
}
