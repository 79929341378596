import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import { StyledModalContent } from "./Modal.style";
import { verifyResponse } from "utils/httpRequest";
import { Container, Draggable } from "react-smooth-dnd";
import { calculateNewRank } from "utils/data";
import MediaContentDisplay from "components/common/MediaManager/MediaContentDisplay";
import MediaManager from "components/common/MediaManager";
import { useSnackbar } from "notistack";
import ModalContentDlg from "./ModalContentDlg";
import {
  getModelContentByModalId,
  deleteModalContent,
  exchangeModalContentOrder,
} from "services/modal";
import { MODAL_CONTENT_TYPE_INDEX } from "constants/global";

const ModalContentForm = (props) => {
  const { onSubmit, access } = props;
  const [modal, setModal] = useState(null);
  const [modalContents, setModalContents] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openMediaManager, mediaManager] = MediaManager();

  const { enqueueSnackbar } = useSnackbar();

  const [
    openModalContentDialog,
    modalContentDialog,
    modalContentDialogLoading,
  ] = ModalContentDlg({
    onSubmit: () => {
      refreshContents();
      onSubmit();
    },
    openMediaManager: openMediaManager,
    modal: modal,
  });

  const openForm = (editModal) => {
    setModal(editModal);

    setOpen(true);
  };

  const refreshContents = async () => {
    setLoading(true);
    const response = await getModelContentByModalId(modal ? modal.id : 0);
    verifyResponse(response);
    setModalContents(response.data);
    setLoading(false);
  };

  const deleteContent = async (modalContentId) => {
    setLoading(true);
    const response = await deleteModalContent(modalContentId);
    verifyResponse(response);
    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar("Modal Content Deleted", {
        variant: "success",
      });
      refreshContents();
    } else {
      // handling error message
      enqueueSnackbar("Modal Content Deleted Fail", {
        variant: "warning",
      });
    }
    setLoading(true);
  };

  useEffect(() => {
    refreshContents();
  }, [modal]);

  const renderModalContentForm = (content, index) => {
    return (
      <div className="modal-content-card" key={`modal-content-item-${index}`}>
        <div className="modal-content-card-detail">
          <TextField
            id="outlined-read-only-input"
            label="Content"
            multiline
            rows={4}
            value={content.content}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="outlined-read-only-input"
            label="Extension"
            multiline
            rows={4}
            value={content.ext}
            InputProps={{
              readOnly: true,
            }}
          />
          <MediaContentDisplay resource={content.resourceDto} small />
        </div>
        <div className="control-block">
          <Chip label={MODAL_CONTENT_TYPE_INDEX[content.type]} />
          {access.deleteModalContentAccess && (
            <Button
              style={{
                backgroundColor: "red",
                borderColor: "red",
                color: "white",
              }}
              variant="outlined"
              size="small"
              onClick={() => {
                deleteContent(content.id);
              }}
            >
              Delete
            </Button>
          )}
          {access.editModalContentAccess && (
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                openModalContentDialog(content);
              }}
            >
              Edit
            </Button>
          )}
        </div>
      </div>
    );
  };

  const formContent = open ? (
    <StyledModalContent>
      <div className="header-actions">
        <h3 className="header-title">"{modal.tag}" Content List</h3>
        <div className="actions">
          {access.addModalContentAccess && (
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                openModalContentDialog();
              }}
            >
              New Content
            </Button>
          )}
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            close
          </Button>
        </div>
      </div>

      <Container
        className="sort-list"
        onDrop={async (e) => {
          const updateIndexArray = calculateNewRank({
            addedIndex: e.addedIndex,
            removedIndex: e.removedIndex,
            indexString: "contentIndex",
            list: modalContents,
          });

          await exchangeModalContentOrder(updateIndexArray);
          refreshContents();
        }}
      >
        {modalContents?.map((content, index) =>
          access.exchangeIndexModalContentAccess ? (
            <Draggable key={content.id}>
              {renderModalContentForm(content, index)}
            </Draggable>
          ) : (
            <div key={content.id}>{renderModalContentForm(content, index)}</div>
          )
        )}
      </Container>

      {modalContentDialog}
      {mediaManager}
    </StyledModalContent>
  ) : null;

  return [openForm, formContent, loading || modalContentDialogLoading];
};

export default ModalContentForm;
