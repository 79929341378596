import React, { useState } from "react"
import PropTypes from "prop-types"
import TextField from "@mui/material/TextField"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import Button from "@mui/material/Button"
import {
  StyledRedeemCodeSearch,
  StyledRedeemCodeSearchBar,
} from "./Redeem.style"

const RedeemSearch = ({ setSearchOptions }) => {
  const [redeemCode, setRedeemCode] = React.useState("")
  const [productCode, setProductCode] = React.useState("")
  const [batchId, setBatchId] = React.useState("")
  const [redeemMemberEmail, setRedeemMemberEmail] = React.useState("")
  const [status, setStatus] = useState(-1)
  const [pageSize, setPageSize] = useState(10)

  const clearSearch = () => {
    setRedeemCode("")
    setProductCode("")
    setBatchId("")
    setStatus(-1)
    setRedeemMemberEmail("")
  }

  const searchAction = () => {
    const options = {
      pageNo: 1,
      pageSize: pageSize,
      status: status === -1 ? null : status,
      redeemCode: redeemCode === "" ? null : redeemCode,
      redeemMemberEmail: redeemMemberEmail === "" ? null : redeemMemberEmail,
      productCode: productCode === "" ? null : productCode,
      batchId: batchId === "" ? 0 : batchId,
    }

    setSearchOptions(options)
  }

  return (
    <StyledRedeemCodeSearch>
      <StyledRedeemCodeSearchBar>
        <TextField
          className="redeem-code"
          autoFocus
          autoComplete="off"
          margin="dense"
          id="redeem-code-id"
          label="Redeem Code"
          type="text"
          value={redeemCode}
          onChange={(e) => {
            setRedeemCode(e.target.value)
          }}
          variant="outlined"
        />
        <TextField
          className="product-code"
          autoComplete="off"
          margin="dense"
          id="product-code-id"
          label="Product Code"
          type="text"
          value={productCode}
          onChange={(e) => {
            setProductCode(e.target.value)
          }}
          variant="outlined"
        />
        <TextField
          className="member-email"
          autoComplete="off"
          margin="dense"
          id="member-email-id"
          label="Member Email"
          type="text"
          value={redeemMemberEmail}
          onChange={(e) => {
            setRedeemMemberEmail(e.target.value)
          }}
          variant="outlined"
        />
        <FormControl style={{ width: "150px" }}>
          <InputLabel id="redeem-code-status-select-label">Status</InputLabel>
          <Select
            labelId="redeem-code-status-select-label"
            id="redeem-code-status-select"
            value={status}
            label="Status"
            onChange={(e) => {
              setStatus(e.target.value)
            }}
          >
            <MenuItem value={-1}>All</MenuItem>
            <MenuItem value={0}>Pending</MenuItem>
            <MenuItem value={1}>Activated</MenuItem>
          </Select>
        </FormControl>

        <FormControl style={{ width: "120px" }}>
          <TextField
            className="product-code"
            autoComplete="off"
            margin="dense"
            id="batch-id"
            label="Batch ID"
            type="number"
            value={batchId}
            onChange={(e) => {
              setBatchId(e.target.value)
            }}
            variant="outlined"
          />{" "}
        </FormControl>

        <FormControl style={{ width: "150px" }}>
          <InputLabel id="media-page-size-select-label">Page Size</InputLabel>
          <Select
            labelId="redeem-code-page-size-select-label"
            id="redeem-code-page-size-select"
            value={pageSize}
            label="Page Size"
            onChange={(e) => {
              setPageSize(e.target.value)
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
        <Button
          className="search-btn"
          variant="contained"
          size="large"
          onClick={() => {
            searchAction()
          }}
        >
          Search
        </Button>
        <Button
          className="search-btn"
          variant=""
          size="large"
          onClick={clearSearch}
        >
          Clear
        </Button>
      </StyledRedeemCodeSearchBar>
    </StyledRedeemCodeSearch>
  )
}

RedeemSearch.propTypes = {
  setSearchOptions: PropTypes.func.isRequired,
}

export default RedeemSearch
