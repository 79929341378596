import React, { useState, useEffect } from "react"
import {
  searchResource,
  archiveResource,
  unarchiveResource,
  checkAudioFileSize,
  resizeAudioResource,
} from "services/media"
import { findMediaInfoByFileName } from "utils/media"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import Slider from "@mui/material/Slider"
import Select from "@mui/material/Select"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import ArchiveIcon from "@mui/icons-material/Archive"
import AttachmentIcon from "@mui/icons-material/Attachment"
import SearchIcon from "@mui/icons-material/Search"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import TransformIcon from "@mui/icons-material/Transform"
import RefreshIcon from "@mui/icons-material/Refresh"
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn"
import AvTimerRoundedIcon from "@mui/icons-material/AvTimerRounded"
import TextField from "@mui/material/TextField"
import MobileDateRangePicker from "@mui/lab/MobileDateRangePicker"
import DesktopDateRangePicker from "@mui/lab/DesktopDateRangePicker"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import Box from "@mui/material/Box"
import { UseConfirmDialog } from "components/customHooks"
import { useSnackbar } from "notistack"
import LoadingScreen from "../LoadingScreen"
import MediaRecordDlg from "./MediaRecordDlg"
import { verifyResponse } from "utils/httpRequest"
import { bytesToSize, secondToTime } from "utils/convert"

import {
  StyledMediaSearch,
  StyledMediaSearchPanel,
  StyledMediaSearchInput,
  StyledMediaSearchSelect,
  StyledMediaSearchResults,
  StyledMediaSearchResultItem,
  StyledMediaPreview,
  StyledPaperDiv,
} from "./MediaManager.style"

import {
  MEDIA_TYPE,
  MEDIA_TYPE_INDEX,
  EXT_TO_MEDIA_TYPE_COLOR,
} from "constants/global"

const MediaSearch = ({ access, onSelect, handleClose, targetType }) => {
  const [searched, setSearched] = useState(false)
  const [archiveLoading, setArchiveLoading] = useState(false)
  const [searchLoading, setLoadingSearch] = useState(false)
  const [searchResult, setSearchResult] = useState([])
  const [archiveStatus, setArchiveStatus] = useState(0)
  const [searchKeyword, setSearchKeyword] = useState("")
  const [mediaType, setMediaType] = useState(targetType ? targetType[0] : 0)
  const [fromDate, setFromDate] = useState([null, null])
  const [toDate, setToDate] = useState([null, null])
  const [fileSize, setFileSize] = React.useState([0, 7])
  const showAllTypes = !targetType || targetType.length === 0

  const { enqueueSnackbar } = useSnackbar()

  const [ConfirmDialog, openConfirmDialog] = UseConfirmDialog({
    title: "Delete Confirm",
    description: "Are you sure want delete this ?",
    agreeText: "Yes",
    disagreeText: "No",
  })

  const [openMediaRecordDlg, mediaRecordDlg, mediaRecordDlgLoading] =
    MediaRecordDlg({
      onSubmit: () => {
        handleSearch()
      },
    })

  const onSearchInputClick = async (e) => {
    if (e.key === "Enter") {
      handleSearch()
    } else if (e.key === "Escape") {
      setSearchKeyword("")
    }
  }

  const refreshAudioFileSize = async (id) => {
    const response = await checkAudioFileSize({ id })
    verifyResponse(response)

    const updatedFileInfo = response.data

    if (!updatedFileInfo) {
      console.error("invalid response data")
      return
    }

    const updatedResult = searchResult.map((resultItem) => {
      return resultItem.id === id
        ? { ...resultItem, size: updatedFileInfo.size }
        : resultItem
    })
    setSearchResult(updatedResult)
  }

  const resizeAudioFile = async (resource, callback) => {
    // trigger request
    enqueueSnackbar(`Start convert audio file "${resource.name}"`, {
      variant: "success",
    })
    updateSearchResultLoadingStatus(resource.id, true)
    const response = await resizeAudioResource(resource.id)
    verifyResponse(response)
    updateSearchResultLoadingStatus(resource.id, false)
    // run callback function after response
    if (response.rtnCode === 200) {
      enqueueSnackbar(`Audio File "${resource.name}" conversion finished.`, {
        variant: "success",
      })
    } else {
      // display error message
      enqueueSnackbar(
        `Audio File "${resource.name}" Archived Fail. Message: ${response.message}`,
        { variant: "warning" }
      )
      console.log("error: ", response)
    }
  }

  const onSearchInputFocus = (e) => {
    e.target.select()
  }

  const archiveMediaResource = async (resourceId) => {
    // request to archive by given resource
    setArchiveLoading(true)
    const response = await archiveResource(resourceId)
    verifyResponse(response)
    setArchiveLoading(false)

    if (response.rtnCode === 200) {
      // remove archive resource from the searchResult if success
      setSearchResult(
        searchResult.filter((result) => {
          return result.id !== resourceId
        })
      )
      enqueueSnackbar("Media Resource Archived.", { variant: "success" })
    } else {
      enqueueSnackbar("Media Resource Archived Fail.", { variant: "warning" })
    }
  }

  const unarchiveMediaResource = async (resourceId) => {
    // request to unarchive by given resource
    setArchiveLoading(true)
    const response = await unarchiveResource(resourceId)
    verifyResponse(response)
    setArchiveLoading(false)

    if (response.rtnCode === 200) {
      // remove archive resource from the searchResult if success
      setSearchResult(
        searchResult.filter((result) => {
          return result.id !== resourceId
        })
      )
      enqueueSnackbar("Media Resource UnArchived.", { variant: "success" })
    } else {
      enqueueSnackbar("Media Resource UnArchived Fail.", {
        variant: "warning",
      })
    }
  }

  const updateSearchResultLoadingStatus = (resourceId, loadingStatus) => {
    const newSearchResult = searchResult.map((resultItem) => {
      return resultItem.id === resourceId
        ? { ...resultItem, loading: loadingStatus }
        : resultItem
    })
    setSearchResult(newSearchResult)
  }

  const handleSearch = async () => {
    setSearched(true)
    setLoadingSearch(true)
    const response = await searchResource({
      name: searchKeyword,
      type: mediaType,
      isArchive: archiveStatus,
      minFileSize: fileSize[0] === 0 ? 0 : 2 ** fileSize[0] * 1024,
      maxFileSize: 2 ** fileSize[1] * 1024,
    })
    verifyResponse(response)
    setLoadingSearch(false)
    setSearchResult(
      response.data.map((resource) => {
        return {
          ...resource,
          loading: false,
        }
      })
    )
  }

  const handleFileSizeFilterChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return
    }
    //console.log(newValue)
    setFileSize(newValue)
  }

  function valueLabelFormat(value) {
    const units = ["KB", "MB", "GB", "TB"]

    let unitIndex = 0
    let scaledValue = value

    while (scaledValue >= 1024 && unitIndex < units.length - 1) {
      unitIndex += 1
      scaledValue /= 1024
    }

    return `${scaledValue} ${units[unitIndex]}`
  }

  function calculateValue(value) {
    return 2 ** value
  }

  return (
    <StyledMediaSearch>
      <StyledMediaSearchPanel>
        <div className="bar">
          <StyledMediaSearchInput
            type="text"
            placeholder="Search"
            value={searchKeyword}
            onChange={(e) => {
              setSearchKeyword(e.target.value)
            }}
            onFocus={onSearchInputFocus}
            onKeyDown={onSearchInputClick}
          />

          <StyledMediaSearchSelect>
            <FormControl fullWidth>
              <InputLabel id="media-type-select-label">Type</InputLabel>
              <Select
                labelId="media-type-select-label"
                id="media-type-select"
                value={mediaType}
                label="Type"
                onChange={(e) => {
                  setMediaType(e.target.value)
                }}
              >
                {showAllTypes && <MenuItem value={0}>ALL</MenuItem>}

                {!showAllTypes &&
                  targetType.includes(MEDIA_TYPE_INDEX.image) && (
                    <MenuItem value={MEDIA_TYPE_INDEX[MEDIA_TYPE.IMAGE]}>
                      {MEDIA_TYPE.IMAGE.toUpperCase()}
                    </MenuItem>
                  )}
                {!showAllTypes &&
                  targetType.includes(MEDIA_TYPE_INDEX.audio) && (
                    <MenuItem value={MEDIA_TYPE_INDEX[MEDIA_TYPE.AUDIO]}>
                      {MEDIA_TYPE.AUDIO.toUpperCase()}
                    </MenuItem>
                  )}
                {!showAllTypes &&
                  targetType.includes(MEDIA_TYPE_INDEX.video) && (
                    <MenuItem value={MEDIA_TYPE_INDEX[MEDIA_TYPE.VIDEO]}>
                      {MEDIA_TYPE.VIDEO.toUpperCase()}
                    </MenuItem>
                  )}
                {!showAllTypes &&
                  targetType.includes(MEDIA_TYPE_INDEX.document) && (
                    <MenuItem value={MEDIA_TYPE_INDEX[MEDIA_TYPE.DOCUMENT]}>
                      {MEDIA_TYPE.DOCUMENT.toUpperCase()}
                    </MenuItem>
                  )}
              </Select>
            </FormControl>
          </StyledMediaSearchSelect>

          <StyledMediaSearchSelect>
            <FormControl fullWidth>
              <InputLabel id="media-type-select-label">Status</InputLabel>
              <Select
                labelId="media-archive-select-label"
                id="media-archive-select"
                value={archiveStatus}
                label="Status"
                onChange={(e) => {
                  setArchiveStatus(e.target.value)
                }}
              >
                <MenuItem value={0}>Available</MenuItem>
                <MenuItem value={1}>Archived</MenuItem>
                <MenuItem value={2}>All</MenuItem>
              </Select>
            </FormControl>
          </StyledMediaSearchSelect>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDateRangePicker
              startText="Mobile start"
              value={fromDate}
              onChange={(newValue) => {
                setFromDate(newValue)
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </>
              )}
            />
            <DesktopDateRangePicker
              startText="Desktop start"
              value={toDate}
              onChange={(newValue) => {
                setToDate(newValue)
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </>
              )}
            />
          </LocalizationProvider>

          <Button
            className="search-btn"
            variant="contained"
            size="large"
            endIcon={<SearchIcon />}
            onClick={() => {
              handleSearch()
            }}
          >
            Search
          </Button>
        </div>
        <div className="size-filter">
          <Slider
            min={0}
            step={1}
            max={20}
            scale={calculateValue}
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
            value={fileSize}
            defaultValue={fileSize}
            onChange={handleFileSizeFilterChange}
            valueLabelDisplay="auto"
            disableSwap
          />
        </div>
        <div>
          Size Range: {valueLabelFormat(calculateValue(fileSize[0]))} -{" "}
          {valueLabelFormat(calculateValue(fileSize[1]))}
        </div>
      </StyledMediaSearchPanel>

      {searchLoading && <div className="loading-label">Loading ...</div>}
      {!searchLoading && searched && (
        <StyledMediaSearchResults>
          {searchResult.length === 0 ? (
            <div className="search-result-info">No record found</div>
          ) : (
            <>
              <div className="search-result-info">
                Total <strong>{searchResult.length}</strong> records found.
              </div>
              {searchResult.map((resultItem, index) => {
                const mediaInfo = findMediaInfoByFileName(resultItem.awsKey)

                return (
                  <StyledMediaSearchResultItem
                    key={`search-result-item-${resultItem.id}`}
                  >
                    <div className="upload-property title truncated">
                      <UploadFileIcon />
                      {resultItem.name}
                    </div>
                    <div className="upload-property">
                      <AttachmentIcon />
                      {`${bytesToSize(resultItem.size)} (${mediaInfo.type})`}
                      {(mediaInfo.type === MEDIA_TYPE.VIDEO ||
                        mediaInfo.type === MEDIA_TYPE.AUDIO) && (
                        <>
                          <AvTimerRoundedIcon />
                          <div>{secondToTime(resultItem.totalLength)}</div>
                        </>
                      )}
                    </div>
                    <div className="description">{resultItem.description}</div>
                    <StyledMediaPreview>
                      {mediaInfo.type === MEDIA_TYPE.VIDEO && (
                        <video controls id={`preview-video-${index}`}>
                          <source src={resultItem.urlDto.videoUrl_240} />
                        </video>
                      )}
                      {mediaInfo.type === MEDIA_TYPE.AUDIO && (
                        <video controls id={`preview-video-${index}`}>
                          <source src={resultItem.url} />
                        </video>
                      )}
                      {mediaInfo.type === MEDIA_TYPE.DOCUMENT && (
                        <StyledPaperDiv
                          color={EXT_TO_MEDIA_TYPE_COLOR[mediaInfo.ext]}
                        >
                          <a href={resultItem.url}>{mediaInfo.ext}</a>
                        </StyledPaperDiv>
                      )}
                      {mediaInfo.type === MEDIA_TYPE.IMAGE && (
                        <img
                          src={resultItem.urlDto.imgUrl_XS}
                          alt="upload-preview"
                        />
                      )}
                    </StyledMediaPreview>
                    <div className="actions">
                      {mediaInfo.type === MEDIA_TYPE.AUDIO && (
                        <>
                          <Tooltip title="Calculate Audio File Size">
                            <IconButton
                              sx={{ color: "#fe5004" }}
                              aria-label="recalculate audio file size"
                              component="label"
                              onClick={() => {
                                refreshAudioFileSize(resultItem.id)
                              }}
                            >
                              <RefreshIcon />
                            </IconButton>
                          </Tooltip>
                          {resultItem.loading ? (
                            <Tooltip title="Converting Audio File">
                              <IconButton
                                sx={{ color: "#3d71ef" }}
                                aria-label="Converting Audio File"
                                component="label"
                              >
                                <CircularProgress
                                  sx={{
                                    width: "24px!important",
                                    height: "24px!important",
                                  }}
                                  color="inherit"
                                />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Resize Audio File">
                              <IconButton
                                sx={{ color: "#3d71ef" }}
                                aria-label="Resize Audio File"
                                component="label"
                                onClick={() => {
                                  resizeAudioFile(
                                    resultItem,
                                    (callbackPlayload) => {
                                      console.log(
                                        "callbackPlayload:",
                                        callbackPlayload
                                      )
                                    }
                                  )
                                }}
                              >
                                <TransformIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      )}

                      {onSelect === null &&
                        resultItem.status === 0 &&
                        access.archiveMediaAccess && (
                          <Tooltip title="Archive Resource">
                            <IconButton
                              sx={{ color: "red" }}
                              aria-label="Archive Resource"
                              component="label"
                              onClick={() => {
                                openConfirmDialog(() => {
                                  // Archive resource
                                  archiveMediaResource(resultItem.id)
                                }, `Are you sure want to archive media "${resultItem.name}"`)
                              }}
                            >
                              <ArchiveIcon />
                            </IconButton>
                          </Tooltip>
                        )}

                      {onSelect === null &&
                        resultItem.status === -1 &&
                        access.archiveMediaAccess && (
                          <Tooltip title="Unarchive">
                            <IconButton
                              color="error"
                              aria-label="Unarchive"
                              component="label"
                              onClick={() => {
                                openConfirmDialog(() => {
                                  // Unarchive resource
                                  unarchiveMediaResource(resultItem.id)
                                }, `Are you sure want to unarchive media "${resultItem.name}"`)
                              }}
                            >
                              <ArchiveIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      {access.editMediaAccess && (
                        <Tooltip title="Edit">
                          <IconButton
                            sx={{ color: "#333" }}
                            aria-label="Edit"
                            component="label"
                            onClick={() => {
                              openMediaRecordDlg(resultItem)
                            }}
                          >
                            <AssignmentTurnedInIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {onSelect && (
                        <Button
                          variant="contained"
                          startIcon={<AssignmentTurnedInIcon />}
                          size="small"
                          onClick={() => {
                            onSelect(resultItem)
                            handleClose()
                          }}
                        >
                          Select
                        </Button>
                      )}
                    </div>
                  </StyledMediaSearchResultItem>
                )
              })}
            </>
          )}
        </StyledMediaSearchResults>
      )}
      {mediaRecordDlg}
      {ConfirmDialog}
      <LoadingScreen
        loading={searchLoading || mediaRecordDlgLoading || archiveLoading}
      />
    </StyledMediaSearch>
  )
}

export default MediaSearch
