import React, { useRef, useEffect } from "react";
import styled from "@emotion/styled";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { findMediaInfoByFileName } from "utils/media";
import {
  MEDIA_TYPE_INDEX,
  MEDIA_TYPES,
  EXT_TO_MEDIA_TYPE_COLOR,
} from "constants/global";

const StyledMediaContentDisplay = styled.div`
  display: flex;

  img {
    max-height: 100%;
    max-width: 100%;
  }
  video {
    max-height: 100%;
    max-width: 100%;
  }

  .image-select-frame {
    position: relative;
    display: flex;
    height: 200px;
    width: 330px;
    border: 2px dashed gray;
    font-size: 16px;
    color: gray;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    text-transform: capitalize;
    padding: 10px;
    border-radius: 5px;

    &.small {
      height: 100px;
      width: 180px;
    }

    .type-label {
      position: absolute;
      top: 0;
      left: 0;
    }

    .MuiChip-root {
      height: 20px;
      font-size: 12px;
      top: 5px;
      left: 5px;
      background-color: rgb(150 104 243);
      color: white;
    }
  }

  .action-btn {
    position: absolute;
    top: -12px;
    right: -12px;
    width: 10px;
    height: 10px;
    background-color: #1976d2;
    color: white;
    &:hover {
      background-color: #68acf0;
    }
  }
`;

const StyledDocumentPaperWrapper = styled.div`
  width: 95%;
  height: ${(props) => (props.small ? "150px" : "95px")};
  padding: 10px;
  border: 1px solid #ddd;
  background-size: cover;
  background-clip: content-box;
  background-color: ${(props) => (props.color ? props.color : `#f66f6f`)};
  box-sizing: border-box;
  position: relative;
  margin: auto;
  overflow: hidden;

  font: 400 2em/180px "Oswald", sans-serif;
  color: #fbfbfb;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;

  a {
    color: #fbfbfb;
    text-decoration: none;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    border: 50px solid transparent;
    border-bottom: 50px solid #fefefe;
    bottom: -60px;
    right: -65px;
    box-shadow: 0px 7px 6px -9px black;
    transform: rotate(135deg);
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    border: 50px solid transparent;
    border-top: 50px solid #fefefe;
    top: -60px;
    left: -65px;
    box-shadow: 0px -7px 6px -9px black;
    transform: rotate(135deg);
  }
`;

const MediaContentDisplay = ({ resource, small }) => {
  const {
    id,
    type,
    awsBucket,
    awsKey,
    url,
    urlDto,
    selectAction,
    clearResourceAction,
  } = resource ? resource : {};

  const playerRef = useRef(null);
  const mediaInfo = findMediaInfoByFileName(awsKey);

  const resourceType = resource
    ? MEDIA_TYPES.find((item) => item.value === resource.type)?.text
    : "Unknown";

  let mediaUrl = `${url}`;

  useEffect(() => {
    playerRef.current && playerRef.current.load();
  }, [awsBucket, awsKey]);
  if (!resource) {
    return (
      <StyledMediaContentDisplay>
        <div className={`image-select-frame ${small && "small"}`}>
          Empty Resource
        </div>
      </StyledMediaContentDisplay>
    );
  }

  return (
    <StyledMediaContentDisplay>
      <div className={`image-select-frame ${small && "small"}`}>
        {!id || !type ? (
          <>
            <div>Select Resource</div>
            {selectAction && (
              <IconButton
                className="action-btn"
                aria-label="add"
                size="large"
                color="primary"
                onClick={() => {
                  selectAction();
                }}
              >
                <AddIcon />
              </IconButton>
            )}
          </>
        ) : (
          <>
            {type === MEDIA_TYPE_INDEX.video && (
              <video ref={playerRef} controls>
                <source src={urlDto.videoUrl_240} />
              </video>
            )}
            {type === MEDIA_TYPE_INDEX.audio && (
              <video ref={playerRef} controls>
                <source src={mediaUrl} />
              </video>
            )}
            {type === MEDIA_TYPE_INDEX.document && (
              <StyledDocumentPaperWrapper
                color={EXT_TO_MEDIA_TYPE_COLOR[mediaInfo.ext]}
                className={`${small && "small"}`}
              >
                {mediaInfo.ext}
              </StyledDocumentPaperWrapper>
            )}
            {type === MEDIA_TYPE_INDEX.image && (
              <img src={urlDto.imgUrl_XS} alt="upload-preview" />
            )}
            {clearResourceAction && (
              <IconButton
                className="action-btn"
                aria-label="add"
                size="large"
                color="primary"
                onClick={() => {
                  clearResourceAction();
                }}
              >
                <ClearIcon />
              </IconButton>
            )}
            <Chip className="type-label" label={resourceType} />
          </>
        )}
      </div>
    </StyledMediaContentDisplay>
  );
};

export default MediaContentDisplay;
