import React, { useEffect, useState } from "react"
import PageHeading from "components/common/LayoutComponents/PageHeading"
import PageContent from "components/common/LayoutComponents/PageContent"
import Button from "@mui/material/Button"
import MediaManager from "components/common/MediaManager"
import { StyledMedia } from "./Media.style"
import { MEDIA_TYPE_INDEX, MEDIA_TYPES } from "constants/global"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardActions from "@mui/material/CardActions"
import Typography from "@mui/material/Typography"

import { getResourceStatInfo } from "services/media"
import { verifyResponse } from "utils/httpRequest"
import LoadingScreen from "components/common/LoadingScreen"

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
)

const Media = () => {
  const [openMediaManager, mediaManager] = MediaManager()
  const [statInfo, setStatInfo] = useState(null)
  const [loading, setLoading] = useState(false)

  const loadResources = async () => {
    setLoading(true)
    const response = await getResourceStatInfo()
    setLoading(false)
    verifyResponse(response)
    if (response.rtnCode === 200) {
      setStatInfo(response.data)
    }
  }

  useEffect(() => {
    loadResources()
  }, [])

  return (
    <StyledMedia>
      <PageHeading>
        <div>Media</div>
        <div className="actions">
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              openMediaManager(null, [
                MEDIA_TYPE_INDEX.image,
                MEDIA_TYPE_INDEX.video,
                MEDIA_TYPE_INDEX.audio,
                MEDIA_TYPE_INDEX.document,
                MEDIA_TYPE_INDEX.unknown,
              ])
            }}
          >
            Open Media Management
          </Button>
        </div>
      </PageHeading>
      <PageContent>
        {statInfo && (
          <div className="item-list ">
            {statInfo.map((mediaResourceTypeInfo) => {
              return (
                <Box
                  className="item"
                  sx={{ minWidth: 275 }}
                  key={`card-item-${mediaResourceTypeInfo.type}`}
                >
                  <Card variant="outlined">
                    <CardContent>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Resource Type
                      </Typography>
                      <Typography variant="h5" component="div">
                        {
                          MEDIA_TYPES.find(
                            (item) => item.value === mediaResourceTypeInfo.type
                          )?.text
                        }
                      </Typography>
                      <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        <span className="count-label">
                          {mediaResourceTypeInfo.typeCount}
                        </span>
                        {bull}
                        {mediaResourceTypeInfo.typeCount > 1
                          ? "Records"
                          : "Record"}
                      </Typography>
                      <Typography variant="body2">
                        {mediaResourceTypeInfo.type === 0 &&
                          "UnClassify resource"}
                        {mediaResourceTypeInfo.type === 1 &&
                          "Audio resource, mp3 only"}
                        {mediaResourceTypeInfo.type === 2 &&
                          "Video resource, mp4 only"}
                        {mediaResourceTypeInfo.type === 3 && "Image resource"}
                        {mediaResourceTypeInfo.type === 4 &&
                          "Document resource, PDF, docx, xlsx"}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        onClick={() => {
                          openMediaManager(null, [
                            MEDIA_TYPE_INDEX.image,
                            MEDIA_TYPE_INDEX.video,
                            MEDIA_TYPE_INDEX.audio,
                            MEDIA_TYPE_INDEX.document,
                            MEDIA_TYPE_INDEX.unknown,
                          ])
                        }}
                      >
                        Find Resource
                      </Button>
                    </CardActions>
                  </Card>
                </Box>
              )
            })}
          </div>
        )}
      </PageContent>
      {mediaManager}
      <LoadingScreen loading={loading} />
    </StyledMedia>
  )
}

export default Media
