import React, { useEffect, useState } from "react"
import { Button } from "@mui/material"
import {
  MEDIA_TYPE_INDEX,
  CHANNEL_TYPES,
  INNERCISE_MODE,
} from "constants/global"
import IconButton from "@mui/material/IconButton"
import ClearIcon from "@mui/icons-material/Clear"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import {
  StyledProgramInnerciseListControl,
  StyledProgramInnerciseResourceWarper,
  StyledProgramInnerciseResourcePreview,
} from "./ProgramInnerciseListControl.style"

const ProgramInnerciseListControl = ({
  openMediaManager,
  openPlaybookDlg,
  resources,
  setResources,
  innerciseMode,
}) => {
  const [acceptedMediaType, setAcceptedMediaType] = useState([
    MEDIA_TYPE_INDEX.video,
    MEDIA_TYPE_INDEX.audio,
  ])
  const addResource = () => {
    openMediaManager((media) => {
      setResources([...resources, { channelType: 1, resource: media }])
    }, acceptedMediaType)
  }

  const removeResource = (resourceId) => {
    setResources(
      resources.filter((chResource) => {
        return chResource.resource.id !== resourceId
      })
    )
  }

  const updateChannelMediaType = (resourceId, typeId) => {
    setResources(
      resources.map((chResource) => {
        return chResource.resource.id === resourceId
          ? {
              ...chResource,
              channelType: parseInt(typeId),
            }
          : chResource
      })
    )
  }

  useEffect(() => {
    // recalculate the channel type for current innercise
    resources.length === 0
      ? setAcceptedMediaType([MEDIA_TYPE_INDEX.video, MEDIA_TYPE_INDEX.audio])
      : setAcceptedMediaType([resources[0].resource.type])
  }, [resources])

  return (
    <StyledProgramInnerciseListControl>
      <div className="header-bar">
        <h4>Resources</h4>
        {innerciseMode === INNERCISE_MODE.INNERCISE.value && (
          <Button variant="contained" onClick={addResource}>
            Add Media Resource
          </Button>
        )}
        {innerciseMode === INNERCISE_MODE.PLAYBOOK.value && (
          <Button variant="contained" onClick={openPlaybookDlg}>
            Add Playbook Question
          </Button>
        )}
      </div>
      <div className="resource-list">
        {resources.map((channelResource) => {
          const { id, name, type, urlDto } = channelResource.resource

          return (
            <StyledProgramInnerciseResourceWarper
              key={`resource-list-item-${id}`}
            >
              <StyledProgramInnerciseResourcePreview>
                <IconButton
                  className="action-btn"
                  aria-label="remove resource"
                  size="large"
                  color="primary"
                  onClick={() => {
                    removeResource(id)
                  }}
                >
                  <ClearIcon />
                </IconButton>

                {type === MEDIA_TYPE_INDEX.audio && (
                  <div className="resource-name">{name}</div>
                )}

                {type === MEDIA_TYPE_INDEX.video && (
                  <video controls>
                    <source src={urlDto.videoUrl_240} />
                  </video>
                )}
                {type === MEDIA_TYPE_INDEX.audio && (
                  <video controls>
                    <source src={urlDto.originalUrl} />
                  </video>
                )}
              </StyledProgramInnerciseResourcePreview>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Channel Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue={channelResource.channelType}
                  label="Channel Type"
                  onChange={(e) => {
                    updateChannelMediaType(
                      channelResource.resource.id,
                      e.target.value
                    )
                  }}
                >
                  {Object.values(CHANNEL_TYPES).map((chType) => {
                    return (
                      <MenuItem
                        key={`channel-type-menu-item-${chType.value}`}
                        value={chType.value}
                      >
                        {chType.text}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </StyledProgramInnerciseResourceWarper>
          )
        })}
      </div>
    </StyledProgramInnerciseListControl>
  )
}

export default ProgramInnerciseListControl
