import React, { useState } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "@emotion/styled";
import { Dialog } from "@mui/material";

const StyledNotificationDlg = styled(Dialog)`
  .MuiPaper-root {
    min-width: 600px;
    min-height: 300px;
  }
  ul {
    padding: 0 0 0 15px;
    li {
      margin: 0;
      line-height: 18px;
    }
  }
`;

const UseNotificationDialog = () => {
  const [open, setOpen] = useState(false);
  const [bodyContent, setBodyContent] = useState("");

  const onClose = () => {
    setOpen(false);
  };

  const openDlg = (dlgContent) => {
    setBodyContent(dlgContent);
    setOpen(true);
  };

  const content = (
    <StyledNotificationDlg open={open} onClose={onClose}>
      <DialogTitle>System Notification Message</DialogTitle>
      <DialogContent>{bodyContent}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>OK</Button>
      </DialogActions>
    </StyledNotificationDlg>
  );

  return [content, openDlg];
};

export default UseNotificationDialog;
