import React, { useState } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { addContributor, editContributor } from "services/contributor";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import TagsInput from "components/common/TagsInput";
import PropTypes from "prop-types";
import { StyledContributorDialog } from "./Contributor.style";

const ContributorDlg = ({ onSubmit }) => {
  const initContributor = {
    userName: "",
    email: "",
    intro: "",
    address: "",
    tag: "",
    portraitAwskey: "",
    portraitAwsbucket: "",
    coverAwskey: "strng",
    coverAwsbucket: "",
  };

  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [contributor, setContributor] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [tags, setTags] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const openDlg = (editContributor) => {
    reset();
    setContributor(editContributor || initContributor);
    setIsEditMode(editContributor ? true : false);
    if (editContributor) {
      setTags(
        editContributor.tag === ""
          ? []
          : editContributor.tag.split(",").map((tag) => {
              return { id: tag, text: tag };
            })
      );
    } else {
      setTags([]);
    }
    setOpen(true);
  };

  const handleAction = async (data) => {
    setLoading(true);
    const requestData = {
      ...data,
      address: "",
      tag: tags.map((tag) => tag.id).join(","),
      portraitAwskey: "",
      portraitAwsbucket: "",
      coverAwskey: "",
      coverAwsbucket: "",
    };
    const response = isEditMode
      ? await editContributor({
          ...contributor,
          ...data,
          tag: tags.map((tag) => tag.id).join(","),
        })
      : await addContributor(requestData);
    if (response.rtnCode === 200) {
      enqueueSnackbar(
        `'${isEditMode ? "Update" : "Create"} Contributor Success'`,
        {
          variant: "success",
        }
      );
    } else {
      enqueueSnackbar(
        `'${isEditMode ? "Update" : "Create"} Contributor Fail'`,
        {
          variant: "warning",
        }
      );
    }
    onSubmit && onSubmit();
    setLoading(false);
    handleClose();
  };

  const dlg = (
    <StyledContributorDialog open={open}>
      <DialogTitle>
        {isEditMode ? "Edit Contributor" : "Create Contributor"}
      </DialogTitle>
      <form onSubmit={handleSubmit(handleAction)}>
        <DialogContent>
          <Box>
            <FormControl fullWidth margin="dense">
              <TextField
                label="Name"
                variant="standard"
                fullWidth
                defaultValue={contributor.userName}
                type="text"
                {...register("userName", {
                  required: true,
                })}
                error={!!errors?.userName}
                helperText={!!errors?.userName ? "* Name is required" : " "}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth margin="dense">
              <TextField
                label="Email"
                variant="standard"
                fullWidth
                type="text"
                defaultValue={contributor.email}
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                })}
                error={!!errors?.email}
                helperText={!!errors?.email ? "* Email is required" : " "}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth>
              <TextField
                autoFocus
                autoComplete="off"
                margin="dense"
                id="program-innercise-name"
                label="Description"
                fullWidth
                multiline
                rows={4}
                defaultValue={contributor.intro}
                {...register("intro", { required: true })}
                error={!!errors?.intro}
                variant="standard"
              />
            </FormControl>
          </Box>
          <Box>
            <div className="label">Tags</div>
            <TagsInput tags={tags} setTags={setTags} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" size="small">
            {isEditMode ? "Save" : "Create"}
          </Button>
        </DialogActions>
      </form>
    </StyledContributorDialog>
  );

  return [openDlg, dlg, loading];
};

ContributorDlg.propTypes = {
  onSubmit: PropTypes.object.isRequired,
};

export default ContributorDlg;
