import styled from "@emotion/styled"
import Dialog from "@mui/material/Dialog"

const CategorySelectorDialog = styled(Dialog)`
  .MuiPaper-root {
    width: 700px;
  }
  h5 {
    font-size: 16px;
    margin: 10px 0;
  }
  font-size: 14px;

  .category-check-item {
    display: inline-block;
    margin-right: 20px;
  }

  .subcategory-section {
    .MuiFormControlLabel-root {
      transform: scale(0.85);
    }
  }
`

export { CategorySelectorDialog }
