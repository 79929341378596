import styled from "styled-components"

const StyledChartDisplay = styled.div`
  /* width: 300px; */
  font-size: 12px;

  .title {
    font-size: 18px;
    font-weight: 400;
    margin: 10px 0px 20px 45px;
  }
`

export { StyledChartDisplay }
