import React, { useState, useEffect } from "react";
import moment from "moment";
import Button from "@mui/material/Button";
import PageHeading from "components/common/LayoutComponents/PageHeading";
import PageContent from "components/common/LayoutComponents/PageContent";
import { verifyResponse } from "utils/httpRequest";
import LoadingScreen from "components/common/LoadingScreen";
import { exportCSV } from "utils/export";
import RedeemDlg from "./RedeemDlg";
import RedeemSearch from "./RedeemSearch";
import RedeemTable from "./RedeemTable";
import RedeemDetail from "./RedeemDetail";
import RedeemBatchDetail from "./RedeemBatchDetail";
import { PERMISSIONS } from "constants/permission";
import { UsePermissionVerification } from "components/customHooks";
import { getSubscriptionProducts } from "services/subscription";
import { getRedeemCodes } from "services/redeem";
import { StyledRedeemSection } from "./Redeem.style";

const Member = () => {
  const [loading, setLoading] = useState(false);
  const [subscriptionProduct, setSubscriptionProduct] = useState([]);
  const [codes, setCodes] = useState([]);
  const [selectedRedeemCode, setSelectedRedeemCode] = useState(null);
  const [searchOptions, setSearchOptions] = useState(null);
  const [searchResultInfo, setSearchResultInfo] = useState(null);
  const [selectedExportRedeems, setSelectedExportRedeems] = useState([]);

  const AccessControl = UsePermissionVerification();

  const [openBatchDetail, batchContent, batchLoading] = RedeemBatchDetail();

  const [openRedeemDialog, redeemDialog, redeemDialogLoading] = RedeemDlg({
    onSubmit: (id) => {
      openBatchDetail(id);
    },
    subscriptionProduct,
  });

  useEffect(() => {
    AccessControl.SetAccess({
      ...AccessControl.Access,
      listRedeemAccess: AccessControl.VerifyAccess([
        PERMISSIONS.REDEEM.REDEEM_LIST,
      ]),
      addRedeemAccess: AccessControl.VerifyAccess([
        PERMISSIONS.REDEEM.REDEEM_ADD,
      ]),
      getBatchInfoRedeemAccess: AccessControl.VerifyAccess([
        PERMISSIONS.REDEEM.REDEEM_BATCH_INFO,
      ]),
    });
  }, [AccessControl.UserPermissions]);

  const loadSubscriptionProduct = async () => {
    setLoading(true);
    const response = await getSubscriptionProducts();
    setLoading(false);
    verifyResponse(response);
    setSubscriptionProduct(response.data);
  };

  const searchRedeemCodes = async () => {
    setCodes([]);
    closeRedeemDetail();
    try {
      setLoading(true);
      const response = await getRedeemCodes(searchOptions);
      verifyResponse(response);
      setCodes(response.data.list);
      setSearchResultInfo({
        currentPageNo: response.data.currentPageNo,
        hasNext: response.data.hasNext,
        pageSize: response.data.pageSize,
        pages: response.data.pages,
        total: response.data.total,
      });
    } catch (error) {
      console.error("err: ", error);
    } finally {
      setLoading(false);
    }
  };

  const openRedeemDetail = (redeem) => {
    setSelectedRedeemCode(redeem);
  };

  const closeRedeemDetail = () => {
    setSelectedRedeemCode(null);
  };

  const exportByData = (exportData) => {
    const headers = {
      batchId: "Batch ID".replace(/,/g, ""),
      redeemCode: "Redeem Code".replace(/,/g, ""),
      productCode: "Product Code".replace(/,/g, ""),
      createdAt: "Created Date".replace(/,/g, ""),
      status: "Status".replace(/,/g, ""),
    };
    const itemsFormatted = [];
    for (const redeem of exportData) {
      itemsFormatted.push({
        batchId: redeem.batchId,
        redeemCode: redeem.redeemCode,
        productCode: redeem.productCode,
        createdAt: moment(redeem.createdAt).format("MM/DD/YYYY h:mm:ss a"),
        status: redeem.redeemStatus === 0 ? "Pending" : "Activated",
      });
    }
    const csvFileName = `redeem code export (${moment().format("LLL")})`;
    exportCSV(headers, itemsFormatted, csvFileName);
  };

  const exportSelectedResult = () => {
    exportByData(selectedExportRedeems);
  };

  const exportSearchResult = async () => {
    const exportSearchOption = {
      ...searchOptions,
      pageSize: 999999999,
    };
    try {
      setLoading(true);
      const response = await getRedeemCodes(exportSearchOption);
      verifyResponse(response);
      const exportData = response.data.list;
      exportByData(exportData);
    } catch (error) {
      console.error("err: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSubscriptionProduct();
  }, []);

  useEffect(() => {
    searchOptions && searchRedeemCodes();
  }, [searchOptions]);

  return (
    <PageContent>
      <StyledRedeemSection>
        <PageHeading>
          <div>Redeem Code</div>
          <div className="actions">
            {AccessControl.Access.addRedeemAccess &&
              !batchContent &&
              !selectedRedeemCode && (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    openRedeemDialog();
                  }}
                >
                  Add Redeem
                </Button>
              )}
          </div>
        </PageHeading>
        <div>
          {!batchContent && !selectedRedeemCode && (
            <RedeemSearch setSearchOptions={setSearchOptions} />
          )}

          {selectedRedeemCode && (
            <RedeemDetail
              redeem={selectedRedeemCode}
              subscriptionProduct={subscriptionProduct}
              closeRedeemDetail={closeRedeemDetail}
            />
          )}
          {!selectedRedeemCode &&
            !batchContent &&
            codes &&
            codes.length > 0 && (
              <RedeemTable
                redeemCodes={codes}
                access={AccessControl.Access}
                openRedeemDetail={openRedeemDetail}
                openBatchDetail={openBatchDetail}
                searchResultInfo={searchResultInfo}
                searchOptions={searchOptions}
                setSearchOptions={setSearchOptions}
                exportAction={exportSearchResult}
                exportSelectedResult={exportSelectedResult}
                selectedExportRedeems={selectedExportRedeems}
                setSelectedExportRedeems={setSelectedExportRedeems}
              />
            )}
        </div>
      </StyledRedeemSection>
      <LoadingScreen loading={loading || redeemDialogLoading || batchLoading} />
      {redeemDialog}
      {batchContent}
    </PageContent>
  );
};

export default Member;
