import React, { useState } from "react"
import PropTypes from "prop-types"
import Button from "@mui/material/Button"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/Add"
import ReorderIcon from "@mui/icons-material/Reorder"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Chip from "@mui/material/Chip"
import LoadingScreen from "components/common/LoadingScreen"
import { Container, Draggable } from "react-smooth-dnd"
import { verifyResponse } from "utils/httpRequest"
import { exchangeArrayItem } from "utils/data"
import {
  getSubCategories,
  exchangeCategoryIndex,
  deleteCategory,
  deleteSubCategory,
} from "services/category"
import { UseConfirmDialog } from "components/customHooks"
import CategoryReorderProgramDlg from "./CategoryReorderProgram"
import CategoryReorderSubCategoryDlg from "./CategoryReorderSubcategory"
import UseNotificationDialog from "components/customHooks/NotificationDialog"
import { useSnackbar } from "notistack"
import { StyledCategoryItem } from "./Category.style"

function CategoryItem(props) {
  const { row, actions, access, loading } = props

  const {
    openCategoryDialog,
    openSubCategoryDialog,
    removeCategory,
    removeSubCategory,
    openConfirmDialog,
    setLoading,
  } = actions

  const [openProgramReorderDlg, programReorderDlg, programReorderDlgLoading] =
    CategoryReorderProgramDlg({ access })

  const [expanded, setExpanded] = React.useState(false)
  const [subCategoryLoaded, setSubCategoryLoaded] = useState(false)
  const [subCategory, setSubCategory] = useState([])

  const loadSubCategory = async (categoryId) => {
    try {
      setLoading(true)
      const subCate = await getSubCategories(categoryId)
      setSubCategory(subCate)
    } catch (e) {
      console.error("getSubCategories error: ", e)
    } finally {
      setSubCategoryLoaded(true)
      setLoading(false)
    }
  }

  const handleChange = (panel) => async (event, isExpanded) => {
    if (access.listSubCategoryAccess && !subCategoryLoaded) {
      await loadSubCategory(row.id)
    }
    setExpanded(isExpanded ? panel : false)
  }

  const [
    openSubCategoryReorderDlg,
    subCategoryReorderDlg,
    subCategoryReorderDlgLoading,
  ] = CategoryReorderSubCategoryDlg({
    access,
    reloadCallback: (categoryId) => {
      loadSubCategory(categoryId)
    },
  })

  return (
    <>
      <StyledCategoryItem>
        <Accordion
          expanded={expanded === row.id}
          onChange={handleChange(row.id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <div style={{ width: "20%", flexShrink: 0 }}>
              <Chip label={`Category id : ${row.id}`} color="primary" />
            </div>
            <Typography sx={{ color: "text.secondary", textAlign: "right" }}>
              {`${row.name}   `}
            </Typography>
            <Chip
              sx={{ marginLeft: "20px" }}
              label={row.fullName}
              color="info"
            />
          </AccordionSummary>
          <AccordionDetails>
            <div className="action-bar">
              {row.isOnboarding === 1 && (
                <Chip
                  label="Show Onboarding"
                  color="primary"
                  variant="outlined"
                />
              )}
              {access.addSubCategoryAccess && (
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    color: "white",
                    backgroundColor: "#407d97",
                    marginRight: "10px",
                  }}
                  startIcon={<ReorderIcon />}
                  onClick={() => {
                    openSubCategoryReorderDlg(subCategory, row.id)
                  }}
                >
                  Subcategory Order
                </Button>
              )}
              {access.addSubCategoryAccess && (
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    color: "white",
                    backgroundColor: "#406397",
                    marginRight: "10px",
                  }}
                  startIcon={<AddIcon />}
                  onClick={() => {
                    openSubCategoryDialog(row.id, null, () => {
                      loadSubCategory(row.id)
                    })
                  }}
                >
                  Add Sub
                </Button>
              )}
              {access.deleteSubCategoryAccess && (
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    color: "white",
                    backgroundColor: "red",
                    marginRight: "10px",
                  }}
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    openConfirmDialog(async () => {
                      await removeCategory(row)
                    })
                  }}
                >
                  Delete
                </Button>
              )}
              {access.editSubCategoryAccess && (
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<EditIcon />}
                  onClick={() => {
                    openCategoryDialog(row)
                  }}
                >
                  Edit
                </Button>
              )}
            </div>

            {access.listSubCategoryAccess && subCategory.length > 0 ? (
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>SubCategory Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell sx={{ width: "130px", textAlign: "center" }}>
                      Program Count
                    </TableCell>
                    <TableCell
                      align="right"
                      size="small"
                      sx={{ width: "400px", textAlign: "center" }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subCategory.map((subCate) => (
                    <TableRow key={subCate.id}>
                      <TableCell component="th" scope="row">
                        {subCate.id}
                      </TableCell>
                      <TableCell>{subCate.name}</TableCell>
                      <TableCell>{subCate.description}</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {subCate.programCount}
                      </TableCell>
                      <TableCell align="right">
                        {access.deleteSubCategoryAccess && (
                          <>
                            <Button
                              variant="contained"
                              size="small"
                              style={{
                                color: "white",
                                backgroundColor: "orange",
                                marginRight: "10px",
                              }}
                              startIcon={<ReorderIcon />}
                              onClick={() => {
                                openProgramReorderDlg(subCate)
                              }}
                            >
                              Program Order
                            </Button>

                            <Button
                              variant="contained"
                              size="small"
                              style={{
                                color: "white",
                                backgroundColor: "red",
                                marginRight: "10px",
                              }}
                              startIcon={<DeleteIcon />}
                              onClick={() => {
                                openConfirmDialog(async () => {
                                  await removeSubCategory(
                                    subCate,
                                    loadSubCategory
                                  )
                                })
                              }}
                            >
                              Delete
                            </Button>
                          </>
                        )}
                        {access.editSubCategoryAccess && (
                          <Button
                            variant="contained"
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={() => {
                              openSubCategoryDialog(
                                row.id,
                                subCate,
                                function () {
                                  loadSubCategory(row.id)
                                }
                              )
                            }}
                          >
                            Edit
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <>
                <Typography variant="h6" gutterBottom component="div">
                  SubCategory
                </Typography>
                <p>There is SubCategory.</p>
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </StyledCategoryItem>
      {programReorderDlg}
      {subCategoryReorderDlg}
      <LoadingScreen
        loading={
          loading || programReorderDlgLoading || subCategoryReorderDlgLoading
        }
      />
    </>
  )
}

CategoryItem.propTypes = {
  row: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
}

const CategoryTable = ({ data, actions, access }) => {
  const { refreshCategories, setCategories } = actions
  const [loading, setLoading] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const [ConfirmDialog, openConfirmDialog] = UseConfirmDialog({
    title: "Delete Confirm",
    description: "Are you sure want delete this ?",
    agreeText: "Yes",
    disagreeText: "No",
  })

  const [NotificationDlgContent, openNotificationDlg] = UseNotificationDialog()

  const removeCategory = async (category) => {
    setLoading(true)
    const response = await deleteCategory(category.id)
    setLoading(false)

    switch (response.data.rtnCode) {
      case 200:
        enqueueSnackbar("Category Removed", { variant: "success" })
        break
      case 20064:
        enqueueSnackbar(response.data.message, { variant: "warning" })
        break
      default:
        enqueueSnackbar("Remove Category Fail", { variant: "warning" })
    }
    refreshCategories()
  }

  const exchangeCategoryOrder = async (newOrderArray) => {
    const response = await exchangeCategoryIndex(newOrderArray)
    verifyResponse(response)
    if (response.rtnCode === 200) {
      await refreshCategories()
    } else {
      //
      enqueueSnackbar(`Category reordering fail, ${response.message}`, {
        variant: "warning",
      })
    }
  }

  const removeSubCategory = async (subCategory, reloadSubCategory) => {
    setLoading(true)
    const response = await deleteSubCategory(subCategory.id)
    setLoading(false)

    switch (response.data.rtnCode) {
      case 200:
        reloadSubCategory(subCategory.categoryId)
        enqueueSnackbar("SubCategory Removed", { variant: "success" })
        break
      case 20063:
        enqueueSnackbar(response.data.message, { variant: "warning" })
        break
      case 20067:
        enqueueSnackbar(response.data.message, { variant: "warning" })
        openNotificationDlg(
          <div>
            <h4>{`SubCategory '${subCategory.name}' still using by program : `}</h4>
            <ul>
              {response.data.data.map((cate) => (
                <li
                  key={`cate-title-${cate.id}`}
                >{`${cate.title} (Program ID: ${cate.id})`}</li>
              ))}
            </ul>
          </div>
        )
        break

      default:
        enqueueSnackbar("Remove SubCategory Fail", { variant: "warning" })
    }
    refreshCategories()
  }

  return (
    <Container
      onDrop={async (e) => {
        const updateArray = exchangeArrayItem({
          addedIndex: e.addedIndex,
          removedIndex: e.removedIndex,
          indexString: "categoryIndex",
          list: data,
        })
        setCategories(updateArray)
        exchangeCategoryOrder(
          updateArray.map((item) => {
            return { id: item.id, index: item.categoryIndex }
          })
        )
      }}
    >
      {data.map((row) => (
        <Draggable key={row.id}>
          <CategoryItem
            key={row.id}
            row={row}
            access={access}
            actions={{
              ...actions,
              removeCategory,
              removeSubCategory,
              openConfirmDialog,
            }}
            loading={loading}
          />
        </Draggable>
      ))}
      {ConfirmDialog}
      {NotificationDlgContent}
    </Container>
  )
}

CategoryTable.propTypes = {
  data: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  access: PropTypes.object.isRequired,
}

export default CategoryTable
