import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import PageHeading from "components/common/LayoutComponents/PageHeading";
import { getFaqs } from "services/faq";
import { verifyResponse } from "utils/httpRequest";
import LoadingScreen from "components/common/LoadingScreen";
import { PERMISSIONS } from "constants/permission";
import { UsePermissionVerification } from "components/customHooks";
import FaqDlg from "./FaqDlg";
import FaqList from "./FaqList";
import { StyledFaqSection } from "./Faq.style";

const FAQ = () => {
  const [loading, setLoading] = useState(false);
  const [faqs, setFaqs] = useState([]);

  const AccessControl = UsePermissionVerification();

  const [openFaqDialog, faqDialog, faqDialogLoading] = FaqDlg({
    onSubmit: () => {
      refreshFaqList();
    },
  });

  const refreshFaqList = async () => {
    setLoading(true);
    const response = await getFaqs();
    verifyResponse(response);
    setFaqs(response.data.list);
    setLoading(false);
  };

  useEffect(() => {
    refreshFaqList();
  }, []);

  useEffect(() => {
    AccessControl.SetAccess({
      ...AccessControl.Access,
      listFaqAccess: AccessControl.VerifyAccess([PERMISSIONS.FAQ.FAQ_LIST]),
      addFaqAccess: AccessControl.VerifyAccess([PERMISSIONS.FAQ.FAQ_ADD]),
      editFaqAccess: AccessControl.VerifyAccess([PERMISSIONS.FAQ.FAQ_EDIT]),
      deleteFaqAccess: AccessControl.VerifyAccess([PERMISSIONS.FAQ.FAQ_DELETE]),
      exchangeIndexFaqAccess: AccessControl.VerifyAccess([
        PERMISSIONS.FAQ.FAQ_EXCHANGE_INDEX,
      ]),
    });
  }, [AccessControl.UserPermissions]);

  return (
    <StyledFaqSection>
      <PageHeading>
        <div>FAQs</div>
        <div className="actions">
          {AccessControl.Access.addFaqAccess && (
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                openFaqDialog();
              }}
            >
              New FAQ Item
            </Button>
          )}
        </div>
      </PageHeading>
      {AccessControl.Access.listFaqAccess && (
        <div>
          <FaqList
            faqs={faqs}
            access={AccessControl.Access}
            openFaqDialog={openFaqDialog}
            refreshFaqList={refreshFaqList}
          />
        </div>
      )}
      <LoadingScreen loading={loading || faqDialogLoading} />
      {faqDialog}
    </StyledFaqSection>
  );
};

export default FAQ;
