import React, { useState } from "react"
import createDOMPurify from "dompurify"
import Button from "@mui/material/Button"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import Chip from "@mui/material/Chip"
import { verifyResponse } from "utils/httpRequest"
import LoadingScreen from "components/common/LoadingScreen"
import { Container, Draggable } from "react-smooth-dnd"
import { useSnackbar } from "notistack"
import { UseConfirmDialog } from "components/customHooks"
import { calculateNewRank } from "utils/data"
import { deleteFaq, exchangeFaqIndex } from "services/faq"
import { StyledFaqList } from "./Faq.style"

const FaqList = ({ faqs, access, openFaqDialog, refreshFaqList }) => {
  const DOMPurify = createDOMPurify(window)
  const [loading, setLoading] = useState(false)
  const [ConfirmDialog, openConfirmDialog] = UseConfirmDialog({
    title: "Delete Confirm",
    description: "Are you sure want delete this ?",
    agreeText: "Yes",
    disagreeText: "No",
  })

  const removeFaq = async (id) => {
    setLoading(true)
    const response = await deleteFaq(id)
    verifyResponse(response)
    if (response.rtnCode === 200) {
      enqueueSnackbar("FAQ Removed", { variant: "success" })
    } else {
      enqueueSnackbar("Delete FAQ Fail", { variant: "warning" })
    }
    setLoading(false)
    refreshFaqList()
  }

  const { enqueueSnackbar } = useSnackbar()

  const renderFaqItem = (faq, index) => {
    return (
      <div className="faq-item" key={`faq-list-item-${index}`}>
        <div className="header">
          <div>Question {index + 1}</div>
          <div>
            {access.deleteFaqAccess && (
              <Button
                variant="contained"
                size="small"
                style={{
                  color: "white",
                  backgroundColor: "red",
                  marginRight: "10px",
                }}
                startIcon={<DeleteIcon />}
                onClick={() => {
                  openConfirmDialog(() => {
                    removeFaq(faq.id)
                  })
                }}
              >
                Delete
              </Button>
            )}
            {access.editFaqAccess && (
              <Button
                variant="contained"
                size="small"
                startIcon={<EditIcon />}
                onClick={() => {
                  openFaqDialog(faq)
                }}
              >
                Edit
              </Button>
            )}
          </div>
        </div>
        <div className="faq-content-session">
          <Chip label="Title" />
          <div className="faq-content">{faq.title}</div>
        </div>
        <div className="faq-content-session">
          <Chip label="Answer" />
          <div className="faq-content">
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(faq.answer),
              }}
            />
          </div>
        </div>
      </div>
    )
  }
  return (
    <StyledFaqList>
      <Container
        onDrop={async (e) => {
          const updateIndexArray = calculateNewRank({
            addedIndex: e.addedIndex,
            removedIndex: e.removedIndex,
            indexString: "faqIndex",
            list: faqs,
          })
          await exchangeFaqIndex(updateIndexArray)
          refreshFaqList()
        }}
      >
        {faqs.map((faq, index) =>
          access.exchangeIndexFaqAccess ? (
            <Draggable key={faq.id}>{renderFaqItem(faq, index)}</Draggable>
          ) : (
            <div key={faq.id}>{renderFaqItem(faq, index)}</div>
          )
        )}
      </Container>
      {ConfirmDialog}
      <LoadingScreen loading={loading} />
    </StyledFaqList>
  )
}

export default FaqList
