import { httpPost } from "utils/httpRequest";

const searchOrders = async ({
  pageNo,
  pageSize,
  orderStatus,
  createdStartAt,
  createdEndAt,
  productCode,
  memberEmail,
}) => {
  const resp = await httpPost("admin/order/list", {
    pageNo,
    pageSize,
    orderStatus,
    createdStartAt,
    createdEndAt,
    productCode,
    memberEmail,
  });
  return resp.data;
};

const getOrderDetail = async (orderId) => {
  const resp = await httpPost(`admin/order/queryOrderById`, {
    orderId,
  });
  return resp.data;
};

const getMemberOrders = async (memberId) => {
  const resp = await httpPost("admin/order/list", {
    pageNo: 1,
    pageSize: 99999,
    memberId,
  });
  return resp.data;
};

export { searchOrders, getOrderDetail, getMemberOrders };
