import React, { useState } from "react"
import { StyledChartDisplay } from "./ChartDisplay.style"
import {
  Bar,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts"

const sampleData = {
  xName: "Name of X",
  bars: [
    {
      name: "uv",
      fillColor: "#8884d8",
    },
    {
      name: "pv",
      fillColor: "#82ca9d",
    },
  ],
  list: [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
    },
  ],
}
const BarChartDisplay = ({ data, width, height }) => {
  const [displayData, setDisplayData] = useState(sampleData)
  const content = displayData ? (
    <StyledChartDisplay>
      <h3 className="title">{displayData.title}</h3>
      <BarChart
        width={width || 930}
        height={height || 250}
        data={displayData.list}
      >
        <CartesianGrid strokeDasharray="30 3" />
        <XAxis dataKey={displayData.xNameKey} />
        <YAxis />
        <Tooltip />
        <Legend />
        {displayData.bars.map((bar) => (
          <Bar
            dataKey={bar.name}
            fill={bar.fillColor}
            key={`bar-item-${bar.name}`}
          />
        ))}
      </BarChart>
    </StyledChartDisplay>
  ) : (
    <div>null</div>
  )
  return [content, setDisplayData]
}

export default BarChartDisplay
