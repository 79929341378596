import React, { useState } from "react"
import Button from "@mui/material/Button"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Pagination from "@mui/material/Pagination"
import { useSnackbar } from "notistack"
import { verifyResponse } from "utils/httpRequest"
import LoadingScreen from "components/common/LoadingScreen"
import moment from "moment"
import { syncToMarketoByUserId } from "services/stat"
import { StyledMemberTable } from "./Member.style"

const MemberTable = ({
  members,
  access,
  openMemberDetail,
  searchResultInfo,
  searchOptions,
  setSearchOptions,
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)

  const syncMemberToMarketo = async (memberId) => {
    setLoading(true)
    const response = await syncToMarketoByUserId(memberId)
    verifyResponse(response)
    setLoading(false)

    try {
      response.rtnCode === 200
        ? enqueueSnackbar("Started Marketo Sync Process", {
            variant: "success",
          })
        : enqueueSnackbar("Fail to started Marketo Sync Process", {
            variant: "warning",
          })
    } catch (e) {
      enqueueSnackbar("Request Fail", {
        variant: "warning",
      })
    }
  }
  return (
    <StyledMemberTable>
      {searchResultInfo && searchResultInfo.total > 0 && (
        <div className="search-result-info">
          {searchResultInfo.total} record found.
        </div>
      )}

      {members && members.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Join At</TableCell>
                <TableCell>Plan</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>

            {access.listMemberAccess && (
              <TableBody>
                {members.map((row) => (
                  <TableRow
                    key={`member-item-${row.userId}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.userId}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>
                    <TableCell>{row.firstName}</TableCell>
                    <TableCell>{row.lastName}</TableCell>
                    <TableCell>
                      {moment(row.registerAt).format("lll")}
                    </TableCell>
                    <TableCell>{row.subscriptionPlan}</TableCell>
                    <TableCell>
                      {access.editMemberAccess && (
                        <>
                          <Button
                            style={{ width: "100px" }}
                            onClick={() => {
                              openMemberDetail(row.userId)
                            }}
                            variant="outlined"
                          >
                            Detail
                          </Button>
                          <Button
                            style={{ width: "200px", marginLeft: "20px" }}
                            onClick={() => {
                              syncMemberToMarketo(row.userId)
                            }}
                            variant="outlined"
                          >
                            Sync To Marketo
                          </Button>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}

                {searchResultInfo && (
                  <TableRow key={"pg"}>
                    <TableCell colSpan={7}>
                      <div className="pagination-section">
                        <Pagination
                          count={searchResultInfo.pages}
                          page={searchResultInfo.currentPageNo}
                          onChange={(event, value) => {
                            setSearchOptions({
                              ...searchOptions,
                              pageNo: value,
                            })
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      )}
      <LoadingScreen loading={loading} />
    </StyledMemberTable>
  )
}

export default MemberTable
