import React, { useState, useEffect, useRef } from "react"
import Button from "@mui/material/Button"
import { Box } from "@mui/material"
import TextField from "@mui/material/TextField"
import Editor from "@monaco-editor/react"

import PageHeading from "components/common/LayoutComponents/PageHeading"
import PageContent from "components/common/LayoutComponents/PageContent"
import { verifyResponse } from "utils/httpRequest"
import LoadingScreen from "components/common/LoadingScreen"
import { PERMISSIONS } from "constants/permission"
import { getRedisValueByKey } from "services/redis"
import { UsePermissionVerification } from "components/customHooks"
import { StyledRedis } from "./Redis.style"
import * as monaco from "monaco-editor"
import { loader } from "@monaco-editor/react"

loader.config({ monaco })

const Role = () => {
  const [loading, setLoading] = useState(false)
  const [editorData, setEditorData] = useState(null)
  const [searchKeyValue, setSearchKeyValue] = useState(null)
  const AccessControl = UsePermissionVerification()

  const searchKeyRef = useRef("")

  const searchRedisValueByKey = async (key) => {
    setLoading(true)
    const response = await getRedisValueByKey({ key })
    setLoading(false)
    verifyResponse(response)
    setSearchKeyValue(key)
    setEditorData(response.data)
  }

  const handleSearch = () => {
    const searchKey = searchKeyRef.current.value.trim()
    searchKeyRef.current.value = ""

    if (searchKey === "") {
      // need notification
    } else {
      searchRedisValueByKey(searchKey)
    }
  }

  const handleTextInputEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleSearch()
    }
  }

  useEffect(() => {
    AccessControl.SetAccess({
      ...AccessControl.Access,
      searchRoleAccess: AccessControl.VerifyAccess([
        PERMISSIONS.ROLE.ROLE_SEARCH,
      ]),
      addRoleAccess: AccessControl.VerifyAccess([PERMISSIONS.ROLE.ROLE_ADD]),
      editRoleAccess: AccessControl.VerifyAccess([PERMISSIONS.ROLE.ROLE_EDIT]),
      deleteRoleAccess: AccessControl.VerifyAccess([
        PERMISSIONS.ROLE.ROLE_DELETE,
      ]),
      getDetailByIdRoleAccess: AccessControl.VerifyAccess([
        PERMISSIONS.ROLE.ROLE_GET_DETAIL_BY_ID,
      ]),
      setPermissionRoleAccess: AccessControl.VerifyAccess([
        PERMISSIONS.ROLE.ROLE_SET_PERMISSION,
      ]),
      getListByIdRoleAccess: AccessControl.VerifyAccess([
        PERMISSIONS.ROLE.ROLE_LIST_BY_ID,
      ]),
    })
  }, [AccessControl.UserPermissions])

  function handleEditorDidMount(editor, monaco) {
    // console.log("did mount")
  }

  return (
    <PageContent>
      <StyledRedis>
        <PageHeading>
          <div>Redis</div>
          <div className="actions"></div>
        </PageHeading>
        <PageContent>
          <Box
            className="search-bar"
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              inputRef={searchKeyRef}
              id="outlined-basic"
              label="Redis Variable Key"
              variant="outlined"
              placeholder="Search Redis Value By Key"
              bubbles="false"
              onKeyDown={handleTextInputEnter}
            />
            <Button
              className="search-btn"
              variant="contained"
              size="large"
              onClick={handleSearch}
            >
              Find
            </Button>
          </Box>

          {searchKeyValue && (
            <div className="search-query-info">
              Search Variable by key{" "}
              <span className="key-value">{searchKeyValue}</span>
            </div>
          )}
          {editorData && (
            <>
              <div className="editor-region">
                <Editor
                  className="json-editor"
                  theme="vs-dark"
                  height="calc(100vh - 310px)"
                  defaultLanguage="json"
                  language="json | text"
                  value={JSON.stringify(editorData, null, 2)}
                  options={{
                    readOnly: true,
                    lineNumbers: "off",
                    formatOnPaste: true,
                  }}
                  onMount={handleEditorDidMount}
                />
              </div>
            </>
          )}
        </PageContent>
      </StyledRedis>
      <LoadingScreen loading={loading} />
    </PageContent>
  )
}

export default Role
