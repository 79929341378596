import styled from "@emotion/styled";
import { Dialog } from "@mui/material";

const StyledContributorPage = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  button {
    margin-left: 10px;
  }
`;

const StyledContributorDetail = styled.div`
  display: flex;
  flex-direction: column;
  .MuiPaper-root {
    margin-bottom: 20px;
  }
  h4 {
    font-size: 18px;
    font-weight: 400;
    color: #865e5e;
  }
  .portrait {
    max-width: 100px;
    border: 4px solid #0e00001c;
    border-radius: 4px;
  }
`;

const StyledContributorDialog = styled(Dialog)`
  margin: auto 0;

  .MuiPaper-root {
    min-width: 650px;
  }
  .label {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 20px;
  }
`;

export {
  StyledContributorPage,
  StyledContributorDetail,
  StyledContributorDialog,
};
