import React, { useEffect, useState } from "react"
import BarChartIcon from "@mui/icons-material/BarChart"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import {
  getAnalyticData,
  getAnalyticSubscriptionByDay,
  getAvgCompletedInnercise,
  getCompletedInnercise,
  getMostPopularCategoryByViews,
  getMostPopularCategoryBySpent,
  getMostPopularInnerciseByViews,
  getMostPopularInnerciseBySpent,
  getRemindTimeStats,
  getInnerciseTimeStats,
  getPaywallData,
} from "services/stat"
import { getUnixEpochTime, getLocalTimeZone } from "utils/time"
import { SESSION_VARIABLES } from "constants/system"
import LoadingScreen from "components/common/LoadingScreen"
import { verifyResponse } from "utils/httpRequest"
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import {
  completedInnerciseToBarChar,
  RemindTimeToBarChart,
  InnerciseTimeStatsToBarChart,
} from "utils/chart"
import TextField from "@mui/material/TextField"
import moment from "moment"
import DataTable from "components/common/DataTable/DataTable"
import { formatTime } from "utils/time"
import SubscriptionSummary from "./templates/SubscriptionSummary"
import {
  subscribeTableCols,
  subscribeMostPopularCategoryTableCols,
  subscribeMostPopularCategoryBySpentTableCols,
  subscribeMostPopularInnerciseTableCols,
  subscribeMostPopularInnerciseSpentTableCols,
  paywallInfoTableCols,
} from "./tableData"
import BarChartDlg from "./BarChartDlg"
import {
  StyledDashboardHeading,
  StyledDashboard,
  StyledInfoCard,
  StyledInfoSearchBar,
} from "./Home.style"

const Home = () => {
  const freeTrialLength = 7
  const [loading, setLoading] = useState(false)
  const [analyticData, setAnalyticData] = useState(null)
  const [analyticRawData, setAnalyticRawData] = useState(null)
  const [subscribeInfo, setSubscribeInfo] = useState({
    monthlySubscription: 0,
    yearlySubscription: 0,
    liftTimeSubscription: 0,
    monthlyFreeTrial: 0,
    yearlyFreeTrial: 0,
    monthlyTotal: 0,
    yearlyTotal: 0,
    total: 0,
  })
  const [completedInnercise, setCompletedInnercise] = useState(null)
  const [avgCompletedInnercise, setAvgCompletedInnercise] = useState(null)
  const [mostPopularCategory, setMostPopularCategory] = useState(null)
  const [mostPopularCategoryBySpent, setMostPopularCategoryBySpent] =
    useState(null)
  const [mostPopularInnercise, setMostPopularInnercise] = useState(null)
  const [mostPopularInnerciseBySpent, setMostPopularInnerciseBySpent] =
    useState(null)
  const [remindTimeStats, setRemindTimeStats] = useState(null)
  const [innerciseTimeStats, setInnerciseTimeStats] = useState(null)
  const [subscribeData, setSubscribeData] = useState([])
  const [paywallData, setPaywallData] = useState([])

  const [dateRange, setDateRange] = useState([
    moment().add("days", -freeTrialLength),
    moment(),
  ])

  const [chartContent, openChartDlg] = BarChartDlg()
  const [openDataTableDlg, dataTableDlg] = DataTable()

  const loadAnalyticData = async () => {
    const idToken = localStorage.getItem(SESSION_VARIABLES.ACCESS_TOKEN)
    if (idToken) {
      try {
        setLoading(true)
        const response = await getAnalyticData({
          startAt: getUnixEpochTime(dateRange[0]),
          endAt: getUnixEpochTime(dateRange[1]),
        })
        verifyResponse(response)
        if (response.rtnCode === 200) {
          setAnalyticData(response.data)
        }
      } catch (e) {
        console.error("loading error: ", e)
      } finally {
        setLoading(false)
      }
    } else {
      setTimeout(loadAnalyticData, 500)
    }
  }

  const loadAnalyticSubscribeData = async () => {
    const idToken = localStorage.getItem(SESSION_VARIABLES.ACCESS_TOKEN)
    if (idToken) {
      try {
        setLoading(true)
        const response = await getAnalyticSubscriptionByDay({
          startAt: getUnixEpochTime(dateRange[0]),
          endAt: getUnixEpochTime(dateRange[1]),
          localTimeZone: getLocalTimeZone(),
          freeTrialLength,
        })
        verifyResponse(response)
        if (response.rtnCode === 200) {
          setAnalyticRawData(response.data)
        }
      } catch (e) {
        console.error("loading error: ", e)
      } finally {
        setLoading(false)
      }
    } else {
      setTimeout(loadAnalyticData, 500)
    }
  }

  const loadAvgCompletedInnercise = async () => {
    const idToken = localStorage.getItem(SESSION_VARIABLES.ACCESS_TOKEN)

    if (idToken) {
      try {
        setLoading(true)
        const response = await getAvgCompletedInnercise({
          startAt: getUnixEpochTime(dateRange[0]),
          endAt: getUnixEpochTime(dateRange[1]),
          intervalValue: 3,
          limitSize: 50,
        })
        verifyResponse(response)
        if (response.rtnCode === 200) {
          setAvgCompletedInnercise(response.data)
        }
      } catch (e) {
        console.error("loading error: ", e)
      } finally {
        setLoading(false)
      }
    } else {
      setTimeout(loadAvgCompletedInnercise, 500)
    }
  }

  const loadCompletedInnercise = async () => {
    const idToken = localStorage.getItem(SESSION_VARIABLES.ACCESS_TOKEN)

    if (idToken) {
      try {
        setLoading(true)
        const response = await getCompletedInnercise({
          startAt: getUnixEpochTime(dateRange[0]),
          endAt: getUnixEpochTime(dateRange[1]),
          intervalValue: 5,
          limitSize: 50,
        })
        verifyResponse(response)
        if (response.rtnCode === 200) {
          setCompletedInnercise(response.data)
        }
      } catch (e) {
        console.error("loading error: ", e)
      } finally {
        setLoading(false)
      }
    } else {
      setTimeout(loadCompletedInnercise, 500)
    }
  }

  const loadMostPopularCategory = async () => {
    const idToken = localStorage.getItem(SESSION_VARIABLES.ACCESS_TOKEN)

    if (idToken) {
      try {
        setLoading(true)
        const response = await getMostPopularCategoryByViews({
          startAt: getUnixEpochTime(dateRange[0]),
          endAt: getUnixEpochTime(dateRange[1]),
          limitSize: 50,
        })
        verifyResponse(response)
        if (response.rtnCode === 200) {
          setMostPopularCategory(response.data)
        }
      } catch (e) {
        console.error("loading error: ", e)
      } finally {
        setLoading(false)
      }
    } else {
      setTimeout(loadMostPopularCategory, 500)
    }
  }

  const loadMostPopularCategoryBySpent = async () => {
    const idToken = localStorage.getItem(SESSION_VARIABLES.ACCESS_TOKEN)

    if (idToken) {
      try {
        setLoading(true)
        const response = await getMostPopularCategoryBySpent({
          startAt: getUnixEpochTime(dateRange[0]),
          endAt: getUnixEpochTime(dateRange[1]),
          limitSize: 50,
        })
        verifyResponse(response)
        if (response.rtnCode === 200) {
          setMostPopularCategoryBySpent(
            response.data.map((item) => {
              return {
                ...item,
                innercisingTime: formatTime(parseInt(item.innercisingTime)),
              }
            })
          )
        }
      } catch (e) {
        console.error("loading error: ", e)
      } finally {
        setLoading(false)
      }
    } else {
      setTimeout(loadMostPopularCategory, 500)
    }
  }

  const loadMostPopularInnercise = async () => {
    const idToken = localStorage.getItem(SESSION_VARIABLES.ACCESS_TOKEN)

    if (idToken) {
      try {
        setLoading(true)
        const response = await getMostPopularInnerciseByViews({
          startAt: getUnixEpochTime(dateRange[0]),
          endAt: getUnixEpochTime(dateRange[1]),
          limitSize: 50,
        })
        verifyResponse(response)
        if (response.rtnCode === 200) {
          setMostPopularInnercise(
            response.data.map((item) => {
              return {
                id: item.id,
                title: item.title,
                category: item.categoryList.map((cat) => cat.name).join(","),
                views: item.views,
              }
            })
          )
        }
      } catch (e) {
        console.error("loading error: ", e)
      } finally {
        setLoading(false)
      }
    } else {
      setTimeout(loadMostPopularCategory, 500)
    }
  }

  const loadMostPopularInnerciseBySpent = async () => {
    const idToken = localStorage.getItem(SESSION_VARIABLES.ACCESS_TOKEN)

    if (idToken) {
      try {
        setLoading(true)
        const response = await getMostPopularInnerciseBySpent({
          startAt: getUnixEpochTime(dateRange[0]),
          endAt: getUnixEpochTime(dateRange[1]),
          limitSize: 50,
        })
        verifyResponse(response)
        if (response.rtnCode === 200) {
          setMostPopularInnerciseBySpent(
            response.data.map((item) => {
              return {
                ...item,
                innercisingTime: formatTime(parseInt(item.innercisingTime)),
              }
            })
          )
        }
      } catch (e) {
        console.error("loading error: ", e)
      } finally {
        setLoading(false)
      }
    } else {
      setTimeout(loadMostPopularCategory, 500)
    }
  }

  const loadRemindTimeStats = async () => {
    const idToken = localStorage.getItem(SESSION_VARIABLES.ACCESS_TOKEN)

    if (idToken) {
      try {
        setLoading(true)
        const response = await getRemindTimeStats({
          startAt: getUnixEpochTime(dateRange[0]),
          endAt: getUnixEpochTime(dateRange[1]),
          intervalValue: 5,
          limitSize: 300,
        })
        verifyResponse(response)
        if (response.rtnCode === 200) {
          setRemindTimeStats(response.data)
        }
      } catch (e) {
        console.error("loading error: ", e)
      } finally {
        setLoading(false)
      }
    } else {
      setTimeout(loadRemindTimeStats, 500)
    }
  }

  const loadInnerciseTimeStats = async () => {
    const idToken = localStorage.getItem(SESSION_VARIABLES.ACCESS_TOKEN)

    if (idToken) {
      try {
        setLoading(true)
        const response = await getInnerciseTimeStats({
          startAt: getUnixEpochTime(dateRange[0]),
          endAt: getUnixEpochTime(dateRange[1]),
          intervalValue: 5,
          limitSize: 300,
        })
        verifyResponse(response)
        if (response.rtnCode === 200) {
          setInnerciseTimeStats(response.data)
        }
      } catch (e) {
        console.error("loading error: ", e)
      } finally {
        setLoading(false)
      }
    } else {
      setTimeout(loadInnerciseTimeStats, 500)
    }
  }

  const loadPaywallData = async () => {
    const idToken = localStorage.getItem(SESSION_VARIABLES.ACCESS_TOKEN)

    if (idToken) {
      try {
        setLoading(true)
        const response = await getPaywallData({
          startAt: getUnixEpochTime(dateRange[0]),
          endAt: getUnixEpochTime(dateRange[1]),
          localTimeZone: getLocalTimeZone(),
        })
        verifyResponse(response)
        if (response.rtnCode === 200) {
          setPaywallData(
            response.data.map((paywall) => {
              return { ...paywall, id: paywall.day }
            })
          )
        }
      } catch (e) {
        console.error("loading error: ", e)
      } finally {
        setLoading(false)
      }
    } else {
      setTimeout(loadInnerciseTimeStats, 500)
    }
  }

  useEffect(() => {
    loadAnalyticData()
    loadAnalyticSubscribeData()
  }, [])

  useEffect(() => {
    let monthly = 0
    let yearly = 0
    let lifeTime = 0
    let total = 0
    let monthlyFreeTrial = 0
    let yearlyFreeTrial = 0
    let totalMonthlyConvertCount = 0
    let totalYearlyConvertCount = 0
    let totalPreviousMonthlyTrialCount = 0
    let totalPreviousYearlyTrialCount = 0

    if (analyticRawData) {
      for (const record of analyticRawData) {
        monthly += parseInt(record.monthlySubStartCount)
        monthlyFreeTrial += parseInt(record.monthlyTrialCount)
        yearly += parseInt(record.yearlySubStartCount)
        yearlyFreeTrial += parseInt(record.yearlyTrialCount)
        lifeTime += parseInt(record.lifeTimeCount)
        total += parseInt(record.total)
        totalMonthlyConvertCount += record.monthlyConvertCount
          ? parseInt(record.monthlyConvertCount)
          : 0
        totalYearlyConvertCount += record.yearlyConvertCount
          ? parseInt(record.yearlyConvertCount)
          : 0
        totalPreviousMonthlyTrialCount += record.previousMonthlyTrialCount
          ? parseInt(record.previousMonthlyTrialCount)
          : 0

        totalPreviousYearlyTrialCount += record.previousYearlyTrialCount
          ? parseInt(record.previousYearlyTrialCount)
          : 0
      }

      setSubscribeInfo({
        monthlySubscription: monthly,
        yearlySubscription: yearly,
        liftTimeSubscription: lifeTime,
        monthlyFreeTrial,
        yearlyFreeTrial,
        monthlyTotal: monthly + monthlyFreeTrial,
        yearlyTotal: yearly + yearlyFreeTrial,
        totalMonthlyConvertRate:
          totalPreviousMonthlyTrialCount === 0
            ? 0
            : `${parseInt(
                (totalMonthlyConvertCount / totalPreviousMonthlyTrialCount) *
                  100
              )}%`,
        totalYearlyConvertRate:
          totalPreviousYearlyTrialCount === 0
            ? 0
            : `${parseInt(
                (totalYearlyConvertCount / totalPreviousYearlyTrialCount) * 100
              )}%`,
        total,
      })

      // console.log("subscribeInfo: ", subscribeInfo)

      const days = dateRange[1].diff(dateRange[0], "days")

      const dateList = []
      for (let i = 0; i < days; i++) {
        const date = dateRange[0].clone().add("days", i)
        dateList.push(date.format("YYYY-MM-DD"))
      }

      const analyticData = []

      for (let dateString of dateList) {
        const dateRelatedObj = analyticRawData.find(
          (item) => item.day === dateString
        )

        const dataObj = {
          id: dateString,
          day: dateString,
          totalSubscription: dateRelatedObj
            ? parseInt(dateRelatedObj.total)
            : 0,
          monthlyFreeTrialCount: dateRelatedObj
            ? parseInt(dateRelatedObj.monthlyTrialCount)
            : 0,
          monthlyCount: dateRelatedObj
            ? parseInt(dateRelatedObj.monthlySubStartCount)
            : 0,
          yearlyFreeTrialCount: dateRelatedObj
            ? parseInt(dateRelatedObj.yearlyTrialCount)
            : 0,
          yearlyCount: dateRelatedObj
            ? parseInt(dateRelatedObj.yearlySubStartCount)
            : 0,
          lifeTimeCount: dateRelatedObj
            ? parseInt(dateRelatedObj.lifeTimeCount)
            : 0,
          monthlyConvertRate: dateRelatedObj
            ? parseInt(dateRelatedObj.monthlyConvertRate) * 100
            : 0,
          yearlyConvertRate: dateRelatedObj
            ? parseInt(dateRelatedObj.yearlyConvertRate) * 100
            : 0,
        }
        analyticData.push(dataObj)
      }

      setSubscribeData(analyticData)
    }
  }, [analyticRawData])

  useEffect(() => {
    loadAnalyticData()
    loadAvgCompletedInnercise()
    loadCompletedInnercise()
    loadMostPopularCategory()
    loadMostPopularCategoryBySpent()
    loadMostPopularInnercise()
    loadMostPopularInnerciseBySpent()
    loadRemindTimeStats()
    loadInnerciseTimeStats()
    loadAnalyticSubscribeData()
    loadPaywallData()
  }, [dateRange])

  return (
    <>
      <StyledDashboardHeading>Dashboard</StyledDashboardHeading>
      {chartContent}
      <StyledInfoSearchBar>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateRangePicker
            className="media-search-range-picker"
            startText="Date From"
            endText="Date To"
            value={dateRange}
            onOpen={() => {
              setTimeout(() => {
                ;[...document.querySelectorAll("div")]
                  .filter((a) => a.textContent === "MUI X: Missing license key")
                  .forEach((a) => a.remove())
              }, 0)
            }}
            onChange={(newValue) => {
              setDateRange([moment(newValue[0]), moment(newValue[1])])
            }}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} className="time-input" />
                <TextField {...endProps} className="time-input" />
              </>
            )}
          />
        </LocalizationProvider>
      </StyledInfoSearchBar>
      <StyledDashboard>
        {analyticData ? (
          <>
            {analyticData.memberRegisterCount !== null && (
              <StyledInfoCard>
                <h3>Total Registered Accounts</h3>
                <div className="value">{analyticData.memberRegisterCount}</div>
              </StyledInfoCard>
            )}
            {analyticData.monthPaidMemberCount !== null && (
              <StyledInfoCard>
                <h3>Monthly Subscription</h3>
                <div className="value info">
                  <div>Total: {subscribeInfo.monthlyTotal}</div>
                  <div>{`Free Trial: ${subscribeInfo.monthlyFreeTrial}`}</div>
                  <div>{`Subscription: ${subscribeInfo.monthlySubscription}`}</div>
                  <Tooltip title="Show More Detail" className="icon-btn">
                    <IconButton
                      onClick={() => {
                        //
                        openDataTableDlg({
                          columns: subscribeTableCols,
                          rows: subscribeData,
                          title: "Subscribe Info",
                          summary: <SubscriptionSummary data={subscribeInfo} />,
                        })
                      }}
                    >
                      <BarChartIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </StyledInfoCard>
            )}
            {analyticData.yearPaidMemberCount !== null && (
              <StyledInfoCard>
                <h3>Annual Subscription</h3>
                <div className="value info">
                  <div>Total: {subscribeInfo.yearlyTotal}</div>
                  <div>{`Free Trial: ${subscribeInfo.yearlyFreeTrial}`}</div>
                  <div>{`Subscription: ${subscribeInfo.yearlySubscription}`}</div>
                  <Tooltip title="Show More Detail" className="icon-btn">
                    <IconButton
                      onClick={() => {
                        openDataTableDlg({
                          columns: subscribeTableCols,
                          rows: subscribeData,
                          title: "Subscribe Info",
                          summary: <SubscriptionSummary data={subscribeInfo} />,
                        })
                      }}
                    >
                      <BarChartIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </StyledInfoCard>
            )}
            {analyticData.lifePaidMemberCount !== null && (
              <StyledInfoCard>
                <h3>Lifetime Subscription</h3>
                <div className="value">
                  {subscribeInfo.liftTimeSubscription}
                </div>
                <Tooltip title="Show More Detail" className="icon-btn">
                  <IconButton
                    onClick={() => {
                      openDataTableDlg({
                        columns: subscribeTableCols,
                        rows: subscribeData,
                        title: "Subscribe Info",
                        summary: <SubscriptionSummary data={subscribeInfo} />,
                      })
                    }}
                  >
                    <BarChartIcon />
                  </IconButton>
                </Tooltip>
              </StyledInfoCard>
            )}
            {paywallData !== null && (
              <StyledInfoCard>
                <h3>Paywall Information </h3>
                <div className="value text">
                  See More info in Detail
                  <Tooltip title="Show More Detail" className="icon-btn">
                    <IconButton
                      onClick={() => {
                        openDataTableDlg({
                          columns: paywallInfoTableCols,
                          rows: paywallData,
                          title: "Paywall Information",
                        })
                      }}
                    >
                      <BarChartIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </StyledInfoCard>
            )}
            {analyticData.memberActiveCount !== null && (
              <StyledInfoCard>
                <h3>Active Users</h3>
                <div className="value">{analyticData.memberActiveCount}</div>
              </StyledInfoCard>
            )}
            {avgCompletedInnercise !== null && (
              <StyledInfoCard>
                <h3>Average Completed Innercise </h3>
                <div className="value">
                  {avgCompletedInnercise.averageCompletedInnerciseTimes}
                </div>
              </StyledInfoCard>
            )}

            {completedInnercise !== null && (
              <StyledInfoCard>
                <h3>Completed Innercise</h3>
                <div className="value text">
                  See More info in Detail
                  <Tooltip title="Show More Detail" className="icon-btn">
                    <IconButton
                      onClick={() => {
                        openChartDlg(
                          completedInnerciseToBarChar({
                            list: completedInnercise,
                          })
                        )
                      }}
                    >
                      <BarChartIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </StyledInfoCard>
            )}
            {mostPopularCategory !== null && (
              <StyledInfoCard>
                <h3>Most Popular Category By Views</h3>
                <div className="value text">
                  {mostPopularCategory &&
                    mostPopularCategory.length > 0 &&
                    mostPopularCategory[mostPopularCategory.length - 1].name}
                  <Tooltip title="Show More Detail" className="icon-btn">
                    <IconButton
                      onClick={() => {
                        openDataTableDlg({
                          columns: subscribeMostPopularCategoryTableCols,
                          rows: mostPopularCategory,
                          title: "Most Popular Category",
                        })
                      }}
                    >
                      <BarChartIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </StyledInfoCard>
            )}

            {mostPopularCategoryBySpent !== null && (
              <StyledInfoCard>
                <h3>Most Popular Category By Spent</h3>
                <div className="value text">
                  {mostPopularCategoryBySpent &&
                    mostPopularCategoryBySpent.length > 0 &&
                    mostPopularCategoryBySpent[
                      mostPopularCategoryBySpent.length - 1
                    ].name}
                  <Tooltip title="Show More Detail" className="icon-btn">
                    <IconButton
                      onClick={() => {
                        openDataTableDlg({
                          columns: subscribeMostPopularCategoryBySpentTableCols,
                          rows: mostPopularCategoryBySpent,
                          title: "Most Popular Category By Spent",
                        })
                      }}
                    >
                      <BarChartIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </StyledInfoCard>
            )}

            {mostPopularInnercise !== null && (
              <StyledInfoCard>
                <h3>Most Popular Innercise By Views </h3>
                <div className="value text">
                  {mostPopularInnercise &&
                    mostPopularInnercise.length > 0 &&
                    mostPopularInnercise[mostPopularInnercise.length - 1].title}
                  <Tooltip title="Show More Detail" className="icon-btn">
                    <IconButton
                      onClick={() => {
                        openDataTableDlg({
                          columns: subscribeMostPopularInnerciseTableCols,
                          rows: mostPopularInnercise,
                          title: "Most Innercise Category",
                        })
                      }}
                    >
                      <BarChartIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </StyledInfoCard>
            )}
            {mostPopularInnerciseBySpent !== null && (
              <StyledInfoCard>
                <h3>Most Popular Innercise By Spent </h3>
                <div className="value text">
                  {mostPopularInnerciseBySpent &&
                    mostPopularInnerciseBySpent.length > 0 &&
                    mostPopularInnerciseBySpent[
                      mostPopularInnerciseBySpent.length - 1
                    ].title}
                  <Tooltip title="Show More Detail" className="icon-btn">
                    <IconButton
                      onClick={() => {
                        openDataTableDlg({
                          columns: subscribeMostPopularInnerciseSpentTableCols,
                          rows: mostPopularInnerciseBySpent,
                          title: "Most Popular Innercise By Spent",
                        })
                      }}
                    >
                      <BarChartIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </StyledInfoCard>
            )}

            {remindTimeStats !== null && (
              <StyledInfoCard>
                <h3>Remind Time Stats </h3>
                <div className="value text">
                  See More info in Detail
                  <Tooltip title="Show More Detail" className="icon-btn">
                    <IconButton
                      onClick={() => {
                        openChartDlg(RemindTimeToBarChart(remindTimeStats))
                      }}
                    >
                      <BarChartIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </StyledInfoCard>
            )}
            {innerciseTimeStats !== null && (
              <StyledInfoCard>
                <h3>Innercise Time Stats </h3>
                <div className="value text">
                  See More info in Detail
                  <Tooltip title="Show More Detail" className="icon-btn">
                    <IconButton
                      onClick={() => {
                        openChartDlg(
                          InnerciseTimeStatsToBarChart(innerciseTimeStats)
                        )
                      }}
                    >
                      <BarChartIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </StyledInfoCard>
            )}
          </>
        ) : (
          <div>Loading</div>
        )}
        <LoadingScreen loading={loading} />
        {dataTableDlg}
      </StyledDashboard>
    </>
  )
}

export default Home
