import { httpPost, httpGet } from "utils/httpRequest"

const searchAdminUser = async ({ pageNo, pageSize }) => {
  const resp = await httpPost("admin/adminUser/list", {
    pageNo,
    pageSize,
  })
  return resp.data
}

const getAdminUser = async (adminUserId) => {
  const resp = await httpPost(`admin/adminUser/queryDetail/${adminUserId}`)
  return resp.data
}

const SetAdminUserRole = async ({ userId, roleIdList }) => {
  const resp = await httpPost(`admin/adminUser/setRole`, {
    userId,
    roleIdList,
  })
  return resp.data
}

const getAllRoles = async () => {
  const resp = await httpGet(`admin/role/listAllRole`)
  return resp.data
}

const getAdminUserPermissions = async () => {
  const resp = await httpPost(`admin/adminUser/listPermissionOfUser`)
  return resp.data
}

const addAdminUser = async ({ email, password, nickName }) => {
  const resp = await httpPost(`admin/adminUser/add`, {
    email,
    password,
    nickName,
  })
  return resp.data
}

const deleteAdminUser = async (adminId) => {
  const resp = await httpPost(`admin/adminUser/delete`, {
    targetUserId: adminId,
  })
  return resp.data
}

const resetAdminUserPassword = async ({ targetUserId, newPassword }) => {
  const resp = await httpPost(`admin/adminUser/resetPasswordForOther`, {
    targetUserId,
    newPassword,
  })
  return resp.data
}

const resetAdminSelfPassword = async ({ targetUserId, newPassword }) => {
  const resp = await httpPost(`admin/adminUser/resetPasswordForSelf`, {
    targetUserId,
    newPassword,
  })
  return resp.data
}

export {
  searchAdminUser,
  getAdminUser,
  SetAdminUserRole,
  getAllRoles,
  getAdminUserPermissions,
  addAdminUser,
  deleteAdminUser,
  resetAdminUserPassword,
  resetAdminSelfPassword,
}
