import React, { useEffect, useState } from "react"
import Fab from "@mui/material/Fab"
import AddIcon from "@mui/icons-material/Add"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications"
import DeleteIcon from "@mui/icons-material/Delete"
import Chip from "@mui/material/Chip"
import {
  RAIL_GROUP_STATUS,
  RAIL_GROUP_TYPE_INDEX,
  RAIL_GROUP_TYPE,
} from "constants/global"
import { useSnackbar } from "notistack"
import { Container, Draggable } from "react-smooth-dnd"
import { calculateNewRank } from "utils/data"
import { UseConfirmDialog } from "components/customHooks"
import RailGroupDlg from "./RailGroupDlg"
import {
  getRailGroups,
  exchangeGroupIndex,
  deleteRailGroup,
} from "services/rails"
import { verifyResponse } from "utils/httpRequest"
import { RailGroupManagementWrapper } from "./RailGroupManagement.style"

const RailGroupManagement = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [railGroups, setRailGroups] = useState([])
  const [existingRails, setExistingRails] = useState([])
  const [openRailGroupDlg, railGroupDlg, railGroupLoading] = RailGroupDlg({
    existingRails,
    setExistingRails,
    onSubmit: () => {
      loadRailGroups()
    },
  })

  const [ConfirmDialog, openConfirmDialog] = UseConfirmDialog({
    title: "Delete Rail Group Confirm",
    description: "Are you sure want delete this Rail Group?",
    agreeText: "Yes",
    disagreeText: "No",
  })

  const loadRailGroups = async () => {
    setLoading(true)
    const response = await getRailGroups({ status: RAIL_GROUP_STATUS.ACTIVE })
    setLoading(false)
    verifyResponse(response)
    setRailGroups(
      response.data.map((group) => {
        const rule = group.rule ? JSON.parse(group.rule) : {}
        return {
          ...group,
          ruleObject: rule,
        }
      })
    )
  }

  const removeRailGroup = async (id) => {
    setLoading(true)
    const response = await deleteRailGroup({ id })
    setLoading(false)
    verifyResponse(response)
    if (response.rtnCode) {
      await loadRailGroups()
      // handling success message
      enqueueSnackbar("Rail Group Delete Success", {
        variant: "success",
      })
    } else {
      // handling error message
      enqueueSnackbar(response.message, {
        variant: "warning",
      })
    }
  }

  useEffect(() => {
    loadRailGroups()
  }, [])

  useEffect(() => {
    const newExistingRailList = []
    for (let group of railGroups) {
      for (let rail of group.railList) {
        newExistingRailList.push(rail.id)
      }
    }
    setExistingRails(newExistingRailList)
  }, [railGroups])

  const GroupContentTemplate = ({ group, key }) => {
    return (
      <div key={key} className="rail-group">
        <div className="content">
          <div className="info-section">
            <div className="label">Group Name</div>
            <strong>{group.name}</strong>
          </div>

          <div className="info-section">
            <div className="label">Type</div>
            {RAIL_GROUP_TYPE_INDEX[group.groupType]}
          </div>

          {group.groupType === RAIL_GROUP_TYPE.REGULAR.value && (
            <div className="info-section">
              <div className="label">Rail List</div>
              <div className="rail-list">
                {group.railList.map((rail) => (
                  <div
                    key={`group-rail-list-item-${rail.id}`}
                    className="rail-item"
                  >
                    {rail.name}
                  </div>
                ))}
                {group.railList.length === 0 && <div>N/A</div>}
              </div>
            </div>
          )}
          {group.groupType === RAIL_GROUP_TYPE.RULE.value && (
            <div className="info-section">
              <div className="label">Rule</div>
              <div className="rule-tag">
                {group.ruleObject.ruleKey.replace(/-/g, " ").toLowerCase()}
              </div>
            </div>
          )}
        </div>
        <div className="actions">
          <Tooltip title="Delete Group">
            <IconButton
              sx={{ color: "red" }}
              aria-label="remove rail group"
              component="label"
              onClick={() => {
                openConfirmDialog(async () => {
                  removeRailGroup(group.id)
                })
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit Group">
            <IconButton
              sx={{ color: "#666666" }}
              aria-label="edit rail group"
              component="label"
              onClick={() => {
                openRailGroupDlg(group)
              }}
            >
              <SettingsApplicationsIcon />
            </IconButton>
          </Tooltip>
          <div>
            <Chip
              size="small"
              sx={{ width: "100%", marginTop: "5px" }}
              label={group.isShow === 1 ? "Show" : "Hide"}
              color={group.isShow === 1 ? "success" : "warning"}
            />
          </div>
        </div>
      </div>
    )
  }

  const content = (
    <RailGroupManagementWrapper>
      <div className="header-bar">
        <span>Rail Group Management</span>
        <Fab color="primary" aria-label="add">
          <AddIcon
            onClick={() => {
              openRailGroupDlg()
            }}
          />
        </Fab>
      </div>
      <div className="groups">
        <Container
          onDrop={async (e) => {
            const updateIndexArray = calculateNewRank({
              addedIndex: e.addedIndex,
              removedIndex: e.removedIndex,
              indexString: "groupIndex",
              list: railGroups,
              updateOriginalList: setRailGroups,
            })
            const response = await exchangeGroupIndex({ updateIndexArray })
            await loadRailGroups()
            if (response.rtnCode === 200) {
              // handling success message
              enqueueSnackbar("Rial Group Update Success", {
                variant: "success",
              })
            } else {
              // handling error message
              enqueueSnackbar("Rial Group Update Fail", {
                variant: "warning",
              })
            }
          }}
        >
          {railGroups.map((group) => (
            <Draggable key={`group-template-item-${group.id}`}>
              <GroupContentTemplate group={group} />
            </Draggable>
          ))}
        </Container>
      </div>
      {railGroupDlg}
      {ConfirmDialog}
    </RailGroupManagementWrapper>
  )

  return [content, loading || railGroupLoading]
}

export default RailGroupManagement
