import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { StyledPermissionTable } from "./Permission.style";

const PermissionTable = ({ permissions, access, openPermissionDialog }) => {
  const [module, setModule] = useState("all");
  const [totalCount, setTotalCount] = useState(
    permissions ? permissions.length : 0
  );
  const [page, setPage] = useState(1);
  const numPerPage = 10;

  const startIndex = page === 1 ? 0 : (page - 1) * numPerPage;

  const modulesList = permissions.map((permission) => permission.module);
  const modules = modulesList.filter(function (item, pos) {
    return modulesList.indexOf(item) === pos;
  });

  useEffect(() => {
    setTotalCount(
      permissions.filter((item) => {
        if (module === "all") {
          return true;
        }
        return item.module === module;
      }).length
    );
    setPage(1);
  }, [module]);

  return (
    <StyledPermissionTable>
      {!permissions ||
        (permissions.length === 0 && (
          <div className="search-result-info">No Record.</div>
        ))}

      {permissions && permissions.length > 0 && (
        <TableContainer component={Paper}>
          <div className="permission-filter">
            <FormControl sx={{ width: "300px" }}>
              <InputLabel id="media-type-select-label">Module</InputLabel>
              <Select
                labelId="media-type-select-label"
                id="media-type-select"
                value={module}
                label="Module"
                onChange={(e) => {
                  setModule(e.target.value);
                }}
              >
                <MenuItem key="all-key" value="all">
                  ALL
                </MenuItem>
                {modules.map((mod) => (
                  <MenuItem key={`permission-module-item-${mod.tag}`} value={mod}>
                    {mod}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Tag</TableCell>
                <TableCell>Module</TableCell>
                <TableCell>URL</TableCell>
                <TableCell>Description</TableCell>
                <TableCell sx={{ minWidth: 80, textAlign: "right" }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {permissions
                .filter((item) => {
                  if (module === "all") {
                    return true;
                  }
                  return item.module === module;
                })
                .sort((a, b) => a.id - b.id)
                .slice(startIndex, startIndex + numPerPage)
                .map((row) => (
                  <TableRow
                    key={`member-item-${row.id}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.tag}
                    </TableCell>
                    <TableCell>{row.module}</TableCell>
                    <TableCell>{row.endpointUrl}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell sx={{ textAlign: "right" }}>
                      {access.editPermissionAccess && (
                        <Button
                          style={{ width: "80px" }}
                          onClick={() => {
                            openPermissionDialog(row);
                          }}
                          variant="outlined"
                        >
                          Edit
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}

              <TableRow key={"pg"}>
                <TableCell colSpan={7}>
                  <div className="pagination-section">
                    <Pagination
                      count={Math.ceil(totalCount / numPerPage)}
                      page={page}
                      onChange={(event, value) => {
                        setPage(value);
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </StyledPermissionTable>
  );
};

export default PermissionTable;
