import React from "react";
import { StyledFileDropZone } from "./MediaManager.style";
import { useDropzone } from "react-dropzone";

const MediaDropZone = ({ onDrop }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <StyledFileDropZone {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>Drag & drop some files here, or click to select files</p>
      )}
    </StyledFileDropZone>
  );
};

export default MediaDropZone;
