import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import PageHeading from "components/common/LayoutComponents/PageHeading";
import PageContent from "components/common/LayoutComponents/PageContent";
import { getAllContributor } from "services/contributor";
import { verifyResponse } from "utils/httpRequest";
import ContributorDlg from "./ContributorDlg";
import ContributorDetail from "./ContributorDetail";
import ContributorTable from "./ContributorTable";
import { StyledContributorPage } from "./Contributor.style";

const Contributor = () => {
  const [loading, setLoading] = useState(false);
  const [detailContributor, setDetailContributor] = useState(null);
  const [contributors, setContributors] = useState(false);

  const [openContributorDlg, contributorDlgContent, contributorDlgLoading] =
    ContributorDlg({
      contributors,
      onSubmit: () => {
        loadContributors();
      },
    });

  const openContributorDetail = (selectedContributor) => {
    setDetailContributor(selectedContributor);
  };

  const closeContributorDetail = () => {
    setDetailContributor(null);
  };

  const loadContributors = async () => {
    setLoading(true);
    const response = await getAllContributor();
    setLoading(false);
    verifyResponse(response);
    setContributors(response.data.list);
  };

  useEffect(() => {
    loadContributors();
  }, []);

  return (
    <PageContent>
      <StyledContributorPage>
        <PageHeading>
          <div>Contributor</div>
          <div className="actions">
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                openContributorDlg();
              }}
            >
              New Contributor
            </Button>
          </div>
        </PageHeading>

        {!detailContributor && (
          <ContributorTable
            contributors={contributors}
            openDetailPage={openContributorDetail}
            openEditDlg={openContributorDlg}
          />
        )}

        {detailContributor && (
          <ContributorDetail
            contributor={detailContributor}
            close={closeContributorDetail}
            refreshContributors={loadContributors}
          />
        )}
        {contributorDlgContent}
      </StyledContributorPage>
    </PageContent>
  );
};

export default Contributor;
