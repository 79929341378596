const PERMISSIONS = {
  ADMIN_USER: {
    ADMIN_USER_LOGIN: "Admin-User-Login",
    ADMIN_USER_LIST: "Admin-User-List",
    ADMIN_USER_DETAIL: "Admin-User-Detail",
    ADMIN_USER_ADD: "Admin-User-Add",
    ADMIN_USER_RESET_PASSWORD_SELF: "Admin-User-Reset-Password-Self",
    ADMIN_USER_RESET_PASSWORD_OTHER: "Admin-User-Reset-Password-Other",
    ADMIN_USER_SET_ROLE: "Admin-User-Set-Role",
    ADMIN_USER_LIST_PERMISSIONS: "Admin-User-List-Permissions",
    ADMIN_USER_DELETE: "Admin-User-Delete",
  },
  STAT: {
    ADMIN_STAT_ANALYTIC_DATA: "Admin-Stat-Analytic-Data",
    STAT_GET_AVG_COMPLETED_INNERCISE: "Stat-Get-Avg-Completed-Innercise",
    STAT_GET_MEAN_COMPLETE_INNERCISE: "Stat-Get-Mean-Completed-Innercise",
    STAT_GET_MOST_POPULAR_CATEGORY: "Stat-Get-Most-Popular-Category",
    STAT_GET_MOST_POPULAR_INNERCISE: "Stat-Get-Most-Popular-Innercise",
    STAT_GET_MOST_REVIEWED_PROGRAMS: "Stat-Get-Most-Reviewed-Programs",
    STAT_GET_REMIND_TIME_STATS: "Stat-Get-Remind-Time-Stats",
    STAT_GET_INNERCISE_TIME_STATS: "Stat-Get-Innercise-Time-Stats",
    STAT_GET_ANALYTIC_SUBSCRIBE_DETAIL: "Stat-Get-Analytic-Subscribe-Detail",
    STAT_GET_COMPLETED_INNERCISE: "Stat-Get-Completed-Innercise",
    STAT_GET_ANALYTIC_SUBSCRIPTION_BY_DAY:
      "Stat-Get-Analytic-Subscription-By-Day",
    STAT_GET_MOST_POPULAR_CATEGORY_BY_VIEWS:
      "Stat-Get-Most-Popular-Category-By-Views",
    STAT_GET_MOST_POPULAR_INNERCISE_BY_VIEWS:
      "Stat-Get-Most-Popular-Innercise-By-Views",
    STAT_GET_MOST_POPULAR_CATEGORY_BY_SPENT:
      "Stat-Get-Most-Popular-Category-By-Spent",
    STAT_GET_MOST_POPULAR_INNERCISE_BY_SPENT:
      "Stat-Get-Most-Popular-Innercise-By-Spent",
    STAT_GET_PAYWALL_DATA: "Stat-Get-Paywall-Data",
  },
  CATEGORY: {
    CATEGORY_LIST: "Category-List",
    CATEGORY_LIST_ALL: "Category-List-All",
    CATEGORY_PUB: "Category-Pub",
    CATEGORY_ADD: "Category-Add",
    CATEGORY_EDIT: "Category-Edit",
    CATEGORY_DELETE: "Category-Delete",
    CATEGORY_LIST_SUBCATEGORY: "Category-List-SubCategory",
    CATEGORY_ADD_SUBCATEGORY: "Category-Add-SubCategory",
    CATEGORY_EDIT_SUBCATEGORY: "Category-Edit-SubCategory",
    CATEGORY_DELETE_SUBCATEGORY: "Category-Delete-SubCategory",
    CATEGORY_EXCHANGE_INDEX: "Category-Exchange-Index",
  },
  FAQ: {
    FAQ_LIST: "Faq-List",
    FAQ_ADD: "Faq-Add",
    FAQ_EDIT: "Faq-Edit",
    FAQ_DELETE: "Faq-Delete",
    FAQ_EXCHANGE_INDEX: "Faq-Exchange-Index",
  },
  RESOURCE: {
    RESOURCE_LIST: "Resource-List",
    RESOURCE_ADD: "Resource-Add",
    RESOURCE_EDIT: "Resource-Edit",
    RESOURCE_ARCHIVE: "Resource-Archive",
    RESOURCE_QUERY: "Resource-Query",
    RESOURCE_QUERY_BY_ID: "Resource-Query-By-Id",
    RESOURCE_QUERY_SEARCH: "Resource-Query-Search",
    RESOURCE_UNARCHIVE: "Resource-Unarchive",
    RESOURCE_STAT_INFO: "Resource-Stat-Info",
    RESOURCE_CHECK_FILE_SIZE: "Resource-Check-File-Size",
  },
  PRIME: {
    PRIME_ADD: "Prime-Add",
    PRIME_EDIT: "Prime-Edit",
    PRIME_EXCHANGE_INDEX: "Prime-Exchange-Index",
    PRIME_LIST: "Prime-List",
    PRIME_QUERY_BY_ID: "Prime-Query-By-Id",
    PRIME_DELETE: "Prime-Delete",
  },
  PROGRAM: {
    PROGRAM_RANKS: "Program-Ranks",
    PROGRAM_LIST: "Program-List",
    PROGRAM_ADD: "Program-Add",
    PROGRAM_PUB: "Program-Pub",
    PROGRAM_CANCEL_PUB: "Program-Cancel-Pub",
    PROGRAM_EDIT: "Program-Edit",
    PROGRAM_DELETE: "Program-Delete",
    PROGRAM_QUERY_BASE: "Program-Query-Base",
    PROGRAM_QUERY_DETAIL: "Program-Query-Detail",
    PROGRAM_ADD_LEVEL: "Program-Add-Level",
    PROGRAM_EDIT_LEVEL: "Program-Edit-Level",
    PROGRAM_DELETE_LEVEL: "Program-Delete-Level",
    PROGRAM_LIST_INNERCISE_BY_LEVEL: "Program-List-Innercise-By-Level",
    PROGRAM_ADD_INNERCISE: "Program-Add-Innercise",
    PROGRAM_EDIT_INNERCISE: "Program-Edit-Innercise",
    PROGRAM_DELETE_INNERCISE: "Program-Delete-Innercise",
    PROGRAM_LIST_BY_SUBCATEGORY_ID: "Program-List-By-SubCategory-Id",
    PROGRAM_EXCHANGE_INDEX: "Program-Exchange-Index",
    PROGRAM_EXCHANGE_LEVEL_INDEX: "Program-Exchange-Level-Index",
    PROGRAM_QUERY_INNERCISE_BY_TITLE: "Program-Query-Innercise-By-Title",
  },
  SUBSCRIPTION: {
    SUBSCRIPTION_GET_INFO: "Subscription-Get-Info",
    SUBSCRIPTION_DESCRIPTION_LIST_ALL: "Subscription-Description-ListAll",
    SUBSCRIPTION_DESCRIPTION_ADD: "Subscription-Description-Add",
    SUBSCRIPTION_DESCRIPTION_EDIT: "Subscription-Description-Edit",
    SUBSCRIPTION_DESCRIPTION_DELETE: "Subscription-Description-Delete",
    SUBSCRIPTION_DESCRIPTION_EXCHANGE_INDEX:
      "Subscription-Description-Exchange-Index",
    SUBSCRIPTION_GET_TERMS: "Subscription-Get-Terms",
    SUBSCRIPTION_ADD_TERMS: "Subscription-Add-Terms",
    SUBSCRIPTION_EDIT_TERMS: "Subscription-Edit-Terms",
    SUBSCRIPTION_LIST_ALL_GROUP: "Subscription-List-All-Group",
    SUBSCRIPTION_ADD_GROUP: "Subscription-Add-Group",
    SUBSCRIPTION_EDIT_GROUP: "Subscription-Edit-Group",
    SUBSCRIPTION_DELETE: "Subscription-Delete",
    SUBSCRIPTION_QUERY_GROUP_DETAIL: "Subscription-Query-Group-Detail",
    SUBSCRIPTION_GET_ALL_PRODUCT_LIST: "Subscription-Get-All-Product-List",
    SUBSCRIPTION_ADD_PRODUCT: "Subscription-Add-Product",
    SUBSCRIPTION_EDIT_PRODUCT: "Subscription-Edit-Product",
    SUBSCRIPTION_PUB_PRODUCT: "Subscription-Pub-Product",
    SUBSCRIPTION_CANCEL_PUB_PRODUCT: "Subscription-Cancel-Pub-Product",
    SUBSCRIPTION_ADD_PLATFORM_PRODUCT: "Subscription-Add-Platform-Product",
    SUBSCRIPTION_EDIT_PLATFORM_PRODUCT: "Subscription-Edit-Platform-Product",
    SUBSCRIPTION_DELETE_PLATFORM_PRODUCT:
      "Subscription-Delete-Platform-Product",
    SUBSCRIPTION_LIST_PLATFORM_PRODUCT: "Subscription-List-Platform-Product",
  },
  REVIEW: {
    REVIEW_LIST: "Review-List",
    REVIEW_PUB: "Review-Pub",
    REVIEW_CANCEL_PUB: "Review-Cancel-Pub",
    REVIEW_PUB_BATCH: "Review-Pub-Batch",
  },
  MEMBER: {
    MEMBER_LIST: "Member-List",
    MEMBER_EDIT: "Member-Edit",
    MEMBER_DEACTIVATE: "Member-Deactivate",
    MEMBER_REACTIVATE: "Member-Reactivate",
    MEMBER_QUERY_DETAIL: "Member-Query-Detail",
    MEMBER_REMOVE_FREE_TRIAL: "Member-Remove-Free-Trial",
    MEMBER_GET_MARKETO_LOG: "Member-Get-Marketo-Log",
    MEMBER_SYNC_TO_MARKETO: "Member-Sync-To-Marketo",
    INIT_FREEMIUM: "initFreemium",
  },
  SYSTEM_SETTING: {
    SYSTEM_SETTING_LIST_ALL: "System-Setting-List-All",
    SYSTEM_SETTING_EDIT: "System-Setting-Edit",
    CONFIG_CMS: "Config-CMS",
  },
  MODAL: {
    MODAL_LIST_ALL_CONTENT_TYPES: "Modal-List-All-Content-Types",
    MODAL_LIST: "Modal-List",
    MODAL_ADD: "Modal-Add",
    MODAL_EDIT: "Modal-Edit",
    MODAL_DELETE: "Modal-Delete",
    MODAL_GET_CONTENT_BY_ID: "Modal-Get-Content-By-Id",
    MODAL_CONTENT_ADD: "Modal-Content-Add",
    MODAL_CONTENT_EDIT: "Modal-Content-Edit",
    MODAL_CONTENT_INDEX_EXCHANGE: "Modal-Content-Index-Exchange",
    MODAL_CONTENT_DELETE: "Modal-Content-Delete",
  },
  PERMISSION: {
    PERMISSION_EDIT: "Permission-Edit",
    PERMISSION_LIST_BY_MODULE: "Permission-List-By-Module",
    PERMISSION_LIST: "Permission-List",
    PERMISSION_MODULE_LIST: "Permission-Module-List",
    PERMISSION_ADD: "Permission-Add",
    PERMISSION_DELETE: "Permission-Delete",
  },
  ROLE: {
    ROLE_SEARCH: "Role-Search",
    ROLE_LIST_ALL: "Role-List-All",
    ROLE_GET_DETAIL_BY_ID: "Role-Get-Detail-By-Id",
    ROLE_ADD: "Role-Add",
    ROLE_EDIT: "Role-Edit",
    ROLE_LIST_BY_ID: "Role-List-By-Id",
    ROLE_SET_PERMISSION: "Role-Set-Permission",
    ROLE_DELETE: "Role-Delete",
  },
  REDEEM: {
    REDEEM_LIST: "Redeem-List",
    REDEEM_ADD: "Redeem-Add",
    REDEEM_BATCH_INFO: "Redeem-Get-Batch-Info-By-Id",
  },
  ORDER: {
    ORDER_LIST: "Order-List",
    ORDER_QUERY_BY_ID: "Order-Query-By-Id",
  },
  START_HERE: {
    START_HERE_RESOURCE_LIST: "Start-Here-Resource-List",
    START_HERE_RESOURCE_ADD: "Start-Here-Resource-Add",
    START_HERE_RESOURCE_EDIT: "Start-Here-Resource-Edit",
    START_HERE_RESOURCE_DELETE: "Start-Here-Resource-Delete",
    START_HERE_RESOURCE_EXCHANGE_INDEX: "Start-Here-Resource-Exchange-Index",
  },
  SYSTEM_CONFIG: {
    SYSTEM_CONFIG_LIST: "System-Config-List",
    SYSTEM_CONFIG_EDIT: "System-Config-Edit",
    SYSTEM_CONFIG_DELETE: "System-Config-Delete",
    SYSTEM_CONFIG_QUERY_BY_KEY: "System-Config-Query-By-Key",
    SYSTEM_CONFIG_ADD: "System-Config-Add",
  },
}

export { PERMISSIONS }
