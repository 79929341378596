import axios from "axios"
import { getRandomNumber } from "./number"

const httpGet = (url) => {
  const token = localStorage.getItem("atoken")

  const requestUrl = `${process.env.REACT_APP_API_HOST}/${url}`
  return axios.get(requestUrl, {
    headers: {
      access_token: token,
      client_type: 0,
      client_version: 1.0,
      salt: getRandomNumber(),
    },
  })
}

const httpPost = (url, data) => {
  const token = localStorage.getItem("atoken")
  const adminUserId = localStorage.getItem("adminUserId")
  const requestUrl = `${process.env.REACT_APP_API_HOST}/${url}`
  return axios.post(requestUrl, data, {
    headers: {
      access_token: token,
      user_id: adminUserId,
      client_type: 0,
      client_version: 1.0,
      salt: getRandomNumber(),
    },
  })
}

const verifyResponse = (response) => {
  // redirect to login page if the token invalided
  if (response === undefined) {
    localStorage.clear()
    window.location = "/"
  }
  if (response.rtnCode && response.rtnCode === 401) {
    localStorage.clear()
    console.log("show stop")
    window.location = "/"
  }
}

const httpUrlRequest = (url) => {
  return axios.get(url, {})
}

export { verifyResponse, httpGet, httpPost, httpUrlRequest }
