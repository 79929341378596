import React, { useState, useEffect } from "react"
import Button from "@mui/material/Button"
import PageHeading from "components/common/LayoutComponents/PageHeading"
import PageContent from "components/common/LayoutComponents/PageContent"
import { verifyResponse } from "utils/httpRequest"
import { PERMISSIONS } from "constants/permission"
import { UsePermissionVerification } from "components/customHooks"
import LoadingScreen from "components/common/LoadingScreen"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import EditIcon from "@mui/icons-material/Edit"
import Chip from "@mui/material/Chip"
import { calculateNewRank } from "utils/data"
import { Container, Draggable } from "react-smooth-dnd"
import StartHereDlg from "./StartHereDlg"
import { UseConfirmDialog } from "components/customHooks"
import { START_RESOURCE_DATA_TYPE } from "constants/global"
import {
  listStartResource,
  deleteStartResource,
  exchangeStartResourceIndex,
} from "services/startHere"

import { StartHereWrapper } from "./StartHere.style"

const StartHere = () => {
  const [loading, setLoading] = useState(false)
  const [startResources, setStartResources] = useState([])

  const AccessControl = UsePermissionVerification()

  const [ConfirmDialog, openConfirmDialog] = UseConfirmDialog({
    title: "Delete Confirm",
    description: "Are you sure want delete this ?",
    agreeText: "Yes",
    disagreeText: "No",
  })

  const [openStartHereDlg, startHereDlg, loadingStartHereDlg] = StartHereDlg({
    onSubmit: () => {
      queryStartInnercise()
    },
  })

  const removeStartInnercise = async (removeResourceId) => {
    setLoading(true)
    const response = await deleteStartResource({ id: removeResourceId })
    setLoading(false)
    verifyResponse(response)
    queryStartInnercise()
  }

  const queryStartInnercise = async () => {
    setLoading(true)
    const response = await listStartResource({
      pageNo: 1,
      pageSize: 1000,
      dataType: START_RESOURCE_DATA_TYPE.INNERCISE,
      dataTitle: "",
    })
    setLoading(false)
    verifyResponse(response)
    setStartResources(response.data.list)
  }

  const StartResourceCard = ({ resource }) => {
    return (
      <div className="resource-item">
        <div className="thumb-container">
          {resource.isHome === 1 && (
            <Chip className="chip" label="Home" size="small" color="success" />
          )}
          <img
            className="thumb"
            src={resource.innerciseDto.thumbResourceDto.urlDto.imgUrl_XS}
            alt="thumb"
          />
        </div>
        <div className="content">
          <div className="title">
            ({resource.innerciseDto.id}) {resource.innerciseDto.title}
          </div>
          <p>{resource.innerciseDto.description}</p>
          <div className="actions">
            {AccessControl.Access.deleteStartResource && (
              <Tooltip title="Delete Resource">
                <IconButton
                  sx={{ color: "#722020" }}
                  aria-label="edit resource"
                  component="label"
                  onClick={() => {
                    openConfirmDialog(async () => {
                      removeStartInnercise(resource.id)
                    })
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Tooltip>
            )}
            {AccessControl.Access.editStartResource && (
              <Tooltip title="Edit Resource">
                <IconButton
                  sx={{ color: "#635ec1" }}
                  aria-label="edit resource"
                  component="label"
                  onClick={() => {
                    openStartHereDlg(resource)
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    queryStartInnercise()
  }, [])

  useEffect(() => {
    AccessControl.SetAccess({
      ...AccessControl.Access,
      addStartResource: AccessControl.VerifyAccess([
        PERMISSIONS.START_HERE.START_HERE_RESOURCE_ADD,
      ]),
      deleteStartResource: AccessControl.VerifyAccess([
        PERMISSIONS.START_HERE.START_HERE_RESOURCE_DELETE,
      ]),
      editStartResource: AccessControl.VerifyAccess([
        PERMISSIONS.START_HERE.START_HERE_RESOURCE_EDIT,
      ]),
      exchangeIndexStartResource: AccessControl.VerifyAccess([
        PERMISSIONS.START_HERE.START_HERE_RESOURCE_EXCHANGE_INDEX,
      ]),
      listStartResource: AccessControl.VerifyAccess([
        PERMISSIONS.START_HERE.START_HERE_RESOURCE_LIST,
      ]),
    })
  }, [AccessControl.UserPermissions])

  return (
    <PageContent>
      <StartHereWrapper>
        <PageHeading>
          <div>Start Resources</div>
          <div className="actions">
            {AccessControl.Access.addStartResource && (
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  openStartHereDlg()
                }}
              >
                Add Innercise
              </Button>
            )}
          </div>
        </PageHeading>
        <div>
          {startResources.length > 0 && (
            <Container
              onDrop={async (e) => {
                const updateIndexArray = calculateNewRank({
                  addedIndex: e.addedIndex,
                  removedIndex: e.removedIndex,
                  indexString: "dataIndex",
                  list: startResources,
                  updateOriginalList: setStartResources,
                })
                await exchangeStartResourceIndex(updateIndexArray)
                queryStartInnercise()
              }}
            >
              {startResources.map((resource) => {
                return AccessControl.Access.exchangeIndexStartResource ? (
                  <Draggable key={resource.id}>
                    <StartResourceCard resource={resource} />
                  </Draggable>
                ) : (
                  <StartResourceCard resource={resource} />
                )
              })}
            </Container>
          )}
        </div>
      </StartHereWrapper>
      <LoadingScreen loading={loading || loadingStartHereDlg} />
      {startHereDlg}
      {ConfirmDialog}
    </PageContent>
  )
}

export default StartHere
