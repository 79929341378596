import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import PageHeading from "components/common/LayoutComponents/PageHeading";
import PageContent from "components/common/LayoutComponents/PageContent";
import { getRoles, getPermissionsByModule } from "services/permission";
import { verifyResponse } from "utils/httpRequest";
import LoadingScreen from "components/common/LoadingScreen";
import { PERMISSIONS } from "constants/permission";
import { UsePermissionVerification } from "components/customHooks";
import RoleDlg from "./RoleDlg";
import RoleDetail from "./RoleDetail";
import RoleTable from "./RoleTable";
import { StyledRole } from "./Role.style";

const Role = () => {
  const [roleLoading, setRoleLoading] = useState(false);
  const [permissionLoading, setPermissionLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [permissionByModule, setPermissionByModule] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const AccessControl = UsePermissionVerification();
  const [openRoleDialog, roleDialog, roleDialogLoading] = RoleDlg({
    onSubmit: () => {
      loadRoles();
    },
  });

  useEffect(() => {
    AccessControl.SetAccess({
      ...AccessControl.Access,
      searchRoleAccess: AccessControl.VerifyAccess([
        PERMISSIONS.ROLE.ROLE_SEARCH,
      ]),
      addRoleAccess: AccessControl.VerifyAccess([PERMISSIONS.ROLE.ROLE_ADD]),
      editRoleAccess: AccessControl.VerifyAccess([PERMISSIONS.ROLE.ROLE_EDIT]),
      deleteRoleAccess: AccessControl.VerifyAccess([
        PERMISSIONS.ROLE.ROLE_DELETE,
      ]),
      getDetailByIdRoleAccess: AccessControl.VerifyAccess([
        PERMISSIONS.ROLE.ROLE_GET_DETAIL_BY_ID,
      ]),
      setPermissionRoleAccess: AccessControl.VerifyAccess([
        PERMISSIONS.ROLE.ROLE_SET_PERMISSION,
      ]),
      getListByIdRoleAccess: AccessControl.VerifyAccess([
        PERMISSIONS.ROLE.ROLE_LIST_BY_ID,
      ]),
    });
  }, [AccessControl.UserPermissions]);

  const loadRoles = async () => {
    setRoleLoading(true);
    const response = await getRoles();
    verifyResponse(response);
    setRoleLoading(false);
    setRoles(response.data.list);
  };

  const loadPermissionsByModule = async () => {
    setPermissionLoading(true);
    const response = await getPermissionsByModule();
    verifyResponse(response);
    setPermissionLoading(false);
    setPermissionByModule(response.data);
  };

  const closeRoleDetail = () => {
    setSelectedRole(null);
  };

  useEffect(() => {
    loadRoles();
    loadPermissionsByModule();
  }, []);

  const [roleDetailContent, roleDetailLoading] = RoleDetail({
    role: selectedRole,
    access: AccessControl.Access,
    permissionByModule,
    closeRoleDetail,
  });

  return (
    <PageContent>
      <StyledRole>
        <PageHeading>
          <div>Role</div>
          <div className="actions">
            {selectedRole && (
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  closeRoleDetail();
                }}
              >
                Back
              </Button>
            )}
            {!selectedRole && AccessControl.Access.addRoleAccess && (
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  openRoleDialog();
                }}
              >
                Add Role
              </Button>
            )}
          </div>
        </PageHeading>
        <div>
          {!selectedRole && (
            <RoleTable
              roles={roles}
              access={AccessControl.Access}
              setSelectedRole={setSelectedRole}
              openRoleDialog={openRoleDialog}
            />
          )}
          {selectedRole && roleDetailContent}
        </div>
      </StyledRole>
      <LoadingScreen
        loading={
          roleLoading ||
          roleDialogLoading ||
          permissionLoading ||
          roleDetailLoading
        }
      />
      {roleDialog}
    </PageContent>
  );
};

export default Role;
