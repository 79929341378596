import styled from "@emotion/styled";

const LeftPanelWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  color: black;
  height: 100%;
  overflow: auto;
  width: 310px;
  border-right: 1px solid #0000000f;
  align-items: flex-start;
  flex-direction: column;
  /* padding-top: 30px; */
`;

const PanelMenu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const PanelMenuItem = styled.li`
  list-style: none;
  color: black;
  padding: 20px 25px 8px 25px;
  font-weight: bold;
  margin-top: 20px;
  font-weight: 500;
  &:first-of-type {
    margin-top: 0;
  }
  &.clickable {
    margin-top: 0;
    font-weight: 300;
    font-size: 14px;
    padding: 0;
    &:hover {
      cursor: pointer;
      background-color: #f7ecec;
      color: #8658bb;
      cursor: pointer;
      -webkit-transition: all 0.25s ease-out;
      -moz-transition: all 0.25s ease-out;
      -o-transition: all 0.25s ease-out;
      transition: all 0.25s ease-out;
    }

    button {
      width: 100%;
      padding: 10px 30px;
      border: 0;
      background-color: transparent;
      cursor: pointer;
      font-size: 14px;
      text-align: left;
      &:hover {
        color: #8658bb;
      }
    }
  }
`;

const StyledProfileImageWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
`;

const StyledLoginPenalLoadingIcon = styled.img`
  width: 35px;
`;

const StyledLoginPenal = styled.div`
  display: flex;
  font-size: 16px;
  margin-right: 15px;
  padding: 40px 30px;
  a {
    color: #bfc7d6;
    text-underline-offset: 2px;
  }
`;

const StyledLoginPenalItem = styled.div`
  margin-right: 20px;
  display: inline-block;
`;

export {
  LeftPanelWrapper,
  PanelMenu,
  PanelMenuItem,
  StyledProfileImageWrapper,
  StyledLoginPenalLoadingIcon,
  StyledLoginPenal,
  StyledLoginPenalItem,
};
