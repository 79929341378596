import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import { getMemberDetail } from "services/member"
import { verifyResponse } from "utils/httpRequest"
import MemberInfo from "./MemberInfo"
import MemberReviews from "./MemberReviews"
import MemberSubscriptions from "./MemberSubscriptions"
import MemberAccount from "./MemberAccount"
import MemberHistory from "./MemberHistory"
import { StyledMemberDetail } from "./Member.style"

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`member-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ padding: "30px 10px" }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `member-tab-${index}`,
    "aria-controls": `member-tabpanel-${index}`,
  }
}

const MemberDetail = ({ memberId, access, onClose, refreshSearch }) => {
  const [loading, setLoading] = useState(0)
  const [value, setValue] = useState(0)
  const [member, setMember] = useState(null)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const loadMemberDetail = async () => {
    setLoading(true)
    const response = await getMemberDetail(memberId)
    setLoading(false)
    verifyResponse(response)
    setMember(response.data)
  }

  useEffect(() => {
    loadMemberDetail()
  }, [])

  return (
    <StyledMemberDetail>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            disabled={loading}
            value={value}
            onChange={handleChange}
            aria-label="member detail tabs"
          >
            <Tab label="Member Info" {...a11yProps(0)} />
            <Tab label="Review" {...a11yProps(1)} />
            <Tab label="Subscription" {...a11yProps(2)} />
            <Tab label="Account" {...a11yProps(3)} />
            <Tab label="History" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {loading && "Loading..."}
          {member && <MemberInfo member={member} />}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {member && <MemberReviews memberId={memberId} />}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {member && (
            <MemberSubscriptions
              memberId={memberId}
              vipInfoId={member.vipInfo ? member.vipInfo.id : 0}
              payment={member.vipInfo ? member.vipInfo.payment : 0}
              subscriptionStatus={
                member.vipInfo ? member.vipInfo.subscriptionStatus : 0
              }
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={3}>
          <MemberAccount
            member={member}
            access={access}
            onClose={onClose}
            refreshMember={loadMemberDetail}
            refreshSearch={refreshSearch}
          />
        </TabPanel>

        <TabPanel value={value} index={4}>
          <MemberHistory member={member} />
        </TabPanel>
      </Box>
    </StyledMemberDetail>
  )
}

export default MemberDetail
