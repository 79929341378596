import styled from "styled-components"
import { Dialog } from "@mui/material"

const DataTableWrapper = styled(Dialog)`
  display: flex;
  .MuiDialog-container {
    margin: 0 auto;
    .MuiPaper-root {
      max-width: calc(100% - 30px);
    }
  }
  .MuiDataGrid-columnHeaderTitleContainer {
    /* justify-content: center; */
  }
  .MuiDataGrid-columnHeaderTitleContainer {
    font-weight: bold !important;
  }
`

export { DataTableWrapper }
