import React, { useState } from "react";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { StyledOrderSearch, StyledOrderSearchBar } from "./Order.style";

const OrderSearch = ({ setSearchOptions }) => {
  const [email, setEmail] = React.useState("");
  const [productCode, setProductCode] = React.useState("");
  const [status, setStatus] = useState(-1);
  const [pageSize, setPageSize] = useState(10);
  const [dateRange, setDateRange] = React.useState([null, null]);

  const clearSearch = () => {
    setEmail("");
    setProductCode("");
    setStatus(-1);
  };

  const searchAction = () => {
    const options = {
      pageNo: 1,
      pageSize: pageSize,
      status: status === -1 ? null : status,
      memberEmail: email === "" ? null : email,
      productCode: productCode === "" ? null : productCode,
      createdStartAt:
        dateRange[0] && dateRange[1] ? moment(dateRange[0]).valueOf() : null,
      createdEndAt:
        dateRange[0] && dateRange[1] ? moment(dateRange[0]).valueOf() : null,
    };

    setSearchOptions(options);
  };

  return (
    <StyledOrderSearch>
      <StyledOrderSearchBar>
        <TextField
          className="redeem-code"
          autoFocus
          autoComplete="off"
          margin="dense"
          id="member-email-id"
          label="Member Email"
          type="text"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          variant="outlined"
        />
        <TextField
          className="product-code"
          autoComplete="off"
          margin="dense"
          id="product-code-id"
          label="Product Code"
          type="text"
          value={productCode}
          onChange={(e) => {
            setProductCode(e.target.value);
          }}
          variant="outlined"
        />
        <FormControl style={{ width: "150px" }}>
          <InputLabel id="redeem-code-status-select-label">Status</InputLabel>
          <Select
            labelId="redeem-code-status-select-label"
            id="redeem-code-status-select"
            value={status}
            label="Status"
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <MenuItem value={-1}>All</MenuItem>
            <MenuItem value={0}>Pending</MenuItem>
            <MenuItem value={1}>Activated</MenuItem>
          </Select>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateRangePicker
            className="media-search-range-picker"
            startText="Date From"
            endText="Date To"
            value={dateRange}
            onOpen={() => {
              setTimeout(() => {
                [
                  ...document
                    .querySelector(".media-search-range-picker")
                    .querySelectorAll("div"),
                ]
                  .filter((a) => a.textContent === "MUI X: Missing license key")
                  .forEach((a) => a.remove());
              }, 0);
            }}
            onChange={(newValue) => {
              setDateRange(newValue);
            }}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} className="time-input" />
                <TextField {...endProps} className="time-input" />
              </>
            )}
          />
        </LocalizationProvider>

        <FormControl style={{ width: "150px" }}>
          <InputLabel id="media-page-size-select-label">Page Size</InputLabel>
          <Select
            labelId="redeem-code-page-size-select-label"
            id="redeem-code-page-size-select"
            value={pageSize}
            label="Page Size"
            onChange={(e) => {
              setPageSize(e.target.value);
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
        <Button
          className="search-btn"
          variant="contained"
          size="large"
          onClick={() => {
            searchAction();
          }}
        >
          Search
        </Button>
        <Button
          className="search-btn"
          variant=""
          size="large"
          onClick={clearSearch}
        >
          Clear
        </Button>
      </StyledOrderSearchBar>
    </StyledOrderSearch>
  );
};

export default OrderSearch;
