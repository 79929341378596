import React, { useState, useEffect } from "react"
import _ from "lodash"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import { useSnackbar } from "notistack"
import TextField from "@mui/material/TextField"
import { verifyResponse } from "utils/httpRequest"
import { PRODUCT_PLATFORM, CURRENCY_LIST } from "constants/global"
import { editPlatformProduct, addPlatformProduct } from "services/subscription"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const SubscriptionProductPlatform = ({
  productId,
  access,
  code,
  platforms,
  refreshPlatform,
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const [platformTab, setPlatformTab] = useState(0)
  const [editMode, setEditMode] = useState({})
  const [selectedPlatform, setSelectedPlatform] = useState(null)

  const existPlatformIdList = platforms.map((platform) => platform.platform)
  const pendingPlatforms = PRODUCT_PLATFORM.filter(
    (item) => !existPlatformIdList.includes(item.value)
  )

  useEffect(() => {
    const editModeList = _.chain(platforms)
      .keyBy("id")
      .map((i) => false)
      .value()
    setEditMode(editModeList)
  }, [platforms])

  useEffect(() => {
    platforms.length > 0 && setSelectedPlatform(platforms[0])
    setPlatformTab(0)
  }, [platforms.length])

  const setPlatformEditMode = (platFormId, mode) => {
    setEditMode({
      ...editMode,
      [platFormId]: mode,
    })
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    }
  }

  const addPlatform = async (platformId) => {
    const response = await addPlatformProduct({
      productId,
      platform: platformId,
      price: 9.99,
      currency: "USD",
    })
    verifyResponse(response)
    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar("New Platform Added", {
        variant: "success",
      })
    } else {
      // handling error message
      enqueueSnackbar("Fail add Platform", { variant: "warning" })
    }
    refreshPlatform(code)
  }
  const updatePlatform = async () => {
    const response = await editPlatformProduct({
      ...selectedPlatform,
      currency: selectedPlatform.coalesce,
    })
    verifyResponse(response)
    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar("Platform Updated", {
        variant: "success",
      })
    } else {
      // handling error message
      enqueueSnackbar("Platform Update Fail", { variant: "warning" })
    }
    refreshPlatform(code)
  }

  return access.listSubscriptionPlatformProductAccess &&
    platforms &&
    platforms.length > 0 &&
    selectedPlatform ? (
    <>
      <div className="platform-add-buttons">
        {access.addSubscriptionPlatformProductAccess &&
          pendingPlatforms.map((pendingPlatform) => (
            <Button
              key={`padding-platform-add-btn-${pendingPlatform.value}`}
              variant="outlined"
              size="small"
              onClick={() => {
                addPlatform(pendingPlatform.value)
              }}
            >
              {`Add ${pendingPlatform.text}`}
            </Button>
          ))}
      </div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={platformTab}
            onChange={(Event, newValue) => {
              setPlatformTab(newValue)
              setSelectedPlatform(platforms[newValue])
            }}
            aria-label="product platform"
          >
            {platforms.map((platform) => {
              const platformType = PRODUCT_PLATFORM.find(
                (item) => item.value === platform.platform
              )
              return (
                <Tab
                  key={`platform-tab-${platformType.type}`}
                  label={platformType.text}
                  {...a11yProps(platform.type)}
                />
              )
            })}
          </Tabs>
        </Box>

        {platforms.map((row, index) => (
          <TabPanel
            key={`platform-tabPanel-${row.id}`}
            value={platformTab}
            index={index}
          >
            <div className="form-row-two-cols">
              <FormControl>
                <TextField
                  autoFocus
                  variant="standard"
                  autoComplete="off"
                  disabled={!editMode[row.id]}
                  id="subscription-product-platform-price"
                  label="Price"
                  type="text"
                  value={selectedPlatform.price}
                  onChange={(e) => {
                    setSelectedPlatform({
                      ...selectedPlatform,
                      price: e.target.value,
                    })
                  }}
                />
              </FormControl>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 230 }}>
                <InputLabel id="subscription-product-platform-currency-label">
                  Currency
                </InputLabel>
                <Select
                  disabled={!editMode[row.id]}
                  labelId="subscription-product-platform-currency"
                  id="subscription-product-platform-currency"
                  value={selectedPlatform.coalesce.toLowerCase()}
                  label="Currency"
                  onChange={(e) => {
                    setSelectedPlatform({
                      ...selectedPlatform,
                      coalesce: e.target.value,
                    })
                  }}
                >
                  {CURRENCY_LIST.map((currency) => (
                    <MenuItem
                      key={`subscription-product-platform-${currency}`}
                      value={currency}
                    >
                      {currency.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {access.editSubscriptionPlatformProductAccess && (
                <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                  {editMode[row.id] ? (
                    <Button
                      className="platform-action-button"
                      variant="contained"
                      size="small"
                      onClick={() => {
                        updatePlatform()
                        setPlatformEditMode(row.id, false)
                      }}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      className="platform-action-button"
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        setPlatformEditMode(row.id, true)
                      }}
                    >
                      Edit
                    </Button>
                  )}
                </FormControl>
              )}
            </div>
          </TabPanel>
        ))}
      </Box>
    </>
  ) : (
    <div className="platform-add-buttons">
      {access.listSubscriptionPlatformProductAccess &&
        pendingPlatforms.map((pendingPlatform) => (
          <Button
            key={`padding-platform-add-btn-${pendingPlatform.value}`}
            variant="outlined"
            size="small"
            onClick={() => {
              addPlatform(pendingPlatform.value)
            }}
          >
            {`Add ${pendingPlatform.text}`}
          </Button>
        ))}
    </div>
  )
}

export default SubscriptionProductPlatform
