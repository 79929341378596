import React, { useState, useEffect, useRef } from "react"
import { Button } from "@mui/material"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import CircularProgress from "@mui/material/CircularProgress"
import { getPrograms } from "services/program"
import { verifyResponse } from "utils/httpRequest"
import { ProgramSelectorWrapper } from "./ProgramSelector.style"

const ProgramSelector = ({ onSelect }) => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [isSearched, setISearched] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [searchText, setSearchText] = useState([])
  const [selectedProgram, setSelectedProgram] = useState(null)

  const searchProgramByTitle = async () => {
    // query program by title
    setLoading(true)
    setISearched(true)
    const response = await getPrograms({
      pageNo: 1,
      pageSize: 1000,
      title: searchText,
      innerciseType: 1,
      pubStatus: 1,
      hasCategory: 1,
    })
    setLoading(false)
    verifyResponse(response)
    console.log("response: ", response)
    setSearchResults(response.data.data.list)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const openDlg = (editRole) => {
    setOpen(true)
  }

  const dlg = (
    <ProgramSelectorWrapper open={open}>
      <DialogTitle>Program Selector</DialogTitle>
      <DialogContent>
        <div className="search-bar">
          <TextField
            className="search-text"
            placeholder="Search Program by Title"
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value)
            }}
            onKeyUp={(e) => {
              if (e.code === "Enter") {
                console.log("e: ", e.target.value)
                searchProgramByTitle()
              }
            }}
          />
          <Button
            variant="contained"
            size="small"
            disabled={searchText.toString().trim() === ""}
            onClick={searchProgramByTitle}
          >
            Search
          </Button>
          <Button
            variant="primary"
            size="small"
            onClick={() => {
              //clear search input text
              setSearchText("")
            }}
          >
            Clear
          </Button>
        </div>
        <div className="search-results">
          {loading && <CircularProgress className="loading-icon" />}
          {!loading && isSearched && (
            <div className="notice">
              <strong>{`${searchResults.length} `}</strong>records found
            </div>
          )}
          {!loading &&
            searchResults.map((result) => {
              return (
                <div
                  className={`result-item ${
                    selectedProgram &&
                    selectedProgram.id === result.id &&
                    "selected"
                  }`}
                  onClick={() => {
                    setSelectedProgram(result)
                  }}
                >
                  <img
                    className="thumb"
                    src={result.thumbResourceDto.urlDto.imgUrl_XS}
                    alt="thumb"
                  />
                  <div className="content">
                    <div className="title">{result.title}</div>
                    <p>{result.description}</p>
                  </div>
                </div>
              )
            })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          variant="contained"
          size="small"
          disabled={selectedProgram === null}
          onClick={() => {
            onSelect(selectedProgram)
            setOpen(false)
          }}
        >
          Select
        </Button>
      </DialogActions>
    </ProgramSelectorWrapper>
  )

  return [openDlg, dlg, loading]
}

export default ProgramSelector
