import styled from "@emotion/styled"

const StyledRedis = styled.div`
  display: flex;
  flex-direction: column;

  .search-bar {
    display: flex;
    flex-direction: row;
  }
  .search-btn {
    width: 200px;
  }

  .search-query-info {
    background-color: #a0cc72;
    padding: 12px;
    margin: 0 8px;
    color: #845d5d;
    font-size: 14px;
    font-weight: 400;
    border-radius: 3px;
    .key-value {
      font-weight: bold;
      color: #5f2e2e;
    }
  }

  .editor-region {
    padding: 8px;
  }
`

export { StyledRedis }
