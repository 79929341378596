// import AWS from "aws-sdk";
import axios from "axios"

// AWS.config.update({
//   accessKeyId: process.env.REACT_APP_S3_KEY_ID,
//   secretAccessKey: process.env.REACT_APP_S3_ACCESS_KEY,
//   httpOptions: {
//     timeout: 120 * 60 * 1000,
//   },
// });

// const uploadAwsFile = async ({ bucket, bucketKey, file }) => {
//   const params = {
//     Body: file,
//     Bucket: bucket,
//     Key: bucketKey,
//   };

//   const myBucket = new AWS.S3({
//     params: { Bucket: bucket },
//     region: process.env.REACT_APP_S3_REGION,
//   });

//   myBucket
//     .putObject(params)
//     .on("httpUploadProgress", (evt) => {
//       //   setProgress(Math.round((evt.loaded / evt.total) * 100));
//     })
//     .send((err, data) => {
//       if (err) {
//         console.error(err);
//         // setUploading(false);
//       } else {
//         //
//         console.log("uploaded");
//       }
//     });
// };

const uploadPortraitImage = async ({ file, uploadUrl }) => {
  // const data = await fetch(boldUrl);
  // const postData = await data.blob();
  // return axios.post(uploadUrl, postData);
  // formData.append("image", response.data, "new_upload.jpg");
  // formData.append("file", boldUrl);
  console.log("file: ", file)
  await axios.put(uploadUrl, file)
}

// export { uploadAwsFile, uploadPortraitImage };
export { uploadPortraitImage }
