import React, { useEffect, useState } from "react"
import Button from "@mui/material/Button"
import { getCategories } from "services/category"
import PageHeading from "components/common/LayoutComponents/PageHeading"
import LoadingScreen from "components/common/LoadingScreen"
import { PERMISSIONS } from "constants/permission"
import { UsePermissionVerification } from "components/customHooks"
import { StyledCategorySection } from "./Category.style"
import CategoryTable from "./CategoryTable"
import CategoryDlg from "./CategoryDlg"
import SubCategoryDlg from "./SubCategoryDlg"
import { verifyResponse } from "utils/httpRequest"

const Category = () => {
  const [loading, setLoading] = useState(false)
  const [subCateLoading, setSubCateLoading] = useState(false)
  const [categoryDataList, setCategoryDataList] = useState([])
  const [categoryTableData, setCategoryTableData] = useState([])

  const AccessControl = UsePermissionVerification()

  const [openCategoryDlg, NewCategoryDlg, categoryLoading] = CategoryDlg({
    onSubmit: () => {
      getCategoryList()
    },
  })

  const [openSubCategoryDlg, SubCategoryDlgContent, subCategoryLoading] =
    SubCategoryDlg({
      onSubmit: () => {
        getCategoryList()
      },
      categories: categoryDataList,
    })

  const getCategoryList = async () => {
    try {
      setSubCateLoading(true)
      const response = await getCategories({
        pageNo: 1,
        pageSize: 1000,
      })
      verifyResponse(response)
      setCategoryDataList(response.data.list)
    } catch (e) {
      console.error("getCategoryList Error: ", e)
    } finally {
      setSubCateLoading(false)
    }
  }

  useEffect(() => {
    getCategoryList()
  }, [])

  useEffect(() => {
    AccessControl.SetAccess({
      ...AccessControl.Access,
      addCategoryAccess: AccessControl.VerifyAccess([
        PERMISSIONS.CATEGORY.CATEGORY_ADD,
      ]),
      pubCategoryAccess: AccessControl.VerifyAccess([
        PERMISSIONS.CATEGORY.CATEGORY_PUB,
      ]),
      listCategoryAccess: AccessControl.VerifyAccess([
        PERMISSIONS.CATEGORY.CATEGORY_LIST,
      ]),
      listSubCategoryAccess: AccessControl.VerifyAccess([
        PERMISSIONS.CATEGORY.CATEGORY_LIST_SUBCATEGORY,
      ]),
      editCategoryAccess: AccessControl.VerifyAccess([
        PERMISSIONS.CATEGORY.CATEGORY_EDIT,
      ]),
      deleteCategoryAccess: AccessControl.VerifyAccess([
        PERMISSIONS.CATEGORY.CATEGORY_DELETE,
      ]),
      addSubCategoryAccess: AccessControl.VerifyAccess([
        PERMISSIONS.CATEGORY.CATEGORY_ADD_SUBCATEGORY,
      ]),
      deleteSubCategoryAccess: AccessControl.VerifyAccess([
        PERMISSIONS.CATEGORY.CATEGORY_DELETE_SUBCATEGORY,
      ]),
      editSubCategoryAccess: AccessControl.VerifyAccess([
        PERMISSIONS.CATEGORY.CATEGORY_EDIT_SUBCATEGORY,
      ]),
      listProgramBySubCategoryAccess: AccessControl.VerifyAccess([
        PERMISSIONS.PROGRAM.PROGRAM_EXCHANGE_INDEX,
      ]),
    })
  }, [AccessControl.UserPermissions])

  useEffect(() => {
    if (categoryDataList) {
      setLoading(true)
      Promise.all(
        categoryDataList.map(async (category) => {
          return category
        })
      ).then((data) => {
        setCategoryTableData(data)
        setLoading(false)
      })
    }
  }, [categoryDataList])

  const tableActions = {
    openCategoryDialog: openCategoryDlg,
    openSubCategoryDialog: openSubCategoryDlg,
    refreshCategories: getCategoryList,
    setCategories: setCategoryDataList,
    setLoading,
  }

  return (
    <StyledCategorySection>
      <PageHeading>
        <div>Category</div>
        <div className="actions">
          {AccessControl.Access.addCategoryAccess && (
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                openCategoryDlg()
              }}
            >
              New Category
            </Button>
          )}
          {/* {AccessControl.Access.pubCategoryAccess && (
            <Button
              style={{ color: "#819B54", border: "1px solid #819B54" }}
              variant="outlined"
              size="small"
            >
              Publish Category
            </Button>
          )} */}
        </div>
      </PageHeading>
      {NewCategoryDlg}
      {SubCategoryDlgContent}
      {AccessControl.Access.listCategoryAccess && (
        <CategoryTable
          data={categoryTableData}
          actions={tableActions}
          access={AccessControl.Access}
        />
      )}
      <LoadingScreen
        loading={
          loading || subCateLoading || categoryLoading || subCategoryLoading
        }
      />
    </StyledCategorySection>
  )
}

export default Category
