import styled from "@emotion/styled";
import { Dialog } from "@mui/material";

const StyledRole = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledRoleTable = styled.div`
  display: flex;
  flex-direction: column;
  .pagination-section {
    display: flex;
    justify-content: flex-end;
  }
`;

const StyledRoleDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  .MuiPaper-root {
    width: 600px;
  }
  .error {
    font-size: 0.75rem;
    color: #d32f2f;
    margin-top: 3px;
  }
`;

const StyledRoleDetail = styled.div`
  display: flex;
  flex-direction: column;
  .permission-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .MuiFormControlLabel-label {
    font-size: 14px;
  }

  .permission-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .select-all-checkbox {
    margin-left: 20px;
  }
`;

export { StyledRole, StyledRoleTable, StyledRoleDialog, StyledRoleDetail };
