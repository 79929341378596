import { httpPost } from "utils/httpRequest";

const getAdminUserInfo = async () => {
  const resp = await httpPost("admin/adminUser/login");
  return resp.data;
};

const getUploadPortraitUrl = async () => {
  const resp = await httpPost("admin/adminUser/getUploadPortraitUrl");
  return resp.data;
};

export { getAdminUserInfo, getUploadPortraitUrl };
