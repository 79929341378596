import { httpPost } from "utils/httpRequest"

const addProgram = async ({
  title,
  description,
  innerciseType,
  introResourceId,
  thumbResourceId,
  thumbResourceUIList,
  introImageId,
  rank,
  contributorIdList,
  subCategoryList,
}) => {
  const resp = await httpPost("admin/program/add", {
    title,
    description,
    innerciseType,
    introResourceId,
    thumbResourceId,
    thumbResourceUIList,
    introImageId,
    rank,
    contributorIdList: contributorIdList ? contributorIdList : [],
    subCategoryList,
  })

  return resp
}

const removeProgram = async (programId) => {
  const resp = await httpPost("admin/program/deleteProgram", {
    programId,
  })
  return resp
}

const updateProgram = async ({
  id,
  title,
  description,
  innerciseType,
  introResourceId,
  thumbResourceId,
  thumbResourceUIList,
  introImageId,
  pubStatus,
  rank,
  contributorIdList,
  subCategoryList,
}) => {
  const resp = await httpPost("admin/program/edit", {
    id,
    title,
    description,
    innerciseType,
    introResourceId,
    thumbResourceId,
    thumbResourceUIList,
    introImageId,
    pubStatus,
    rank,
    contributorIdList: contributorIdList ? contributorIdList : [],
    subCategoryList,
  })

  return resp
}

const getPrograms = async ({ pageNo, pageSize, title, pubStatus }) => {
  const resp = await httpPost("admin/program/list", {
    pageNo,
    pageSize,
    title,
    pubStatus,
  })

  return resp
}

const getProgramDetail = async (id) => {
  const resp = await httpPost(`admin/program/queryDetail/${id}`, {})
  return resp
}

const pubProgram = async (id) => {
  const resp = await httpPost(`admin/program/pub`, {
    programIdList: [id],
  })
  return resp
}

const cancelPubProgram = async (id) => {
  const resp = await httpPost(`admin/program/cancelPub`, {
    programIdList: [id],
  })
  return resp
}

// program level

const addProgramLevel = async ({ programId, levelName, levelRank }) => {
  const resp = await httpPost("admin/program/addLevel", {
    programId,
    levelName,
    levelRank,
  })

  return resp
}

const deleteProgramLevel = async (levelId) => {
  const resp = await httpPost(`admin/program/deleteLevel`, { levelId })
  return resp
}

const editProgramLevel = async ({ id, levelName, levelRank }) => {
  const resp = await httpPost(`admin/program/editLevel`, {
    id,
    levelName,
    levelRank,
  })
  return resp
}

// innercise
const addProgramInnercise = async (props) => {
  const {
    programId,
    levelId,
    title,
    type,
    description,
    resourceId,
    thumbResourceId,
    attachIds,
    innerciseResourceList,
    freeFlag,
  } = props

  const resp = await httpPost("admin/program/addInnercise", {
    programId,
    levelId,
    title,
    type,
    description,
    resourceId,
    thumbResourceId,
    attachIds,
    innerciseResourceList,
    freeFlag,
  })

  return resp
}

const updateProgramInnercise = async ({
  id,
  levelId,
  title,
  type,
  description,
  resourceId,
  thumbResourceId,
  attachIds,
  innerciseResourceList,
  freeFlag,
}) => {
  const resp = await httpPost("admin/program/editInnercise", {
    id,
    levelId,
    title,
    type,
    description,
    resourceId,
    thumbResourceId,
    attachIds,
    innerciseResourceList,
    freeFlag,
  })

  return resp
}

const getProgramInnerciseById = async (id) => {
  const resp = await httpPost("admin/program/queryInnerciseById", {
    id,
  })

  return resp.data
}

const deleteProgramInnercise = async (innerciseId) => {
  const resp = await httpPost("admin/program/deleteInnercise", {
    innerciseId,
  })
  return resp
}

const getProgramBySubCategoryId = async (subCategoryId) => {
  const resp = await httpPost("admin/program/listBySubCategory", {
    subCategoryId,
    pageNo: 1,
    pageSize: 9999,
    pubStatus: 1,
  })
  return resp.data
}

const exchangeProgramIndex = async ({ updateIndexArray, subCategoryId }) => {
  const resp = await httpPost("admin/program/exchangeIndex", {
    updateIndexArray,
    subCategoryId,
  })
  return resp.data
}

const exchangeProgramLevelIndex = async ({ updateIndexArray, programId }) => {
  const resp = await httpPost("admin/program/exchangeLevelIndex", {
    updateIndexArray,
    programId,
  })
  return resp.data
}

const queryInnerciseByTitle = async ({ pageNo, pageSize, title }) => {
  const resp = await httpPost("admin/program/queryInnerciseByTitle", {
    pageNo,
    pageSize,
    title,
  })
  return resp.data
}

const showProgramProgress = async ({ programIdList }) => {
  const resp = await httpPost("admin/program/showProgress", {
    programIdList,
  })
  return resp.data
}

const hideProgramProgress = async ({ programIdList }) => {
  const resp = await httpPost("admin/program/hideProgress", {
    programIdList,
  })
  return resp.data
}

const setProgramLockable = async ({ programIdList }) => {
  const resp = await httpPost("admin/program/setLockable", {
    programIdList,
  })
  return resp.data
}

const cancelProgramLockable = async ({ programIdList }) => {
  const resp = await httpPost("admin/program/cancelLockable", {
    programIdList,
  })
  return resp.data
}

// playbook

const addProgramPlaybook = async (props) => {
  const {
    programId,
    levelId,
    title,
    description,
    thumbResourceId,
    estTimeLength,
  } = props
  const resp = await httpPost("admin/program/addPlaybook", {
    programId,
    levelId,
    title,
    description,
    thumbResourceId,
    estTimeLength,
  })
  return resp
}

const editProgramPlaybook = async (props) => {
  const {
    programId,
    levelId,
    title,
    description,
    thumbResourceId,
    estTimeLength,
    id,
  } = props
  const resp = await httpPost("admin/program/editPlaybook", {
    programId,
    levelId,
    title,
    description,
    thumbResourceId,
    estTimeLength,
    id,
  })
  return resp
}

const addProgramPlaybookQuestion = async (props) => {
  const { playbookId, answerType, title, answerList } = props
  const resp = await httpPost("admin/program/playbook/addQuestion", {
    playbookId,
    answerType,
    title,
    answerList,
  })
  return resp
}

const editProgramPlaybookQuestionEditTitle = async (props) => {
  const { id, title } = props
  const resp = await httpPost("admin/program/playbook/question/editTitle", {
    id,
    title,
  })
  return resp
}

const deleteProgramPlaybookQuestion = async (props) => {
  const { id } = props
  let resp

  try {
    resp = await httpPost("admin/program/playbook/delQuestion", {
      id,
    })
    return resp.data
  } catch (error) {
    return error
  }
}

const exchangeChangeProgramPlaybookQuestionIndex = async (props) => {
  const { updateIndexArray } = props
  const resp = await httpPost("admin/program/playbook/question/exchangeIndex", {
    updateIndexArray: updateIndexArray,
  })
  return resp
}

export {
  addProgram,
  removeProgram,
  updateProgram,
  getPrograms,
  getProgramDetail,
  pubProgram,
  cancelPubProgram,
  addProgramLevel,
  deleteProgramLevel,
  editProgramLevel,
  addProgramInnercise,
  updateProgramInnercise,
  getProgramInnerciseById,
  deleteProgramInnercise,
  getProgramBySubCategoryId,
  exchangeProgramIndex,
  exchangeProgramLevelIndex,
  queryInnerciseByTitle,
  showProgramProgress,
  hideProgramProgress,
  setProgramLockable,
  cancelProgramLockable,
  addProgramPlaybook,
  addProgramPlaybookQuestion,
  editProgramPlaybook,
  deleteProgramPlaybookQuestion,
  editProgramPlaybookQuestionEditTitle,
  exchangeChangeProgramPlaybookQuestionIndex,
}
