import React, { useState, useEffect, useRef } from "react"
import Button from "@mui/material/Button"
import PageHeading from "components/common/LayoutComponents/PageHeading"
import PageContent from "components/common/LayoutComponents/PageContent"
import { verifyResponse } from "utils/httpRequest"
import { PERMISSIONS } from "constants/permission"
import { UsePermissionVerification } from "components/customHooks"
import LoadingScreen from "components/common/LoadingScreen"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import CancelIcon from "@mui/icons-material/Cancel"
import ProgramSelector from "components/common/ProgramSelector"
import { UseConfirmDialog } from "components/customHooks"

import {
  listFeatureProgram,
  addFeaturedProgram,
  deleteFeaturedProgram,
} from "services/featuredProgram"

import { FeaturedProgramWrapper } from "./FeaturedProgram.style"

const FeaturedProgram = () => {
  // state variables
  const [loading, setLoading] = useState(false)
  const [featuredProgram, setFeaturedProgram] = useState([])
  const AccessControl = UsePermissionVerification()

  // ref variables
  const sortedFeatureProgramRef = useRef([])

  // Hooks
  const [ConfirmDialog, openConfirmDialog] = UseConfirmDialog({
    title: "Delete Confirm",
    description: "Are you sure want delete this ?",
    agreeText: "Yes",
    disagreeText: "No",
  })

  const [openProgramSelectorDlg, programSelectorDlg, programSelectorLoading] =
    ProgramSelector({
      onSelect: (selectedProgram) => {
        addNewFeaturedProgram(selectedProgram.id)
      },
    })

  // Functions
  const queryFeatureProgram = async () => {
    setLoading(true)
    const response = await listFeatureProgram({
      pageNo: 1,
      pageSize: 1000,
    })
    setLoading(false)
    verifyResponse(response)
    setFeaturedProgram(response.data.list)
  }

  const addNewFeaturedProgram = async (programId) => {
    setLoading(true)
    const response = await addFeaturedProgram({ programId })
    setLoading(true)
    verifyResponse(response)
    queryFeatureProgram()
  }
  const removeNewFeaturedProgram = async (programId) => {
    setLoading(true)
    const response = await deleteFeaturedProgram({ id: programId })
    setLoading(true)
    verifyResponse(response)
    console.log("response: ", response)
    queryFeatureProgram()
  }

  const processFeaturedProgramList = () => {
    // empty array list
    sortedFeatureProgramRef.current = []

    featuredProgram.forEach((program) => {
      // find target category object from list
      program.programDto.subCategoryList.forEach((subCategoryObj) => {
        const targetCategoryObj = sortedFeatureProgramRef.current.find(
          (categoryObj) => categoryObj.categoryId === subCategoryObj.categoryId
        )

        if (targetCategoryObj) {
          sortedFeatureProgramRef.current = [
            ...sortedFeatureProgramRef.current.filter(
              (categoryObject) =>
                categoryObject.categoryId !== subCategoryObj.categoryId
            ),
            {
              ...targetCategoryObj,
              programs: [
                ...targetCategoryObj.programs,
                { ...program.programDto, featuredProgramId: program.id },
              ],
            },
          ]
        } else {
          sortedFeatureProgramRef.current = [
            ...sortedFeatureProgramRef.current,
            {
              categoryId: subCategoryObj.categoryId,
              categoryTitle: subCategoryObj.categoryName,
              programs: [
                { ...program.programDto, featuredProgramId: program.id },
              ],
            },
          ]
        }
      })
      setTimeout(() => {
        setLoading(true)
        setLoading(false)
      }, 50)
    })
  }

  useEffect(() => {
    queryFeatureProgram()
  }, [])

  useEffect(() => {
    // sort feature program into sorted object list
    processFeaturedProgramList()
  }, [featuredProgram])

  // useEffect(() => {
  //   console.log("sortedFeatureProgram final: ", sortedFeatureProgramRef.current)
  // }, [sortedFeatureProgramRef.current])

  useEffect(() => {
    AccessControl.SetAccess({
      ...AccessControl.Access,
      addStartResource: AccessControl.VerifyAccess([
        PERMISSIONS.START_HERE.START_HERE_RESOURCE_ADD,
      ]),
      deleteStartResource: AccessControl.VerifyAccess([
        PERMISSIONS.START_HERE.START_HERE_RESOURCE_DELETE,
      ]),
      editStartResource: AccessControl.VerifyAccess([
        PERMISSIONS.START_HERE.START_HERE_RESOURCE_EDIT,
      ]),
      exchangeIndexStartResource: AccessControl.VerifyAccess([
        PERMISSIONS.START_HERE.START_HERE_RESOURCE_EXCHANGE_INDEX,
      ]),
      listStartResource: AccessControl.VerifyAccess([
        PERMISSIONS.START_HERE.START_HERE_RESOURCE_LIST,
      ]),
    })
  }, [AccessControl.UserPermissions])

  return (
    <PageContent>
      <FeaturedProgramWrapper>
        <PageHeading>
          <div>Feature Program</div>
          <div className="actions">
            {AccessControl.Access.addStartResource && (
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  openProgramSelectorDlg()
                }}
              >
                Add Program
              </Button>
            )}
          </div>
        </PageHeading>
        <div>
          {sortedFeatureProgramRef.current.length > 0 &&
            sortedFeatureProgramRef.current.map((category) => (
              <div
                className="category-section"
                key={`category-section-${category.categoryId}`}
              >
                <h3>{category.categoryTitle}</h3>
                <div className="program-list">
                  {category.programs.map((program) => (
                    <div
                      className="program"
                      key={`program-item-${program.featuredProgramId}`}
                    >
                      <img
                        src={program.thumbResourceDto.urlDto.imgUrl_XS}
                        alt="thumb"
                      />
                      <div className="title">{program.title}</div>
                      <div className="delete-btn">
                        <Tooltip title="Delete Resource">
                          <IconButton
                            sx={{ color: "#b13c3c" }}
                            aria-label="edit resource"
                            component="label"
                            onClick={() => {
                              openConfirmDialog(async () => {
                                removeNewFeaturedProgram(
                                  program.featuredProgramId
                                )
                              }, `Are you sure want delete featured program [ ${program.title} ] ?`)
                            }}
                          >
                            <CancelIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </FeaturedProgramWrapper>
      <LoadingScreen loading={loading || programSelectorLoading} />
      {programSelectorDlg}
      {ConfirmDialog}
    </PageContent>
  )
}

export default FeaturedProgram
