import React, { useState, useEffect } from "react"
import Button from "@mui/material/Button"
import { Container, Draggable } from "react-smooth-dnd"
import PageHeading from "components/common/LayoutComponents/PageHeading"
import MediaManager from "components/common/MediaManager"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { getPrimes, exchangeOrder } from "services/prime"
import { calculateNewRank } from "utils/data"
import { verifyResponse } from "utils/httpRequest"
import {
  UseConfirmDialog,
  UsePermissionVerification,
} from "components/customHooks"
import { PERMISSIONS } from "constants/permission"
import LoadingScreen from "components/common/LoadingScreen"
// import Paper from "@mui/material/Paper"
import Pagination from "@mui/material/Pagination"
import PrimeCard from "./PrimeCard"
import PrimeDlg from "./PrimeDlg"
import { StyledPrimeSection, StyledPrimeList } from "./Prime.style"

const Prime = () => {
  const [loading, setLoading] = useState(false)
  const [primes, setPrimes] = useState([])
  const [displayOption, setDisplayOption] = useState({
    currentPageNo: 1,
    hasNext: false,
    pageSize: 10,
    pages: 0,
    total: 0,
  })
  const [openMediaManager, mediaManager] = MediaManager()
  const [openPrimeDialog, primeDialog, primeDialogLoading] = PrimeDlg({
    onSubmit: () => {
      refreshPrimes()
    },
    openMediaManager: openMediaManager,
  })

  const [ConfirmDialog, openConfirmDialog] = UseConfirmDialog({
    title: "Delete Confirm",
    description: "Are you sure want delete this ?",
    agreeText: "Yes",
    disagreeText: "No",
  })

  const AccessControl = UsePermissionVerification()

  const refreshPrimes = async () => {
    setLoading(true)
    const response = await getPrimes()
    verifyResponse(response)
    setPrimes(response.data.list)
    setDisplayOption({
      ...displayOption,
      total: response.data.list.length,
      hasNext: response.data.list.length / displayOption.pageSize > 1,
      pages:
        response.data.list.length % displayOption.pageSize === 0
          ? Math.floor(response.data.list.length / displayOption.pageSize)
          : Math.floor(response.data.list.length / displayOption.pageSize) + 1,
    })
    setLoading(false)
  }

  useEffect(() => {
    refreshPrimes()
  }, [])

  useEffect(() => {
    setDisplayOption({
      ...displayOption,
      hasNext: displayOption.total / displayOption.pageSize > 1,
      pages:
        displayOption.total % displayOption.pageSize === 0
          ? Math.floor(displayOption.total / displayOption.pageSize)
          : Math.floor(displayOption.total / displayOption.pageSize) + 1,
    })
  }, [displayOption.pageSize])

  useEffect(() => {
    AccessControl.SetAccess({
      ...AccessControl.Access,
      listPrimeAccess: AccessControl.VerifyAccess([
        PERMISSIONS.PRIME.PRIME_LIST,
      ]),
      addPrimeAccess: AccessControl.VerifyAccess([PERMISSIONS.PRIME.PRIME_ADD]),
      editPrimeAccess: AccessControl.VerifyAccess([
        PERMISSIONS.PRIME.PRIME_EDIT,
      ]),
      deletePrimeAccess: AccessControl.VerifyAccess([
        PERMISSIONS.PRIME.PRIME_DELETE,
      ]),
      exchangeIndexPrimeAccess: AccessControl.VerifyAccess([
        PERMISSIONS.PRIME.PRIME_EXCHANGE_INDEX,
      ]),
      queryDetailPrimeAccess: AccessControl.VerifyAccess([
        PERMISSIONS.PRIME.PRIME_QUERY_BY_ID,
      ]),
    })
  }, [AccessControl.UserPermissions])

  return (
    <>
      <StyledPrimeSection>
        <PageHeading>
          <div>Daily Primes</div>
          <div className="actions">
            {AccessControl.Access.addPrimeAccess && (
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  openPrimeDialog()
                }}
              >
                New Daily Prime
              </Button>
            )}
            {/* <Button
              style={{ color: "#819B54", border: "1px solid #819B54" }}
              variant="outlined"
              size="small"
            >
              Publish Prime
            </Button> */}
          </div>
        </PageHeading>

        {AccessControl.Access.listPrimeAccess && (
          <StyledPrimeList>
            <div className="row-section">
              <FormControl style={{ width: "100px" }}>
                <InputLabel id="demo-simple-select-label">Page Size</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={displayOption.pageSize}
                  label="Page Size"
                  onChange={(e) => {
                    setDisplayOption({
                      ...displayOption,
                      pageSize: e.target.value,
                    })
                  }}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={99999999999999}>all</MenuItem>
                </Select>
              </FormControl>
            </div>
            <Container
              onDrop={async (e) => {
                const updateIndexArray = calculateNewRank({
                  addedIndex: e.addedIndex,
                  removedIndex: e.removedIndex,
                  indexString: "primeIndex",
                  list: primes,
                })
                await exchangeOrder(updateIndexArray)
                refreshPrimes()
              }}
            >
              {primes
                .slice(
                  (displayOption.currentPageNo - 1) * displayOption.pageSize,
                  (displayOption.currentPageNo - 1) * displayOption.pageSize +
                    displayOption.pageSize
                )
                .map((prime, index) => {
                  return AccessControl.Access.exchangeIndexPrimeAccess ? (
                    <Draggable key={prime.id}>
                      <PrimeCard
                        prime={prime}
                        access={AccessControl.Access}
                        openPrimeDialog={openPrimeDialog}
                        refreshPrimes={refreshPrimes}
                        openConfirmDialog={openConfirmDialog}
                      />
                    </Draggable>
                  ) : (
                    <PrimeCard
                      prime={prime}
                      access={AccessControl.Access}
                      openPrimeDialog={openPrimeDialog}
                      refreshPrimes={refreshPrimes}
                      openConfirmDialog={openConfirmDialog}
                    />
                  )
                })}

              <div className="pagination-section row-section">
                <Pagination
                  count={displayOption.pages}
                  page={displayOption.currentPageNo}
                  onChange={(event, value) => {
                    console.log("event:", value, event)
                    console.log("option : ", displayOption)
                    setDisplayOption({
                      ...displayOption,
                      currentPageNo: value,
                    })
                  }}
                />
              </div>
            </Container>
          </StyledPrimeList>
        )}

        {primeDialog}
        {ConfirmDialog}
        <LoadingScreen loading={loading || primeDialogLoading} />
      </StyledPrimeSection>
      {mediaManager}
    </>
  )
}

export default Prime
