import { httpPost, httpGet } from "utils/httpRequest"

//==========================
// Subscription Group
//==========================
const getSubscriptionGroups = async () => {
  const resp = await httpGet("admin/subscription/listAllGroup")
  return resp.data
}

const addSubscriptionGroup = async ({ name, code, description }) => {
  const resp = await httpPost("admin/subscription/addGroup", {
    name,
    code,
    description,
  })
  return resp.data
}

const editSubscriptionGroup = async ({
  name,
  code,
  description,
  id,
  pubStatus,
}) => {
  const resp = await httpPost("admin/subscription/editGroup", {
    name,
    code,
    description,
    id,
    pubStatus,
  })
  return resp.data
}

//==========================
// Subscription Product
//==========================

const getSubscriptionProducts = async () => {
  const resp = await httpPost("admin/subscription/getAllProductList", {})
  return resp.data
}

const addSubscriptionProduct = async ({
  groupId,
  code,
  name,
  description,
  duration,
  durationUnit,
  price,
  currency,
  subscriptionType,
  programRank,
  levelRank,
  trialFlag,
  trialDuration,
}) => {
  const resp = await httpPost("admin/subscription/addProduct", {
    groupId,
    code,
    name,
    description,
    duration,
    durationUnit,
    price,
    currency,
    subscriptionType,
    programRank,
    levelRank,
    trialFlag,
    trialDuration,
  })
  return resp.data
}

const editSubscriptionProduct = async ({
  id,
  groupId,
  code,
  name,
  description,
  duration,
  durationUnit,
  price,
  currency,
  subscriptionType,
  programRank,
  levelRank,
  trialFlag,
  trialDuration,
}) => {
  const resp = await httpPost("admin/subscription/editProduct", {
    id,
    groupId,
    code,
    name,
    description,
    duration,
    durationUnit,
    price,
    currency,
    subscriptionType,
    programRank,
    platform: 0,
    levelRank,
    trialFlag,
    trialDuration,
  })
  return resp.data
}

const pubSubscriptionProduct = async (id) => {
  const resp = await httpPost(`admin/subscription/pubProduct/${id}`)
  return resp.data
}

const cancelPubSubscriptionProduct = async (id) => {
  const resp = await httpPost(`admin/subscription/cancelPubProduct/${id}`)
  return resp.data
}

//==========================
// Platform Product
//==========================

const addPlatformProduct = async ({ productId, platform, price, currency }) => {
  const resp = await httpPost("admin/subscription/addPlatformProduct", {
    productId,
    platform,
    price,
    currency,
  })
  return resp.data
}

const editPlatformProduct = async ({
  id,
  productId,
  platform,
  price,
  currency,
}) => {
  const resp = await httpPost("admin/subscription/editPlatformProduct", {
    id,
    productId,
    platform,
    price,
    currency,
  })
  return resp.data
}

const listPlatform = async (code) => {
  const resp = await httpPost("admin/subscription/listPlatformProduct", {
    code,
  })
  return resp.data
}

export {
  getSubscriptionGroups,
  addSubscriptionGroup,
  editSubscriptionGroup,
  getSubscriptionProducts,
  addSubscriptionProduct,
  editSubscriptionProduct,
  pubSubscriptionProduct,
  cancelPubSubscriptionProduct,
  addPlatformProduct,
  editPlatformProduct,
  listPlatform,
}
