import { httpPost } from "utils/httpRequest"

const getRedeemCodes = async ({
  pageNo,
  pageSize,
  redeemStatus,
  redeemCode,
  redeemMemberEmail,
  productCode,
  batchId,
}) => {
  const resp = await httpPost("admin/redeemCode/list", {
    pageNo,
    pageSize,
    redeemStatus,
    redeemCode,
    redeemMemberEmail,
    productCode,
    batchId,
  })
  return resp.data
}

const addRedeemCodes = async ({
  codeType,
  productCode,
  duration,
  durationUnit,
  startAt,
  endAt,
  redeemCodeCount,
  description,
}) => {
  const resp = await httpPost("admin/redeemCode/add", {
    codeType,
    productCode,
    duration,
    durationUnit,
    startAt,
    endAt,
    redeemCodeCount,
    description,
  })
  return resp.data
}

const getRedeemBatchInfo = async (batchId) => {
  const resp = await httpPost("admin/redeemCode/getBatchInfoById", {
    batchId,
  })
  return resp.data
}

export { getRedeemCodes, addRedeemCodes, getRedeemBatchInfo }
