import { httpPost } from "utils/httpRequest";

const searchReviews = async ({
  pageNo,
  pageSize,
  pubStatus,
  startAt,
  endAt,
  userEmail,
}) => {
  const resp = await httpPost("admin/review/listReview", {
    pageNo,
    pageSize,
    pubStatus,
    startAt,
    endAt,
    userEmail,
  });

  return resp.data;
};

const pubReview = async (reviewId) => {
  const resp = await httpPost(`admin/review/pubReview/${reviewId}`);
  return resp.data;
};

const cancelPubReview = async (reviewId) => {
  const resp = await httpPost(`admin/review/cancelPubReview/${reviewId}`);
  return resp.data;
};

const getReviewsByMemberId = async (memberId) => {
  const resp = await httpPost("admin/review/listReview", {
    pageNo: 1,
    pageSize: 99999,
    memberId,
  });

  return resp.data;
};

export { searchReviews, pubReview, cancelPubReview, getReviewsByMemberId };
