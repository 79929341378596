import React, { useState } from "react";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import { StyledRoleTable } from "./Role.style";

const RoleTable = ({ roles, access, openRoleDialog, setSelectedRole }) => {
  const [page, setPage] = useState(1);
  const numPerPage = 10;
  const totalCount = roles ? roles.length : 0;

  const startIndex = page === 1 ? 0 : (page - 1) * numPerPage;

  return (
    <StyledRoleTable>
      {!roles ||
        (roles.length === 0 && (
          <div className="search-result-info">No Record.</div>
        ))}

      {roles && roles.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="role table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell sx={{ minWidth: 160, textAlign: "right" }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {roles.slice(startIndex, startIndex + numPerPage).map((row) => (
                <TableRow
                  key={`role-item-${row.id}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell sx={{ textAlign: "right" }}>
                    {access.getDetailByIdRoleAccess && (
                      <Button
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                          setSelectedRole(row);
                        }}
                        variant="outlined"
                      >
                        Permission
                      </Button>
                    )}
                    {access.editRoleAccess && (
                      <Button
                        style={{ width: "80px" }}
                        onClick={() => {
                          openRoleDialog(row);
                        }}
                        variant="outlined"
                      >
                        Edit
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}

              <TableRow key={"pg"}>
                <TableCell colSpan={7}>
                  <div className="pagination-section">
                    <Pagination
                      count={Math.ceil(totalCount / numPerPage)}
                      page={page}
                      onChange={(event, value) => {
                        setPage(value);
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </StyledRoleTable>
  );
};

export default RoleTable;
