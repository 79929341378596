import React, { useState } from "react"
import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import FormLabel from "@mui/material/FormLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import InnerciseSelector from "components/common/InnerciseSelector/InnerciseSelector"
import { addStartResource, editStartResource } from "services/startHere"
import { verifyResponse } from "utils/httpRequest"
import { START_RESOURCE_DATA_TYPE } from "constants/global"
import { StartHereDialog } from "./StartHere.style"

const StartHereDlg = ({ onSubmit }) => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [startResource, setStartResource] = useState(null)
  const [resourceTitle, setResourceTitle] = useState("")
  const [resourceDescription, setResourceDescription] = useState("")
  const [resourceThumb, setResourceThumb] = useState("")

  const initStartResource = {
    dataType: START_RESOURCE_DATA_TYPE.INNERCISE,
    dataId: 0,
    isHome: 0,
    id: 0,
  }

  const [
    openInnerciseSelectorDlg,
    innerciseSelectorDlg,
    innerciseSelectorLoading,
  ] = InnerciseSelector({
    onSelect: (selectedInnercise) => {
      console.log("selectedInnercise: ", selectedInnercise)
      setStartResource({
        ...startResource,
        dataId: selectedInnercise.id,
      })
      setResourceTitle(selectedInnercise.title)
      setResourceDescription(selectedInnercise.description)
      setResourceThumb(selectedInnercise.thumbResourceDto.urlDto.imgUrl_XS)
    },
  })

  const handleClose = () => {
    setOpen(false)
  }

  const addNewStartResource = async () => {
    setLoading(true)
    const response = await addStartResource(startResource)
    setLoading(false)
    verifyResponse(response)
    console.log("response: ", response)

    onSubmit && onSubmit()

    setOpen(false)
  }

  const editNewStartResource = async () => {
    setLoading(true)
    const response = await editStartResource(startResource)
    setLoading(false)
    verifyResponse(response)
    onSubmit && onSubmit()
    setOpen(false)
  }

  const openDlg = (editStartResource) => {
    // reset();
    if (editStartResource) {
      setResourceTitle(editStartResource.innerciseDto.title)
      setResourceDescription(editStartResource.innerciseDto.description)
      setResourceThumb(
        editStartResource.innerciseDto.thumbResourceDto.urlDto.imgUrl_XS
      )
    }

    setStartResource(
      editStartResource
        ? {
            ...startResource,
            dataType: START_RESOURCE_DATA_TYPE.INNERCISE,
            dataId: editStartResource.dataId,
            isHome: editStartResource.isHome,
            id: editStartResource.id,
          }
        : initStartResource
    )
    setIsEditMode(editStartResource ? true : false)
    setOpen(true)
  }

  const dlg = (
    <StartHereDialog open={open}>
      <DialogTitle>
        {isEditMode ? "Edit Start Resource" : "Add New Start Resource"}
      </DialogTitle>
      <DialogContent>
        <div className="actions">
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              openInnerciseSelectorDlg()
            }}
          >
            Select Innercise
          </Button>
        </div>

        <Box>
          <div className="innercise">
            {startResource && startResource.dataId !== 0 ? (
              <div className="innercise-content">
                <img
                  className="thumb"
                  src={resourceThumb}
                  alt="resource thumb"
                />
                <div className="info">Title: {resourceTitle}</div>
                <p className="info">Description: {resourceDescription}</p>
              </div>
            ) : (
              <div className="no-selection">No Resource Selected Yet...</div>
            )}
          </div>
        </Box>

        <FormControl>
          <FormLabel id="radio-buttons-is-home-group-label">Is Home</FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-is-home-group-label"
            value={startResource ? startResource.isHome : 0}
            name="radio-buttons-is-home"
            onChange={(e) => {
              setStartResource({
                ...startResource,
                isHome: e.target.value,
              })
            }}
          >
            <FormControlLabel value="1" control={<Radio />} label="Yes" />
            <FormControlLabel value="0" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          type="submit"
          variant="contained"
          size="small"
          disabled={startResource && startResource.dataId === 0}
          onClick={() => {
            isEditMode ? editNewStartResource() : addNewStartResource()
          }}
        >
          {isEditMode ? "Update" : "Create"}
        </Button>
      </DialogActions>
      {innerciseSelectorDlg}
    </StartHereDialog>
  )

  return [openDlg, dlg, loading || innerciseSelectorLoading]
}

export default StartHereDlg
