import React, { useState } from "react"
import Button from "@mui/material/Button"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import FormControl from "@mui/material/FormControl"
import TextField from "@mui/material/TextField"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Chip from "@mui/material/Chip"
import ClearIcon from "@mui/icons-material/Clear"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import { StyledInputLabel } from "components/Style/Forms.style"
import { Box } from "@mui/material"
import { useSnackbar } from "notistack"
import { useForm } from "react-hook-form"
import {
  PLAYBOOK_RESPONSE_QUESTION_TYPE,
  PLAYBOOK_RESPONSE_QUESTION_TYPE_DICT,
} from "constants/global"
import {
  addProgramPlaybookQuestion,
  editProgramPlaybookQuestionEditTitle,
} from "services/program"
import { StyledProgramPlaybookQuestionDialog } from "./ProgramPlaybookQuestionDlg.style"
import { verifyResponse } from "utils/httpRequest"

const ProgramPlaybookQuestionDialog = (props) => {
  const { refreshPlaybook } = props
  const initPlaybookQuestion = {
    id: 0, // playbook id
    answerType: PLAYBOOK_RESPONSE_QUESTION_TYPE.SHORT_TEXT,
    title: "",
    answerList: [],
    dataIndex: 0,
  }

  const { enqueueSnackbar } = useSnackbar()

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm()

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [playbookId, setPlaybookId] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false)
  const [playbookQuestion, setPlaybookQuestion] = useState(initPlaybookQuestion)
  const [options, setOptions] = useState([])

  const openDlg = ({ playbookId, playbookQuestion }) => {
    setPlaybookId(playbookId || null)
    reset()

    if (playbookQuestion) {
      setIsEditMode(true)
      setPlaybookQuestion(playbookQuestion)
      setValue("title", playbookQuestion.title)
      setValue("responseType", playbookQuestion.responseType)
      setOptions(playbookQuestion.answerList ? playbookQuestion.answerList : [])
    } else {
      setIsEditMode(false)
      setPlaybookQuestion(initPlaybookQuestion)
      setValue("title", "")
      setValue("responseType", PLAYBOOK_RESPONSE_QUESTION_TYPE.SHORT_TEXT)
      setOptions([])
    }
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (event) => {
    setValue("responseType", event.target.value)
  }

  const handleAction = async (data) => {
    if (isEditMode) {
      // update question title only
      setLoading(true)
      const response = await editProgramPlaybookQuestionEditTitle({
        id: playbookQuestion.id,
        title: data.title,
      })
      setLoading(false)
      verifyResponse(response)
      if (response.data.rtnCode === 200) {
        enqueueSnackbar("Update Playbook Question Succeed", {
          variant: "success",
        })
        refreshPlaybook()
        setOpen(false)
      } else {
        enqueueSnackbar("Update Playbook Question Fail", {
          variant: "warning",
        })
      }
    } else {
      // create question with answer if exist
      setLoading(true)
      const response = await addProgramPlaybookQuestion({
        playbookId: playbookId,
        answerType: data.responseType,
        title: data.title,
        answerList: options,
      })
      setLoading(false)
      verifyResponse(response)

      if (response.data.rtnCode === 200) {
        enqueueSnackbar("Create Playbook Question Succeed", {
          variant: "success",
        })
        refreshPlaybook()
        setOpen(false)
      } else {
        enqueueSnackbar("Create Playbook Question Fail", {
          variant: "warning",
        })
      }
    }
  }

  const addDropdownOption = (key) => {
    if (key && key.trim() !== "") {
      if (
        watch("hasOther") &&
        options.filter((option) => option.otherFlag).length > 0
      ) {
        enqueueSnackbar("Only one other type of option allow", {
          variant: "warning",
        })
        return
      }

      setOptions([
        ...options,
        {
          title: key,
          otherFlag: watch("hasOther") ? 1 : 0,
        },
      ])
    }
  }

  const removeItemByIndex = (array, index) => {
    // Make a copy of the array to avoid modifying the original array
    let newArray = array.slice()
    // Remove the item at the specified index
    newArray.splice(index, 1)
    // Return the new array
    return newArray
  }

  const removeDropdownOption = (index) => {
    setOptions(removeItemByIndex(options, index))
  }

  const dlg = (
    <StyledProgramPlaybookQuestionDialog open={open}>
      <DialogTitle>Playbook</DialogTitle>
      <form onSubmit={handleSubmit(handleAction)}>
        <DialogContent>
          <Box>
            <FormControl fullWidth>
              <TextField
                autoComplete="off"
                margin="dense"
                id="program-playbook-title"
                label="Question"
                fullWidth
                multiline
                rows={4}
                defaultValue={playbookQuestion.title}
                {...register("title", { required: true })}
                error={!!errors?.question}
                variant="standard"
              />
            </FormControl>
          </Box>

          <Box>
            <FormControl sx={{ m: 1, minWidth: 220 }}>
              <InputLabel id="program-type-select-label">
                Answer Type
              </InputLabel>
              <Select
                labelId="program-type-select"
                id="program-type-select"
                defaultValue={playbookQuestion.answerType}
                disabled={isEditMode}
                {...register("responseType", {
                  required: isEditMode ? false : true,
                })}
                label="Answer Type"
                onChange={handleChange}
              >
                {PLAYBOOK_RESPONSE_QUESTION_TYPE_DICT.map((responseType) => {
                  return (
                    <MenuItem
                      value={responseType.VALUE}
                      key={`playbook-response-key-${responseType.VALUE}`}
                    >
                      {responseType.TEXT}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Box>

          {(watch("responseType") ===
            PLAYBOOK_RESPONSE_QUESTION_TYPE.CHECKBOX ||
            watch("responseType") ===
              PLAYBOOK_RESPONSE_QUESTION_TYPE.DROPDOWN) && (
            <Box>
              {!isEditMode && (
                <>
                  <StyledInputLabel>Add Dropdown Options</StyledInputLabel>
                  <div className="answer-option-text-input">
                    <FormControl fullWidth>
                      <TextField
                        autoFocus
                        autoComplete="off"
                        margin="dense"
                        id="program-playbook-question-answer-option"
                        label="Answer Option"
                        fullWidth
                        variant="standard"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            addDropdownOption(e.target.value)
                            e.target.value = ""
                            e.preventDefault()
                          }
                        }}
                      />
                    </FormControl>

                    <FormControlLabel
                      sx={{ width: "150px", marginLeft: "0px" }}
                      control={
                        <Checkbox
                          {...register("hasOther", { required: false })}
                        />
                      }
                      label="Has Other"
                    />
                  </div>
                </>
              )}

              {options.map((option, index) => {
                return (
                  <div
                    className="answer-option"
                    key={`answer-option-index-${index}`}
                  >
                    <div>
                      {option.otherFlag ? (
                        <Chip
                          label="Other Option"
                          color="success"
                          size="small"
                        />
                      ) : (
                        <Chip
                          label="Normal Option"
                          color="primary"
                          size="small"
                        />
                      )}
                      {option.title}
                    </div>
                    <Tooltip title="Remove">
                      <IconButton
                        aria-label="remove answer option"
                        sx={{ color: "#4e1c1c" }}
                        component="label"
                        onClick={() => {
                          removeDropdownOption(index)
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )
              })}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
          <Button type="submit">
            {isEditMode ? "Update Playbook" : "Create Playbook"}
          </Button>
        </DialogActions>
      </form>
    </StyledProgramPlaybookQuestionDialog>
  )

  return [openDlg, dlg, loading]
}

export default ProgramPlaybookQuestionDialog
