import { httpPost } from "utils/httpRequest"

// general stat data
const getAnalyticData = async ({ startAt, endAt }) => {
  const resp = await httpPost("admin/stat/analyticData", { startAt, endAt })
  return resp.data
}

const getAnalyticSubscribeDetail = async ({
  startAt,
  endAt,
  queryType,
  localTimeZone,
  freeTrialLength,
}) => {
  const resp = await httpPost("admin/stat/analyticSubscribeDetail", {
    startAt,
    endAt,
    queryType,
    localTimeZone,
    freeTrialLength,
  })
  return resp.data
}

const getAnalyticSubscriptionByDay = async ({
  startAt,
  endAt,
  localTimeZone,
  freeTrialLength,
}) => {
  const resp = await httpPost("admin/stat/analyticSubscriptionByDay", {
    startAt,
    endAt,
    localTimeZone,
    freeTrialLength,
  })
  return resp.data
}

// get Marketo Sync Log
const getMarketoSyncLog = async ({ pageNo, pageSize, startAt, endAt }) => {
  const resp = await httpPost("admin/member/marketo/log/list", {
    pageNo,
    pageSize,
    startAt,
    endAt,
  })
  return resp.data
}

// Sync Action Marketo
const syncToMarketo = async ({ memberIdList }) => {
  const resp = await httpPost("admin/member/syncToMarketo", {})
  return resp.data
}

// Sync Action Marketo by User Id
const syncToMarketoByUserId = async (userId) => {
  const resp = await httpPost("admin/member/syncToMarketo", {
    memberIdList: [userId],
  })
  return resp.data
}

// Get average completed Innercise of users
const getAvgCompletedInnercise = async ({
  startAt,
  endAt,
  intervalValue,
  limitSize,
}) => {
  const resp = await httpPost("admin/stat/averageCompletedInnercise", {
    startAt,
    endAt,
    intervalValue,
    limitSize,
  })
  return resp.data
}

// Get mean of completed Innercise of users
const getMeanCompletedInnercise = async ({
  startAt,
  endAt,
  intervalValue,
  limitSize,
}) => {
  const resp = await httpPost("admin/stat/meanCompletedInnercise", {
    startAt,
    endAt,
    intervalValue,
    limitSize,
  })
  return resp.data
}

// Get completed Innercise of users
const getCompletedInnercise = async ({
  startAt,
  endAt,
  intervalValue,
  limitSize,
}) => {
  const resp = await httpPost("admin/stat/completedInnercise", {
    startAt,
    endAt,
    intervalValue,
    limitSize,
  })
  return resp.data
}

// Get most popular categories
const getMostPopularCategory = async ({
  startAt,
  endAt,
  intervalValue,
  limitSize,
}) => {
  const resp = await httpPost("admin/stat/mostPopularCategory", {
    startAt,
    endAt,
    intervalValue,
    limitSize,
  })
  return resp.data
}

// Get most popular categories by views
const getMostPopularCategoryByViews = async ({ startAt, endAt, limitSize }) => {
  const resp = await httpPost("admin/stat/mostPopularCategoryByViews", {
    startAt,
    endAt,
    limitSize,
  })
  return resp.data
}

// Get most popular categories by spent
const getMostPopularCategoryBySpent = async ({ startAt, endAt, limitSize }) => {
  const resp = await httpPost("admin/stat/mostPopularCategoryBySpent", {
    startAt,
    endAt,
    limitSize,
  })
  return resp.data
}

// Get most popular innercise
const getMostPopularInnercise = async ({
  startAt,
  endAt,
  intervalValue,
  limitSize,
}) => {
  const resp = await httpPost("admin/stat/mostPopularInnercise", {
    startAt,
    endAt,
    intervalValue,
    limitSize,
  })
  return resp.data
}

// Get most popular innercise
const getMostPopularInnerciseByViews = async ({
  startAt,
  endAt,
  limitSize,
}) => {
  const resp = await httpPost("admin/stat/mostPopularInnerciseByViews", {
    startAt,
    endAt,
    limitSize,
  })
  return resp.data
}

// Get most popular innercise
const getMostPopularInnerciseBySpent = async ({
  startAt,
  endAt,
  limitSize,
}) => {
  const resp = await httpPost("admin/stat/mostPopularInnerciseBySpent", {
    startAt,
    endAt,
    limitSize,
  })
  return resp.data
}

// Get most reviewed programs
const getMostReviewedPrograms = async ({
  startAt,
  endAt,
  intervalValue,
  limitSize,
}) => {
  const resp = await httpPost("admin/stat/mostReviewedPrograms", {
    startAt,
    endAt,
    intervalValue,
    limitSize,
  })
  return resp.data
}

// Get most reviewed programs
const getRemindTimeStats = async ({
  startAt,
  endAt,
  intervalValue,
  limitSize,
}) => {
  const resp = await httpPost("admin/stat/remindTimeStats", {
    startAt,
    endAt,
    intervalValue,
    limitSize,
  })
  return resp.data
}

// Get most reviewed programs
const getInnerciseTimeStats = async ({
  startAt,
  endAt,
  intervalValue,
  limitSize,
}) => {
  const resp = await httpPost("admin/stat/innerciseTimeStats", {
    startAt,
    endAt,
    intervalValue,
    limitSize,
  })
  return resp.data
}

// Get Paywall Data
const getPaywallData = async ({ startAt, endAt, localTimeZone }) => {
  const resp = await httpPost("admin/stat/queryPaywallData", {
    startAt,
    endAt,
    localTimeZone,
  })
  return resp.data
}

export {
  getAnalyticData,
  getAnalyticSubscribeDetail,
  getAnalyticSubscriptionByDay,
  getMarketoSyncLog,
  syncToMarketo,
  syncToMarketoByUserId,
  getAvgCompletedInnercise,
  getMeanCompletedInnercise,
  getCompletedInnercise,
  getMostPopularCategory,
  getMostPopularCategoryByViews,
  getMostPopularCategoryBySpent,
  getMostPopularInnercise,
  getMostPopularInnerciseByViews,
  getMostPopularInnerciseBySpent,
  getMostReviewedPrograms,
  getRemindTimeStats,
  getInnerciseTimeStats,
  getPaywallData,
}
