import styled from "@emotion/styled";
import { Dialog } from "@mui/material";

const StyledSubscriptionGroupDlg = styled(Dialog)`
  /* display: flex; */
  .MuiPaper-root {
    min-width: 600px;
    min-height: 360px;
  }
  .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
  }

  .MuiFormControl-root {
    margin: 0 0 30px 0px;
    width: 100%;

    &:last-of-type {
      margin: 0;
    }
  }
`;
const StyledSubscriptionGroup = styled.div`
  display: flex;

  .empty-group-des {
    padding: 20px;
    border: 1px solid #ffe2e2;
    background-color: #cdfffa;
    border-radius: 4px;
    width: 100%;
    font-size: 16px;
  }
`;

export { StyledSubscriptionGroupDlg, StyledSubscriptionGroup };
