const obj = {
  rtnCode: 200,
  data: [
    {
      remindTime: "02:01 PM",
      totalCount: 1,
    },
    {
      remindTime: "02:56 PM",
      totalCount: 1,
    },
    {
      remindTime: "03:00 PM",
      totalCount: 1,
    },
    {
      remindTime: "03:15 PM",
      totalCount: 1,
    },
    {
      remindTime: "03:16 pm",
      totalCount: 1,
    },
    {
      remindTime: "03:30 PM",
      totalCount: 2,
    },
    {
      remindTime: "04:12 PM",
      totalCount: 1,
    },
    {
      remindTime: "04:15 PM",
      totalCount: 5,
    },
    {
      remindTime: "04:20 PM",
      totalCount: 1,
    },
    {
      remindTime: "04:24 PM",
      totalCount: 1,
    },
    {
      remindTime: "04:30 PM",
      totalCount: 1,
    },
    {
      remindTime: "04:45 PM",
      totalCount: 2,
    },
    {
      remindTime: "05:00 AM",
      totalCount: 1,
    },
    {
      remindTime: "05:00 PM",
      totalCount: 2,
    },
    {
      remindTime: "05:01 PM",
      totalCount: 1,
    },
    {
      remindTime: "05:03 PM",
      totalCount: 1,
    },
    {
      remindTime: "05:19 PM",
      totalCount: 1,
    },
    {
      remindTime: "05:30 AM",
      totalCount: 1,
    },
    {
      remindTime: "05:30 PM",
      totalCount: 1,
    },
    {
      remindTime: "05:45 PM",
      totalCount: 1,
    },
    {
      remindTime: "06:00 AM",
      totalCount: 1,
    },
    {
      remindTime: "06:04 PM",
      totalCount: 1,
    },
    {
      remindTime: "06:15 AM",
      totalCount: 1,
    },
    {
      remindTime: "06:26 PM",
      totalCount: 1,
    },
    {
      remindTime: "06:28 PM",
      totalCount: 1,
    },
    {
      remindTime: "06:30 AM",
      totalCount: 2,
    },
    {
      remindTime: "06:30 PM",
      totalCount: 1,
    },
    {
      remindTime: "06:31 AM",
      totalCount: 1,
    },
    {
      remindTime: "06:35 am",
      totalCount: 1,
    },
    {
      remindTime: "06:55 PM",
      totalCount: 1,
    },
    {
      remindTime: "07:00 AM",
      totalCount: 2,
    },
    {
      remindTime: "07:13 AM",
      totalCount: 1,
    },
    {
      remindTime: "07:15 AM",
      totalCount: 5,
    },
    {
      remindTime: "07:30 AM",
      totalCount: 2,
    },
    {
      remindTime: "07:35 PM",
      totalCount: 1,
    },
    {
      remindTime: "07:37 AM",
      totalCount: 1,
    },
    {
      remindTime: "07:45 AM",
      totalCount: 6,
    },
    {
      remindTime: "07:57 AM",
      totalCount: 1,
    },
    {
      remindTime: "08:00 AM",
      totalCount: 2,
    },
    {
      remindTime: "08:00 PM",
      totalCount: 1,
    },
    {
      remindTime: "08:04 PM",
      totalCount: 1,
    },
    {
      remindTime: "08:11 AM",
      totalCount: 1,
    },
    {
      remindTime: "08:12 AM",
      totalCount: 1,
    },
    {
      remindTime: "08:15 AM",
      totalCount: 2,
    },
    {
      remindTime: "08:15 PM",
      totalCount: 1,
    },
    {
      remindTime: "08:18 PM",
      totalCount: 1,
    },
    {
      remindTime: "08:20 AM",
      totalCount: 1,
    },
    {
      remindTime: "08:29 AM",
      totalCount: 1,
    },
    {
      remindTime: "08:30 AM",
      totalCount: 1,
    },
    {
      remindTime: "08:30 PM",
      totalCount: 1,
    },
    {
      remindTime: "08:40 PM",
      totalCount: 1,
    },
    {
      remindTime: "08:45 AM",
      totalCount: 2,
    },
    {
      remindTime: "08:45 PM",
      totalCount: 3,
    },
    {
      remindTime: "08:52 PM",
      totalCount: 1,
    },
    {
      remindTime: "09:00 AM",
      totalCount: 2,
    },
    {
      remindTime: "09:00 PM",
      totalCount: 1,
    },
    {
      remindTime: "09:06 PM",
      totalCount: 1,
    },
    {
      remindTime: "09:11 PM",
      totalCount: 1,
    },
    {
      remindTime: "09:15 AM",
      totalCount: 1,
    },
    {
      remindTime: "09:15 PM",
      totalCount: 3,
    },
    {
      remindTime: "09:16 AM",
      totalCount: 1,
    },
    {
      remindTime: "09:28 PM",
      totalCount: 1,
    },
    {
      remindTime: "09:30 AM",
      totalCount: 2,
    },
    {
      remindTime: "09:30 PM",
      totalCount: 1,
    },
    {
      remindTime: "09:31 PM",
      totalCount: 1,
    },
    {
      remindTime: "09:45 PM",
      totalCount: 4,
    },
    {
      remindTime: "09:50 PM",
      totalCount: 1,
    },
    {
      remindTime: "09:54 PM",
      totalCount: 1,
    },
    {
      remindTime: "10:00 AM",
      totalCount: 3,
    },
    {
      remindTime: "10:00 PM",
      totalCount: 1,
    },
    {
      remindTime: "10:03 AM",
      totalCount: 1,
    },
    {
      remindTime: "10:06 PM",
      totalCount: 1,
    },
    {
      remindTime: "10:15 PM",
      totalCount: 2,
    },
    {
      remindTime: "10:17 AM",
      totalCount: 1,
    },
    {
      remindTime: "10:19 PM",
      totalCount: 1,
    },
    {
      remindTime: "10:30 AM",
      totalCount: 1,
    },
    {
      remindTime: "10:31 AM",
      totalCount: 1,
    },
    {
      remindTime: "10:32 AM",
      totalCount: 1,
    },
    {
      remindTime: "10:41 AM",
      totalCount: 1,
    },
    {
      remindTime: "10:45 AM",
      totalCount: 1,
    },
    {
      remindTime: "10:55 AM",
      totalCount: 1,
    },
    {
      remindTime: "11:00 am",
      totalCount: 1,
    },
    {
      remindTime: "11:00 AM",
      totalCount: 1,
    },
    {
      remindTime: "11:00 PM",
      totalCount: 1,
    },
    {
      remindTime: "11:17 AM",
      totalCount: 1,
    },
    {
      remindTime: "11:30 AM",
      totalCount: 2,
    },
    {
      remindTime: "11:45 AM",
      totalCount: 1,
    },
    {
      remindTime: "12:00 AM",
      totalCount: 1,
    },
    {
      remindTime: "12:03 PM",
      totalCount: 1,
    },
    {
      remindTime: "12:15 PM",
      totalCount: 1,
    },
    {
      remindTime: "12:19 PM",
      totalCount: 1,
    },
    {
      remindTime: "12:30 AM",
      totalCount: 1,
    },
    {
      remindTime: "12:45 PM",
      totalCount: 1,
    },
  ],
  message: "Success",
}

const meanOfCompletedInnerciseToBarChar = ({ list, interval }) => {
  const returnData = {
    title: "Completed Innercises Disputing",
    xNameKey: "name",
    bars: [
      {
        name: "Num of User",
        fillColor: "#8884d8",
      },
    ],
    list: list.map((item) => {
      return {
        name: `${item.start}-${item.end}`,
        "Num of User": item.memberCount,
      }
    }),
  }

  return returnData
}

const completedInnerciseToBarChar = ({ list }) => {
  const displayList = []
  const maxInnerciseCount =
    list.length > 0 ? list[list.length - 1].innerciseCount : 0

  for (let i = 1; i <= maxInnerciseCount; i++) {
    const target = list.find((item) => parseInt(item.innerciseCount) === i)
    target
      ? displayList.push({
          innerciseCount: parseInt(target.innerciseCount),
          memberCount: parseInt(target.memberCount),
        })
      : displayList.push({
          innerciseCount: i,
          memberCount: 0,
        })
  }

  const returnData = {
    title: "Completed Innercises Disputing",
    xNameKey: "name",
    bars: [
      {
        name: "Num of User",
        fillColor: "#8884d8",
      },
    ],
    list: displayList.map((item) => {
      return {
        name: item.innerciseCount,
        "Num of User": item.memberCount,
      }
    }),
  }

  return returnData
}

const RemindTimeToBarChart = (list) => {
  const returnData = {
    title: "Remind Time Distribution",
    xNameKey: "name",
    bars: [
      {
        name: "Users",
        fillColor: "#8884d8",
      },
    ],
    list: list.map((item) => {
      return {
        name: item.remindTime,
        Users: item.totalCount,
      }
    }),
  }

  return returnData
}

const InnerciseTimeStatsToBarChart = (list) => {
  const returnData = {
    title: "Innercise Time Distribution",
    xNameKey: "Time",
    bars: [
      {
        name: "Innercises",
        fillColor: "#8884d8",
      },
    ],
    list: list.map((item) => {
      return {
        Time: `${item.start}-${item.end}`,
        Innercises: item.memberCount,
      }
    }),
  }

  return returnData
}

export {
  completedInnerciseToBarChar,
  meanOfCompletedInnerciseToBarChar,
  RemindTimeToBarChart,
  InnerciseTimeStatsToBarChart,
}
