import { httpPost } from "utils/httpRequest"

const getRailGroups = async ({ status }) => {
  const resp = await httpPost("admin/rail/group/list", {})
  return resp.data
}

const addRailGroup = async ({ name, groupType, rule, isShow }) => {
  const resp = await httpPost("admin/rail/group/add", {
    name,
    groupType,
    rule,
    isShow,
  })
  return resp.data
}

const editRailGroup = async ({ name, groupType, rule, isShow, id }) => {
  const resp = await httpPost("admin/rail/group/edit", {
    name,
    groupType,
    rule,
    isShow,
    id,
  })
  return resp.data
}

const deleteRailGroup = async ({ id }) => {
  const resp = await httpPost("admin/rail/group/delete", {
    id,
  })
  return resp.data
}

const addRailIntoGroup = async ({ groupId, railIdList }) => {
  const resp = await httpPost("admin/rail/group/rail/add", {
    groupId,
    railIdList,
  })
  return resp.data
}

const editRailIntoGroup = async ({ groupId, railIdList }) => {
  const resp = await httpPost("admin/rail/group/rail/edit", {
    groupId,
    railIdList,
  })
  return resp.data
}

const showGroup = async ({ id }) => {
  const resp = await httpPost("admin/rail/group/show", {
    id,
  })
  return resp.data
}

const hideGroup = async ({ id }) => {
  const resp = await httpPost("admin/rail/group/hide", {
    id,
  })
  return resp.data
}

const exchangeGroupIndex = async ({ updateIndexArray }) => {
  const resp = await httpPost("admin/rail/group/exchangeIndex", {
    updateIndexArray,
  })
  return resp.data
}

const getRails = async ({ groupId }) => {
  const resp = await httpPost("admin/rail/list", {
    groupId,
  })
  return resp.data
}

const addRail = async ({
  name,
  railType,
  uiType,
  railTag,
  description,
  groupId,
  categoryIdList,
}) => {
  const resp = await httpPost("admin/rail/add", {
    name,
    railType,
    railTag,
    uiType,
    description,
    groupId,
    categoryIdList,
  })
  return resp.data
}

const editRail = async ({
  name,
  railType,
  railTag,
  uiType,
  description,
  groupId,
  categoryIdList,
  id,
}) => {
  const resp = await httpPost("admin/rail/edit", {
    name,
    railType,
    railTag,
    uiType,
    description,
    groupId,
    categoryIdList,
    id,
  })
  return resp.data
}

const deleteRail = async ({ id }) => {
  const resp = await httpPost("admin/rail/delete", {
    id,
  })
  return resp.data
}

const exchangeRailIndex = async ({ updateIndexArray }) => {
  const resp = await httpPost("admin/rail/exchangeIndex", {
    updateIndexArray,
  })
  return resp.data
}

const getRailResourceByRailId = async ({
  pageNo,
  pageSize,
  railId,
  dataType,
  isHome,
}) => {
  const resp = await httpPost("admin/rail/resource/PageList", {
    pageNo,
    pageSize,
    railId,
    dataType,
    isHome,
  })
  return resp.data
}

const addRailResource = async ({ railId, dataType, dataId, isHome }) => {
  const resp = await httpPost("admin/rail/resource/add", {
    railId,
    dataType,
    dataId,
    isHome,
  })
  return resp.data
}

const editRailResource = async ({ railId, dataType, dataId, isHome, id }) => {
  const resp = await httpPost("admin/rail/resource/edit", {
    railId,
    dataType,
    dataId,
    isHome,
    id,
  })
  return resp.data
}

const deleteRailResource = async ({ id }) => {
  const resp = await httpPost("admin/rail/resource/delete", {
    id,
  })
  return resp.data
}

const exchangeRailResourceIndex = async ({ updateIndexArray }) => {
  const resp = await httpPost("admin/rail/resource/exchangeIndex", {
    updateIndexArray,
  })
  return resp.data
}

export {
  getRailGroups,
  addRailGroup,
  editRailGroup,
  deleteRailGroup,
  showGroup,
  hideGroup,
  exchangeGroupIndex,
  addRailIntoGroup,
  editRailIntoGroup,
  getRails,
  addRail,
  editRail,
  deleteRail,
  exchangeRailIndex,
  getRailResourceByRailId,
  addRailResource,
  editRailResource,
  deleteRailResource,
  exchangeRailResourceIndex,
}
