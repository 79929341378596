import React, { useState, useEffect, useMemo } from "react"
import PageHeading from "components/common/LayoutComponents/PageHeading"
import PageContent from "components/common/LayoutComponents/PageContent"
import Button from "@mui/material/Button"
import LoadingScreen from "components/common/LoadingScreen"
import { verifyResponse } from "utils/httpRequest"
import { getSystemConfigs } from "services/systemConfig"
import { UsePermissionVerification } from "components/customHooks"
import { PERMISSIONS } from "constants/permission"
import Editor from "@monaco-editor/react"
import SystemConfigDlg from "./SystemConfigDlg"
import { SystemConfigWrapper } from "./SystemConfig.style"

const SystemConfig = () => {
  const [configs, setConfigs] = useState([])
  const [loading, setLoading] = useState(false)
  const [displayConfig, setDisplayConfig] = useState(null)

  const [
    openSystemConfigDialog,
    systemConfigDialog,
    systemConfigDialogLoading,
  ] = SystemConfigDlg({
    onSubmit: () => {
      loadConfigs()
    },
  })

  const AccessControl = UsePermissionVerification()

  const loadConfigs = async () => {
    setLoading(true)
    const response = await getSystemConfigs()
    setLoading(false)
    verifyResponse(response)
    setConfigs(response.data)
  }

  const CodeDisplay = ({ access }) => {
    return displayConfig ? (
      <>
        <div className="display-config-header">
          <div className="heading">Json Editor</div>
          {access.editSystemConfig && (
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                openSystemConfigDialog(displayConfig)
              }}
            >
              Edit
            </Button>
          )}
        </div>
        <div className="display-code-info">
          <div className="name">
            <div className="label-text">Name</div>
            {displayConfig.name}
          </div>
          <div className="key">
            <div className="label-text">Key</div>
            {displayConfig.configKey}
          </div>
        </div>
        <Editor
          className="json-editor"
          theme="vs-dark"
          height="calc(100vh - 310px)"
          defaultLanguage="json"
          value={displayConfig.configValue}
          options={{ readOnly: true }}
        />
      </>
    ) : (
      <div>Please Select a Configuration ...</div>
    )
  }

  useEffect(() => {
    loadConfigs()
  }, [])

  useEffect(() => {
    AccessControl.SetAccess({
      ...AccessControl.Access,
      addSystemConfig: AccessControl.VerifyAccess([
        PERMISSIONS.SYSTEM_CONFIG.SYSTEM_CONFIG_ADD,
      ]),
      editSystemConfig: AccessControl.VerifyAccess([
        PERMISSIONS.SYSTEM_CONFIG.SYSTEM_CONFIG_EDIT,
      ]),
      deleteSystemConfig: AccessControl.VerifyAccess([
        PERMISSIONS.SYSTEM_CONFIG.SYSTEM_CONFIG_DELETE,
      ]),
    })
  }, [AccessControl.UserPermissions])

  return (
    <PageContent>
      <SystemConfigWrapper>
        <PageHeading>
          <div>System Settings</div>
          <div className="actions">
            {AccessControl.Access.addSystemConfig && (
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  openSystemConfigDialog()
                }}
              >
                Add System Config
              </Button>
            )}
          </div>
        </PageHeading>
        <div className="config-panel">
          <div className="config-list">
            {configs.map((config) => (
              <div
                className={`config-item ${
                  displayConfig &&
                  displayConfig.configKey === config.configKey &&
                  "selected"
                }`}
                onClick={() => {
                  setDisplayConfig(config)
                }}
              >
                <div className="info">
                  <div className="name">{config.name}</div>
                  <div className="key">{config.configKey}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="config-value-display">
            <CodeDisplay access={AccessControl.Access} />
          </div>
        </div>
      </SystemConfigWrapper>
      <LoadingScreen loading={loading || systemConfigDialogLoading} />
      {systemConfigDialog}
    </PageContent>
  )
}

export default SystemConfig
