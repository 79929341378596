import React, { useState, useEffect } from "react"
import moment from "moment"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { getMemberOrders } from "services/order"
import { getSubscriptionProducts } from "services/subscription"
import {
  cancelKeepSubscription,
  reactivateKeepSubscription,
} from "services/member"
import { verifyResponse } from "utils/httpRequest"
import {
  DURATION_UNIT_LIST,
  ORDER_STATUS,
  PAYMENT_TYPE,
  PAYMENT_TYPE_INDEX,
  SUBSCRIPTION_STATUS,
} from "constants/global"
import { StyledMemberSubscriptions } from "./Member.style"
import { Button } from "@mui/material"

const MemberSubscriptions = ({
  memberId,
  vipInfoId,
  payment,
  subscriptionStatus,
}) => {
  const [subscriptions, setSubscriptions] = useState([])
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)

  const loadMemberSubscription = async () => {
    setLoading(true)
    const response = await getMemberOrders(memberId)
    verifyResponse(response)
    setSubscriptions(response.data.list)
    setLoading(false)
  }

  const loadSubscriptionProduct = async () => {
    setLoading(true)
    const response = await getSubscriptionProducts()
    verifyResponse(response)
    setProducts(response.data)
    setLoading(false)
  }

  const getProductByCode = (code) => {
    return products.find((product) => product.code === code)
  }

  const cancelSubscription = async () => {
    setLoading(true)
    const response = await cancelKeepSubscription({ vipInfoId, memberId })
    setLoading(false)
    verifyResponse(response)
    loadSubscriptionProduct()
  }

  const reactivateSubscription = async () => {
    setLoading(true)
    const response = await reactivateKeepSubscription({ vipInfoId, memberId })
    setLoading(false)
    verifyResponse(response)
    loadSubscriptionProduct()
  }

  useEffect(() => {
    loadSubscriptionProduct()
    loadMemberSubscription()
  }, [])

  if (loading) {
    return (
      <Card className="card" sx={{ marginBottom: "10px" }}>
        <CardContent>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            <CircularProgress />
          </Typography>
        </CardContent>
      </Card>
    )
  }

  return (
    <StyledMemberSubscriptions>
      {subscriptions.length === 0 ? (
        <Card className="card" sx={{ marginBottom: "10px" }}>
          <CardContent>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              No subscription yet.
            </Typography>
          </CardContent>
        </Card>
      ) : (
        subscriptions.map((subscription, index) => {
          const product = getProductByCode(
            subscription.appleProductId || subscription.productCode
          )
          return (
            <div
              className="review"
              key={`subscription-item-${subscription.id}`}
            >
              <Card className="card" sx={{ marginBottom: "10px" }}>
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Subscription Order Number:
                  </Typography>
                  <Typography variant="h5" component="div">
                    {subscription.orderNo}
                  </Typography>

                  {/* Add Subscription Status Control Here */}
                  {/* payment === PAYMENT_TYPE_INDEX.INFUSION_SOFT && */}
                  {payment === PAYMENT_TYPE_INDEX.INFUSION_SOFT &&
                    index === 0 && (
                      <div>
                        <Button
                          className="can-btn"
                          variant="contained"
                          color="error"
                          onClick={() => {
                            console.log(
                              "subscriptionStatus: ",
                              subscriptionStatus
                            )
                            subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVATE
                              ? cancelSubscription()
                              : reactivateSubscription()
                          }}
                        >
                          {subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVATE
                            ? "Cancel Subscription"
                            : "Reactivated Subscription"}
                        </Button>
                      </div>
                    )}

                  <Box sx={{ flexGrow: 0.5 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Grid item xs={4} md={6}>
                          <Typography
                            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            Product:
                          </Typography>
                          <Typography variant="body2">
                            {product &&
                              product.groupName &&
                              product.name &&
                              `${product.groupName} ${product.name}`}
                          </Typography>
                        </Grid>

                        <Grid item xs={4} md={6}>
                          <Typography
                            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            Duration:
                          </Typography>
                          <Typography variant="body2">
                            {`${subscription.duration} ${
                              DURATION_UNIT_LIST.find(
                                (o) => o.value === subscription.durationUnit
                              ).text
                            }`}
                          </Typography>
                        </Grid>
                        {subscription.payment !== 0 &&
                          subscription.payment !== 2 &&
                          subscription.orderStatus === 1 && (
                            <Grid item xs={6}>
                              <Typography
                                sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
                                color="text.secondary"
                                gutterBottom
                              >
                                Transaction ID:
                              </Typography>
                              <Typography variant="body2">
                                {subscription.transactionId}
                              </Typography>
                            </Grid>
                          )}

                        {subscription.orderStatus === 1 && (
                          <Grid item xs={6}>
                            <Typography
                              sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              Effect Date:
                            </Typography>
                            <Typography variant="body2">
                              {`${moment(subscription.thirdStartAt).format(
                                "LL"
                              )} to ${moment(subscription.thirdEndAt).format(
                                "LL"
                              )}
                              `}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>

                      <Grid item xs={4} md={6}>
                        {subscription.orderStatus === 0 && (
                          <Grid item xs={6}>
                            <Typography
                              sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              Order Date:
                            </Typography>
                            <Typography variant="body2">
                              {moment(subscription.createdAtStamp).format(
                                "LLL"
                              )}
                            </Typography>
                          </Grid>
                        )}

                        {subscription.orderStatus === 1 && (
                          <Grid item xs={6}>
                            <Typography
                              sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              Order Paid At:
                            </Typography>
                            <Typography variant="body2">
                              {moment(subscription.payAtStamp).format("LLL")}
                            </Typography>
                          </Grid>
                        )}

                        <Grid item xs={6}>
                          <Typography
                            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            Order Status:
                          </Typography>
                          <Typography variant="body2">
                            {ORDER_STATUS[subscription.orderStatus]}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            Payment:
                          </Typography>
                          <Typography variant="body2">
                            {subscription.promotionDetailType !== 1 ? (
                              <>
                                {`${subscription.orderCurrency.toUpperCase()} ${
                                  subscription.orderPrice
                                }`}
                                <br />
                                {subscription.orderStatus === 1 &&
                                  `Payment from ${
                                    PAYMENT_TYPE[subscription.payment]
                                  }`}
                              </>
                            ) : (
                              <>Free Trail</>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </div>
          )
        })
      )}
    </StyledMemberSubscriptions>
  )
}

export default MemberSubscriptions
