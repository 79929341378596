import React, { useState, useRef, useEffect } from "react"
import Button from "@mui/material/Button"
import DeleteIcon from "@mui/icons-material/Delete"
import SendIcon from "@mui/icons-material/Send"
import MediaDropZone from "./MediaDropZone"
import MediaUploadCard from "./MediaUploadCard"
import { generateUniqueString } from "utils/media"
import { getRandomInt } from "utils/number"
import { getCMSConfig } from "services/config"
import { verifyResponse } from "utils/httpRequest"
import {
  StyledFileUploadCardZone,
  StyledMediaUploadControlPanel,
} from "./MediaManager.style"

const MediaUpload = () => {
  const [cmsConfig, setCmsConfig] = useState({})
  const [uploadList, setUploadList] = useState([])
  const uploadListRef = useRef(null)
  uploadListRef.current = uploadList

  //generateUniqueString
  const onDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((newFile) => {
      newFile.key = generateUniqueString()
      newFile.validated = false
      newFile.uploaded = false
      newFile.progress = 0
      newFile.uploading = false
      newFile.duration = 0
      newFile.uploaded = false
      newFile.uploadName = ""
      newFile.description = ""

      return newFile
    })
    setUploadList([...uploadList, ...newFiles])
  }

  const initProcess = async () => {
    // init object, get S3 config variables
    const response = await getCMSConfig()
    verifyResponse(response)

    const config = {
      S3_KEY_ID: response.data.REACT_APP_S3_KEY_ID,
      S3_ACCESS_KEY: response.data.REACT_APP_S3_ACCESS_KEY,
      S3_PRIVATE_BUCKET: response.data.REACT_APP_S3_PRIVATE_BUCKET,
      S3_PUBLIC_BUCKET: response.data.REACT_APP_S3_PUBLIC_BUCKET,
      S3_UPLOAD_BUCKET: response.data.REACT_APP_S3_UPLOAD_BUCKET,
      S3_REGION: response.data.REACT_APP_S3_REGION,
    }
    setCmsConfig(config)
  }

  useEffect(() => {
    initProcess()
  }, [])

  return (
    <div>
      <MediaDropZone onDrop={onDrop} />
      {uploadList.length !== 0 && (
        <StyledMediaUploadControlPanel>
          <div>{`${uploadList.length} Medias Waiting for upload`}</div>
          <div>
            <>
              <span>{uploadList.filter((file) => file.validated).length}</span>
              <Button
                variant="contained"
                endIcon={<SendIcon />}
                onClick={() => {
                  document
                    .querySelectorAll(".file-upload-btn.validated")
                    .forEach((obj) => obj.click())
                }}
              >
                Start All Ready
              </Button>
              <Button
                variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  document
                    .querySelectorAll(".file-remove-btn.uploadSucceeded")
                    .forEach((obj) => obj.click())
                }}
              >
                Delete Finished
              </Button>
            </>
          </div>
        </StyledMediaUploadControlPanel>
      )}
      <StyledFileUploadCardZone>
        {uploadList.map((uploadItem, key) => (
          <MediaUploadCard
            key={`upload-file-${getRandomInt(1, 999999)}`}
            index={key}
            cmsConfig={cmsConfig}
            uploadFile={uploadItem}
            uploadList={uploadList}
            setUploadList={setUploadList}
          />
        ))}
      </StyledFileUploadCardZone>
    </div>
  )
}

export default MediaUpload
