import React, { useEffect, useState } from "react"
import Fab from "@mui/material/Fab"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import AddIcon from "@mui/icons-material/Add"
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications"
import DeleteIcon from "@mui/icons-material/Delete"
import LoadingScreen from "components/common/LoadingScreen"
import { Container, Draggable } from "react-smooth-dnd"
import { getRails, deleteRail, exchangeRailIndex } from "services/rails"
import { UseConfirmDialog } from "components/customHooks"
import { verifyResponse } from "utils/httpRequest"
import { calculateNewRank } from "utils/data"
import { useSnackbar } from "notistack"
import { RAIL_TAG_INDEX, RAIL_UI_TYPE_INDEX } from "constants/global"
import RailDlg from "./RailDlg"
import { RailManagementWrapper } from "./RailManagement.style"

const RailManagement = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [rails, setRails] = useState([])
  const [openRailDlg, railDlgContent, railDlgLoading] = RailDlg({
    onSubmit: () => {
      loadRails()
    },
  })

  const [ConfirmDialog, openConfirmDialog] = UseConfirmDialog({
    title: "Delete Rail Confirm",
    description: "Are you sure want delete this Rail?",
    agreeText: "Yes",
    disagreeText: "No",
  })

  const loadRails = async () => {
    setLoading(true)
    const response = await getRails({})
    setLoading(false)
    verifyResponse(response)
    setRails(response.data)
  }

  const deleteRailItem = async (railId) => {
    setLoading(true)
    const response = await deleteRail({
      id: railId,
    })
    setLoading(false)
    verifyResponse(response)
    loadRails()

    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar("Rial Delete success", {
        variant: "success",
      })
    } else {
      // handling error message
      enqueueSnackbar("Rial Delete Fail", {
        variant: "warning",
      })
    }
  }

  useEffect(() => {
    loadRails()
  }, [])

  const RailContentTemplate = ({ rail, key }) => {
    return (
      <div key={key} className="rail-section">
        <div className="content">
          <div className="info-1">
            <div className="info-section">
              <div className="label">Name</div>
              <strong>{rail.name}</strong>
            </div>
            <div className="info-section">
              <div className="label">Rail Type</div>
              {RAIL_TAG_INDEX[rail.railTag]}
            </div>
            <div className="info-section">
              <div className="label">UI Type</div>
              {RAIL_UI_TYPE_INDEX[rail.uiType]}
            </div>
            <div className="info-section">
              <div className="label">Status</div>
              {rail.status === -1 ? "Deleted" : "Active"}
            </div>
          </div>

          <div className="info-2">
            <div className="heading">Description</div>
            <p>{rail.description}</p>
          </div>

          <div className="info-3">
            <div className="heading">Category</div>
            <div className="category-section">
              {rail.categoryList.map((cate) => (
                <div
                  key={`template-category-item-${cate.id}`}
                  className="category-item"
                >
                  {cate.name}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="actions">
          <Tooltip title="Delete Rail">
            <IconButton
              sx={{ color: "#e20707" }}
              aria-label="remove rail"
              component="label"
              onClick={() => {
                openConfirmDialog(async () => {
                  await deleteRailItem(rail.id)
                })
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit Rail">
            <IconButton
              sx={{ color: "#b89797" }}
              aria-label="edit rail"
              component="label"
              onClick={() => {
                openRailDlg(rail)
              }}
            >
              <SettingsApplicationsIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    )
  }

  return (
    <RailManagementWrapper>
      <div className="header-bar">
        <span>Rail Management</span>
        <Fab color="primary" aria-label="add">
          <AddIcon
            onClick={() => {
              openRailDlg()
            }}
          />
        </Fab>
      </div>
      <div className="rails">
        <Container
          onDrop={async (e) => {
            const updateIndexArray = calculateNewRank({
              addedIndex: e.addedIndex,
              removedIndex: e.removedIndex,
              indexString: "railIndex",
              list: rails,
              updateOriginalList: setRails,
            })
            const response = await exchangeRailIndex({ updateIndexArray })
            loadRails()

            if (response.rtnCode === 200) {
              enqueueSnackbar("Rial Order update success", {
                variant: "success",
              })
            } else {
              enqueueSnackbar("Rial Order update fail", {
                variant: "warning",
              })
            }
          }}
        >
          {rails.map((rail) => (
            <Draggable key={`rail-content-template-${rail.id}`}>
              <RailContentTemplate rail={rail} />
            </Draggable>
          ))}
        </Container>
      </div>
      {ConfirmDialog}
      {railDlgContent}
      <LoadingScreen loading={loading || railDlgLoading} />
    </RailManagementWrapper>
  )
}

export default RailManagement
