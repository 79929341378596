import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Chip from "@mui/material/Chip";
import MediaContentDisplay from "components/common/MediaManager/MediaContentDisplay";
import { PrimeType, PrimeTypeIndex } from "constants/global";
import { useSnackbar } from "notistack";
import { StyledPrimeCard } from "./Prime.style";
import { deletePrime } from "services/prime";

const PrimeCard = ({
  prime,
  access,
  openPrimeDialog,
  refreshPrimes,
  openConfirmDialog,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const deletePrimeClick = async (id) => {
    const response = await deletePrime(id);
    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar("Prime Deleted", {
        variant: "success",
      });
    } else {
      // handling error message
      enqueueSnackbar("Prime Delete Fail", {
        variant: "warning",
      });
    }
    refreshPrimes();
  };

  return (
    <StyledPrimeCard>
      <h2 className="title">
        {prime.title || "N/A"}
        <Chip label={PrimeTypeIndex[prime.type]} />
      </h2>
      <div
        className={`preview ${
          prime.type === PrimeType.Quote.value && "content"
        }`}
      >
        <div className="content">
          <h4>Display Title</h4>
          <div className="display-title-content">{prime.displayTitle}</div>
        </div>

        <div className="content">
          <h4>Thumbnail</h4>
          <MediaContentDisplay resource={prime.thumbResourceDto} />
        </div>

        <div className="content">
          <h4>Resource / Quote Content</h4>
          {prime.type === PrimeType.Quote.value ? (
            <div className="quote-content">{prime.quoteContent}</div>
          ) : (
            <MediaContentDisplay resource={prime.resourceDto} />
          )}
        </div>
      </div>
      <div className="actions">
        {access.deletePrimeAccess && (
          <DeleteIcon
            className="icon-button red"
            role="button"
            title="Delete"
            onClick={() => {
              openConfirmDialog(() => {
                deletePrimeClick(prime.id);
              }, `Are you sure want to delete daily prime "${prime.title}"`);
            }}
          />
        )}
        {access.editPrimeAccess && (
          <EditIcon
            className="icon-button"
            title="Edit"
            role="button"
            onClick={() => {
              openPrimeDialog(prime);
            }}
          />
        )}
      </div>
    </StyledPrimeCard>
  );
};

export default PrimeCard;
