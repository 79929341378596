import styled from "@emotion/styled"

const StyledMarketPanel = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledSearch = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledSearchBar = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 30px);
  align-items: center;
  /* border: 1px solid #f9eded; */
  padding: 20px 15px;
  border-radius: 7px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

  .time-input {
    width: 200px;
  }
  .search-btn {
    padding: 14px 22px;
  }
  .MuiInputBase-root {
    margin-right: 10px;
  }
`

const StyledActionPanel = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 30px);
  align-items: center;
  /* border: 1px solid #f9eded; */
  padding: 20px 15px;
  border-radius: 7px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  margin-bottom: 20px;
`

const StyledResultTable = styled.div`
  margin-top: 20px;
  .pagination-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .search-result-info {
    background-color: white;
    padding: 15px 10px;
    margin: 15px 0;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 0px,
      rgb(0 0 0 / 10%) 0px 0px 1px 0px;
    font-size: 15px;
  }
`

export {
  StyledMarketPanel,
  StyledSearch,
  StyledSearchBar,
  StyledActionPanel,
  StyledResultTable,
}
