import React from "react";
import styled from "@emotion/styled";

const PageHeadingWrapper = styled.h1`
  /* border: 1px solid gray; */
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .actions {
    button {
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;

const PageHeading = (props) => {
  return <PageHeadingWrapper>{props.children}</PageHeadingWrapper>;
};

export default PageHeading;
