import styled from "@emotion/styled";

const StyledImageCrop = styled.div`
  display: flex;
  flex-direction: column;

  .preview {
    display: flex;
    flex-direction: column;
    h5 {
      font-size: 18px;
      font-weight: 200;
      color: #865e5e;
    }
    img {
      max-width: 200px !important;
      border-radius: 4px;
    }
  }
  .actions {
    display: flex;
    flex-direction: row;
    padding: 15px 0;
  }
`;

const StyledFileDropZone = styled.div`
  border: 1px dashed #d7c8c8;
  border-radius: 8px;
  margin: 13px 0;
  padding: 8px 35px;
  background-color: #01000005;
  font-size: 14px;
  color: gray;
  cursor: pointer;
`;

export { StyledImageCrop, StyledFileDropZone };
