import React from "react";
import moment from "moment";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
  DURATION_UNIT_LIST,
  ORDER_STATUS,
  PAYMENT_TYPE,
} from "constants/global";
import { StyledRedeemDetail } from "../Redeem/Redeem.style";

const OrderDetail = ({ order, subscriptionProduct, closeOrderDetail }) => {
  const content = (
    <StyledRedeemDetail>
      <Card sx={{ maxWidth: 500 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Order #
          </Typography>
          <Typography variant="h5" component="div">
            {order.orderNo}
          </Typography>

          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Transaction ID:
          </Typography>
          <Typography variant="body2">{order.transactionId} </Typography>

          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Member User:
          </Typography>
          <Typography variant="body2">
            {`${order.memberNickName}  (${order.memberEmail})`}
          </Typography>

          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Status:
          </Typography>
          <Typography variant="body2">
            {order.redeemStatus ? "Activated" : "Inactivated"}
          </Typography>
          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Product:
          </Typography>
          <Typography variant="body2">
            {`${
              subscriptionProduct.find((o) => o.code === order.productCode)
                ?.name
            }  (${order.productCode})`}
          </Typography>
          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Duration:
          </Typography>
          <Typography variant="body2">
            {`${order.duration} ${
              DURATION_UNIT_LIST.find((o) => o.value === order.durationUnit)
                .text
            }`}
          </Typography>
          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Order Price:
          </Typography>
          <Typography variant="body2">
            {`$${order.payPrice} ${order.payCurrency}`}
            <div> {`Payment from ${PAYMENT_TYPE[order.payment]}`}</div>
          </Typography>
          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Order Status:
          </Typography>
          <Typography variant="body2">
            {ORDER_STATUS[order.orderStatus]}
          </Typography>

          <Typography
            sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
            color="text.secondary"
          >
            Created Date:
          </Typography>
          <Typography variant="body2">
            {moment(order.createdAt).format("lll")}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            onClick={() => {
              closeOrderDetail();
            }}
          >
            Close
          </Button>
        </CardActions>
      </Card>
    </StyledRedeemDetail>
  );

  return content;
};

export default OrderDetail;
