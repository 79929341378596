import React, { useState } from "react";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { StyledReviewSearch, StyledReviewSearchBar } from "./Review.style";

const ReviewSearch = ({ setSearchOptions }) => {
  const [email, setEmail] = useState("");
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [pubStatus, setPubStatus] = useState(-1);
  const [pageSize, setPageSize] = useState(10);

  const clearSearch = () => {
    setEmail("");
    setDateRange([null, null]);
    setPubStatus(-1);
  };

  const searchAction = () => {
    const options = {
      pageNo: 1,
      pageSize: pageSize,
      pubStatus: pubStatus === -1 ? null : pubStatus,
      startAt:
        dateRange[0] && dateRange[1] ? moment(dateRange[0]).valueOf() : null,
      endAt:
        dateRange[0] && dateRange[1] ? moment(dateRange[0]).valueOf() : null,
      userEmail: email === "" ? null : email,
    };

    setSearchOptions(options);
  };

  return (
    <StyledReviewSearch>
      <StyledReviewSearchBar>
        <TextField
          className="email"
          autoFocus
          autoComplete="off"
          margin="dense"
          id="review-search-user-email"
          label="Email"
          type="text"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          variant="outlined"
        />
        <FormControl style={{ width: "150px" }}>
          <InputLabel id="media-status-select-label">Status</InputLabel>
          <Select
            labelId="media-status-select-label"
            id="media-status-select"
            value={pubStatus}
            label="Status"
            onChange={(e) => {
              setPubStatus(e.target.value);
            }}
          >
            <MenuItem value={-1}>All</MenuItem>
            <MenuItem value={0}>Unpublished</MenuItem>
            <MenuItem value={1}>Published</MenuItem>
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateRangePicker
            className="media-search-range-picker"
            startText="Date From"
            endText="Date To"
            value={dateRange}
            onOpen={() => {
              setTimeout(() => {
                [
                  ...document
                    .querySelector(".media-search-range-picker")
                    .querySelectorAll("div"),
                ]
                  .filter((a) => a.textContent === "MUI X: Missing license key")
                  .forEach((a) => a.remove());
              }, 0);
            }}
            onChange={(newValue) => {
              setDateRange(newValue);
            }}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} className="time-input" />
                <TextField {...endProps} className="time-input" />
              </>
            )}
          />
        </LocalizationProvider>
        <FormControl style={{ width: "150px" }}>
          <InputLabel id="media-page-size-select-label">Page Size</InputLabel>
          <Select
            labelId="media-page-size-select-label"
            id="media-page-size-select"
            value={pageSize}
            label="Page Size"
            onChange={(e) => {
              setPageSize(e.target.value);
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
        <Button
          className="search-btn"
          variant="contained"
          size="large"
          onClick={() => {
            searchAction();
          }}
        >
          Search
        </Button>
        <Button
          className="search-btn"
          variant=""
          size="large"
          onClick={clearSearch}
        >
          Clear
        </Button>
      </StyledReviewSearchBar>
    </StyledReviewSearch>
  );
};

export default ReviewSearch;
