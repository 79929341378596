import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PageHeading from "components/common/LayoutComponents/PageHeading";
import PageContent from "components/common/LayoutComponents/PageContent";
import LoadingScreen from "components/common/LoadingScreen";
import { PERMISSIONS } from "constants/permission";
import { UsePermissionVerification } from "components/customHooks";
import SubscriptionGroupDialog from "./SubscriptionGroupDlg";
import { getSubscriptionGroups } from "services/subscription";
import { verifyResponse } from "utils/httpRequest";
import { StyledSubscriptionGroup } from "./SubscriptionGroup.style";

const SubscriptionGroup = () => {
  const [loading, setLoading] = useState(false);
  const [subscriptionGroups, setSubscriptionGroup] = useState([]);

  const AccessControl = UsePermissionVerification();

  const [
    openSubscriptionGroupDlg,
    subscriptionGroupDlg,
    subscriptionGroupDlgLoading,
  ] = SubscriptionGroupDialog({
    onSubmit: () => {
      refreshSubscriptionGroup();
    },
  });

  useEffect(() => {
    refreshSubscriptionGroup();
  }, []);

  useEffect(() => {
    AccessControl.SetAccess({
      ...AccessControl.Access,
      addSubscriptionGroupAccess: AccessControl.VerifyAccess([
        PERMISSIONS.SUBSCRIPTION.SUBSCRIPTION_ADD_GROUP,
      ]),
      listSubscriptionGroupAccess: AccessControl.VerifyAccess([
        PERMISSIONS.SUBSCRIPTION.SUBSCRIPTION_LIST_ALL_GROUP,
      ]),
      editSubscriptionGroupAccess: AccessControl.VerifyAccess([
        PERMISSIONS.SUBSCRIPTION.SUBSCRIPTION_EDIT_GROUP,
      ]),
    });
  }, [AccessControl.UserPermissions]);

  const refreshSubscriptionGroup = async () => {
    setLoading(true);
    const response = await getSubscriptionGroups();
    verifyResponse(response);
    setSubscriptionGroup(response.data);
    setLoading(false);
  };

  return (
    <PageContent>
      <PageHeading>
        <div>Subscription Group</div>
        <div className="actions">
          {AccessControl.Access.addSubscriptionGroupAccess && (
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                openSubscriptionGroupDlg();
              }}
            >
              Add New Subscription Group
            </Button>
          )}
        </div>
      </PageHeading>

      {AccessControl.Access.listSubscriptionGroupAccess && (
        <StyledSubscriptionGroup>
          {!subscriptionGroups || subscriptionGroups.length === 0 ? (
            <div className="empty-group-des">
              No Subscription Group yet, please create a Subscription Group
            </div>
          ) : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 50 }}>Index</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell align="right" style={{ minWidth: 200 }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subscriptionGroups.map((group, index) => (
                    <TableRow
                      key={`table-row-subscription-group-${group.id}`}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{group.name}</TableCell>
                      <TableCell>{group.code}</TableCell>
                      <TableCell>{group.description}</TableCell>
                      <TableCell align="right">
                        {AccessControl.Access.editSubscriptionGroupAccess && (
                          <Button
                            variant="contained"
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={() => {
                              openSubscriptionGroupDlg(group);
                            }}
                          >
                            Edit
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </StyledSubscriptionGroup>
      )}

      {subscriptionGroupDlg}
      <LoadingScreen loading={loading || subscriptionGroupDlgLoading} />
    </PageContent>
  );
};

export default SubscriptionGroup;
