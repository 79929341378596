import { httpPost } from "utils/httpRequest"

const addCategory = async ({ name, fullName, isOnboarding }) => {
  const resp = await httpPost("admin/category/add", {
    name: name,
    fullName: fullName,
    pubStatus: 1,
    isOnboarding: isOnboarding ? 1 : 0,
  })
  return resp
}

const updateCategory = async ({ id, name, fullName, isOnboarding }) => {
  const resp = await httpPost("admin/category/edit", {
    id: id,
    name: name,
    fullName: fullName,
    isOnboarding: isOnboarding ? 1 : 0,
  })
  return resp
}

const deleteCategory = async (id) => {
  const resp = await httpPost(`admin/category/delete/${id}`, {})
  return resp
}

const getCategories = async (config) => {
  const response = await httpPost("admin/category/list", config)
  return response.data
}

const getSubCategories = async (categoryId) => {
  const resp = await httpPost(
    `admin/category/listSubCategory/${categoryId}`,
    {}
  )
  return resp.data.data
}

const addSubCategory = async ({ name, description, categoryId }) => {
  const resp = await httpPost("admin/category/addSubCategory", {
    name,
    description,
    categoryId,
    pubStatus: 1,
  })
  return resp
}

const updateSubCategory = async ({ id, name, description, categoryId }) => {
  const resp = await httpPost("admin/category/editSubCategory", {
    id,
    name,
    description,
    categoryId,
  })
  return resp
}

const deleteSubCategory = async (id) => {
  const resp = await httpPost(`admin/category/deleteSubCategory/${id}`, {})
  return resp
}

const getFullCategories = async () => {
  const resp = await httpPost("admin/category/listAll", {})
  return resp.data
}

const exchangeCategoryIndex = async (updateIndexArray) => {
  const resp = await httpPost("admin/category/exchangeIndex", {
    updateIndexArray,
  })
  return resp.data
}

const exchangeSubCategoryIndex = async (updateIndexArray) => {
  const resp = await httpPost("admin/category/exchangeSubCategoryIndex", {
    updateIndexArray,
  })
  return resp.data
}

export {
  addCategory,
  updateCategory,
  deleteCategory,
  getCategories,
  getSubCategories,
  addSubCategory,
  updateSubCategory,
  deleteSubCategory,
  getFullCategories,
  exchangeCategoryIndex,
  exchangeSubCategoryIndex,
}
