import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Container, Draggable } from "react-smooth-dnd"
import styled from "@emotion/styled"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { useSnackbar } from "notistack"
import {
  getProgramBySubCategoryId,
  exchangeProgramIndex,
} from "services/program"
import { verifyResponse } from "utils/httpRequest"
import { exchangeArrayItem } from "utils/data"
import { StyledCategoryProgramItem } from "./Category.style"

const StyledCategoryReorderProgramDialog = styled(Dialog)`
  .MuiPaper-root {
    min-width: 880px;
    min-height: 600px;
  }
  .smooth-dnd-draggable-wrapper {
    padding: 0 5px;
  }
`

const CategoryReorderProgramDlg = ({ access }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState(null)
  const [programs, setPrograms] = useState([])
  const [isChanged, setIsChanged] = useState(false)

  const openDlg = (currentSubCategory) => {
    setCategory(currentSubCategory)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const loadPrograms = async () => {
    try {
      if (category) {
        setLoading(true)
        const response = await getProgramBySubCategoryId(category.id)
        verifyResponse(response)

        if (response.rtnCode === 200) {
          setPrograms(response.data.list)
        } else {
          enqueueSnackbar("Fail to list category programs", {
            variant: "warning",
          })
        }
      }
    } catch (e) {
      enqueueSnackbar("Fail to list category programs", {
        variant: "warning",
      })
    } finally {
      setLoading(false)
    }
  }

  const updateOrder = async () => {
    try {
      setLoading(true)
      const response = await exchangeProgramIndex({
        subCategoryId: category.id,
        updateIndexArray: programs.map((program, index) => {
          return {
            id: program.id,
            index: index,
          }
        }),
      })

      if (response.rtnCode !== 200) {
        enqueueSnackbar("Fail to the program order", {
          variant: "warning",
        })
      } else {
        setIsChanged(false)
        enqueueSnackbar("Update program order success", {
          variant: "success",
        })
      }
    } catch (e) {
      enqueueSnackbar("Fail to the program order", {
        variant: "warning",
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (category) {
      loadPrograms()
    }
  }, [category])

  // render program list item
  const renderProgramItem = (program) => {
    return (
      <StyledCategoryProgramItem key={`category-program-item-${program.id}`}>
        <div className="thumbnail">
          <img
            src={program.thumbResourceDto.urlDto.imgUrl_XS}
            alt="thumbnail"
          />
        </div>
        <div className="info">
          <div className="identity">Program ID: {program.id} </div>
          <div className="title">Title: {program.title}</div>
          <div className="desc">{program.description}</div>
        </div>
      </StyledCategoryProgramItem>
    )
  }

  const dlg = (
    <StyledCategoryReorderProgramDialog open={open} onClose={handleClose}>
      <DialogTitle>
        Reorder Program for category "<i>{category?.name}"</i>
      </DialogTitle>
      <DialogContent>
        {access.listProgramBySubCategoryAccess ? (
          <Container
            onDrop={async (e) => {
              setIsChanged(true)
              const updateIndexArray = exchangeArrayItem({
                addedIndex: e.addedIndex,
                removedIndex: e.removedIndex,
                indexString: "programIndex",
                list: programs,
              })
              setPrograms(updateIndexArray)
            }}
          >
            {programs &&
              programs.map((program) => (
                <Draggable key={program.id}>
                  {renderProgramItem(program)}
                </Draggable>
              ))}
          </Container>
        ) : (
          programs &&
          programs.map((program) => (
            <Draggable key={program.id}>{renderProgramItem(program)}</Draggable>
          ))
        )}
      </DialogContent>

      <DialogActions>
        {access.listProgramBySubCategoryAccess && (
          <Button
            variant="contained"
            color="secondary"
            onClick={updateOrder}
            disabled={!isChanged}
          >
            Save
          </Button>
        )}
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </StyledCategoryReorderProgramDialog>
  )

  return [openDlg, dlg, loading]
}

CategoryReorderProgramDlg.propTypes = {
  onSubmit: PropTypes.func,
}

CategoryReorderProgramDlg.defaultProps = {
  onSubmit: null,
}

export default CategoryReorderProgramDlg
