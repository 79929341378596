import { httpPost } from "utils/httpRequest"

const searchMembers = async ({
  pageNo,
  pageSize,
  searchName,
  searchEmail,
  isGuest,
  trialFlag,
  isActive,
}) => {
  const resp = await httpPost("admin/member/list", {
    pageNo,
    pageSize,
    searchName,
    searchEmail,
    isGuest,
    trialFlag,
    isActive,
  })
  return resp.data
}

const getMemberDetail = async (id) => {
  const resp = await httpPost("admin/member/queryDetail", {
    id,
  })
  return resp.data
}

const removeFreeTrial = async (id) => {
  const resp = await httpPost("admin/member/removeFreeTrial", {
    id,
  })
  return resp.data
}

const deactivateMember = async ({ id }) => {
  const resp = await httpPost("admin/member/deActive", {
    id,
  })
  return resp.data
}

const reactivateMember = async ({ id }) => {
  const resp = await httpPost("admin/member/reActive", {
    id,
  })
  return resp.data
}

const updateMemberEmail = async ({ memberId, newEmail }) => {
  const resp = await httpPost("admin/member/updateEmail", {
    memberId,
    newEmail,
  })
  return resp.data
}

const deleteMember = async ({ memberId }) => {
  const resp = await httpPost("admin/member/delete", {
    id: memberId,
  })
  return resp.data
}

const updateMemberPassword = async ({ memberId, newPassword }) => {
  const resp = await httpPost("admin/member/updatePassword", {
    memberId,
    newPassword,
  })
  return resp.data
}

const cancelKeepSubscription = async ({ vipInfoId, memberId }) => {
  const resp = await httpPost("admin/member/cancelSubscription", {
    vipInfoId,
    memberId,
  })
  return resp.data
}

const reactivateKeepSubscription = async ({ vipInfoId, memberId }) => {
  const resp = await httpPost("admin/member/reactivateSubscription", {
    vipInfoId,
    memberId,
  })
  return resp.data
}

export {
  searchMembers,
  removeFreeTrial,
  deactivateMember,
  reactivateMember,
  getMemberDetail,
  updateMemberEmail,
  deleteMember,
  updateMemberPassword,
  cancelKeepSubscription,
  reactivateKeepSubscription,
}
