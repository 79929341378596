import React, { useState, useRef, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import Button from "@mui/material/Button"
import Chip from "@mui/material/Chip"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import ClearIcon from "@mui/icons-material/Clear"
import EditIcon from "@mui/icons-material/Edit"
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined"
import DialogActions from "@mui/material/DialogActions"
import FormControl from "@mui/material/FormControl"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import { verifyResponse } from "utils/httpRequest"
import { Container, Draggable } from "react-smooth-dnd"
import { useSnackbar } from "notistack"
import { useForm } from "react-hook-form"
import { UseConfirmDialog } from "components/customHooks"
import {
  addProgramInnercise,
  updateProgramInnercise,
  addProgramPlaybook,
  editProgramPlaybook,
  deleteProgramPlaybookQuestion,
  getProgramInnerciseById,
  exchangeChangeProgramPlaybookQuestionIndex,
} from "services/program"
import { exchangeArrayItem } from "utils/data"
import MediaContentDisplay from "components/common/MediaManager/MediaContentDisplay"
import { StyledInputLabel } from "components/Style/Forms.style"
import { MEDIA_TYPE_INDEX } from "constants/global"
import {
  INNERCISE_MODE,
  PLAYBOOK_RESPONSE_QUESTION_TYPE_INDEX,
} from "constants/global"
import ProgramInnerciseListControl from "./ProgramInnerciseListControl"
import ProgramPlaybookQuestionDialog from "./ProgramPlaybook/ProgramPlaybookQuestionDlg"
import { StyledProgramDialog } from "./Program.style"

const ProgramInnerciseDialog = (props) => {
  const { onSubmit, openMediaManager } = props
  const [thumbnailResource, setThumbnailResource] = useState(false)
  const [resources, setResources] = useState([])
  const [innerciseMode, setInnerciseMode] = useState(
    INNERCISE_MODE.INNERCISE.value
  )
  const thumbnailResourceRef = useRef(null)

  const initInnercise = {
    programId: 0,
    levelId: 0,
    title: "",
    type: 0,
    description: "",
    resourceId: null,
    thumbResourceId: null,
    attachIds: [],
    freeFlag: 0,
  }

  const { enqueueSnackbar } = useSnackbar()

  const [ConfirmDialog, openConfirmDialog] = UseConfirmDialog({
    title: "Delete Question Confirm",
    description: "Are you sure want delete this question ?",
    agreeText: "Yes",
    disagreeText: "No",
  })

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm()

  const [programId, setProgramId] = useState(0)
  const [programLevelId, setProgramLevelId] = useState(0)
  const [innerciseId, setInnerciseId] = useState()
  const [innercise, setInnercise] = useState(initInnercise)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [attachments, setAttachments] = useState([])
  const [isChanged, setIsChanged] = useState(false)

  useEffect(() => {
    resources.length === 0
      ? setValue("innerciseResources", null)
      : setValue("innerciseResources", resources)
  }, [resources])

  useEffect(() => {
    if (innerciseId && open) {
      setValue("title", "")
      setValue("description", "")
      refreshInnercise()
    }
  }, [innerciseId, open])

  useEffect(() => {
    if (isEditMode && innerciseMode === INNERCISE_MODE.INNERCISE.value) {
      // set thumbnail image
      thumbnailResourceRef.current = innercise.thumbResourceDto
      setValue("thumbResourceId", innercise.thumbResourceDto.id)

      // set basic info
      setValue("title", innercise.title)
      setValue("description", innercise.description)

      // set attachment
      innercise.attachDtos
        ? setAttachments(innercise.attachDtos)
        : setAttachments([])

      const currentResources = innercise.resourceDtos.map((resource) => {
        return {
          channelType: resource.channelType,
          resource,
        }
      })

      // set innercise resources
      setTimeout(() => {
        setValue("innerciseResources", currentResources)
      }, 200)
      setResources(currentResources)
    } else if (isEditMode && innerciseMode === INNERCISE_MODE.PLAYBOOK.value) {
      thumbnailResourceRef.current = innercise.thumbResourceDto
      setValue("thumbResourceId", innercise.thumbResourceDto.id)

      // set basic info
      setValue("title", innercise.title)
      setValue("description", innercise.description)

      setAttachments([])
      setResources([])
      const currentResources = []
      setTimeout(() => {
        setValue("innerciseResources", currentResources)
      }, 200)
      setResources(currentResources)
    } else {
      thumbnailResourceRef.current = null
      setAttachments([])
      setResources([])
      setValue("innerciseResources", null)
    }

    setTimeout(() => {
      updateThumbnailResource()
    }, 0)
  }, [innercise])

  const refreshInnercise = async () => {
    setLoading(true)
    const response = await getProgramInnerciseById(innerciseId)
    setLoading(false)
    verifyResponse(response)

    if (response.rtnCode === 200) {
      setInnercise(response.data)
    } else {
      //
    }
  }

  const [
    openPlaybookQuestionDlg,
    playbookQuestionDlg,
    playbookQuestionDlgLoading,
  ] = ProgramPlaybookQuestionDialog({
    refreshPlaybook: refreshInnercise,
  })

  const openDlg = (innerciseMode, programId, levelId, innerciseId) => {
    reset()
    setInnerciseMode(innerciseMode)
    setProgramId(programId)
    setProgramLevelId(levelId)

    if (innerciseId) {
      setIsEditMode(true)
      setInnerciseId(innerciseId)
    } else {
      setIsEditMode(false)
      setInnercise({
        ...initInnercise,
        programId: programId,
        levelId: levelId,
      })
    }

    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const updateThumbnailResource = () => {
    setThumbnailResource({
      ...thumbnailResourceRef.current,
      selectAction: selectThumbnailResource,
      clearResourceAction: clearThumbnailResource,
    })
  }

  const selectThumbnailResource = () => {
    openMediaManager(
      (media) => {
        thumbnailResourceRef.current = media
        setValue("thumbResourceId", media.id)
        updateThumbnailResource()
      },
      [MEDIA_TYPE_INDEX.image]
    )
  }

  const clearThumbnailResource = () => {
    thumbnailResourceRef.current = null
    setValue("thumbResourceId", null)
    updateThumbnailResource()
  }

  const removeAttachment = (attachId) => {
    setAttachments(attachments.filter((attach) => attach.id !== attachId))
  }

  const removePlaybookQuestion = async (id) => {
    setLoading(true)
    const response = await deleteProgramPlaybookQuestion({ id })
    setLoading(false)
    verifyResponse(response)

    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar("Playbook Question Deleted", {
        variant: "success",
      })
      // refresh
      refreshInnercise()
    } else {
      // handling success message
      enqueueSnackbar("Delete Playbook Question Fail", {
        variant: "warning",
      })
    }
  }

  const handleAction = async (data) => {
    setLoading(true)

    try {
      let response

      if (innerciseMode === INNERCISE_MODE.INNERCISE.value) {
        if (isEditMode) {
          // update innercise
          response = await updateProgramInnercise({
            ...data,
            type: resources.length === 0 ? 0 : resources[0].resource.type,
            id: innercise.id,
            programId: programId,
            levelId: programLevelId,
            freeFlag: 0,
            attachIds: attachments.map((attach) => attach.id),
            innerciseResourceList: resources.map((chResource) => {
              return {
                channelType: chResource.channelType,
                resourceId: parseInt(chResource.resource.id),
              }
            }),
          })
        } else {
          // create innercise
          response = await addProgramInnercise({
            ...data,
            type: resources.length === 0 ? 0 : resources[0].resource.type,
            programId: programId,
            levelId: programLevelId,
            attachIds: attachments.map((attach) => attach.id),
            innerciseResourceList: resources.map((chResource) => {
              return {
                channelType: chResource.channelType,
                resourceId: parseInt(chResource.resource.id),
              }
            }),
          })
        }
      }

      if (innerciseMode === INNERCISE_MODE.PLAYBOOK.value) {
        if (isEditMode) {
          // update playbook
          response = await editProgramPlaybook({
            ...data,
            id: innercise.id,
            programId: programId,
            levelId: programLevelId,
            estTimeLength: 234,
          })

          // update question index if there are changed
          if (isChanged) {
            const exchangeResponse =
              await exchangeChangeProgramPlaybookQuestionIndex({
                updateIndexArray: innercise.questionDtoList.map((question) => {
                  return { id: question.id, index: question.dataIndex }
                }),
              })

            verifyResponse(exchangeResponse)
          }
        } else {
          // create playbook
          response = await addProgramPlaybook({
            ...data,
            programId: programId,
            levelId: programLevelId,
            estTimeLength: 234,
          })
        }
      }

      verifyResponse(response)

      if (response.data.rtnCode === 200) {
        // handling success message
        enqueueSnackbar(
          isEditMode ? "Innercise Updated" : "Create Innercise Success",
          {
            variant: "success",
          }
        )
      } else {
        // handling error message
        enqueueSnackbar(
          isEditMode ? "Innercise Update Fail" : "Create Innercise Fail",
          {
            variant: "warning",
          }
        )
      }

      onSubmit()
      setOpen(false)
    } catch (error) {
      enqueueSnackbar(
        isEditMode ? "Innercise Update Fail" : "Create Innercise Fail",
        {
          variant: "warning",
        }
      )
    } finally {
      setLoading(false)
    }
  }

  const dlg = useMemo(() => {
    return (
      <StyledProgramDialog open={open} fullWidth={true}>
        <DialogTitle>
          {innerciseMode === INNERCISE_MODE.INNERCISE.value && (
            <>{isEditMode ? "Edit Innercise" : "Add Innercise"}</>
          )}

          {innerciseMode === INNERCISE_MODE.PLAYBOOK.value && (
            <>{isEditMode ? "Edit Playbook" : "Add Playbook"}</>
          )}
        </DialogTitle>
        <form onSubmit={handleSubmit(handleAction)}>
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                autoFocus
                focused
                autoComplete="off"
                margin="dense"
                id="program-innercise-title"
                label="Title"
                fullWidth
                defaultValue={innercise.title}
                {...register("title", { required: true })}
                error={!!errors?.title}
                variant="standard"
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                autoFocus
                focused
                autoComplete="off"
                margin="dense"
                id="program-innercise-description"
                label="Description"
                fullWidth
                multiline
                rows={7}
                defaultValue={innercise.description}
                {...register("description", { required: true })}
                error={!!errors?.description}
                variant="standard"
              />
            </FormControl>

            <div className="form-control-row two-col">
              <div>
                <StyledInputLabel>Thumbnail</StyledInputLabel>
                <input
                  type="hidden"
                  defaultValue={innercise.thumbResourceId}
                  {...register("thumbResourceId", { required: true })}
                />
                <MediaContentDisplay resource={thumbnailResource} />
                {!!errors?.thumbResourceId && (
                  <div className="error-message">
                    * Thumbnail Resource Required
                  </div>
                )}
              </div>
            </div>

            {isEditMode && innerciseMode === INNERCISE_MODE.PLAYBOOK.value && (
              <div className="questions">
                <div className="action-bar">
                  <Button
                    variant="contained"
                    onClick={() => {
                      // add new question
                      openPlaybookQuestionDlg({ playbookId: innercise.id })
                    }}
                  >
                    New Question
                  </Button>
                </div>

                {innercise.questionDtoList && (
                  <div className="question-list">
                    <Container
                      onDrop={async (e) => {
                        setIsChanged(true)
                        const updateIndexArray = exchangeArrayItem({
                          addedIndex: e.addedIndex,
                          removedIndex: e.removedIndex,
                          indexString: "dataIndex",
                          list: innercise.questionDtoList,
                        })
                        setInnercise({
                          ...innercise,
                          questionDtoList: updateIndexArray,
                        })
                      }}
                    >
                      {innercise.questionDtoList.map((question) => (
                        <Draggable key={question.id}>
                          <div
                            className="question"
                            key={`playbook-question-item-${question.id}`}
                          >
                            <div className="title">
                              <div className="content">
                                <Chip
                                  label={
                                    PLAYBOOK_RESPONSE_QUESTION_TYPE_INDEX[
                                      question.answerType
                                    ]
                                  }
                                  color="primary"
                                  size="small"
                                />
                                {question.title}
                              </div>
                              <div className="actions">
                                <Tooltip title="edit">
                                  <IconButton
                                    aria-label="edit playbook question title"
                                    sx={{ color: "#bf851a" }}
                                    component="label"
                                    onClick={() => {
                                      openPlaybookQuestionDlg({
                                        playbookId: innercise.id,
                                        playbookQuestion: question,
                                      })
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Remove">
                                  <IconButton
                                    aria-label="remove playbook question"
                                    sx={{ color: "#bf851a" }}
                                    component="label"
                                    onClick={() => {
                                      openConfirmDialog(() => {
                                        removePlaybookQuestion(question.id)
                                      })
                                    }}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                            {question.answerList &&
                              question.answerList.length > 0 && (
                                <ol>
                                  {question.answerList.map((answer) => (
                                    <li
                                      key={`playbook-question-answer-${question.id}-${answer.dataIndex}`}
                                    >
                                      {answer.title}{" "}
                                      {answer.otherFlag ? "(other)" : ""}
                                    </li>
                                  ))}
                                </ol>
                              )}
                          </div>
                        </Draggable>
                      ))}
                    </Container>
                  </div>
                )}
              </div>
            )}

            {innerciseMode === INNERCISE_MODE.INNERCISE.value && (
              <>
                <div className="form-control-row ">
                  <ProgramInnerciseListControl
                    openMediaManager={openMediaManager}
                    openPlaybookDlg={() => {
                      openPlaybookQuestionDlg({ playbookId: innercise.id })
                    }}
                    resources={resources}
                    setResources={setResources}
                    innerciseMode={innerciseMode}
                  />
                  <input
                    type="hidden"
                    {...register("innerciseResources", { required: true })}
                  />
                  {!!errors?.innerciseResources && (
                    <div className="error-message">
                      * Innercise Resource Required
                    </div>
                  )}
                </div>
                <div className="form-control-row">
                  <div className="attachments-section">
                    <StyledInputLabel>Attachments</StyledInputLabel>
                    <Button
                      onClick={() => {
                        openMediaManager(
                          (media) => {
                            setAttachments([...attachments, media])
                          },
                          [MEDIA_TYPE_INDEX.document, MEDIA_TYPE_INDEX.image]
                        )
                      }}
                    >
                      Add Attachment
                    </Button>
                    <div className="attachment-list">
                      {attachments.length === 0
                        ? "No Attachments ..."
                        : attachments.map((attachment) => (
                            <div
                              className="attachment-item"
                              key={`attachment-item-${attachment.id}`}
                            >
                              <MediaContentDisplay
                                resource={attachment}
                                small
                              />
                              <div className="info">{attachment.name}</div>
                              <IconButton
                                className="attachment-close-btn"
                                aria-label="delete"
                                size="small"
                                color="success"
                              >
                                <RemoveCircleOutlinedIcon
                                  fontSize="small"
                                  onClick={() => {
                                    removeAttachment(attachment.id)
                                  }}
                                />
                              </IconButton>
                            </div>
                          ))}
                    </div>
                  </div>
                </div>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              close
            </Button>
            <Button type="submit" autoFocus>
              {isEditMode ? "Update" : "Create"}
            </Button>
          </DialogActions>
        </form>
        {playbookQuestionDlg}
        {ConfirmDialog}
      </StyledProgramDialog>
    )
  }, [open, thumbnailResource, innercise, playbookQuestionDlg])

  return [openDlg, dlg, loading || playbookQuestionDlgLoading]
}

ProgramInnerciseDialog.propTypes = {
  onSubmit: PropTypes.func,
  openMediaManager: PropTypes.func.isRequired,
}

ProgramInnerciseDialog.defaultProps = {
  onSubmit: null,
}

export default ProgramInnerciseDialog
