import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";
import { StyledAdminTable } from "./Admin.style";

const AdminTable = ({
  adminUsers,
  access,
  openAdminDialog,
  showAdminDetail,
}) => {
  const [page, setPage] = useState(1);
  const [filterVal, setFilterVal] = useState("");
  const [displayUsers, setDisplayUsers] = useState([]);
  const numPerPage = 10;
  const totalCount = adminUsers ? displayUsers.length : 0;
  const startIndex = page === 1 ? 0 : (page - 1) * numPerPage;

  const filterUsers = () => {
    if (adminUsers === undefined || adminUsers === null) return false;

    setDisplayUsers(
      adminUsers && filterVal === ""
        ? adminUsers
        : adminUsers.filter((user) => {
            return (
              user.email.includes(filterVal) ||
              user.nickName.includes(filterVal)
            );
          })
    );
  };
  useEffect(() => {
    filterUsers();
    setPage(1);
  }, [filterVal, adminUsers]);

  return (
    <StyledAdminTable>
      {!adminUsers ||
        (adminUsers.length === 0 && (
          <div className="search-result-info">No Record.</div>
        ))}
      {adminUsers && adminUsers.length > 0 && (
        <>
          <div className="filter-bar ">
            <TextField
              id={"admin-table-name-filter"}
              autoComplete="off"
              sx={{ width: "300px" }}
              label="Search by Name or Email"
              variant="outlined"
              size="large"
              value={filterVal}
              onChange={(e) => setFilterVal(e.target.value.trim())}
            />
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="role table">
              <TableHead>
                <TableRow>
                  <TableCell>Nick name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell sx={{ minWidth: 160, textAlign: "right" }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayUsers
                  .slice(startIndex, startIndex + numPerPage)
                  .map((row) => (
                    <TableRow
                      key={`role-item-${row.userId}`}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell scope="row">{row.nickName}</TableCell>
                      <TableCell scope="row">{row.email}</TableCell>
                      <TableCell>{row.firstName}</TableCell>
                      <TableCell>{row.lastName}</TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        {access.getDetailAdminUserAccess && (
                          <Button
                            style={{ width: "80px" }}
                            onClick={() => {
                              showAdminDetail(row);
                            }}
                            variant="outlined"
                          >
                            Detail
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                <TableRow key={"pg"}>
                  <TableCell colSpan={7}>
                    <div className="pagination-section">
                      <Pagination
                        count={Math.ceil(totalCount / numPerPage)}
                        page={page}
                        onChange={(event, value) => {
                          setPage(value);
                        }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </StyledAdminTable>
  );
};

AdminTable.propTypes = {
  adminUsers: PropTypes.array.isRequired,
  access: PropTypes.object.isRequired,
  openAdminDialog: PropTypes.object.isRequired,
  showAdminDetail: PropTypes.object.isRequired,
};

export default AdminTable;
