import styled from "@emotion/styled"
import Dialog from "@mui/material/Dialog"

const StyledProgramPlaybookQuestionDialog = styled(Dialog)`
  .MuiPaper-root {
    min-width: 800px;
  }

  .MuiFormControl-root {
    margin: 10px 0;
  }

  h5 {
    font-size: 16px;
    margin: 10px 0;
  }
  font-size: 14px;

  .subcategory-section {
    .MuiFormControlLabel-root {
      transform: scale(0.85);
    }
  }

  .answer-option-text-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .answer-option {
    /* background-color: #dee0bb; */
    border-radius: 5px;
    padding: 3px 15px;
    font-size: 14px;
    margin-top: 10px;
    /* color: #8f6565; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
      rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
      
    .MuiChip-root {
      margin-right: 20px;
      min-width: 110px;
    }
  }
`

export { StyledProgramPlaybookQuestionDialog }
