import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import LoadingScreen from "components/common/LoadingScreen"
import { useSnackbar } from "notistack"
import { useForm } from "react-hook-form"
import { addSubCategory, updateSubCategory } from "services/category"

const StyledSubCategoryDialog = styled(Dialog)`
  .MuiPaper-root {
    min-width: 380px;
    min-height: 250px;
  }
  .MuiFormControl-root {
    margin-left: 0;
  }
`

const SubCategoryDlg = (props) => {
  const initCategory = { name: "" }
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  const { onSubmit, categories } = props
  const [categoryId, setCategoryId] = useState(initCategory)
  const [subCategory, setSubCategory] = useState(initCategory)
  const targetSubCategoryRefreshRef = useRef()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setOpen(false)
  }

  const openDlg = (cateId, editCategory, subCategoryRefresh) => {
    reset()
    setCategoryId(cateId)
    setIsEditMode(editCategory ? true : false)
    targetSubCategoryRefreshRef.current = subCategoryRefresh
    setSubCategory(editCategory ? { ...editCategory } : initCategory)
    setOpen(true)
  }

  const handleAction = async (data) => {
    // send request
    setLoading(true)
    const response = isEditMode
      ? await updateSubCategory({ ...data, categoryId, id: subCategory.id })
      : await addSubCategory({ ...data, categoryId })
    if (response.data.rtnCode === 200) {
      targetSubCategoryRefreshRef.current()
      enqueueSnackbar(
        isEditMode
          ? "Update Subcategory success"
          : "Create Subcategory success",
        { variant: "success" }
      )
    } else {
      enqueueSnackbar(
        isEditMode ? "Update Subcategory Fail" : "Create Subcategory Fail",
        { variant: "warning" }
      )
    }
    setLoading(false)
    onSubmit()
    handleClose()
  }

  const dlg = (
    <StyledSubCategoryDialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(handleAction)}>
        <DialogTitle>
          {isEditMode ? "Edit Sub-Category" : "Add sub-Category"}
        </DialogTitle>
        <DialogContent>
          {isEditMode && (
            <FormControl sx={{ m: 1, minWidth: 230 }} variant="standard">
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                fullWidth
                labelId="edit-subCategory-id-select"
                id="edit-subCategory-id-select"
                value={categoryId}
                label="Category"
                onChange={(e) => {
                  setCategoryId(e.target.value)
                }}
              >
                <MenuItem key={`category-select-item-all`} value={-1}>
                  All
                </MenuItem>
                {categories.map((category) => (
                  <MenuItem
                    key={`category-select-item-${category.id}`}
                    value={category.id}
                  >
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <TextField
            autoFocus
            autoComplete="off"
            margin="dense"
            id="sub-cat-name"
            label="Category Name"
            type="text"
            fullWidth
            defaultValue={subCategory.name}
            {...register("name", { required: true })}
            error={!!errors?.name}
            helperText={!!errors?.name ? "Name is required" : " "}
            variant="standard"
          />

          <TextField
            autoFocus
            autoComplete="off"
            margin="dense"
            id="sub-cat-description"
            label="Category Description"
            type="text"
            fullWidth
            defaultValue={subCategory.description}
            {...register("description", { required: true })}
            error={!!errors?.description}
            helperText={!!errors?.description ? "Description is required" : " "}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" size="small">
            {isEditMode ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </form>
      <LoadingScreen loading={loading} />
    </StyledSubCategoryDialog>
  )

  return [openDlg, dlg, loading]
}

SubCategoryDlg.propTypes = {
  onSubmit: PropTypes.func,
}

SubCategoryDlg.defaultProps = {
  onSubmit: null,
}

export default SubCategoryDlg
