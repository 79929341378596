import React from "react";
import styled from "@emotion/styled";

const NotFoundWrapper = styled.div`
  min-height: calc(100vh - 140px);
`;

const NotFound = () => {
  return <NotFoundWrapper>404 Not Found</NotFoundWrapper>;
};

export default NotFound;
