import styled from "@emotion/styled"
import Dialog from "@mui/material/Dialog"

const StyledReview = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`

const StyledReviewSearch = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledReviewSearchBar = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 30px);
  align-items: center;
  /* border: 1px solid #f9eded; */
  padding: 20px 15px;
  border-radius: 7px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

  .email {
    margin: 0;
    width: 300px;
  }
  .time-input {
    width: 150px;
  }
  .search-btn {
    padding: 14px 22px;
  }
  .MuiInputBase-root {
    margin-right: 10px;
  }
`

const StyledReviewResult = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  th {
    font-weight: bold;
    font-size: 16px;
  }

  .pagination-section {
    display: flex;
    justify-content: flex-end;
    background-color: white;
  }

  .search-info {
    display: flex;
    width: calc() (100% - 40px);
    /* height: 80px; */
    border-radius: 4px;
    background-color: white;
    margin-bottom: 20px;
    padding: 20px 15px;
    font-size: 15px;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 0px,
      rgb(0 0 0 / 10%) 0px 0px 1px 0px;
  }
`

const StyledReviewDlg = styled(Dialog)`
  .MuiPaper-root {
    min-width: 600px;
    min-height: 560px;
  }

  .MuiFormControl-root {
    margin: 0 0 30px 0px;
    width: 100%;
  }

  .MuiOutlinedInput-root {
    width: 100%;
  }
`

export {
  StyledReview,
  StyledReviewSearch,
  StyledReviewSearchBar,
  StyledReviewResult,
  StyledReviewDlg,
}
