import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import { useSnackbar } from "notistack"
import { useForm } from "react-hook-form"
import { addCategory, updateCategory } from "services/category"

const StyledCategoryDialog = styled(Dialog)`
  .MuiPaper-root {
    min-width: 380px;
    min-height: 250px;
  }

  .MuiFormControlLabel-label {
    font-size: 12px;
    color: #666;
  }
`

const CategoryDlg = ({ onSubmit }) => {
  const initCategory = { name: "", fullName: "", isOnboarding: 1 }
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  const [category, setCategory] = useState(initCategory)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setOpen(false)
  }

  const openDlg = (editCategory) => {
    reset()
    setIsEditMode(editCategory ? true : false)
    setCategory(editCategory ? { ...editCategory } : initCategory)
    setOpen(true)
  }

  const handleAction = async (data) => {
    // send request
    setLoading(true)
    const response = isEditMode
      ? await updateCategory({ ...data, id: category.id })
      : await addCategory(data)
    if (response.data.rtnCode === 200) {
      // handling success message
      enqueueSnackbar(
        isEditMode ? "Update category success" : "Create category success",
        { variant: "success" }
      )
    } else {
      // handling error message
      enqueueSnackbar(
        isEditMode ? "Update category fail" : "Create category fail",
        { variant: "warning" }
      )
    }
    setLoading(false)
    onSubmit()
    handleClose()
  }

  const dlg = (
    <StyledCategoryDialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(handleAction)}>
        <DialogTitle>
          {isEditMode ? "Edit Category" : "Add Category"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            autoComplete="off"
            margin="dense"
            id="cat-name"
            label="Category Name"
            type="text"
            fullWidth
            defaultValue={category.name}
            {...register("name", { required: true })}
            error={!!errors?.name}
            helperText={!!errors?.name ? "Name is required" : " "}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleAction()
              }
            }}
            variant="standard"
          />

          <TextField
            autoFocus
            autoComplete="off"
            margin="dense"
            id="cat-full-name"
            label="Full Name"
            type="text"
            fullWidth
            defaultValue={category.fullName}
            {...register("fullName", { required: true })}
            error={!!errors?.fullName}
            helperText={!!errors?.name ? "Name is required" : " "}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleAction()
              }
            }}
            variant="standard"
          />
          <FormControlLabel
            control={
              <Switch
                defaultChecked={category.isOnboarding === 1}
                color="warning"
                {...register("isOnboarding", { required: false })}
              />
            }
            label="Show Onboarding"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" size="small">
            {isEditMode ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </form>
    </StyledCategoryDialog>
  )

  return [openDlg, dlg, loading]
}

CategoryDlg.propTypes = {
  onSubmit: PropTypes.func,
}

CategoryDlg.defaultProps = {
  onSubmit: null,
}

export default CategoryDlg
