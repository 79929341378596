import React, { useState, useEffect, useRef } from "react"
import ReactCrop from "react-image-crop"
import { Button } from "@mui/material"
import { useDropzone } from "react-dropzone"
import "react-image-crop/dist/ReactCrop.css"
import { StyledImageCrop, StyledFileDropZone } from "./ImageCrop.style"

const ImageCrop = ({ onCrop }) => {
  const [src, setSrc] = useState(null)
  const [blobBackup, setBlobBackup] = useState(null)
  const [croppedImageUrl, setCroppedImageUrl] = useState(null)
  const imageRef = useRef(null)
  const fileUrlRef = useRef(null)
  const [crop, setCrop] = useState({
    unit: "%",
    width: 80,
    aspect: 1,
  })

  const onSelectFile = (files) => {
    if (files && files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener("load", () => setSrc(reader.result))
      reader.readAsDataURL(files[0])
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onSelectFile,
  })

  // If you setState the crop in here you should return false.
  const onImageLoaded = (image) => {
    imageRef.current = image
  }

  const onCropComplete = (crop) => {
    makeClientCrop(crop)
  }

  const onCropChange = (crop, percentCrop) => {
    setCrop(crop)
  }

  const makeClientCrop = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImgUrl = await getCroppedImg(
        imageRef.current,
        crop,
        "newFile.jpeg"
      )
      setCroppedImageUrl(croppedImgUrl)
    }
  }

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas")
    const pixelRatio = window.devicePixelRatio
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext("2d")

    canvas.width = crop.width * pixelRatio * scaleX
    canvas.height = crop.height * pixelRatio * scaleY

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = "high"

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    )

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty")
            return
          }
          setBlobBackup(blob)
          blob.name = fileName
          window.URL.revokeObjectURL(fileUrlRef.current)
          fileUrlRef.current = window.URL.createObjectURL(blob)
          resolve(fileUrlRef.current)
        },
        "image/jpeg",
        1
      )
    })
  }

  return (
    <StyledImageCrop>
      <StyledFileDropZone {...getRootProps()}>
        <input
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          {...getInputProps()}
        />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag & drop some files here, or click to select files</p>
        )}
      </StyledFileDropZone>

      {src && (
        <ReactCrop
          src={src}
          crop={crop}
          ruleOfThirds
          onImageLoaded={onImageLoaded}
          onComplete={onCropComplete}
          onChange={onCropChange}
        />
      )}
      {croppedImageUrl && (
        <>
          <div className="preview">
            <h5>Preview</h5>
            <img
              alt="Crop"
              style={{ maxWidth: "100%" }}
              src={croppedImageUrl}
            />
          </div>

          <div className="actions">
            <Button
              variant="contained"
              onClick={() => {
                onCrop && onCrop(blobBackup)
                setCroppedImageUrl(null)
                setSrc(null)
              }}
            >
              Crop
            </Button>
          </div>
        </>
      )}
    </StyledImageCrop>
  )
}

export default ImageCrop
