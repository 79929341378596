import styled from "@emotion/styled";
import Dialog from "@mui/material/Dialog";

const StyledProgramCategoryDialog = styled(Dialog)`
  .MuiPaper-root {
    width: 700px;
  }
  h5 {
    font-size: 16px;
    margin: 10px 0;
  }
  font-size: 14px;

  .subcategory-section {
    .MuiFormControlLabel-root {
      transform: scale(0.85);
    }
  }
`;

export { StyledProgramCategoryDialog };
