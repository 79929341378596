import React, { useState, useEffect } from "react"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { StyledProgramCategoryDialog } from "./ProgramCategoryDlg.style"

const ProgramCategoryDialog = (props) => {
  const { categories, programSubCategories, onUpdate } = props
  const [selectedSubCategory, setSelectedSubCategory] =
    useState(programSubCategories)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const openDlg = async (programId) => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setSelectedSubCategory(programSubCategories)
  }, [programSubCategories])

  const onCheckBoxChange = (e, category, subCategory) => {
    if (e.target.checked) {
      setSelectedSubCategory([
        ...selectedSubCategory,
        {
          categoryId: category.id,
          categoryName: category.name,
          subCategoryId: subCategory.id,
          subCategoryName: subCategory.name,
        },
      ])
    } else {
      setSelectedSubCategory(
        selectedSubCategory.filter((el) => el.subCategoryId !== subCategory.id)
      )
    }
  }

  const dlg = (
    <StyledProgramCategoryDialog open={open}>
      <DialogTitle>Category Selector</DialogTitle>
      <DialogContent>
        {categories.map((category) => (
          <div
            key={`category-list-item-${category.id}`}
            className="category-section"
          >
            <h5>{category.name}</h5>
            <div className="subcategory-section">
              {category.subCategoryList.map((subCategory) => (
                <FormControlLabel
                  key={`subCategory-list-item-label-${subCategory.id}`}
                  control={
                    <Checkbox
                      defaultChecked={
                        programSubCategories.find(
                          (el) => el.subCategoryId === subCategory.id
                        ) !== undefined
                      }
                      onChange={(e) => {
                        onCheckBoxChange(e, category, subCategory)
                      }}
                    />
                  }
                  label={subCategory.name}
                />
              ))}
            </div>
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>close</Button>
        <Button
          onClick={() => {
            onUpdate(selectedSubCategory)
            setOpen(false)
          }}
        >
          Update
        </Button>
      </DialogActions>
    </StyledProgramCategoryDialog>
  )

  return [openDlg, dlg, loading]
}

export default ProgramCategoryDialog
