import React, { useState, useEffect } from "react"
import Button from "@mui/material/Button"
import PageHeading from "components/common/LayoutComponents/PageHeading"
import PageContent from "components/common/LayoutComponents/PageContent"
import PermissionDlg from "./PermissionDlg"
import { verifyResponse } from "utils/httpRequest"
import { getPermissionModules, getPermissions } from "services/permission"
import { PERMISSIONS } from "constants/permission"
import { UsePermissionVerification } from "components/customHooks"
import PermissionTable from "./PermissionTable"
import LoadingScreen from "components/common/LoadingScreen"
import { StyledPermission } from "./Permission.style"

const Permission = () => {
  const [permissionLoading, setPermissionLoading] = useState(false)
  const [moduleLoading, setModuleLoading] = useState(false)
  const [permissionModules, setPermissionModules] = useState([])
  const [permissions, setPermissions] = useState([])

  const AccessControl = UsePermissionVerification()

  const [openPermissionDialog, permissionDialog, permissionDialogLoading] =
    PermissionDlg({
      onSubmit: () => {
        loadPermissions()
      },
      modules: permissionModules,
    })

  const loadPermissionModules = async () => {
    setModuleLoading(true)
    const response = await getPermissionModules()
    verifyResponse(response)
    setModuleLoading(false)
    setPermissionModules(response.data)
  }

  const loadPermissions = async () => {
    setPermissionLoading(true)
    const response = await getPermissions()
    verifyResponse(response)
    setPermissionLoading(false)
    setPermissions(response.data.list)
  }

  useEffect(() => {
    loadPermissionModules()
    loadPermissions()
  }, [])

  useEffect(() => {
    AccessControl.SetAccess({
      ...AccessControl.Access,
      listPermissionAccess: AccessControl.VerifyAccess([
        PERMISSIONS.PERMISSION.PERMISSION_LIST,
      ]),
      addPermissionAccess: AccessControl.VerifyAccess([
        PERMISSIONS.PERMISSION.PERMISSION_ADD,
      ]),
      editPermissionAccess: AccessControl.VerifyAccess([
        PERMISSIONS.PERMISSION.PERMISSION_EDIT,
      ]),
      deletePermissionAccess: AccessControl.VerifyAccess([
        PERMISSIONS.PERMISSION.PERMISSION_DELETE,
      ]),
    })
  }, [AccessControl.UserPermissions])

  return (
    <PageContent>
      <StyledPermission>
        <PageHeading>
          <div>Permissions</div>
          <div className="actions">
            {AccessControl.Access.addPermissionAccess && (
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  openPermissionDialog()
                }}
              >
                New Permission
              </Button>
            )}
          </div>
        </PageHeading>
        <div>
          {permissions && permissions.length > 0 && (
            <PermissionTable
              permissions={permissions}
              access={AccessControl.Access}
              openPermissionDialog={openPermissionDialog}
            />
          )}
        </div>
      </StyledPermission>
      <LoadingScreen
        loading={(permissionLoading || moduleLoading, permissionDialogLoading)}
      />
      {permissionDialog}
    </PageContent>
  )
}

export default Permission
