import styled from "@emotion/styled"
import Dialog from "@mui/material/Dialog"

const StyledSystemSetting = styled.div`
  display: flex;
  flex-direction: column;
  table {
    td {
      font-size: 12px;
      overflow-wrap: break-word;
      word-break: break-all;
      .MuiChip-root {
        font-size: 10px;
        height: 18px;
        margin-right: 10px;
      }
    }
  }

  .color-box {
    width: 80px;
    height: 35px;
    line-height: 35px;
    font-size: 12px;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    margin: 10px 0;
    color: black;
    border-radius: 3px;
    &.dark-mode {
      color: #f7eeee;
    }
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
`

const StyledSystemSettingDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;

  .MuiPaper-root {
    max-width: 800px;
  }

  .ql-editor {
    height: 150px;
    overflow: auto;
  }

  .MuiDialogContent-root {
    display: flex;
    width: auto;
    flex-direction: column;
    max-height: calc(100vh - 200px);
    min-width: 700px;
  }

  .image-select-frame {
    display: flex;
    height: 200px;
    width: 330px;
    border: 2px dashed gray;
    font-size: 16px;
    color: gray;
    align-items: center;
    justify-content: space-around;
    margin-left: 8px;
    cursor: pointer;
    text-transform: capitalize;
    padding: 10px;
    border-radius: 5px;
  }

  .color-box {
    width: 100px;
    height: 60px;
    font-size: 15px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    color: black;
    border-radius: 3px;
    &.dark-mode {
      color: #f7eeee;
    }
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
`

const StyledInputLabel = styled.h4`
  font-size: 15px;
  color: gray;
`

const StyledSettingTable = styled.div`
  display: flex;
`

export {
  StyledSystemSetting,
  StyledSystemSettingDialog,
  StyledInputLabel,
  StyledSettingTable,
}
