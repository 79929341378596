import React, { useState, useEffect } from "react"
import Button from "@mui/material/Button"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import { UseConfirmDialog } from "components/customHooks"
import PropTypes from "prop-types"
import { useForm } from "react-hook-form"
import { useSnackbar } from "notistack"
import { verifyResponse } from "utils/httpRequest"
import { getAdminUser, SetAdminUserRole, deleteAdminUser } from "services/admin"
import AdminResetPassword from "./AdminResetPassword"
import { StyledAdminDetail } from "./Admin.style"

const AdminDetail = ({ admin, roles, access, closeDetail }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { register, handleSubmit, setValue, watch, reset } = useForm()
  const [loading, setLoading] = useState(false)

  const [resetPasswordContent, resetPasswordLoading] = AdminResetPassword({
    admin,
  })

  const [ConfirmDialog, openConfirmDialog] = UseConfirmDialog({
    title: "Delete Admin",
    description: "Are you sure want delete this Admin User ?",
    agreeText: "Yes",
    disagreeText: "No",
  })

  const loadAdminDetail = async () => {
    reset()
    if (admin && roles) {
      setLoading(true)
      const response = await getAdminUser(admin.id)
      verifyResponse(response)
      const roleNameList = response.data.roleList.map((role) => {
        return role.name
      })
      for (const role of roles) {
        setValue(`role-${role.id}`, roleNameList.includes(role.name))
      }
      setLoading(false)
    }
  }

  const removeAdminUser = async (adminId) => {
    const response = await deleteAdminUser(adminId)

    if (response.rtnCode === 200) {
      closeDetail()
      enqueueSnackbar("Admin User Removed", {
        variant: "success",
      })
    } else {
      enqueueSnackbar("Admin User Remove Fail", {
        variant: "warning",
      })
    }
  }

  useEffect(() => {
    loadAdminDetail()
  }, [admin])

  const handleAction = async (data) => {
    const arr = Object.keys(data).map((key) => ({ key, value: data[key] }))
    const roleIdList = arr
      .filter((o) => o.value)
      .map((o) => parseInt(o.key.split("-")[1]))

    try {
      setLoading(true)
      const response = await SetAdminUserRole({
        userId: admin.id,
        roleIdList,
      })
      verifyResponse(response)
      if (response.rtnCode === 200) {
        enqueueSnackbar("User Roles Updated", {
          variant: "success",
        })
      } else {
        enqueueSnackbar("User Roles Update Fail", {
          variant: "warning",
        })
      }
    } catch (e) {
      console.error("SetAdminUserRole error: ", e)
    } finally {
      setLoading(false)
    }
  }

  const content =
    admin && !loading ? (
      <StyledAdminDetail>
        <Card sx={{ maxWidth: 800 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Nickname Name:
            </Typography>
            <Typography variant="h5" component="div">
              {admin.nickName}
            </Typography>
            <Typography
              sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
              color="text.secondary"
            >
              Admin Email:
            </Typography>
            <Typography variant="body2">{admin.email}</Typography>
          </CardContent>
        </Card>

        <Card sx={{ marginTop: "20px" }}>
          <form name="role-control" onSubmit={handleSubmit(handleAction)}>
            <CardContent>
              <div className="role-header">
                <Typography variant="h5" component="div">
                  Roles :
                </Typography>
              </div>
              {roles.map((role) => (
                <div key={`role-item-${role.id}`}>
                  <FormControlLabel
                    checked={watch(`role-${role.id}`)}
                    control={
                      <Checkbox
                        size="small"
                        onChange={(e) => {
                          setValue(`role-${role.id}`, e.target.checked)
                        }}
                      />
                    }
                    label={role.name}
                    key={`role-item-${role.id}`}
                    {...register(`role-${role.id}`)}
                  />
                </div>
              ))}
            </CardContent>
            <CardActions sx={{ justifyContent: "flex-end" }}>
              {access && access.setRoleAdminUserAccess && (
                <Button type="submit" variant="contained" size="small">
                  Save
                </Button>
              )}
            </CardActions>
          </form>
        </Card>
        {access &&
          access.resetPasswordOtherAdminUserAccess &&
          resetPasswordContent}

        <Card sx={{ maxWidth: 800, marginTop: "20px" }}>
          <CardContent>
            <Typography variant="h5" component="div">
              Remove Admin User
            </Typography>
            <Typography
              sx={{ fontSize: 14, mb: 1.5, marginTop: 3 }}
              color="text.error"
            >
              Warning, once the account is deleted, all user data will disappear
              and cannot be recovered.
            </Typography>
            <div>
              <Button
                type="button"
                variant="contained"
                size="large"
                color="error"
                onClick={() => {
                  openConfirmDialog(() => {
                    removeAdminUser(admin.id)
                  })
                }}
              >
                Delete Account
              </Button>
            </div>
          </CardContent>
        </Card>

        {ConfirmDialog}
      </StyledAdminDetail>
    ) : null

  return [content, loading || resetPasswordLoading]
}

AdminDetail.propTypes = {
  admin: PropTypes.object.isRequired,
  roles: PropTypes.object.isRequired,
  access: PropTypes.object.isRequired,
}

export default AdminDetail
