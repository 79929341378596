import React from "react"
import Button from "@mui/material/Button"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Chip from "@mui/material/Chip"
import Paper from "@mui/material/Paper"
import Checkbox from "@mui/material/Checkbox"
import Pagination from "@mui/material/Pagination"
import { REVIEW_TYPE } from "constants/global"
// import ThumbUpIcon from "@mui/icons-material/ThumbUp"
// import ThumbDownIcon from "@mui/icons-material/ThumbDown"
import moment from "moment"
import { StyledReviewResult } from "./Review.style"

const ReviewResult = ({
  reviews,
  access,
  searchResultInfo,
  searchOptions,
  setSearchOptions,
  publishReview,
  cancelPublishReview,
}) => {
  if (reviews && reviews.length === 0) {
    return (
      <StyledReviewResult>
        <div className="search-info">Please input your search </div>
      </StyledReviewResult>
    )
  }
  return (
    <StyledReviewResult>
      {searchResultInfo && (
        <div className="search-info">
          {searchResultInfo.total} record found.
        </div>
      )}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  {...{
                    inputProps: {
                      "aria-label": `Checkbox select review all`,
                    },
                  }}
                />
              </TableCell>
              {/* <TableCell>Is Like</TableCell> */}
              <TableCell>Status</TableCell>
              <TableCell>Target</TableCell>
              <TableCell>Comment</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reviews.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Checkbox
                    {...{
                      inputProps: {
                        "aria-label": `Checkbox select ${row.content}`,
                      },
                    }}
                  />
                </TableCell>
                {/* <TableCell component="th" scope="row">
                  {row.isLike === 1 ? <ThumbUpIcon /> : <ThumbDownIcon />}
                </TableCell> */}
                <TableCell>
                  <div>{row.pubStatus ? "Published" : "Unpublished"}</div>
                  <div>Star : {row.star}</div>
                </TableCell>
                <TableCell>
                  <div>
                    <Chip
                      label={REVIEW_TYPE[row.type]}
                      size="small"
                      color={row.type === 1 ? "primary" : "success"}
                    />
                  </div>
                  <div>Program: {row.programTitle}</div>
                  <div>Innercise: {row.innerciseTitle}</div>
                </TableCell>
                <TableCell>{row.content}</TableCell>
                <TableCell>
                  <div>
                    <strong>{`${row.firstName} ${row.lastName}`}</strong>
                  </div>
                  <div>{`${row.email}`}</div>
                </TableCell>
                <TableCell>{moment(row.createdAt).format("lll")}</TableCell>
                <TableCell>
                  {row.pubStatus === 1 && access.cancelPubReviewAccess && (
                    <Button
                      style={{ width: "160px" }}
                      onClick={() => {
                        cancelPublishReview(row.id)
                      }}
                      variant="outlined"
                    >
                      Cancel Publish
                    </Button>
                  )}
                  {row.pubStatus === 0 && access.pubReviewAccess && (
                    <Button
                      style={{ width: "160px" }}
                      onClick={() => {
                        publishReview(row.id)
                      }}
                      variant="outlined"
                    >
                      Publish
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {searchResultInfo && (
              <TableRow key={"pg"}>
                <TableCell colSpan={8}>
                  <div className="pagination-section">
                    <Pagination
                      count={searchResultInfo.pages}
                      page={searchResultInfo.currentPageNo}
                      onChange={(event, value) => {
                        setSearchOptions({
                          ...searchOptions,
                          pageNo: value,
                        })
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledReviewResult>
  )
}

export default ReviewResult
