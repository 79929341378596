import React, { useState, useEffect, useRef } from "react"
import { Button } from "@mui/material"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import CircularProgress from "@mui/material/CircularProgress"
import { queryInnerciseByTitle } from "services/program"
import { verifyResponse } from "utils/httpRequest"
import { InnerciseSelectorWrapper } from "./InnerciseSelector.style"

const InnerciseSelector = ({ onSelect }) => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [searchText, setSearchText] = useState([])
  const [selectedInnercise, setSelectedInnercise] = useState(null)

  const searchInnerciseByTitle = async () => {
    // query innercise by title
    setLoading(true)
    const response = await queryInnerciseByTitle({
      pageNo: 1,
      pageSize: 1000,
      title: searchText,
    })
    setLoading(false)
    verifyResponse(response)
    setSearchResults(response.data.list)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const openDlg = (editRole) => {
    setOpen(true)
  }

  const dlg = (
    <InnerciseSelectorWrapper open={open}>
      <DialogTitle> Innercise Selector</DialogTitle>
      <DialogContent>
        <div className="search-bar">
          <TextField
            className="search-text"
            placeholder="Search Innercise by Title"
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value)
            }}
          />
          <Button
            variant="contained"
            size="small"
            disabled={searchText.toString().trim() === ""}
            onClick={searchInnerciseByTitle}
          >
            Search
          </Button>
          <Button
            variant="primary"
            size="small"
            onClick={() => {
              //clear search input text
              setSearchText("")
            }}
          >
            Clear
          </Button>
        </div>
        <div className="search-results">
          {loading && <CircularProgress className="loading-icon" />}
          {!loading &&
            searchResults.map((result) => {
              return (
                <div
                  className={`result-item ${
                    selectedInnercise &&
                    selectedInnercise.id === result.id &&
                    "selected"
                  }`}
                  onClick={() => {
                    setSelectedInnercise(result)
                  }}
                >
                  <img
                    className="thumb"
                    src={result.thumbResourceDto.urlDto.imgUrl_XS}
                    alt="thumb"
                  />
                  <div className="content">
                    <div className="title">{result.title}</div>
                    <p>{result.description}</p>
                  </div>
                </div>
              )
            })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          variant="contained"
          size="small"
          disabled={selectedInnercise === null}
          onClick={() => {
            onSelect(selectedInnercise)
            setOpen(false)
          }}
        >
          Select
        </Button>
      </DialogActions>
    </InnerciseSelectorWrapper>
  )

  return [openDlg, dlg, loading]
}

export default InnerciseSelector
