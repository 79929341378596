import React, { useState } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { addAdminUser } from "services/admin";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { StyledAdminTableDialog } from "./Admin.style";

const AdminDlg = ({ onSubmit }) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const openDlg = () => {
    reset();
    setOpen(true);
  };

  const handleAction = async (data) => {
    setLoading(true);
    const response = await addAdminUser(data);
    if (response.rtnCode === 200) {
      enqueueSnackbar("Create Admin User success", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Create Admin User Fail", {
        variant: "warning",
      });
    }
    onSubmit();
    setLoading(false);
    handleClose();
  };

  const dlg = (
    <StyledAdminTableDialog open={open}>
      <DialogTitle>Create New Admin User</DialogTitle>
      <form onSubmit={handleSubmit(handleAction)}>
        <DialogContent>
          <Box>
            <FormControl fullWidth margin="dense">
              <TextField
                label="Email"
                variant="standard"
                fullWidth
                type="text"
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                })}
                error={!!errors?.email}
                helperText={!!errors?.email ? "* Email is required" : " "}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth margin="dense">
              <TextField
                label="Password"
                variant="standard"
                fullWidth
                type="password"
                {...register("password", { required: true })}
                error={!!errors?.password}
                helperText={!!errors?.password ? "* Password is required" : " "}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth margin="dense">
              <TextField
                label="Nickname"
                variant="standard"
                fullWidth
                {...register("nickName", { required: true })}
                error={!!errors?.nickName}
                helperText={!!errors?.nickName ? "* Nickname is required" : " "}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" size="small">
            Create
          </Button>
        </DialogActions>
      </form>
    </StyledAdminTableDialog>
  );

  return [openDlg, dlg, loading];
};

AdminDlg.propTypes = {
  onSubmit: PropTypes.object.isRequired,
};

export default AdminDlg;
