import React, { useState, useEffect } from "react"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { CATEGORY_SELECTOR_MODE } from "constants/selector"
import { getFullCategories } from "services/category"
import { verifyResponse } from "utils/httpRequest"
import { CategorySelectorDialog } from "./CategorySelector.style"

const CategorySelector = (props) => {
  const { onUpdate } = props
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [mode, setMode] = useState(0)
  const [categories, setCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])

  const openDlg = async (inputCategories, selectMode) => {
    setSelectedCategories([])
    console.log("open categories: ", inputCategories)
    setSelectedCategories(inputCategories)
    setMode(selectMode)
    setOpen(true)
  }

  const loadCategories = async () => {
    setLoading(true)
    const response = await getFullCategories()
    verifyResponse(response)
    setCategories(response.data)
    setLoading(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    loadCategories()
  }, [])

  // useEffect(() => {
  //   console.log("categories: ", categories)
  // }, [categories])

  const onCheckBoxChange = (e, category) => {
    if (e.target.checked) {
      setSelectedCategories([
        ...selectedCategories,
        {
          id: category.id,
          name: category.name,
        },
      ])
    } else {
      setSelectedCategories(
        selectedCategories.filter((el) => el.id !== category.id)
      )
    }
  }

  const dlg = (
    <CategorySelectorDialog open={open}>
      <DialogTitle>Category Selector</DialogTitle>
      <DialogContent>
        {/* Render Selection for SubCategory Mode */}
        {CATEGORY_SELECTOR_MODE.SUB_CATEGORY.value === mode &&
          categories.map((category) => (
            <div key={`category-list-item-${category.id}`}>
              <h5>{category.name}</h5>
              <div className="subcategory-section">
                {category.subCategoryList.map((subCategory) => (
                  <FormControlLabel
                    key={`subCategory-list-item-label-${subCategory.id}`}
                    control={
                      <Checkbox
                        checked={
                          selectedCategories.find(
                            (el) => el.id === subCategory.id
                          ) !== undefined
                        }
                        onChange={(e) => {
                          onCheckBoxChange(e, subCategory)
                        }}
                      />
                    }
                    label={subCategory.name}
                  />
                ))}
              </div>
            </div>
          ))}
        {/* Render Selection for Category Mode */}
        {CATEGORY_SELECTOR_MODE.CATEGORY.value === mode &&
          categories.map((category) => (
            <div
              key={`category-list-item-${category.id}`}
              className="category-check-item"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      selectedCategories.find((el) => el.id === category.id) !==
                      undefined
                    }
                    onChange={(e) => {
                      onCheckBoxChange(e, category)
                    }}
                  />
                }
                label={category.name}
              />
            </div>
          ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>close</Button>
        <Button
          onClick={() => {
            onUpdate(selectedCategories)
            setOpen(false)
          }}
        >
          Update
        </Button>
      </DialogActions>
    </CategorySelectorDialog>
  )

  return [openDlg, dlg, loading]
}

export default CategorySelector
