import moment from "moment"

const getLocalTimeZone = () => {
  const offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset)
  return (
    (offset < 0 ? "+" : "-") +
    ("00" + Math.floor(o / 60)).slice(-2) +
    ":" +
    ("00" + (o % 60)).slice(-2)
  )
}

const getLocalTimeZoneName = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

const getUnixEpochTime = (momentObject) => {
  return momentObject ? momentObject.valueOf() : moment().valueOf()
}

const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  // const formattedHours = hours.toString().padStart(2, "0")
  const formattedMinutes = minutes.toString().padStart(2, "0")
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0")

  if (hours === 0 && minutes === 0) {
    return `${formattedSeconds} s`
  } else if (hours === 0) {
    return `${formattedMinutes} m: ${formattedSeconds} s`
  } else {
    return `${hours} h: ${formattedMinutes} m: ${formattedSeconds} s`
  }
}

export { getLocalTimeZone, getLocalTimeZoneName, getUnixEpochTime, formatTime }
