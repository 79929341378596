import { httpPost } from "utils/httpRequest"

const getRedisValueByKey = async ({ key }) => {
  const resp = await httpPost("admin/adminUtil/queryFromRedis", {
    key,
  })
  return resp.data
}

export { getRedisValueByKey }
