import React, { useState } from "react"
import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { addPermission, updatePermission } from "services/permission"
import { useForm } from "react-hook-form"
import { useSnackbar } from "notistack"
import { StyledPermissionDialog } from "./Permission.style"

const PermissionDlg = (props) => {
  const initPermission = {
    tag: "",
    module: "",
    endpointUrl: "",
    description: "",
  }

  const { enqueueSnackbar } = useSnackbar()

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm()

  const { onSubmit, modules } = props
  const [permission, setPermission] = useState(initPermission)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const openDlg = (editPermission) => {
    reset()
    setPermission(editPermission || initPermission)
    setIsEditMode(editPermission ? true : false)
    setOpen(true)
  }

  const handleAction = async (data) => {
    setLoading(true)
    const response = isEditMode
      ? await updatePermission({ ...data, id: permission.id })
      : await addPermission(data)
    if (response.rtnCode === 200) {
      enqueueSnackbar(
        isEditMode ? "Permission Update" : "Create Permission success",
        {
          variant: "success",
        }
      )
    } else {
      enqueueSnackbar(
        isEditMode ? "Update Permission Fail" : "Create Permission Fail",
        {
          variant: "warning",
        }
      )
    }
    onSubmit()
    setLoading(false)
    handleClose()
  }

  const dlg = (
    <StyledPermissionDialog open={open}>
      <DialogTitle>
        {isEditMode ? "Edit Permission" : "Add New Permission"}
      </DialogTitle>
      <form onSubmit={handleSubmit(handleAction)}>
        <DialogContent>
          <Box>
            <FormControl fullWidth margin="dense">
              <TextField
                label="Tag"
                variant="standard"
                fullWidth
                defaultValue={permission.tag}
                {...register("tag", { required: true })}
                error={!!errors?.tag}
                helperText={!!errors?.tag ? "* Tag is required" : " "}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth margin="dense">
              <TextField
                label="End Point Url"
                variant="standard"
                fullWidth
                defaultValue={permission.endpointUrl}
                {...register("endpointUrl", { required: true })}
                error={!!errors?.endpointUrl}
                helperText={!!errors?.endpointUrl ? "* Url is required" : " "}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl variant="standard" fullWidth margin="dense">
              <input
                type="hidden"
                defaultValue={permission.module}
                {...register("module", { required: true })}
              />
              <InputLabel id="permission-modal-select-label">Module</InputLabel>
              <Select
                labelId="permission-module-select"
                id="permission-module-select"
                defaultValue={permission.module}
                label="Module"
                onChange={(e) => {
                  setValue("module", e.target.value)
                }}
              >
                {modules.map((module) => (
                  <MenuItem
                    key={`permission-module-item-${module.tag}`}
                    value={module.tag}
                  >
                    {module.description}
                  </MenuItem>
                ))}
              </Select>
              {!!errors?.module && (
                <div className="error">* Module is required</div>
              )}
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth margin="dense">
              <TextField
                margin="dense"
                label="Description"
                variant="standard"
                rows={5}
                multiline
                defaultValue={permission.description}
                {...register("description", { required: true })}
                error={!!errors?.description}
                helperText={
                  !!errors?.description ? "* Description is required" : ""
                }
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" size="small">
            {isEditMode ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </form>
    </StyledPermissionDialog>
  )

  return [openDlg, dlg, loading]
}

export default PermissionDlg
