const subscribeTableCols = [
  { field: "day", headerName: "Date" },
  {
    field: "totalSubscription",
    headerName: "Total Subscription",
    type: "number",
    align: "center",
    width: 130,
  },
  {
    field: "monthlyCount",
    headerName: "Monthly Sub Start",
    type: "number",
    align: "center",
    width: 130,
  },
  {
    field: "monthlyConvertRate",
    headerName: "Monthly Conv.%",
    type: "number",
    align: "center",
    width: 120,
  },
  {
    field: "yearlyCount",
    headerName: "Yearly Sub Start",
    type: "number",
    align: "center",
    width: 130,
  },
  {
    field: "yearlyConvertRate",
    headerName: "Yearly Conv.%",
    type: "number",
    align: "center",
    width: 110,
  },
  {
    field: "lifeTimeCount",
    headerName: "Life Time",
    type: "number",
    headerAlign: "center",
    align: "center",
    width: 100,
  },
  {
    field: "monthlyFreeTrialCount",
    headerName: "Monthly Trial Start",
    type: "number",
    align: "center",
    width: 130,
  },
  {
    field: "yearlyFreeTrialCount",
    headerName: "Yearly Trial Start",
    type: "number",
    align: "center",
    width: 130,
  },
]

const subscribeMostPopularCategoryTableCols = [
  {
    field: "name",
    headerName: "Category",
    type: "string",
    align: "left",
    width: 500,
  },

  {
    field: "views",
    headerName: "Views",
    type: "number",
    headerAlign: "center",
    align: "center",
    width: 150,
  },
]

const subscribeMostPopularCategoryBySpentTableCols = [
  {
    field: "name",
    headerName: "Category",
    type: "string",
    align: "left",
    width: 500,
  },
  {
    field: "innercisingTime",
    headerName: "Spent",
    type: "number",
    headerAlign: "center",
    align: "center",
    width: 150,
  },
]

const subscribeMostPopularInnerciseTableCols = [
  {
    field: "title",
    headerName: "Innercise",
    type: "string",
    align: "left",
    width: 350,
  },
  {
    field: "category",
    headerName: "Category",
    type: "string",
    align: "left",
    width: 350,
  },
  {
    field: "views",
    headerName: "Views",
    type: "number",
    headerAlign: "center",
    align: "center",
    width: 150,
  },
]

const subscribeMostPopularInnerciseSpentTableCols = [
  {
    field: "title",
    headerName: "Innercise",
    type: "string",
    align: "left",
    width: 500,
  },
  {
    field: "innercisingTime",
    headerName: "Spent",
    type: "number",
    headerAlign: "center",
    align: "center",
    width: 150,
  },
]

const paywallInfoTableCols = [
  {
    field: "day",
    headerName: "Date",
    type: "string",
    align: "left",
    width: 150,
  },
  {
    field: "total",
    headerName: "Total",
    type: "number",
    headerAlign: "center",
    align: "center",
    width: 150,
  },
  {
    field: "homepageCount",
    headerName: "Home Page",
    type: "number",
    headerAlign: "center",
    align: "center",
    width: 150,
  },
  {
    field: "onboardingCount",
    headerName: "OnBoarding",
    type: "number",
    headerAlign: "center",
    align: "center",
    width: 150,
  },
  {
    field: "settingsCount",
    headerName: "Settings",
    type: "number",
    headerAlign: "center",
    align: "center",
    width: 150,
  },
]

export {
  subscribeTableCols,
  subscribeMostPopularCategoryTableCols,
  subscribeMostPopularCategoryBySpentTableCols,
  subscribeMostPopularInnerciseTableCols,
  subscribeMostPopularInnerciseSpentTableCols,
  paywallInfoTableCols,
}
