import styled from "@emotion/styled"

const FeaturedProgramWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .category-section {
    .program-list {
      display: flex;
      flex-direction: row;

      .program {
        position: relative;
        display: flex;
        flex-direction: column;
        border: 1px solid #eee;
        border-radius: 5px;
        width: 220px;
        height: 220px;
        padding: 11px;
        background-color: white;
        margin: 0 20px 0px 0px;
        img {
          width: 100%;
        }
        .title {
          font-size: 14px;
          font-weight: 200;
          margin: 9px 0px;
        }

        .delete-btn {
          /* background-color: gray; */
          width: fit-content;
          position: absolute;
          top: -22px;
          right: -23px;
          svg {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
`

export { FeaturedProgramWrapper }
