import React, { useState, useRef, useCallback } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Button from "@mui/material/Button"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import { verifyResponse } from "utils/httpRequest"
import { useForm, Controller } from "react-hook-form"
import { useSnackbar } from "notistack"
import { addPrime, updatePrime } from "services/prime"
import MediaContentDisplay from "components/common/MediaManager/MediaContentDisplay"
import { PrimeType, MEDIA_TYPE_INDEX } from "constants/global"

const StyledPrimeDialog = styled(Dialog)`
  .MuiPaper-root {
    min-width: 600px;
    min-height: 560px;
  }

  .MuiFormControl-root {
    margin: 0 0 30px 0px;
    width: 100%;
  }

  .MuiOutlinedInput-root {
    width: 100%;
  }

  .media-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    &:first-of-type {
      margin-top: 0;
    }
    .content-grid {
      display: flex;
      width: 100%;
      flex-direction: column;
      label {
        margin: 15px 0 15px 0;
      }
      .error {
        color: red;
        font-size: 13px;
        margin: 8px 0px 0px 10px;
      }
      button {
        margin-bottom: 5px;
      }
      .content {
        border: 2px dashed gray;
        height: 160px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: gray;
        margin-top: 10px;
        padding: 15px 0;
        flex-direction: column;
      }
    }
  }

  .error-label {
    font-size: 12px;
    color: red;
    margin: 8px 0;
  }
`

const PrimeDlg = (props) => {
  const initPrime = {
    title: "",
    type: 0,
    quoteContent: "",
    resourceId: null,
    thumbResourceId: null,
  }

  const { enqueueSnackbar } = useSnackbar()

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "0",
    },
  })

  const { onSubmit, openMediaManager } = props
  const [prime, setPrime] = useState(initPrime)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)

  const [resource, setResource] = useState(false)
  const [thumbnailResource, setThumbnailResource] = useState(false)

  const resourceRef = useRef(null)
  const thumbnailResourceRef = useRef(null)

  const updateResource = () => {
    setResource({
      ...resourceRef.current,
      selectAction: selectResource,
      clearResourceAction: clearResource,
    })
  }

  const selectResource = useCallback(() => {
    openMediaManager(
      (media) => {
        resourceRef.current = media
        setValue("resourceId", media.id)
        updateResource()
      },
      parseInt(watch("type")) === PrimeType.Video.value
        ? [MEDIA_TYPE_INDEX.video]
        : [MEDIA_TYPE_INDEX.audio]
    )
  }, [watch("type")])

  const clearResource = () => {
    resourceRef.current = null
    setValue("resourceId", null)
    updateResource()
  }

  //----------------------------

  const updateThumbnailResource = () => {
    setThumbnailResource({
      ...thumbnailResourceRef.current,
      selectAction: selectThumbnailResource,
      clearResourceAction: clearThumbnailResource,
    })
  }

  const selectThumbnailResource = () => {
    openMediaManager(
      (media) => {
        thumbnailResourceRef.current = media
        setValue("thumbResourceId", media.id)
        updateThumbnailResource()
      },
      [MEDIA_TYPE_INDEX.image, MEDIA_TYPE_INDEX.video]
    )
  }

  const clearThumbnailResource = () => {
    thumbnailResourceRef.current = null
    setValue("thumbResourceId", null)
    updateThumbnailResource()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const openDlg = (editPrime) => {
    reset()

    setPrime(editPrime || initPrime)
    if (editPrime) {
      resourceRef.current = editPrime.resourceDto
      thumbnailResourceRef.current = editPrime.thumbResourceDto
      setValue("type", editPrime.type)
    } else {
      resourceRef.current = null
      thumbnailResourceRef.current = null
    }
    updateResource()
    updateThumbnailResource()
    setIsEditMode(editPrime ? true : false)
    setOpen(true)
  }

  const handleAction = async (data) => {
    // send request
    setLoading(true)
    const response = isEditMode
      ? await updatePrime({ ...data, id: prime.id })
      : await addPrime(data)
    verifyResponse(response)

    if (response.rtnCode === 200) {
      // handling success message
      enqueueSnackbar(isEditMode ? "Prime Updated" : "Create Prime Success", {
        variant: "success",
      })
    } else {
      // handling error message
      enqueueSnackbar(isEditMode ? "Prime Update Fail" : "Create Prime Fail", {
        variant: "warning",
      })
    }
    setLoading(false)
    onSubmit()
    handleClose()
  }

  const dlg = (
    <StyledPrimeDialog open={open} onClose={handleClose}>
      <DialogTitle>{isEditMode ? "Edit Prime" : "Add Daily Prime"}</DialogTitle>
      <form onSubmit={handleSubmit(handleAction)}>
        <DialogContent>
          <div>
            <FormControl>
              <TextField
                autoFocus
                autoComplete="off"
                margin="dense"
                id="prime-title"
                label="Title"
                type="text"
                fullWidth
                defaultValue={prime.title}
                {...register("title", { required: true })}
                error={!!errors?.title}
                helperText={!!errors?.title ? "Title is required" : " "}
                variant="standard"
              />
              <TextField
                autoFocus
                autoComplete="off"
                margin="dense"
                id="prime-display-title"
                label="Display Title"
                type="text"
                fullWidth
                defaultValue={prime.displayTitle}
                {...register("displayTitle", { required: true })}
                error={!!errors?.title}
                helperText={
                  !!errors?.displayTitle ? "Display Title is required" : " "
                }
                variant="standard"
              />
              <FormLabel id="prime-type-label">Type</FormLabel>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={PrimeType.Quote.value.toString()}
                      control={<Radio />}
                      label={PrimeType.Quote.text}
                    />
                    <FormControlLabel
                      value={PrimeType.Audio.value.toString()}
                      control={<Radio />}
                      label={PrimeType.Audio.text}
                    />
                    <FormControlLabel
                      value={PrimeType.Video.value.toString()}
                      control={<Radio />}
                      label={PrimeType.Video.text}
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </div>
          <div>
            <FormControl>
              {parseInt(watch("type")) === 0 ? (
                <TextField
                  label="Quote Content"
                  rows={8}
                  multiline
                  fullWidth
                  defaultValue={prime.quoteContent}
                  {...register("quoteContent", { required: true })}
                  error={!!errors?.quoteContent}
                />
              ) : (
                <div className="media-section">
                  <div className="content-grid">
                    <FormLabel id="prime-resource-label">Resource</FormLabel>
                    <input
                      type="hidden"
                      defaultValue={prime.resourceId}
                      {...register("resourceId", { required: true })}
                    />
                    <MediaContentDisplay resource={resource} />
                    {!!errors?.resourceId}
                    <div className="error">
                      {!!errors?.resourceId && "* Resource is required"}
                    </div>
                  </div>
                </div>
              )}
              <div className="media-section">
                <div className="content-grid">
                  <FormLabel id="prime-thumbnail-label">Thumbnail</FormLabel>
                  <input
                    type="hidden"
                    defaultValue={prime.thumbResourceId}
                    {...register("thumbResourceId", { required: true })}
                  />
                  <MediaContentDisplay resource={thumbnailResource} />
                  <div className="error">
                    {!!errors?.thumbResourceId && "* Thumbnail is required"}
                  </div>
                </div>
              </div>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" size="small">
            {isEditMode ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </form>
    </StyledPrimeDialog>
  )

  return [openDlg, dlg, loading]
}

PrimeDlg.propTypes = {
  onSubmit: PropTypes.func,
}

PrimeDlg.defaultProps = {
  onSubmit: null,
}

export default PrimeDlg
