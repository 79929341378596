import React, { useState, useEffect } from "react"
import { Button } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import StarIcon from "@mui/icons-material/Star"
import StarOutlineIcon from "@mui/icons-material/StarOutline"
import Typography from "@mui/material/Typography"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import {
  getReviewsByMemberId,
  pubReview,
  cancelPubReview,
} from "services/review"
import { verifyResponse } from "utils/httpRequest"
import { StyledMemberReviews } from "./Member.style"
import moment from "moment"
import { getRandomInt } from "utils/number"

const MemberReviews = ({ memberId }) => {
  const [reviews, setReviews] = useState([])
  const [reviewLoading, setReviewLoading] = useState(false)
  const [reviewStatusLoading, setReviewStatusLoading] = useState(0)

  const loadMemberReviews = async () => {
    setReviewLoading(true)
    const response = await getReviewsByMemberId(memberId)
    verifyResponse(response)
    setReviews(response.data.list)
    setReviewLoading(false)
  }

  useEffect(() => {
    loadMemberReviews()
  }, [])

  const changeReviewPubStatus = async (review) => {
    setReviewStatusLoading(review.id)
    review.pubStatus === 0
      ? await pubReview(review.id)
      : await cancelPubReview(review.id)
    loadMemberReviews()
    setTimeout(() => {
      setReviewStatusLoading(0)
    }, 2000)
  }

  if (reviewLoading && reviewStatusLoading === 0) {
    return (
      <Card className="card" sx={{ marginBottom: "10px" }}>
        <CardContent>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            <CircularProgress />
          </Typography>
        </CardContent>
      </Card>
    )
  }

  return (
    <StyledMemberReviews>
      {reviews.length === 0 ? (
        <Card className="card" sx={{ marginBottom: "10px" }}>
          <CardContent>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              No review yet.
            </Typography>
          </CardContent>
        </Card>
      ) : (
        reviews.map((review) => {
          return (
            <div className="review" key={`review-item-${review.id}`}>
              <Card className="card" sx={{ marginBottom: "10px" }}>
                <CardContent>
                  <div>
                    <div className="review-rate">
                      Rate:{" "}
                      {Array.from(Array(review.star)).map(() => {
                        return (
                          <StarIcon
                            key={`star-icon-${getRandomInt(0, 9999999)}`}
                          />
                        )
                      })}
                      {Array.from(Array(5 - review.star)).map(() => {
                        return (
                          <StarOutlineIcon
                            key={`star-outline-icon-${getRandomInt(
                              0,
                              9999999
                            )}`}
                          />
                        )
                      })}
                    </div>
                  </div>
                  <div className="review-time">
                    {moment(review.createdAt).format("LLL")}
                  </div>
                  <Typography className="review-content" variant="body2">
                    {review.content}
                  </Typography>
                </CardContent>
                <CardActions>
                  {reviewStatusLoading === review.id ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      size="small"
                      onClick={() => {
                        changeReviewPubStatus(review)
                      }}
                    >
                      {review.pubStatus === 0 ? "Publish" : "Unpublished"}
                    </Button>
                  )}
                </CardActions>
              </Card>
            </div>
          )
        })
      )}
    </StyledMemberReviews>
  )
}

export default MemberReviews
