import styled from "@emotion/styled";
import { Dialog } from "@mui/material";
import Slider from "@mui/material/Slider";

const StyledSubscriptionProductDlg = styled(Dialog)`
  /* display: flex; */
  .MuiPaper-root {
    min-width: 600px;
    min-height: 360px;
    max-height: calc(100% - 30px);
  }
  .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    max-height: none;
    height: 870px;
  }

  .MuiFormControl-root {
    margin: 0 0 30px 0px;
    width: 100%;

    &:last-of-type {
      margin: 0;
    }
  }

  .control-label {
    font-size: 12px;
    color: #717171;
  }

  .form-row-two-cols {
    display: flex;
    width: 100%;

    height: 70px;

    .MuiFormControl-root,
    .MuiInput-root {
      width: 95%;
    }
  }
`;

const ProgramRankSlider = styled(Slider)({
  color: "#52af77",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#52af77",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const StyledSubscriptionProduct = styled.div`
  width: 100%;
`;

const StyledSubscriptionProductTable = styled.div`
  button {
    margin-left: 10px;
  }
`;

const StyledPlatformSection = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100px;
  /* border: 1px solid gray; */

  .platform-title-label {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    margin: 10px 0 5px 0;
  }
  .platform-action-button {
    width: 50px;
    margin-top: 10px;
    margin-left: 10px;
  }

  .platform-add-buttons {
    button {
      margin-right: 10px;
    }
  }
`;

export {
  StyledSubscriptionProductDlg,
  StyledSubscriptionProduct,
  ProgramRankSlider,
  StyledSubscriptionProductTable,
  StyledPlatformSection,
};
