import styled from "@emotion/styled";
import Dialog from "@mui/material/Dialog";

const StyledFaqDialog = styled(Dialog)`
  .ql-editor {
    height: 250px;
    overflow: auto;
  }

  .MuiPaper-root {
    min-width: 800px;
    min-height: 550px;
  }

  .MuiFormControl-root {
    margin: 0 0 30px 0px;
    width: 100%;
  }

  .MuiTextField-root {
    margin-top: 10px;
  }

  .MuiOutlinedInput-root {
    width: 100%;
  }

  .media-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    &:first-of-type {
      margin-top: 0;
    }
    .content-grid {
      display: flex;
      width: 100%;
      flex-direction: column;
      button {
        margin-bottom: 5px;
      }
      .content {
        border: 2px dashed gray;
        height: 160px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: gray;
        margin-top: 10px;
        padding: 15px 0;
      }
    }
  }
`;

const StyledFaqSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const StyledFaqList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  .faq-item {
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    margin: 10px 0;
    padding: 15px;
    background-color: white;
    border-radius: 3px;
    font-size: 15px;

    .MuiChip-root {
      min-width: 80px;
      margin-right: 20px;
    }

    .faq-content-session {
      display: flex;
      align-items: center;
      margin: 15px 0;
    }

    .faq-content {
      display: inline-block;
      line-height: 20px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
    }
  }
`;

export { StyledFaqDialog, StyledFaqSection, StyledFaqList };
