import styled from "styled-components"
import { Dialog } from "@mui/material"

const RailGroupManagementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .header-bar {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .groups {
    padding: 20px 0;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;

    .rail-group {
      cursor: move;
      font-size: 16px;
      background-color: white;
      border: 1px solid #d6d7d7;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px;
      border-radius: 5px;
      padding: 15px;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 10px;

      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      .content {
        font-size: 12px;
        width: 80%;
        .info-section {
          width: 100%;
          padding: 0px 5px;
          margin-bottom: 5px;
          .label {
            margin-right: 15px;
            display: inline-block;
            background-color: #e8d6d6;
            color: #947474;
            padding: 3px 10px;
            min-width: 100px;
            border-radius: 3px;
          }

          .rule-tag {
            display: inline-block;
            background-color: #97a04d;
            margin: 0px 8px 3px 0;
            padding: 3px 5px;
            border-radius: 3px;
            color: white;
            text-transform: capitalize;
          }

          .rail-list {
            display: inline-block;
            .rail-item {
              display: inline-block;
              background-color: #bfc58f;
              margin: 0px 8px 3px 0;
              padding: 3px 5px;
              border-radius: 3px;
              color: white;
            }
          }
        }
      }

      .actions {
        .MuiIconButton-root {
          margin-left: 5px;
        }
      }
    }
  }
`

const StyledRailGroupDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  .MuiPaper-root {
    width: 100%;
    min-width: 700px;
  }
  .error {
    font-size: 0.75rem;
    color: #d32f2f;
    margin-top: 3px;
  }
  .label-text {
    font-weight: 400;
    font-size: 13px;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.6);
  }

  .rail-select-region {
    .list-label {
      font-size: 16px;
      margin-bottom: 10px;
    }
    .MuiBox-root {
      box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
        rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
      height: 250px;
      overflow-y: scroll;
      border-radius: 5px;
    }
    p {
      font-size: 16px;
      color: #000000b0;
    }
  }

  .rule-settings {
    background-color: #ffffff;
    padding: 20px;
    margin-top: 10px;
    font-size: 15px;
    color: #0000008c;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    input,
    select,
    .uiSelect-select {
      color: #0000008c;
    }

    .random-rule-input-panel {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      font-size: 15px;
      .num-of-random-num {
        width: 70px;
        margin: 0 10px;
        color: #0000008c;
      }
      .target {
        min-width: 200px;
        margin: 0 10px;
      }
    }
  }
`

export { RailGroupManagementWrapper, StyledRailGroupDialog }
