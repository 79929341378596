import React, { useState } from "react"
import PropTypes from "prop-types"
import { Container, Draggable } from "react-smooth-dnd"
import styled from "@emotion/styled"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { useSnackbar } from "notistack"
import { exchangeSubCategoryIndex } from "services/category"
import { exchangeArrayItem } from "utils/data"
import { StyledCategoryProgramItem } from "./Category.style"

const StyledCategoryReorderSubCategoryDialog = styled(Dialog)`
  .MuiPaper-root {
    min-width: 880px;
    min-height: 600px;
  }
  .smooth-dnd-draggable-wrapper {
    padding: 0 5px;
  }
`

const CategoryReorderSubCategoryDlg = ({ access, reloadCallback }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState(null)
  const [isChanged, setIsChanged] = useState(false)
  const [categoryId, setCategoryId] = useState(null)

  const openDlg = (subCategories, inputCategoryId) => {
    setCategories(subCategories)
    setCategoryId(inputCategoryId)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const updateOrder = async () => {
    try {
      setLoading(true)
      const response = await exchangeSubCategoryIndex(
        categories.map((category, index) => {
          return {
            id: category.id,
            index: category.subCategoryIndex,
          }
        })
      )

      if (response.rtnCode !== 200) {
        enqueueSnackbar("Fail to the update SubCategory order", {
          variant: "warning",
        })
      } else {
        setIsChanged(false)
        enqueueSnackbar("Successfully update SubCategory order", {
          variant: "success",
        })
        reloadCallback(categoryId)
      }
    } catch (e) {
      console.error("error: ", e)
      enqueueSnackbar("Fail to the update SubCategory order", {
        variant: "warning",
      })
    } finally {
      setLoading(false)
    }
  }

  // render program list item
  const renderSubCategoryItem = (category) => {
    return (
      <StyledCategoryProgramItem key={`subcategory-item-${category.id}`}>
        <div className="info">
          <div className="identity">SubCategory ID: {category.id} </div>
          <div className="title">Title: {category.name}</div>
          <div className="desc">Description: {category.description}</div>
        </div>
      </StyledCategoryProgramItem>
    )
  }

  const dlg = (
    <StyledCategoryReorderSubCategoryDialog open={open} onClose={handleClose}>
      <DialogTitle>Reorder SubCategory</DialogTitle>
      <DialogContent>
        {access.listProgramBySubCategoryAccess ? (
          <Container
            onDrop={async (e) => {
              setIsChanged(true)
              const updateIndexArray = exchangeArrayItem({
                addedIndex: e.addedIndex,
                removedIndex: e.removedIndex,
                indexString: "subCategoryIndex",
                list: categories,
              })
              setCategories(updateIndexArray)
            }}
          >
            {categories &&
              categories.map((category) => (
                <Draggable key={category.id}>
                  {renderSubCategoryItem(category)}
                </Draggable>
              ))}
          </Container>
        ) : (
          categories &&
          categories.map((category) => (
            <Draggable key={category.id}>
              {renderSubCategoryItem(category)}
            </Draggable>
          ))
        )}
      </DialogContent>

      <DialogActions>
        {access.listProgramBySubCategoryAccess && (
          <Button
            variant="contained"
            color="secondary"
            onClick={updateOrder}
            disabled={!isChanged}
          >
            Save
          </Button>
        )}
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </StyledCategoryReorderSubCategoryDialog>
  )

  return [openDlg, dlg, loading]
}

CategoryReorderSubCategoryDlg.propTypes = {
  onSubmit: PropTypes.func,
}

CategoryReorderSubCategoryDlg.defaultProps = {
  onSubmit: null,
}

export default CategoryReorderSubCategoryDlg
