import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import moment from "moment"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { addRedeemCodes } from "services/redeem"
import { useForm } from "react-hook-form"
import { useSnackbar } from "notistack"
import {
  DURATION_UNIT_LIST,
  DURATION_UNIT_OBJECT,
  REDEEM_CODE_TYPE,
  REDEEM_CODE_TYPE_INDEX,
} from "constants/global"
import { StyledRedeemDialog } from "./Redeem.style"

const RedeemDlg = ({ onSubmit, subscriptionProduct }) => {
  const initRedeem = {
    codeType: 1,
    productCode: "",
    duration: null,
    durationUnit: 1,
    startAt: null,
    endAt: null,
    redeemCodeCount: 0,
  }

  const { enqueueSnackbar } = useSnackbar()

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm()

  const [redeem, setRedeem] = useState(null)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dateRange, setDateRange] = React.useState([null, null])
  const [durationUnit, setDurationUnit] = React.useState(1)
  const [durationError, setDurationError] = useState("")
  const [redeemCodeCountError, setRedeemCodeCountError] = useState("")

  const handleClose = () => {
    setOpen(false)
  }

  const openDlg = () => {
    reset()
    setRedeem(initRedeem)
    setOpen(true)
  }

  const handleAction = async (data) => {
    const postData = {
      ...data,
      durationUnit: durationUnit,
      startAt:
        dateRange[0] && dateRange[1] ? moment(dateRange[0]).valueOf() : null,
      endAt:
        dateRange[0] && dateRange[1] ? moment(dateRange[1]).valueOf() : null,
    }

    setLoading(true)
    const response = await addRedeemCodes(postData)
    if (response.rtnCode === 200) {
      enqueueSnackbar("Redeem code generate success", {
        variant: "success",
      })

      onSubmit(response.data.id)
    } else {
      enqueueSnackbar("Redeem code generate Fail", {
        variant: "warning",
      })
    }

    setLoading(false)
    handleClose()
  }

  useEffect(() => {
    setDateRange([null, null])
    setValue("dateRangeControl", null)

    if (watch("codeType") === REDEEM_CODE_TYPE_INDEX.OFFER_CODE) {
      setDurationUnit(DURATION_UNIT_OBJECT.Month.value)
    }
  }, [watch("codeType")])

  useEffect(() => {
    if (errors.redeemCodeCount) {
      if (errors.redeemCodeCount.type === "min") {
        setRedeemCodeCountError("* Count must > 0")
      } else if (errors.redeemCodeCount.type === "required") {
        setRedeemCodeCountError("* Redeem Code Count is required")
      }
    } else {
      setRedeemCodeCountError("")
    }
  }, [errors.redeemCodeCount])

  useEffect(() => {
    if (errors.duration) {
      if (errors.duration.type === "min") {
        setDurationError("* Duration must > 0")
      } else if (errors.duration.type === "required") {
        setDurationError("* Duration is required")
      }
    } else {
      setDurationError("")
    }
  }, [errors.duration])

  const dlg = redeem ? (
    <StyledRedeemDialog open={open}>
      <DialogTitle>Generate Redeem Codes</DialogTitle>
      <form onSubmit={handleSubmit(handleAction)}>
        <DialogContent>
          <Box>
            <FormControl fullWidth variant="standard" margin="dense">
              <InputLabel id="redeem-code-type-select-label">
                Code Type
              </InputLabel>
              <Select
                labelId="redeem-code-type-select-label-id"
                id="redeem-code-type-select-id"
                defaultValue={redeem.codeType}
                label="Code Type"
                onChange={(e) => {
                  setValue("codeType", e.target.value)
                }}
                {...register("codeType", { required: true })}
              >
                {REDEEM_CODE_TYPE.map((codeType) => (
                  <MenuItem
                    key={`redeem-code-type-${codeType.value}`}
                    value={codeType.value}
                  >
                    {codeType.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth variant="standard" margin="dense">
              <InputLabel id="redeem-product-select-label">Product</InputLabel>
              <Select
                labelId="redeem-product-select-label-id"
                id="redeem-product-select-id"
                defaultValue={redeem.productCode}
                label="Product"
                onChange={(e) => {
                  setValue("productCode", e.target.value)
                }}
                {...register("productCode", { required: true })}
              >
                {subscriptionProduct
                  .filter((product) => {
                    if (
                      watch("codeType") === REDEEM_CODE_TYPE_INDEX.OFFER_CODE
                    ) {
                      return product.code.includes("offercode")
                    } else {
                      return !product.code.includes("offercode")
                    }
                  })
                  .map((product) => (
                    <MenuItem
                      key={`product-code-${product.id}`}
                      value={product.code}
                    >{`${product.groupName}-${product.name}`}</MenuItem>
                  ))}
              </Select>
              <div className="error-message">
                {!!errors?.productCode ? "* Duration is required" : " "}
              </div>
            </FormControl>
          </Box>
          <Box>
            <FormControl
              fullWidth
              variant="standard"
              margin="dense"
              sx={{ marginBottom: "20px" }}
            >
              <TextField
                label="Description"
                variant="standard"
                type="text"
                multiline
                rows={4}
                defaultValue=""
                {...register("description", { required: true, min: 1 })}
                error={!!errors?.description}
                helperText={
                  !!errors?.description ? "* Description is required" : " "
                }
              />
            </FormControl>
          </Box>
          <Box>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="top"
              spacing={2}
              margin="dense"
            >
              <FormControl>
                <TextField
                  label="Duration"
                  variant="standard"
                  type="number"
                  inputProps={{ min: 1 }}
                  defaultValue={1}
                  {...register("duration", { required: true, min: 1 })}
                  error={!!errors?.duration}
                  helperText={durationError}
                />
              </FormControl>
              <FormControl variant="standard" sx={{ width: "140px" }}>
                <InputLabel id="redeem-duration-unit-select-label">
                  Duration Unit
                </InputLabel>
                <Select
                  labelId="redeem-duration-unit-select-label-id"
                  id="redeem-duration-unit-select-id"
                  // defaultValue={1}
                  value={durationUnit}
                  onChange={(e) => {
                    setDurationUnit(e.target.value)
                  }}
                  disabled={
                    watch("codeType") === REDEEM_CODE_TYPE_INDEX.OFFER_CODE
                  }
                  label="Duration Unit"
                >
                  {DURATION_UNIT_LIST.map((durationUnitItem) => (
                    <MenuItem
                      key={`duration-unit-${durationUnitItem.value}`}
                      value={durationUnitItem.value}
                    >
                      {durationUnitItem.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="standard" margin="dense">
                <TextField
                  label="Redeem Code Count"
                  type="number"
                  variant="standard"
                  defaultValue={1}
                  inputProps={{ min: 1 }}
                  disabled={
                    watch("codeType") === REDEEM_CODE_TYPE_INDEX.OFFER_CODE
                  }
                  {...register("redeemCodeCount", { required: true, min: 1 })}
                  error={!!errors?.redeemCodeCount}
                  helperText={redeemCodeCountError}
                />
              </FormControl>
            </Stack>
          </Box>
          <Box>
            <FormControl margin="dense">
              <LocalizationProvider
                variant="standard"
                dateAdapter={AdapterDateFns}
              >
                <input
                  {...register("dateRangeControl", {
                    required:
                      watch("codeType") === REDEEM_CODE_TYPE_INDEX.OFFER_CODE,
                  })}
                  type="hidden"
                />
                <DateRangePicker
                  className="media-search-range-picker-div"
                  startText="Date From"
                  endText="Date To"
                  value={dateRange}
                  onOpen={() => {
                    setTimeout(() => {
                      const htmlDom = document
                        .querySelector(".MuiPickersPopper-root")
                        .querySelector(".media-search-range-picker-div")
                        .querySelectorAll("div")
                      ;[...htmlDom]
                        .filter((a) => {
                          return a.textContent.includes(
                            "MUI X: Missing license key"
                          )
                        })
                        .forEach((a) => a.remove())
                    }, 0)
                  }}
                  onChange={(newValue) => {
                    setDateRange(newValue)
                    setValue("dateRangeControl", newValue)
                  }}
                  renderInput={(startProps, endProps) => (
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="top"
                      spacing={2}
                      margin="dense"
                    >
                      <TextField
                        variant="standard"
                        {...startProps}
                        className="time-input"
                      />
                      <TextField
                        variant="standard"
                        {...endProps}
                        className="time-input"
                      />
                    </Stack>
                  )}
                />
              </LocalizationProvider>
            </FormControl>
            {!!errors?.dateRangeControl && (
              <div className="error-message">* Effect Range is Require</div>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" size="small">
            Generate
          </Button>
        </DialogActions>
      </form>
    </StyledRedeemDialog>
  ) : null

  return [openDlg, dlg, loading]
}

RedeemDlg.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  subscriptionProduct: PropTypes.array.isRequired,
}

export default RedeemDlg
