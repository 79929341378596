import React from "react"
import styled from "@emotion/styled"

const SubscriptionSummaryWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1080px;
  font-size: 14px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  .property-display {
    background-color: #0000001a;
    color: #5f1a1a;
    margin-top: 15px;
    margin-right: 15px;
    padding: 7px 15px;
    border-radius: 5px;
    .title-label {
      display: inline-block;
      font-weight: bold;
      margin-right: 5px;
    }
    .value {
      display: inline-block;
      //
    }
  }
`
const SubscriptionSummary = ({ data }) => {
  return (
    <SubscriptionSummaryWrapper>
      <div className="property-display">
        <div className="title-label">Total: </div>
        <div className="value">{data.total}</div>
      </div>
      <div className="property-display">
        <div className="title-label">Monthly Free Trial: </div>
        <div className="value">{data.monthlyFreeTrial}</div>
      </div>
      <div className="property-display">
        <div className="title-label">Yearly Free Trial: </div>
        <div className="value">{data.yearlyFreeTrial}</div>
      </div>
      <div className="property-display">
        <div className="title-label">Monthly Subscription: </div>
        <div className="value">{data.monthlySubscription}</div>
      </div>
      <div className="property-display">
        <div className="title-label">Yearly Subscription: </div>
        <div className="value">{data.yearlySubscription}</div>
      </div>
      <div className="property-display">
        <div className="title-label">Life Time VIP: </div>
        <div className="value">{data.liftTimeSubscription}</div>
      </div>

      <div className="property-display">
        <div className="title-label">Total Monthly Conv.%: </div>
        <div className="value">{data.totalMonthlyConvertRate}</div>
      </div>
      <div className="property-display">
        <div className="title-label">Total Yearly Conv.%: </div>
        <div className="value">{data.totalYearlyConvertRate}</div>
      </div>
    </SubscriptionSummaryWrapper>
  )
}

export default SubscriptionSummary
