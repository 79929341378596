import React, { useState, useEffect, useRef } from "react"
import AWS from "aws-sdk"
import Button from "@mui/material/Button"
import AttachmentIcon from "@mui/icons-material/Attachment"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import DeleteIcon from "@mui/icons-material/Delete"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import AvTimerRoundedIcon from "@mui/icons-material/AvTimerRounded"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import TextField from "@mui/material/TextField"

import { bytesToSize, secondToTime } from "utils/convert"
import {
  calculateVideoDuration,
  findMediaInfoByFileName,
  generateUniqueFileName,
} from "utils/media"
import {
  MEDIA_TYPE,
  MEDIA_TYPE_INDEX,
  EXT_TO_MEDIA_TYPE,
  EXT_TO_MEDIA_TYPE_COLOR,
} from "constants/global"
import { addResource } from "services/media"
import CircularProgressWithLabel from "./CircularProgressWithLabel"
import {
  StyledFileUploadCard,
  StyledMediaPreview,
  StyledPaperDiv,
} from "./MediaManager.style"

const UploadItemCard = ({
  uploadFile,
  index,
  cmsConfig,
  uploadList,
  setUploadList,
}) => {
  const {
    S3_KEY_ID,
    S3_ACCESS_KEY,
    S3_PRIVATE_BUCKET,
    S3_PUBLIC_BUCKET,
    S3_UPLOAD_BUCKET,
    S3_REGION,
  } = cmsConfig

  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [progress, setProgress] = useState(0)
  const [uploading, setUploading] = useState(false)
  const [uploadSucceeded, setUploadSucceeded] = useState(false)
  const [validated, setValidated] = useState(false)
  const [duration, setDuration] = useState(0)
  const [show, setShow] = useState(true)

  const previewVideoRef = useRef(null)

  AWS.config.update({
    accessKeyId: S3_KEY_ID,
    secretAccessKey: S3_ACCESS_KEY,
    httpOptions: {
      timeout: 120 * 60 * 1000,
    },
  })

  const myBucket = new AWS.S3({
    params: { Bucket: S3_PRIVATE_BUCKET },
    region: S3_REGION,
  })

  useEffect(() => {
    calculateVideoDuration(uploadFile, setDuration)
  }, [uploadFile])

  useEffect(() => {
    const isValidate = name !== "" && description !== ""
    setValidated(isValidate)
    uploadFile.validated = isValidate
  }, [name, description, uploadFile])

  const mediaInfo = findMediaInfoByFileName(uploadFile.name)
  const bucketMediaType = MEDIA_TYPE_INDEX[mediaInfo.type]
  const removeUploadCardFromList = () => {
    setShow(false)
  }

  const upload = async (file) => {
    // file info
    const mediaInfo = findMediaInfoByFileName(file.name)
    // new key
    const awsFileName = generateUniqueFileName(file.name)

    const mediaType = EXT_TO_MEDIA_TYPE[mediaInfo.ext]

    const params = {
      ACL: "public-read",
      Body: file,
      Bucket:
        mediaType === "video" || mediaType === "image"
          ? S3_UPLOAD_BUCKET
          : S3_PRIVATE_BUCKET,
      Key: `${EXT_TO_MEDIA_TYPE[mediaInfo.ext]}/${awsFileName}`,
    }

    // upload file to S3 bucket
    setUploading(true)
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100))
      })
      .send((err, data) => {
        if (err) {
          console.error(err)
          setUploading(false)
        } else {
          // create S3 media record into database
          return addResource({
            mediaName: name.trim(),
            mediaDuration: duration,
            mediaDescription: description.trim(),
            mediaType: bucketMediaType || 0,
            mediaAwsKey: `${EXT_TO_MEDIA_TYPE[mediaInfo.ext]}/${awsFileName}`,
            mediaAwsBucket:
              bucketMediaType === MEDIA_TYPE_INDEX.image
                ? S3_PUBLIC_BUCKET
                : S3_PRIVATE_BUCKET,
            mediaSize: uploadFile.size,
          })
            .then((res) => {
              console.log("final : ", res)
            })
            .catch((err) => {
              console.error("error: ", err)
            })
            .finally(() => {
              setUploading(false)
              setUploadSucceeded(true)
            })
        }
      })
  }

  return show ? (
    <StyledFileUploadCard>
      <div className="upload-property title truncated">
        <UploadFileIcon />
        {uploadFile.name}
      </div>
      <div className="upload-property">
        <AttachmentIcon />
        {`${bytesToSize(uploadFile.size)} (${mediaInfo.type})`}

        {(mediaInfo.type === MEDIA_TYPE.VIDEO ||
          mediaInfo.type === MEDIA_TYPE.AUDIO) && (
          <>
            <AvTimerRoundedIcon />
            <div>{secondToTime(duration)}</div>
          </>
        )}
      </div>
      <StyledMediaPreview>
        {(mediaInfo.type === MEDIA_TYPE.VIDEO ||
          mediaInfo.type === MEDIA_TYPE.AUDIO) && (
          <video
            controls
            id={`preview-video-${index}`}
            ref={(node) => {
              previewVideoRef.current = node
            }}
          >
            <source src={URL.createObjectURL(uploadFile)} />
          </video>
        )}

        {mediaInfo.type === MEDIA_TYPE.DOCUMENT && (
          <StyledPaperDiv color={EXT_TO_MEDIA_TYPE_COLOR[mediaInfo.ext]}>
            {mediaInfo.ext}
          </StyledPaperDiv>
        )}
        {mediaInfo.type === MEDIA_TYPE.IMAGE && (
          <img src={URL.createObjectURL(uploadFile)} alt="upload-preview" />
        )}
      </StyledMediaPreview>
      <div>
        <TextField
          required
          id={`upload-file-name-${index}`}
          autoComplete="off"
          label="Name"
          variant="outlined"
          size="small"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <TextField
          required
          multiline
          name={`upload-file-name-${index}`}
          id={`upload-file-name-${index}`}
          onChange={(e) => setDescription(e.target.value)}
          label="Description"
          variant="outlined"
          value={description}
          rows={3}
        />

        <div className="control-panel">
          <div>
            {uploading && !uploadSucceeded && (
              <CircularProgressWithLabel value={progress} />
            )}
            {uploadSucceeded && <CheckCircleIcon />}
          </div>
          <div>
            <Button
              variant="contained"
              size="small"
              className={`file-remove-btn ${
                uploadSucceeded && "uploadSucceeded"
              }`}
              startIcon={<DeleteIcon />}
              onClick={() => {
                removeUploadCardFromList()
              }}
            >
              Remove
            </Button>
            {!uploadSucceeded && (
              <Button
                variant="contained"
                disabled={!validated || uploading}
                startIcon={<FileUploadIcon />}
                size="small"
                className={`file-upload-btn ${validated && "validated"}`}
                onClick={() => {
                  upload(uploadFile)
                }}
              >
                Upload
              </Button>
            )}
          </div>
        </div>
      </div>
    </StyledFileUploadCard>
  ) : null
}

export default UploadItemCard
