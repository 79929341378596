import React, { useRef } from "react"
import PropTypes from "prop-types"
import Button from "@mui/material/Button"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import Checkbox from "@mui/material/Checkbox"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Pagination from "@mui/material/Pagination"
import moment from "moment"
import { REDEEM_CODE_TYPE_DICT } from "constants/global"
import { StyledRedeemTable } from "./Redeem.style"

const RedeemTable = ({
  redeemCodes,
  access,
  openRedeemDetail,
  openBatchDetail,
  searchResultInfo,
  searchOptions,
  setSearchOptions,
  exportAction,
  exportSelectedResult,
  selectedExportRedeems,
  setSelectedExportRedeems,
}) => {
  const selectAllCheckboxRef = useRef(null)

  return (
    <StyledRedeemTable>
      <TableContainer component={Paper}>
        <div className="search-record-info">
          Total Record Found: {searchResultInfo?.total}
        </div>
        <Table sx={{ minWidth: 650 }} aria-label="role table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  color="primary"
                  // indeterminate={numSelected > 0 && numSelected < rowCount}
                  ref={selectAllCheckboxRef}
                  onChange={(e) => {
                    e.target.checked
                      ? setSelectedExportRedeems([
                          ...selectedExportRedeems,
                          ...redeemCodes,
                        ])
                      : setSelectedExportRedeems([
                          ...selectedExportRedeems.filter((el) => {
                            return !redeemCodes
                              .map((redeem) => redeem.id)
                              .includes(el.id)
                          }),
                        ])
                  }}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Redeem Code</TableCell>
              <TableCell>Product</TableCell>
              <TableCell sx={{ minWidth: 100, textAlign: "center" }}>
                Batch ID
              </TableCell>
              <TableCell sx={{ minWidth: 60, textAlign: "center" }}>
                Code Type
              </TableCell>
              <TableCell sx={{ minWidth: 60 }}>Status</TableCell>
              <TableCell sx={{ minWidth: 60, textAlign: "right" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {redeemCodes.map((row) => (
              <TableRow
                key={`role-item-${row.id}`}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Checkbox
                    color="primary"
                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={selectedExportRedeems
                      .map((redeem) => redeem.id)
                      .includes(row.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedExportRedeems([
                          ...selectedExportRedeems,
                          row,
                        ])
                      } else {
                        setSelectedExportRedeems([
                          ...selectedExportRedeems.filter(
                            (el) => el.id !== row.id
                          ),
                        ])
                      }
                    }}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                  />
                </TableCell>
                <TableCell scope="row">
                  {moment(row.createdAt).format("lll")}
                </TableCell>
                <TableCell scope="row">{row.redeemCode}</TableCell>
                <TableCell>{row.productCode}</TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  <Button
                    style={{ width: "150px" }}
                    onClick={() => {
                      openBatchDetail(row.batchId)
                    }}
                    variant="outlined"
                  >
                    Batch {row.batchId}
                  </Button>
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {REDEEM_CODE_TYPE_DICT[row.codeType].text}
                </TableCell>
                <TableCell>
                  {row.redeemStatus === 0 ? "Pending" : "Activated"}
                </TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  {access.getBatchInfoRedeemAccess && (
                    <Button
                      style={{ width: "80px" }}
                      onClick={() => {
                        openRedeemDetail(row)
                      }}
                      variant="outlined"
                    >
                      Detail
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {searchResultInfo && (
              <TableRow key={"pg"}>
                <TableCell colSpan={4}>
                  {selectedExportRedeems.length > 0 && (
                    <Button
                      style={{ width: "280px", marginRight: "10px" }}
                      onClick={() => {
                        exportSelectedResult()
                      }}
                      variant="outlined"
                    >
                      Export Selected {selectedExportRedeems.length} Redeem(s)
                    </Button>
                  )}
                  <Button
                    style={{ width: "260px" }}
                    onClick={() => {
                      exportAction()
                    }}
                    variant="outlined"
                  >
                    Export all searched records
                  </Button>
                </TableCell>
                <TableCell colSpan={3}>
                  <div className="pagination-section">
                    <Pagination
                      count={searchResultInfo.pages}
                      page={searchResultInfo.currentPageNo}
                      onChange={(event, value) => {
                        setSearchOptions({
                          ...searchOptions,
                          pageNo: value,
                        })
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledRedeemTable>
  )
}

RedeemTable.propTypes = {
  redeemCodes: PropTypes.array.isRequired,
  access: PropTypes.object.isRequired,
  openRedeemDetail: PropTypes.func.isRequired,
  openBatchDetail: PropTypes.func.isRequired,
  searchResultInfo: PropTypes.object,
  searchOptions: PropTypes.object.isRequired,
  setSearchOptions: PropTypes.func.isRequired,
  exportAction: PropTypes.func.isRequired,
  exportSelectedResult: PropTypes.func.isRequired,
  selectedExportRedeems: PropTypes.array.isRequired,
  setSelectedExportRedeems: PropTypes.func.isRequired,
}

RedeemTable.defaultProps = {
  searchResultInfo: null,
}

export default RedeemTable
