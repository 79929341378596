import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import Button from "@mui/material/Button"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import LinkIcon from "@mui/icons-material/Link"
import DialogActions from "@mui/material/DialogActions"
import FormControl from "@mui/material/FormControl"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import Typography from "@mui/material/Typography"
import Chip from "@mui/material/Chip"
import Box from "@mui/material/Box"
import FormGroup from "@mui/material/FormGroup"
import FormLabel from "@mui/material/FormLabel"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import { exchangeArrayItem } from "utils/data"
import { Container, Draggable } from "react-smooth-dnd"
import { useSnackbar } from "notistack"
import { useForm } from "react-hook-form"
import {
  deleteProgramInnercise,
  exchangeProgramLevelIndex,
  addProgram,
  updateProgram,
  getProgramDetail,
  deleteProgramLevel,
  showProgramProgress,
  hideProgramProgress,
  setProgramLockable,
  cancelProgramLockable,
} from "services/program"
import {
  generateInnerciseLinkHtml,
  generateProgramLinkHtml,
} from "utils/program"
import ProgramContributorDialog from "./ProgramContributorDlg"
import ProgramLevelDialog from "./ProgramLevelDlg"
import ProgramInnerciseDialog from "./ProgramInnerciseDlg"
import ProgramCategoryDlg from "./ProgramCategoryDlg"
import ProgramSingleInnerciseControl from "./ProgramSingleInnerciseControl"
import MediaContentDisplay from "components/common/MediaManager/MediaContentDisplay"
import LoadingScreen from "components/common/LoadingScreen"
import ProgramLevelCard from "./ProgramLevelCard"
import {
  MEDIA_TYPE_INDEX,
  PROGRAM_TYPE,
  PROGRAM_TYPE_INDEX,
  RAIL_UI_TYPE,
} from "constants/global"
import {
  StyledInputLabel,
  StyledInputLabelSmall,
  StyledRankSlider,
} from "components/Style/Forms.style"
import {
  StyledProgramLevelContainer,
  StyledProgramDialog,
} from "./Program.style"
import { set } from "lodash"
import { verifyResponse } from "utils/httpRequest"

const ProgramDialog = (props) => {
  const { onSubmit, openMediaManager, categories, access, openConfirmDialog } =
    props

  const activeCategories = categories
    ? categories.filter((el) => el.subCategoryList.length > 0)
    : []

  const initProgram = {
    title: "",
    description: "",
    categoryId:
      activeCategories && activeCategories.length > 0
        ? activeCategories[0].id
        : null,
    subCategoryId:
      activeCategories && activeCategories.length > 0
        ? activeCategories[0].subCategoryList[0].id
        : null,
    thumbResourceId: null,
    introImageId: null,
    introResourceId: null,
    rank: 0,
    pubStatus: 0,
    contributorList: [],
    subCategoryList: [],
    innerciseType: PROGRAM_TYPE_INDEX.MULTIPLE,
  }

  let navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm()

  const [program, setProgram] = useState(initProgram)
  const [singleInnercise, setSingleInnercise] = useState(null)
  const [levels, setLevels] = useState([])
  const [open, setOpen] = useState(false)
  const [innerciseMode, setInnerciseMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [thumbnailResource, setThumbnailResource] = useState(false)
  const [thumbnailRegularResource, setThumbnailRegularResource] =
    useState(false)
  const [thumbnailFullResource, setThumbnailFullResource] = useState(false)
  const [introImage, setIntroImage] = useState(false)
  const [introVideoResource, setIntroVideoResource] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const thumbnailResourceRef = useRef(null)
  const thumbnailRegularResourceRef = useRef(null)
  const thumbnailFullResourceRef = useRef(null)
  const introImageRef = useRef(null)
  const introVideoResourceRef = useRef(null)

  const [openProgramLevelDlg, programLevelDlg, programLevelDlgLoading] =
    ProgramLevelDialog({
      onSubmit: () => {
        refreshProgram(program.id)
      },
      id: program.id,
    })

  const [openContributorDialog, contributorDialog] = ProgramContributorDialog({
    currentContributorList: program.contributorList,
    selectAction: (selectedContributor) => {
      setProgram({
        ...program,
        contributorList: [...program.contributorList, selectedContributor],
      })
    },
  })

  const updateCategories = (newCategoryList) => {
    setProgram({
      ...program,
      subCategoryList: newCategoryList,
    })
  }

  const updateLockable = async () => {
    setLoading(true)
    let response = null

    if (program.isLockable === 0) {
      response = await setProgramLockable({ programIdList: [program.id] })
    } else {
      response = await cancelProgramLockable({ programIdList: [program.id] })
    }
    setLoading(false)
    verifyResponse(response)
    if (response.rtnCode === 200) {
      refreshProgram(program.id)
    } else {
      enqueueSnackbar(response.data.message, {
        variant: "warning",
      })
    }
  }

  const updateShowProgress = async () => {
    setLoading(true)
    let response = null

    if (program.isShowProgress === 0) {
      response = await showProgramProgress({ programIdList: [program.id] })
    } else {
      response = await hideProgramProgress({ programIdList: [program.id] })
    }
    setLoading(false)
    verifyResponse(response)
    if (response.rtnCode === 200) {
      refreshProgram(program.id)
    } else {
      enqueueSnackbar(response.data.message, {
        variant: "warning",
      })
    }
  }

  const [
    openProgramCategoryDlg,
    programCategoryDlg,
    programCategoryDlgLoading,
  ] = ProgramCategoryDlg({
    onSubmit: () => {
      refreshProgram(program.id)
    },
    openMediaManager: openMediaManager,
    categories: categories,
    programSubCategories: program.subCategoryList,
    onUpdate: (data) => {
      updateCategories(data)
    },
  })

  const [
    openProgramInnerciseDlg,
    programInnerciseDlg,
    programInnerciseDlgLoading,
  ] = ProgramInnerciseDialog({
    onSubmit: () => {
      refreshProgram(program.id)
    },
    openMediaManager: openMediaManager,
  })

  //--------------------------------
  // Thumbnail Resource small
  //--------------------------------

  const updateThumbnailResource = () => {
    setThumbnailResource({
      ...thumbnailResourceRef.current,
      selectAction: selectThumbnailResource,
      clearResourceAction: clearThumbnailResource,
    })
  }
  const selectThumbnailResource = () => {
    openMediaManager(
      (media) => {
        thumbnailResourceRef.current = media
        setValue("thumbResourceId", media.id)
        updateThumbnailResource()
      },
      [MEDIA_TYPE_INDEX.image]
    )
  }

  const clearThumbnailResource = () => {
    thumbnailResourceRef.current = null
    setValue("thumbResourceId", null)
    updateThumbnailResource()
  }

  //--------------------------------
  // Thumbnail Resource Regular
  //--------------------------------

  const updateThumbnailRegularResource = () => {
    setThumbnailRegularResource({
      ...thumbnailRegularResourceRef.current,
      selectAction: selectThumbnailRegularResource,
      clearResourceAction: clearThumbnailRegularResource,
    })
  }
  const selectThumbnailRegularResource = () => {
    openMediaManager(
      (media) => {
        thumbnailRegularResourceRef.current = media
        setValue("thumbRegularResourceId", media.id)
        updateThumbnailRegularResource()
      },
      [MEDIA_TYPE_INDEX.image]
    )
  }

  const clearThumbnailRegularResource = () => {
    thumbnailRegularResourceRef.current = null
    setValue("thumbRegularResourceId", null)
    updateThumbnailRegularResource()
  }

  //--------------------------------
  // Thumbnail Resource Full
  //--------------------------------

  const updateThumbnailFullResource = () => {
    setThumbnailFullResource({
      ...thumbnailFullResourceRef.current,
      selectAction: selectThumbnailFullResource,
      clearResourceAction: clearThumbnailFullResource,
    })
  }
  const selectThumbnailFullResource = () => {
    openMediaManager(
      (media) => {
        thumbnailFullResourceRef.current = media
        setValue("thumbFullResourceId", media.id)
        updateThumbnailFullResource()
      },
      [MEDIA_TYPE_INDEX.image]
    )
  }

  const clearThumbnailFullResource = () => {
    thumbnailFullResourceRef.current = null
    setValue("thumbFullResourceId", null)
    updateThumbnailFullResource()
  }

  //--------------------------------
  // Intro Image Resource
  //--------------------------------

  const updateIntroImage = () => {
    setIntroImage({
      ...introImageRef.current,
      selectAction: selectIntroImage,
      clearResourceAction: clearIntroImage,
    })
  }
  const selectIntroImage = () => {
    openMediaManager(
      (media) => {
        introImageRef.current = media
        setValue("introImageId", media.id)
        updateIntroImage()
      },
      [MEDIA_TYPE_INDEX.image]
    )
  }

  const clearIntroImage = () => {
    introImageRef.current = null
    setValue("introImageId", null)
    updateIntroImage()
  }

  //--------------------------------
  // Intro Resource
  //--------------------------------

  const updateIntroVideoResource = () => {
    setIntroVideoResource({
      ...introVideoResourceRef.current,
      selectAction: selectIntroVideoResource,
      clearResourceAction: clearIntroVideoResource,
    })
  }

  const selectIntroVideoResource = () => {
    openMediaManager(
      (media) => {
        introVideoResourceRef.current = media
        setValue("introResourceId", media.id)
        updateIntroVideoResource()
      },
      [MEDIA_TYPE_INDEX.video, MEDIA_TYPE_INDEX.audio]
    )
  }

  const clearIntroVideoResource = () => {
    introVideoResourceRef.current = null
    setValue("introResourceId", null)
    updateIntroVideoResource()
  }

  const openDeleteLevelDlg = (levelId) => {
    openConfirmDialog(async () => {
      setLoading(true)
      const response = await deleteProgramLevel(levelId)
      switch (response.data.rtnCode) {
        case 200:
          enqueueSnackbar("Program Level Deleted", {
            variant: "success",
          })
          break
        case 20062:
          enqueueSnackbar(response.data.message, {
            variant: "warning",
          })
          break
        default:
          enqueueSnackbar("Delete Program Level Fail", { variant: "warning" })
      }
      refreshProgram(program.id)
      setLoading(false)
    })
  }

  const openDeleteInnerciseDlg = (innerciseId) => {
    openConfirmDialog(async () => {
      setLoading(true)
      const response = await deleteProgramInnercise(innerciseId)

      if (response.data.rtnCode === 200) {
        enqueueSnackbar("Program Innercise Deleted", {
          variant: "success",
        })
      } else {
        enqueueSnackbar("Delete Program Innercise Fail", {
          variant: "warning",
        })
      }
      refreshProgram(program.id)
      setLoading(false)
    })
  }

  const openDlg = async ({ programId, openInnerciseMode }) => {
    reset()
    if (programId) {
      await refreshProgram(programId)
    } else {
      setProgram(initProgram)
      thumbnailResourceRef.current = null
      introImageRef.current = null
      introVideoResourceRef.current = null
    }

    // define display section mode
    openInnerciseMode ? setInnerciseMode(true) : setInnerciseMode(false)
    // refresh thumbnail resources
    updateThumbnailResource()
    updateThumbnailRegularResource()
    updateThumbnailFullResource()
    // refresh intro image resource
    updateIntroImage()
    // refresh intro video resource
    updateIntroVideoResource()
    setIsEditMode(programId ? true : false)
    setOpen(true)
  }

  const refreshProgram = async (programId) => {
    setLoading(true)
    const resp = await getProgramDetail(programId)
    const currentProgram = resp.data.data

    const programObj = {
      id: currentProgram.id,
      title: currentProgram.title,
      description: currentProgram.description,
      thumbResourceId: currentProgram.thumbResourceId,
      thumbResourceUIList: currentProgram.thumbResourceUIList || [],
      introImageId: currentProgram.introImageId,
      introResourceId: currentProgram.introResourceId,
      isLockable: currentProgram.isLockable,
      isShowProgress: currentProgram.isShowProgress,
      pubStatus: currentProgram.pubStatus,
      rank: currentProgram.rank,
      contributorList: currentProgram.contributorList,
      subCategoryList: currentProgram.subCategoryList,
      thumbResourceDtoList: currentProgram.thumbResourceDtoList || [],
      innerciseType:
        currentProgram.innerciseType === null
          ? PROGRAM_TYPE_INDEX.MULTIPLE
          : currentProgram.innerciseType,
    }

    const thumbRegularResourceObj = programObj.thumbResourceDtoList.find(
      (item) => {
        return item.uiType === RAIL_UI_TYPE.REGULAR.value
      }
    )

    const thumbFullResourceObj = programObj.thumbResourceDtoList.find(
      (item) => {
        return item.uiType === RAIL_UI_TYPE.FULL_SCREEN.value
      }
    )

    setValue("rank", currentProgram.rank)

    setValue("thumbResourceId", currentProgram.thumbResourceId)
    thumbnailResourceRef.current = currentProgram.thumbResourceDto

    setValue(
      "thumbRegularResourceId",
      thumbRegularResourceObj ? thumbRegularResourceObj.id : null
    )
    thumbnailRegularResourceRef.current = thumbRegularResourceObj

    setValue(
      "thumbFullResourceId",
      thumbFullResourceObj ? thumbFullResourceObj.id : null
    )
    thumbnailFullResourceRef.current = thumbFullResourceObj

    setValue("introImageId", currentProgram.introImageId)
    introImageRef.current = currentProgram.introImageDto

    setValue("introResourceId", currentProgram.introResourceId)
    introVideoResourceRef.current = currentProgram.introResourceDto

    setProgram(programObj)
    setLevels(currentProgram.levelList)

    setSingleInnercise(
      currentProgram.levelList.length > 0 &&
        currentProgram.levelList[0].innerciseList.length > 0
        ? currentProgram.levelList[0].innerciseList[0]
        : null
    )
    //setTypeChanged(false)
    setLoading(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleAction = async (data) => {
    const thumbResourceUIList = []
    data.thumbResourceId &&
      thumbResourceUIList.push({
        uiType: RAIL_UI_TYPE.SMALL.value,
        resourceId: data.thumbResourceId,
      })

    data.thumbRegularResourceId &&
      thumbResourceUIList.push({
        uiType: RAIL_UI_TYPE.REGULAR.value,
        resourceId: data.thumbRegularResourceId,
      })

    data.thumbFullResourceId &&
      thumbResourceUIList.push({
        uiType: RAIL_UI_TYPE.FULL_SCREEN.value,
        resourceId: data.thumbFullResourceId,
      })

    setLoading(true)
    const response = isEditMode
      ? await updateProgram({
          ...data,
          id: program.id,
          pubStatus: program.pubStatus,
          innerciseType: program.innerciseType,
          introImageId: data.introImageId === "" ? null : data.introImageId,
          introResourceId:
            data.introResourceId === "" ? null : data.introResourceId,
          // contributorIdList: program.contributorList
          //   ? program.contributorList.map((contributor) => contributor.id)
          //   : [],
          thumbResourceUIList: thumbResourceUIList,
          subCategoryList: program.subCategoryList.map(
            (subCate) => subCate.subCategoryId
          ),
        })
      : await addProgram({
          ...data,
          programId: program.id,
          pubStatus: program.pubStatus,
          innerciseType: PROGRAM_TYPE_INDEX.MULTIPLE,
          introImageId: data.introImageId === "" ? null : data.introImageId,
          introResourceId:
            data.introResourceId === "" ? null : data.introResourceId,
          // contributorIdList: program.contributorList
          //   ? program.contributorList.map((contributor) => contributor.id)
          //   : [],
          thumbResourceUIList: thumbResourceUIList,
          subCategoryList: program.subCategoryList.map(
            (subCate) => subCate.subCategoryId
          ),
        })

    setLoading(false)
    if (response.data.rtnCode === 401) {
      localStorage.clear()
      navigate("/login")
    }

    if (response.data.rtnCode === 200) {
      enqueueSnackbar(isEditMode ? "Program Updated" : "New Program Created", {
        variant: "success",
      })
    } else {
      enqueueSnackbar(
        isEditMode
          ? `Update Program Fail: ${response.data.message}`
          : `Create Program Fail: ${response.data.message}`,
        { variant: "warning" }
      )
    }

    onSubmit()
    setOpen(false)
  }

  const updateLevelOrder = async () => {
    try {
      setLoading(true)
      const response = await exchangeProgramLevelIndex({
        programId: program.id,
        updateIndexArray: levels.map((level, index) => {
          return {
            id: level.id,
            index: index,
          }
        }),
      })

      if (response.rtnCode !== 200) {
        enqueueSnackbar("Fail to the program level order", {
          variant: "warning",
        })
      } else {
        setIsChanged(false)
        enqueueSnackbar("Update program level success", {
          variant: "success",
        })
      }
    } catch (e) {
      enqueueSnackbar("Fail to the program level", {
        variant: "warning",
      })
    } finally {
      setLoading(false)
    }
  }

  const handleAddProgramLevel = () => {
    openProgramLevelDlg()
  }

  const removeContributor = (contributorId) => {
    setProgram({
      ...program,
      contributorList: program.contributorList.filter(
        (contributor) => contributor.id !== contributorId
      ),
    })
  }

  const changeProgramType = (programType) => {
    setProgram({
      ...program,
      innerciseType: programType,
    })
  }

  useEffect(() => {
    setValue(
      "subCategoryList",
      program.subCategoryList.length > 0 ? true : undefined
    )
  }, [program])

  const dlg = loading ? (
    "null"
  ) : (
    <StyledProgramDialog
      open={open}
      fullWidth={true}
      maxWidth={"lg"}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(handleAction)}>
        <DialogTitle>
          {innerciseMode
            ? "Innercise Settings"
            : isEditMode
            ? "Edit Program"
            : "Add New Program"}

          {innerciseMode &&
            program.innerciseType === PROGRAM_TYPE_INDEX.MULTIPLE &&
            access.addLevelAccess && (
              <Button
                variant="contained"
                size="small"
                color="success"
                startIcon={<AddIcon />}
                onClick={handleAddProgramLevel}
              >
                Add new Level
              </Button>
            )}
        </DialogTitle>
        <DialogContent className="dialog-content">
          {!innerciseMode && isEditMode && (
            <Button
              variant="contained"
              startIcon={<LinkIcon />}
              onClick={async () => {
                const htmlLink = generateProgramLinkHtml({
                  title: program.title,
                })
                await navigator.clipboard.writeText(htmlLink)
              }}
            >
              Copy Deep Link Html
            </Button>
          )}
          {!innerciseMode && (
            <>
              <div className="form-control-row">
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <TextField
                    autoFocus
                    autoComplete="off"
                    margin="dense"
                    id="program-title"
                    label="Title"
                    fullWidth
                    defaultValue={program.title}
                    {...register("title", { required: true })}
                    error={!!errors?.title}
                    helperText={!!errors?.title ? "* Title is required" : " "}
                    variant="standard"
                  />
                </FormControl>
              </div>

              <div className="form-control-row ">
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <TextField
                    autoFocus
                    autoComplete="off"
                    id="program-description"
                    label="Description"
                    multiline
                    rows={7}
                    defaultValue={program.description}
                    {...register("description", { required: true })}
                    error={!!errors?.description}
                    helperText={
                      !!errors?.description ? "* Description is required" : " "
                    }
                  />
                </FormControl>
              </div>

              <div className="title-bar">
                <label>Category</label>
                <Button
                  size="small"
                  variant="contained"
                  onClick={openProgramCategoryDlg}
                  startIcon={
                    program.subCategoryList.length === 0 ? (
                      <AddIcon />
                    ) : (
                      <EditIcon />
                    )
                  }
                >
                  {program.subCategoryList.length === 0 ? "Add" : "Edit"}
                </Button>
              </div>
              <div>
                <div className="form-control-row category-section">
                  <input
                    type="hidden"
                    defaultValue={undefined}
                    {...register("subCategoryList", { required: false })}
                  />
                  {program.subCategoryList.length === 0 && "No Category"}
                  {program.subCategoryList.map((cate) => (
                    <div
                      key={`category-list-item-${cate.subCategoryId}`}
                      className="category-item"
                    >{`${cate.categoryName}-${cate.subCategoryName}`}</div>
                  ))}
                </div>
                <div className="error-message">
                  {!!errors?.subCategoryList
                    ? "* You need as least one category"
                    : ""}
                </div>
              </div>

              <StyledInputLabel>Contributors</StyledInputLabel>
              <div className="form-control-row contributor-section">
                {program.contributorList &&
                  program.contributorList.map((contributor) => (
                    <Chip
                      key={`chip-contributor-item-${contributor.id}`}
                      label={contributor.userName}
                      onDelete={() => {
                        removeContributor(contributor.id)
                      }}
                    />
                  ))}
                <Button
                  onClick={() => {
                    openContributorDialog()
                  }}
                  size="small"
                  variant="contained"
                >
                  Add Contributor
                </Button>
              </div>

              {isEditMode && (
                <div className="form-control-row two-col">
                  <div>
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked={program.isLockable}
                          color="warning"
                          onChange={(e) => {
                            updateLockable()
                          }}
                        />
                      }
                      label="Lockable"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked={program.isShowProgress}
                          color="warning"
                          onChange={() => {
                            updateShowProgress()
                          }}
                        />
                      }
                      label="Show Progress"
                    />
                  </div>
                </div>
              )}

              <div className="form-control-row two-col">
                {isEditMode && (
                  <FormGroup>
                    <FormLabel>Status</FormLabel>
                    <Typography className="control-label" gutterBottom>
                      {program.pubStatus === 0 ? "Unpublished" : "Published"}
                    </Typography>
                  </FormGroup>
                )}
                <Box sx={{ width: 300 }}>
                  <Typography className="control-label" gutterBottom>
                    Program Rank {watch("rank")}
                  </Typography>
                  <StyledRankSlider
                    aria-label="program-rank"
                    getAriaValueText={(value) => {
                      set("rank", value)
                      return `${value}`
                    }}
                    defaultValue={program.rank}
                    {...register("rank", { required: true })}
                    step={1}
                    min={0}
                    max={10}
                  />
                </Box>
              </div>
              <div className="form-control-row two-col">
                <div>
                  <StyledInputLabel>
                    Thumbnail Small{" "}
                    <StyledInputLabelSmall>(4:3)</StyledInputLabelSmall>
                  </StyledInputLabel>
                  <input
                    type="hidden"
                    defaultValue={program.thumbResourceId}
                    {...register("thumbResourceId", { required: true })}
                  />
                  <MediaContentDisplay resource={thumbnailResource} />
                  <div className="error-message">
                    {!!errors?.thumbResourceId ? "* Resource is required" : " "}
                  </div>
                </div>
                <div>
                  <StyledInputLabel>
                    Thumbnail Regular{" "}
                    <StyledInputLabelSmall>(3:4)</StyledInputLabelSmall>
                  </StyledInputLabel>
                  <input
                    type="hidden"
                    defaultValue={program.thumbResourceId}
                    {...register("thumbRegularResourceId", { required: false })}
                  />
                  <MediaContentDisplay resource={thumbnailRegularResource} />
                  <div className="error-message">
                    {!!errors?.thumbResourceId ? "* Resource is required" : " "}
                  </div>
                </div>
                <div>
                  <StyledInputLabel>
                    Thumbnail Full{" "}
                    <StyledInputLabelSmall>(16:9)</StyledInputLabelSmall>
                  </StyledInputLabel>
                  <input
                    type="hidden"
                    defaultValue={program.thumbResourceId}
                    {...register("thumbFullResourceId", { required: false })}
                  />
                  <MediaContentDisplay resource={thumbnailFullResource} />
                  <div className="error-message">
                    {!!errors?.thumbResourceId ? "* Resource is required" : " "}
                  </div>
                </div>
              </div>

              <div className="form-control-row two-col">
                <div>
                  <StyledInputLabel>Intro Image</StyledInputLabel>
                  <input
                    type="hidden"
                    defaultValue={program.introImageId}
                    {...register("introImageId", { required: false })}
                  />
                  <MediaContentDisplay resource={introImage} />
                </div>

                <div>
                  <StyledInputLabel>Intro Video</StyledInputLabel>
                  <MediaContentDisplay resource={introVideoResource} />
                </div>
              </div>

              {isEditMode && (
                <>
                  <StyledInputLabel>Program Type</StyledInputLabel>
                  <FormControl sx={{ m: 1, minWidth: 230 }}>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                      fullWidth
                      labelId="program-category-filter-select"
                      id="program-category-filter-select"
                      value={program.innerciseType}
                      label="Type"
                      onChange={(e) => {
                        changeProgramType(e.target.value)
                      }}
                      disabled={
                        !(
                          levels.length === 0 ||
                          (levels.length === 1 &&
                            levels[0].innerciseList.length <= 1)
                        )
                      }
                    >
                      {Object.values(PROGRAM_TYPE).map((categoryType) => (
                        <MenuItem
                          key={`category-type-select-item-${categoryType.value}`}
                          value={categoryType.value}
                        >
                          {categoryType.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {!(
                    levels.length === 0 ||
                    (levels.length === 1 && levels[0].innerciseList.length <= 1)
                  ) && (
                    <div className="error-message">
                      * Only empty program or one innercise program can be
                      turning into single mode
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {innerciseMode && (
            <>
              <div className="action-bar">
                {program.innerciseType === PROGRAM_TYPE_INDEX.SINGLE && (
                  <>
                    {levels.length > 0 && levels[0].innerciseList.length > 0 ? (
                      <>
                        <Button
                          variant="contained"
                          onClick={() => {
                            openProgramInnerciseDlg(
                              program.id,
                              levels[0].id,
                              levels[0].innerciseList[0].id
                            )
                          }}
                        >
                          Edit Innercise
                        </Button>

                        <Tooltip
                          title="Copy Deep Link Html"
                          style={{ marginLeft: "10px" }}
                        >
                          <IconButton
                            sx={{ color: "#182b06" }}
                            aria-label="copy innercise share link"
                            component="label"
                            onClick={async () => {
                              const htmlLink = generateInnerciseLinkHtml({
                                title: levels[0].innerciseList[0].title,
                              })
                              await navigator.clipboard.writeText(htmlLink)
                            }}
                            size="small"
                          >
                            <LinkIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : (
                      <Button
                        onClick={() => {
                          openProgramInnerciseDlg(program.id, 0, null)
                        }}
                        startIcon={<AddIcon />}
                      >
                        Add Innercise
                      </Button>
                    )}
                  </>
                )}
              </div>

              {program.innerciseType === PROGRAM_TYPE_INDEX.MULTIPLE && (
                <StyledProgramLevelContainer>
                  {access.exchangeLevelIndexAccess ? (
                    <Container
                      onDrop={async (e) => {
                        setIsChanged(true)
                        const updateIndexArray = exchangeArrayItem({
                          addedIndex: e.addedIndex,
                          removedIndex: e.removedIndex,
                          indexString: "levelIndex",
                          list: levels,
                        })
                        setLevels(updateIndexArray)
                      }}
                    >
                      {levels.map((level, index) => (
                        <Draggable key={level.id}>
                          <ProgramLevelCard
                            key={`program-level-card-${level.id}`}
                            programId={program.id}
                            rank={index + 1}
                            level={level}
                            access={access}
                            openProgramInnerciseDlg={openProgramInnerciseDlg}
                            openProgramLevelDlg={openProgramLevelDlg}
                            openDeleteLevelDlg={openDeleteLevelDlg}
                            deleteProgramInnerciseAction={
                              openDeleteInnerciseDlg
                            }
                          />
                        </Draggable>
                      ))}
                    </Container>
                  ) : (
                    <>
                      {levels.map((level, index) => (
                        <ProgramLevelCard
                          key={`program-level-card-${level.id}`}
                          programId={program.id}
                          rank={index + 1}
                          level={level}
                          access={access}
                          openProgramInnerciseDlg={openProgramInnerciseDlg}
                          openProgramLevelDlg={openProgramLevelDlg}
                          openDeleteLevelDlg={openDeleteLevelDlg}
                          deleteProgramInnerciseAction={openDeleteInnerciseDlg}
                        />
                      ))}
                    </>
                  )}
                </StyledProgramLevelContainer>
              )}

              {program.innerciseType === PROGRAM_TYPE_INDEX.SINGLE && (
                <ProgramSingleInnerciseControl
                  innercise={singleInnercise}
                  openMediaManager={openMediaManager}
                />
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {innerciseMode && isChanged && (
            <Button
              variant="contained"
              color="primary"
              onClick={updateLevelOrder}
            >
              update order
            </Button>
          )}
          <Button autoFocus onClick={handleClose}>
            close
          </Button>
          {!innerciseMode && (
            <Button type="submit" autoFocus>
              {isEditMode ? "Update" : "Create"}
            </Button>
          )}
        </DialogActions>
      </form>
      {programLevelDlg}
      {programInnerciseDlg}
      {contributorDialog}
      {programCategoryDlg}
      <LoadingScreen
        loading={
          loading || programInnerciseDlgLoading || programLevelDlgLoading
        }
      />
    </StyledProgramDialog>
  )

  return [
    openDlg,
    dlg,
    loading ||
      programInnerciseDlgLoading ||
      programLevelDlgLoading ||
      programCategoryDlgLoading,
  ]
}

ProgramDialog.propTypes = {
  onSubmit: PropTypes.func,
  openMediaManager: PropTypes.func,
  categories: PropTypes.array,
  access: PropTypes.object,
  openConfirmDialog: PropTypes.func.isRequired,
}

ProgramDialog.defaultProps = {
  onSubmit: null,
}

export default ProgramDialog
