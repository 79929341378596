import styled from "@emotion/styled";

const StyledTagsInput = styled.div`
  .ReactTags__tags {
    padding-top: 20px;
    display: flex;
    .ReactTags__selected {
      .tag-wrapper {
        margin-right: 10px;
        background-color: #d0ddd5;
        padding: 10px;
        border-radius: 3px;
        font-size: 14px;
        display: inline-block;
        margin-bottom: 8px;
        button {
          border-radius: 50%;
          background-color: #c9bbbb;
          color: #755d5d;
          border: 0;
          aspect-ratio: 1 1;
          width: 20px;
          height: 20px;
          margin-left: 8px;
          &:hover {
            background-color: #c4a7a7;
            transition: ease-in-out 0.2s;
          }
        }
      }
    }
  }

  .ReactTags__tagInput {
    input {
      height: 30px;
      /* margin: -8px 0 0px 2px; */
      padding: 1px 10px;
    }
  }
`;

export { StyledTagsInput };
