import React, { useEffect, useState } from "react"
import Button from "@mui/material/Button"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import SettingsIcon from "@mui/icons-material/Settings"
import EditIcon from "@mui/icons-material/Edit"
import PublishIcon from "@mui/icons-material/Publish"
import UnpublishedIcon from "@mui/icons-material/Unpublished"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import LockIcon from "@mui/icons-material/Lock"
import LockOpenIcon from "@mui/icons-material/LockOpen"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TextField from "@mui/material/TextField"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import Paper from "@mui/material/Paper"
import PageHeading from "components/common/LayoutComponents/PageHeading"
import PageContent from "components/common/LayoutComponents/PageContent"
import LoadingScreen from "components/common/LoadingScreen"
import MediaManager from "components/common/MediaManager"
import { useSnackbar } from "notistack"
import { PERMISSIONS } from "constants/permission"
import {
  UsePermissionVerification,
  UseConfirmDialog,
} from "components/customHooks"
import { verifyResponse } from "utils/httpRequest"
import { getFullCategories } from "services/category"
import { PROGRAM_TYPE, PROGRAM_PUB_STATUS } from "constants/global"
import {
  getPrograms,
  removeProgram,
  pubProgram,
  cancelPubProgram,
} from "services/program"
import ProgramDialog from "./ProgramDlg"
import { StyledProgramWrapper } from "./Program.style"

const Program = () => {
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [programs, setPrograms] = useState([])
  const [listProgram, setListProgram] = useState([])
  const [titleFilterVal, setTitleFilterVal] = useState("")
  const [categoryFilterVal, setCategoryFilterVal] = useState("all")
  const AccessControl = UsePermissionVerification()

  const { enqueueSnackbar } = useSnackbar()

  const [ConfirmDialog, openConfirmDialog] = UseConfirmDialog({
    title: "Delete Confirm",
    description: "Are you sure want delete this ?",
    agreeText: "Yes",
    disagreeText: "No",
  })

  const clearFilters = () => {
    setCategoryFilterVal("all")
    setTitleFilterVal("")
  }

  const refreshProgram = async () => {
    setLoading(true)
    const response = await getPrograms({ pageNo: 1, pageSize: 1000 })
    verifyResponse(response)
    setPrograms(response.data.data.list)
    setLoading(false)
  }

  const refreshCategories = async () => {
    setLoading(true)
    const response = await getFullCategories()
    verifyResponse(response)
    setCategories(response.data)
    setLoading(false)
  }

  const removeProgramById = async (programId) => {
    setLoading(true)
    const response = await removeProgram(programId)
    setLoading(false)
    verifyResponse(response)
    switch (response.data.rtnCode) {
      case 200:
        enqueueSnackbar("Program Deleted", {
          variant: "success",
        })
        break
      default:
        enqueueSnackbar("Delete Program Fail", { variant: "warning" })
    }
  }

  const pubProgramById = async (id) => {
    setLoading(true)
    const response = await pubProgram(id)
    setLoading(false)
    verifyResponse(response)
    switch (response.data.rtnCode) {
      case 200:
        enqueueSnackbar("Program Published", {
          variant: "success",
        })
        break
      case 20072:
        enqueueSnackbar("Can not publish program without category", {
          variant: "warning",
        })
        break
      default:
        enqueueSnackbar("Program Publish Fail", { variant: "warning" })
    }
    refreshProgram()
  }

  const cancelPubProgramById = async (id) => {
    setLoading(true)
    const response = await cancelPubProgram(id)
    setLoading(false)
    verifyResponse(response)
    switch (response.data.rtnCode) {
      case 200:
        enqueueSnackbar("Program Unpublished", {
          variant: "success",
        })
        break
      default:
        enqueueSnackbar("Program Publish Fail", { variant: "warning" })
    }
    refreshProgram()
  }

  useEffect(() => {
    setListProgram(
      programs.filter((program) => {
        let isValidated = true
        if (
          titleFilterVal &&
          titleFilterVal.trim() !== "" &&
          !program.title
            .toLowerCase()
            .includes(titleFilterVal.trim().toLowerCase())
        )
          isValidated = false

        if (
          categoryFilterVal &&
          categoryFilterVal !== "all" &&
          !program.subCategoryList
            .map((subCate) => subCate.categoryName)
            .includes(categoryFilterVal)
        ) {
          isValidated = false
        }
        return isValidated
      })
    )
  }, [programs, titleFilterVal, categoryFilterVal])

  useEffect(() => {
    refreshCategories()
    refreshProgram()
  }, [])

  useEffect(() => {
    AccessControl.SetAccess({
      ...AccessControl.Access,
      listProgramAccess: AccessControl.VerifyAccess([
        PERMISSIONS.PROGRAM.PROGRAM_LIST,
      ]),
      pubProgramAccess: AccessControl.VerifyAccess([
        PERMISSIONS.PROGRAM.PROGRAM_PUB,
      ]),
      addProgramAccess: AccessControl.VerifyAccess([
        PERMISSIONS.PROGRAM.PROGRAM_ADD,
      ]),
      editProgramAccess: AccessControl.VerifyAccess([
        PERMISSIONS.PROGRAM.PROGRAM_EDIT,
      ]),
      deleteProgramAccess: AccessControl.VerifyAccess([
        PERMISSIONS.PROGRAM.PROGRAM_DELETE,
      ]),
      addLevelAccess: AccessControl.VerifyAccess([
        PERMISSIONS.PROGRAM.PROGRAM_ADD_LEVEL,
      ]),
      editLevelAccess: AccessControl.VerifyAccess([
        PERMISSIONS.PROGRAM.PROGRAM_EDIT_LEVEL,
      ]),
      deleteLevelAccess: AccessControl.VerifyAccess([
        PERMISSIONS.PROGRAM.PROGRAM_DELETE_LEVEL,
      ]),
      addInnerciseAccess: AccessControl.VerifyAccess([
        PERMISSIONS.PROGRAM.PROGRAM_ADD_INNERCISE,
      ]),
      editInnerciseAccess: AccessControl.VerifyAccess([
        PERMISSIONS.PROGRAM.PROGRAM_EDIT_INNERCISE,
      ]),
      deleteInnerciseAccess: AccessControl.VerifyAccess([
        PERMISSIONS.PROGRAM.PROGRAM_DELETE_INNERCISE,
      ]),
      exchangeLevelIndexAccess: AccessControl.VerifyAccess([
        PERMISSIONS.PROGRAM.PROGRAM_EXCHANGE_LEVEL_INDEX,
      ]),
    })
  }, [AccessControl.UserPermissions])

  const [openMediaManager, mediaManager] = MediaManager()
  const [openProgramDialog, programDialog, programDialogLoading] =
    ProgramDialog({
      onSubmit: () => {
        refreshProgram()
      },
      openMediaManager,
      categories,
      access: AccessControl.Access,
      openConfirmDialog,
    })

  return (
    <StyledProgramWrapper>
      <PageHeading>
        <div>Programs</div>
        <div className="actions">
          {AccessControl.Access.addProgramAccess && (
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                openProgramDialog({})
              }}
            >
              New Program
            </Button>
          )}
        </div>
      </PageHeading>

      {AccessControl.Access.listProgramAccess && programs && !loading && (
        <PageContent>
          <div className="filter-bar ">
            <TextField
              id={"program-table-title-filter"}
              autoComplete="off"
              sx={{ width: "300px" }}
              label="Search by Title"
              variant="outlined"
              size="large"
              value={titleFilterVal}
              onChange={(e) => setTitleFilterVal(e.target.value)}
            />
            <FormControl sx={{ m: 1, minWidth: 230 }}>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                fullWidth
                labelId="program-category-filter-select"
                id="program-category-filter-select"
                value={categoryFilterVal}
                label="Category"
                onChange={(e) => {
                  setCategoryFilterVal(e.target.value)
                }}
              >
                <MenuItem key={`category-select-item-all`} value="all">
                  All
                </MenuItem>
                {categories.map((category) => (
                  <MenuItem
                    key={`category-select-item-${category.id}`}
                    value={category.name}
                  >
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              style={{ height: "55px" }}
              variant="contained"
              size="large"
              onClick={clearFilters}
            >
              Clear
            </Button>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="program table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 310 }}>Title</TableCell>
                  <TableCell align="left" style={{ minWidth: 210 }}>
                    Category
                  </TableCell>
                  <TableCell align="left" style={{ width: 35 }}>
                    Type
                  </TableCell>
                  <TableCell align="center" style={{ width: 35 }}>
                    Level
                  </TableCell>
                  <TableCell align="center" style={{ width: 50 }}>
                    Innercies
                  </TableCell>
                  <TableCell align="left" style={{ width: 80 }}>
                    Status
                  </TableCell>
                  <TableCell align="center" style={{ width: 50 }}>
                    Rank
                  </TableCell>
                  <TableCell align="right" style={{ width: 235 }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listProgram.map((row) => (
                  <TableRow
                    key={`program-table-row-${row.id}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.title}
                    </TableCell>
                    <TableCell align="left">
                      {row.subCategoryList.map((category) => (
                        <div
                          key={`cate-tag-item-${category.subCategoryId}`}
                          className="category-tag"
                        >{`${category.categoryName} - ${category.subCategoryName}`}</div>
                      ))}
                    </TableCell>
                    <TableCell align="left">
                      {PROGRAM_TYPE[row.innerciseType]
                        ? PROGRAM_TYPE[row.innerciseType].text
                        : "error"}
                    </TableCell>
                    <TableCell align="center">{row.levelCount}</TableCell>
                    <TableCell align="center">{row.innerciseCount}</TableCell>
                    <TableCell align="left">
                      {PROGRAM_PUB_STATUS[row.pubStatus].text}{" "}
                      {row.isShowProgress === 0 ? (
                        <Tooltip title="Hide Progress">
                          <VisibilityOffIcon sx={{ color: "#4e4f74" }} />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title="Show Progress"
                          sx={{ color: "#77b216" }}
                        >
                          <VisibilityIcon />
                        </Tooltip>
                      )}
                      {row.isLockable === 0 ? (
                        <Tooltip title="Unlockable">
                          <LockOpenIcon
                            sx={{ color: "#4e4f74", marginLeft: "10px" }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title="Lockable"
                          sx={{ color: "#77b216", marginLeft: "10px" }}
                        >
                          <LockIcon />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="center">{row.rank}</TableCell>
                    <TableCell align="right" className="action-cell">
                      {AccessControl.Access.editProgramAccess && (
                        <>
                          <Tooltip title="Edit Program">
                            <IconButton
                              sx={{ color: "#164dae" }}
                              aria-label="upload picture"
                              component="label"
                              onClick={() => {
                                openProgramDialog({
                                  programId: row.id,
                                  openInnerciseMode: false,
                                })
                              }}
                            >
                              <SettingsIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Edit Innercise">
                            <IconButton
                              sx={{ color: "#a85555" }}
                              aria-label="upload picture"
                              component="label"
                              onClick={() => {
                                openProgramDialog({
                                  programId: row.id,
                                  openInnerciseMode: true,
                                })
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}

                      <>
                        {AccessControl.Access.pubProgramAccess && (
                          <Tooltip
                            title={
                              row.pubStatus
                                ? "Unpublished Program"
                                : "Publish Program"
                            }
                          >
                            <IconButton
                              aria-label="upload picture"
                              sx={{ color: row.pubStatus ? "grey" : "#bf851a" }}
                              component="label"
                              onClick={() => {
                                row.pubStatus
                                  ? cancelPubProgramById(row.id)
                                  : pubProgramById(row.id)
                              }}
                            >
                              {row.pubStatus ? (
                                <UnpublishedIcon />
                              ) : (
                                <PublishIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                        )}
                        {AccessControl.Access.deleteProgramAccess && (
                          <Tooltip title="Delete Program">
                            <IconButton
                              color="error"
                              aria-label="upload picture"
                              component="label"
                              onClick={() => {
                                openConfirmDialog(async () => {
                                  await removeProgramById(row.id)
                                  refreshProgram()
                                })
                              }}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PageContent>
      )}
      {mediaManager}
      {programDialog}
      {ConfirmDialog}
      <LoadingScreen loading={loading || programDialogLoading} />
    </StyledProgramWrapper>
  )
}

export default Program
