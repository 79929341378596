import styled from "@emotion/styled"
import DialogTitle from "@mui/material/DialogTitle"
import { Dialog } from "@mui/material"

const MediaMangerDialogTitle = styled(DialogTitle)`
  position: relative;
  .close-button {
    position: absolute;
    top: 17px;
    right: 30px;
    font-size: 30px;
    cursor: pointer;
    border-radius: 50%;
    padding: 2px;
    color: #514e4e;
    transition: all 0.5s ease;
    &:hover {
      transition: all 0.5s ease;
      background-color: #eee4e4;
    }
  }
`

const MediaManagerWrapper = styled.div`
  padding: 20px;
  height: 100vh;
`

const StyledFileDropZone = styled.div`
  border-radius: 8px;
  margin: 25px 0;
  padding: 20px 35px;
  background-color: #922d2d1f;
  font-size: 18px;
`

const StyledFileUploadCardZone = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
`

const StyledFileUploadCard = styled.li`
  list-style: none;
  margin: 10px 5px;
  padding: 20px;
  height: 440px;
  width: 250px;
  border-radius: 4px;
  font-size: 12px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  .MuiTextField-root {
    font-size: 12px;
    .MuiInputLabel-formControl,
    .MuiInputLabel-asterisk,
    .MuiInputBase-input {
      font-size: 12px;
    }
  }

  .upload-property {
    display: flex;
    height: 27px;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;

    input {
      font-size: 12px;
    }
    &.title {
      height: 50px;
    }

    &.truncated {
      overflow: hidden;
      white-space: break-spaces;
      text-overflow: ellipsis;
    }
    svg {
      margin-right: 10px;
      margin-left: 10px;
      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  button {
    font-size: 10px;
  }
  input {
    width: 100%;
    margin: 3px 0;
  }
  textarea {
    width: 100%;
    height: 50px;
    margin: 3px 0;
  }

  .MuiTextField-root {
    margin: 5px 0;
    width: 100%;
  }

  .MuiInputBase-root {
    /* padding: 5px; */
  }
  .control-panel {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    button {
      margin-left: 5px;
    }
  }
`

const StyledMediaSearch = styled.div`
  .loading-label {
    font-size: 20px;
    padding: 15px 10px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    margin-top: 20px;
  }
`

const StyledMediaUploadControlPanel = styled.div`
  display: flex;
  padding: 10px 15px;
  margin: 10px 0;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  button {
    margin-left: 15px;
  }
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
`

const StyledMediaSearchPanel = styled.div`
  display: flex;
  flex-direction: column;

  .bar {
    display: flex;
    flex-direction: row;
    .search-btn {
      line-height: 2.65;
      margin-left: 10px;
    }
  }

  .size-filter {
    display: flex;
    flex-direction: row;
    max-width: 300px;
  }
`

const StyledMediaSearchInput = styled.input`
  display: inline-block;
  height: 56px;
  width: 60%;
  max-width: 440px;
  padding: 0 20px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  font-size: 16px;
`

const StyledMediaSearchSelect = styled.div`
  display: inline-block;
  width: 160px;
  margin-left: 10px;
`

const StyledMediaSearchResults = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .search-result-info {
    font-size: 16px;
    font-weight: 300;
    /* border: 1px solid gray; */
    color: #000000ab;
    width: 100%;
    padding: 10px 3px;
    margin: 10px 0;
  }
`

const StyledMediaSearchResultItem = styled(StyledFileUploadCard)`
  height: 315px;

  .description {
    /* border: 1px solid gray; */
    height: 70px;
    margin-top: 10px;
    overflow: auto;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .delete-btn {
    color: red;
  }
`

const StyledMediaPreview = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-around;
  height: 115px;
  margin: 10px 0;
  video {
    width: 100%;
    height: 120px;
  }
  img {
    max-height: 100%;
    max-width: 100%;
  }
`

const StyledPaperDiv = styled.div`
  width: 100%;
  height: 120px;
  padding: 10px;
  border: 1px solid #ddd;
  background-size: cover;
  background-clip: content-box;
  background-color: ${(props) => (props.color ? props.color : `#f66f6f`)};
  box-sizing: border-box;
  position: relative;
  margin: auto;

  font: 400 2em/180px "Oswald", sans-serif;
  color: #fbfbfb;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;

  a {
    color: #fbfbfb;
    text-decoration: none;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    border: 50px solid transparent;
    border-bottom: 50px solid #fefefe;
    bottom: -60px;
    right: -65px;
    box-shadow: 0px 7px 6px -9px black;
    transform: rotate(135deg);
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    border: 50px solid transparent;
    border-top: 50px solid #fefefe;
    top: -60px;
    left: -65px;
    box-shadow: 0px -7px 6px -9px black;
    transform: rotate(135deg);
  }
`

const StyledMediaMangerDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  .MuiPaper-root {
    width: 600px;
  }
  .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
  }
`

export {
  MediaMangerDialogTitle,
  MediaManagerWrapper,
  StyledFileDropZone,
  StyledFileUploadCardZone,
  StyledFileUploadCard,
  StyledMediaSearch,
  StyledMediaSearchPanel,
  StyledMediaSearchInput,
  StyledMediaSearchSelect,
  StyledMediaSearchResults,
  StyledMediaSearchResultItem,
  StyledMediaPreview,
  StyledPaperDiv,
  StyledMediaUploadControlPanel,
  StyledMediaMangerDialog,
}
