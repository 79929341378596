import React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import styled from "@emotion/styled"
import LeftPanel from "./LeftPanel"
import Header from "./Header"
import Home from "components/Pages/Home"
import NotFound from "components/Pages/NotFound"
import Profile from "components/Pages/Profile"
import Category from "components/Pages/Category"
import Prime from "components/Pages/Prime"
import Program from "components/Pages/Program"
import Faq from "components/Pages/Faq"
import Modal from "components/Pages/Modal"
import Review from "components/Pages/Review"
import Media from "components/Pages/Media"
import Member from "components/Pages/Member"
import Redeem from "components/Pages/Redeem"
import Permission from "components/Pages/Permission"
import Role from "components/Pages/Role"
import Admin from "components/Pages/Admin"
import SystemSetting from "components/Pages/SystemSetting"
import SubscriptionGroup from "components/Pages/SubscriptionGroup"
import SubscriptionProduct from "components/Pages/SubscriptionProduct"
import Order from "components/Pages/Order"
import Contributor from "components/Pages/Contributor"
import Marketo from "components/Pages/Marketo"
import StartHere from "components/Pages/StartHere/StartHere"
import FeaturedProgram from "components/Pages/FeaturedProgram"
import Rails from "components/Pages/Rails"
import Redis from "components/Pages/Redis"
import SystemConfig from "components/Pages/SystemConfig/SystemConfig"
//------------------------------------------
// lazy load component example
//------------------------------------------
const Login = React.lazy(() => import("components/Pages/Login"))

const RouterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledBody = styled.div`
  display: flex;
  height: calc(100vh - 60px);
`
const ContentPanel = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #0000000a;
`

const CmsTemplate = (PageComponent) => {
  return (
    <>
      <Header />
      <StyledBody>
        <LeftPanel />
        <ContentPanel>
          <PageComponent />
        </ContentPanel>
      </StyledBody>
    </>
  )
}

const WebRoutes = () => {
  return (
    <React.Suspense fallback={null}>
      <RouterWrapper>
        <Router>
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/" element={CmsTemplate(Home)} />
            <Route exact path="/profile" element={CmsTemplate(Profile)} />
            <Route exact path="/category" element={CmsTemplate(Category)} />
            <Route exact path="/prime" element={CmsTemplate(Prime)} />
            <Route exact path="/program" element={CmsTemplate(Program)} />
            <Route exact path="/faq" element={CmsTemplate(Faq)} />
            <Route exact path="/modal" element={CmsTemplate(Modal)} />
            <Route exact path="/review" element={CmsTemplate(Review)} />
            <Route exact path="/media" element={CmsTemplate(Media)} />
            <Route exact path="/member" element={CmsTemplate(Member)} />
            <Route exact path="/redeem" element={CmsTemplate(Redeem)} />
            <Route exact path="/permission" element={CmsTemplate(Permission)} />
            <Route exact path="/role" element={CmsTemplate(Role)} />
            <Route exact path="/admin" element={CmsTemplate(Admin)} />
            <Route exact path="/order" element={CmsTemplate(Order)} />
            <Route exact path="/startHere" element={CmsTemplate(StartHere)} />
            <Route
              exact
              path="/featuredProgram"
              element={CmsTemplate(FeaturedProgram)}
            />
            <Route exact path="/rail" element={CmsTemplate(Rails)} />
            <Route exact path="/redis" element={CmsTemplate(Redis)} />
            <Route
              exact
              path="/systemConfig"
              element={CmsTemplate(SystemConfig)}
            />
            <Route
              exact
              path="/contributor"
              element={CmsTemplate(Contributor)}
            />
            <Route
              exact
              path="/systemSetting"
              element={CmsTemplate(SystemSetting)}
            />
            <Route
              exact
              path="/subscriptionGroup"
              element={CmsTemplate(SubscriptionGroup)}
            />
            <Route
              exact
              path="/product"
              element={CmsTemplate(SubscriptionProduct)}
            />
            <Route exact path="/marketo" element={CmsTemplate(Marketo)} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </RouterWrapper>
    </React.Suspense>
  )
}

export default WebRoutes
