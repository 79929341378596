import React, { useState } from "react"
import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import FormControl from "@mui/material/FormControl"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { StyledRankSlider } from "components/Style/Forms.style"
import { useNavigate } from "react-router-dom"
import { useSnackbar } from "notistack"
import { useForm } from "react-hook-form"
import { addProgramLevel, editProgramLevel } from "services/program"
import { StyledProgramDialog } from "./Program.style"

const ProgramLevelDialog = (props) => {
  const { onSubmit, id } = props

  let navigate = useNavigate()

  const initProgramLevel = {
    programId: id,
    levelName: "",
    rank: 0,
  }

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm()

  const { enqueueSnackbar } = useSnackbar()

  const [programLevel, setProgramLevel] = useState(initProgramLevel)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)

  const openDlg = (editProgramLevel) => {
    reset()
    setProgramLevel(
      editProgramLevel
        ? { ...editProgramLevel, levelName: editProgramLevel.name }
        : initProgramLevel
    )
    setValue("levelRank", editProgramLevel ? editProgramLevel.rank : 0)
    setIsEditMode(editProgramLevel ? true : false)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleAction = async (data) => {
    setLoading(true)
    const response = isEditMode
      ? await editProgramLevel({
          ...data,
          programId: initProgramLevel.programId,
          id: programLevel.id,
        })
      : await addProgramLevel({
          ...data,
          programId: initProgramLevel.programId,
        })

    if (response.data.rtnCode === 401) {
      localStorage.clear()
      navigate("/login")
    }

    if (response.data.rtnCode === 200) {
      enqueueSnackbar(
        isEditMode ? "Update Level Success" : "Create Level Success",
        { variant: "success" }
      )
    } else {
      enqueueSnackbar(isEditMode ? "Update Level Fail" : "Create Level Fail", {
        variant: "warning",
      })
    }
    setLoading(false)
    onSubmit()
    setOpen(false)
  }

  const dlg = (
    <StyledProgramDialog open={open} fullWidth={true}>
      <form onSubmit={handleSubmit(handleAction)}>
        <DialogTitle>
          {isEditMode ? "Edit Program Level" : "Add Program Level"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              autoFocus
              autoComplete="off"
              margin="dense"
              id="program-level-name"
              label="Level Name"
              fullWidth
              defaultValue={programLevel.levelName}
              {...register("levelName", { required: true })}
              error={!!errors?.levelName}
              helperText={!!errors?.levelName ? "* Required" : " "}
              variant="standard"
            />

            <Box sx={{ width: 300 }}>
              <Typography className="control-label" gutterBottom>
                Level Rank {watch("levelRank")}
              </Typography>
              <StyledRankSlider
                aria-label="program-level-rank"
                getAriaValueText={(value) => {
                  return `${value}`
                }}
                defaultValue={programLevel.rank}
                {...register("levelRank", { required: true })}
                step={1}
                min={0}
                max={10}
              />
            </Box>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            close
          </Button>
          <Button type="submit" autoFocus>
            {isEditMode ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </form>
    </StyledProgramDialog>
  )

  return [openDlg, dlg, loading]
}

export default ProgramLevelDialog
