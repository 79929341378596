import { httpPost, httpGet } from "utils/httpRequest";

const getAllContributor = async () => {
  const resp = await httpPost("admin/contributor/list", {
    pageNo: 1,
    pageSize: 9999,
  });
  return resp.data;
};

const searchContributor = async ({ pageNo, pageSize, userName, email }) => {
  const resp = await httpPost("admin/contributor/list", {
    pageNo,
    pageSize,
    userName,
    email,
  });
  return resp.data;
};

const addContributor = async ({
  userName,
  email,
  intro,
  address,
  tag,
  portraitAwskey,
  portraitAwsbucket,
  coverAwskey,
  coverAwsbucket,
}) => {
  const resp = await httpPost("admin/contributor/add", {
    userName,
    email,
    intro,
    address,
    tag,
    portraitAwskey,
    portraitAwsbucket,
    coverAwskey,
    coverAwsbucket,
  });
  return resp.data;
};

const editContributor = async ({
  id,
  userName,
  email,
  intro,
  address,
  tag,
  portraitAwskey,
  portraitAwsbucket,
  coverAwskey,
  coverAwsbucket,
}) => {
  const resp = await httpPost("admin/contributor/edit", {
    id,
    userName,
    email,
    intro,
    address,
    tag,
    portraitAwskey,
    portraitAwsbucket,
    coverAwskey,
    coverAwsbucket,
  });
  return resp.data;
};

export {
  getAllContributor,
  searchContributor,
  addContributor,
  editContributor,
};
