import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const UseConfirmDialog = ({ title, description, agreeText, disagreeText }) => {
  const [open, setOpen] = React.useState(false);
  const [customDescription, setCustomDescription] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const callbackAction = useRef(null);

  const openDialog = (callback, customDesc) => {
    callbackAction.current = callback;
    setCustomDescription(customDesc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCustomDescription(null);
  };

  const handleAgree = () => {
    callbackAction.current();
    setOpen(false);
  };

  const ConfirmDialog = (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {customDescription ? customDescription : description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          {disagreeText || "Disagree"}
        </Button>
        <Button onClick={handleAgree} autoFocus>
          {agreeText || "Agree"}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return [ConfirmDialog, openDialog];
};

export default UseConfirmDialog;
